import React, {useEffect} from 'react';
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";


export default function ReviewPhotos({images, onClick}) {
    const [photos, setPhotos] = React.useState([]);

    useEffect(() => {
        if(images){
            let list = [];
            images.forEach(img=>{

                list.push({src: `${process.env.REACT_APP_CDN_BASE_URL}/${img.key}`, width: img.originWidth, height: img.originHeight})
            });
            setPhotos(list);
        }
    },[images]);

    return (
        <Box>
            {photos.length > 0 &&
                <Box display={"flex"}>
                    {photos.map((value, index) => (
                        <Box mr={1} key={`image-${index}`} onClick={({ index }) => {
                            if(onClick){
                                onClick(index,images);
                            }
                        }}>
                            <Avatar variant="square" src={value.src} />
                        </Box>
                    ))}
                </Box>
            }
        </Box>

    )
};