import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import NumberFormat from 'react-number-format';
import {TextI18n} from "../../TextI18n";
import {TextI18nTruncate} from "../../TextI18nTruncate";
import ListItemButton from "@mui/material/ListItemButton";
import FavoriteIcon from '@mui/icons-material/Favorite';

const shapeInvertStyles = { backgroundColor: '#fff', padding: '0 8px 0 8px', color: '#1abc9c', fontSize: '1 rem' };

const useStyles = makeStyles({
    root: {},
    link: {
        color: '#1abc9c'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    qty: {
        minWidth: '1.5rem',
        textAlign: 'center'
    },
    avatar: {
        backgroundColor: 'none'
    }
});

const selectedBox = () => {
    return (
        <FavoriteIcon color={"secondary"} />
    )
};


export default function ReviewProductList({list, recommends, onSubmit}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [productGroups] = useState(list);
    const [recommendList, setRecommendList] = useState([]);

    console.log('[ProductList]');

    useEffect(() => {
        if(recommends && recommends.length > 0){
            // setRecommendList(recommends);
            let temps = [];
            recommends.forEach(r=>{
                temps.push(r);
            });
            if(temps.length > 0){
                setRecommendList(temps);
            }
        }
    }, [recommends]);

    const handleClick = (product) => {
        const idx = recommendList.findIndex(object => {
            return object._id === product._id;
        });

        if(idx !== -1){
            recommendList.splice(idx, 1);
            setRecommendList([...recommendList])
        } else {
            if(recommendList.length > 4){
                recommendList.splice(recommendList.length-1, 1);
            }
            setRecommendList([...recommendList, product])
        }

    };

    const handleSubmit = () => {
        onSubmit(recommendList);
    };

    return (
        <div className={classes.root}>
            {productGroups.length === 0 &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Typography>
                    {t('product.notFound')}
                </Typography>
            </Box>
            }
            {productGroups.length > 0 &&
            <Box>
                <Box pb={11}>
                    <List className="SortableList" subheader={<li />}>
                        {productGroups.map((productGroup, sectionId) => (
                            <li key={`section-${sectionId}`} className={classes.listSection}>
                                <ul className={classes.ul}>
                                    {productGroup.systemData === 0 &&
                                    <ListSubheader style={{
                                        zIndex: 0,
                                        background: 'linear-gradient(180deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0))'
                                    }}>
                                        <Box mt={3}>
                                            <Typography variant="h6" display="block" color={"black"}>
                                                <TextI18n value={productGroup.name}></TextI18n>
                                            </Typography>
                                        </Box>
                                    </ListSubheader>
                                    }
                                    {productGroup.products.map((value, index) => (
                                        <ListItemButton key={`item-${value._id}`}
                                                      divider={true}
                                                      onClick={e=>{handleClick(value)}}>
                                            <ListItemAvatar>
                                                <Avatar variant="square" sx={{ width: 96 , height: 96}} className={classes.avatar}>
                                                    <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={
                                                        value.images[0]?
                                                            `${process.env.REACT_APP_CDN_BASE_URL}/${value.images[0].permission}/merchant/m/${value.images[0].name}`: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`
                                                    }
                                                         alt={value.name.i18n['en']}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText style={{marginLeft: '10px'}}>
                                                <React.Fragment>
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="h6">
                                                            <TextI18nTruncate value={value.name} lines={2}></TextI18nTruncate>
                                                        </Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" justifyContent="space-between" >
                                                        <Box display="flex" alignItems="center">
                                                            <Box mr={1}>
                                                                <Typography color="caption">
                                                                    {t('product.price')}
                                                                </Typography>
                                                            </Box>
                                                            <Typography>
                                                                <strong><NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} /></strong>
                                                            </Typography>
                                                            <Box ml={1}>
                                                                <Typography color="caption">
                                                                    {t('common.baht')}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        {recommendList.findIndex(object => {
                                                            return object._id === value._id;
                                                        }) >-1 && selectedBox()}
                                                    </Box>
                                                </React.Fragment>
                                            </ListItemText>
                                        </ListItemButton>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </List>
                </Box>
                <Box>
                    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={0}>
                        <div style={{paddingBottom: '30px', backgroundImage: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.99))'}}>
                            <Box pl={1} pr={1}>
                                <Button variant="contained"
                                        size="large"
                                        fullWidth={true}
                                        onClick={handleSubmit}
                                        style={{zIndex: 99, fontWeight: 'bold', paddingTop: '12px', paddingBottom: '12px'}}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center"
                                         style={{fontSize: '1.2rem', width: '100%'}}>
                                        <span>{t('review.submitRecommend')}</span>
                                        <span style={shapeInvertStyles}>{recommendList.length}</span>
                                    </Box>
                                </Button>
                            </Box>
                        </div>
                    </Box>
                </Box>
            </Box>
            }
        </div>
    )
}