import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton";
import VerifiedIcon from "@mui/icons-material/Verified";
import ReportIcon from '@mui/icons-material/Report';
import VerifyDialog from "./VerifyDialog";
import ReportDialog from "./ReportDialog";

export default function Verified({shop}) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        console.log('[Verified]');
    }, []);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box>
            {shop.verified === 0 &&
            <>
                <Box display={"flex"} justifyContent={"center"}>
                    <IconButton aria-label="verified" onClick={handleOpen} >
                        <ReportIcon fontSize="large"  />
                    </IconButton>
                </Box>
                <Box sx={{marginTop: '-13px'}}>
                    <Typography variant={"caption"} sx={{fontSize: '11px'}}>{t('verify.report')}</Typography>
                </Box>
                <ReportDialog open={open} shop={shop} onClose={handleClose} />
            </>
            }
            {shop.verified === 1 &&
            <>
                <Box display={"flex"} justifyContent={"center"}>
                    <IconButton aria-label="verified" onClick={handleOpen} >
                        <VerifiedIcon fontSize="large" color={"info"} />
                    </IconButton>
                </Box>
                <Box sx={{marginTop: '-13px'}}>
                    <Typography variant={"caption"} sx={{color: '#0088d1', fontSize: '11px'}}>{t('common.verified')}</Typography>
                </Box>
                <VerifyDialog open={open} shop={shop} onClose={handleClose} />
            </>
            }
        </Box>
    );
}
