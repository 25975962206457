import React, {forwardRef, useContext, useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CardContent from '@mui/material/CardContent';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from "@mui/material/Stack";
import NumberFormat from "react-number-format";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import {StoreContext} from "../../../core/Context";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import HomeIcon from '@mui/icons-material/Home';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {httpClient} from "../../../core/HttpClient";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import {getProvinceI18n} from "../../TextI18n";
import {PROVINCES} from "../../../core/Static";
import DeliverUtil from "../../../core/DeliverUtil";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

function _validationItem(item) {
    if(!item){
        return false;
    }

    if(!item.name){
        return false;
    }
    if(!item.mobile ||
        item.mobile.length !== 10 ||
        !item.mobile.startsWith('0')
    ){
        return false;
    }

    if(!item.address){
        return false;
    }

    if(!item.province || item.province==='NONE'){
        return false;
    }

    if(!item.district || item.district==='NONE'){
        return false;
    }

    if(!item.subDistrict || item.subDistrict==='NONE'){
        return false;
    }

    if(!item.postalCode){
        return false;
    }

    return true;
}

export default function AddressSelection({list, open, shop, onClose}) {
    const { t, i18n } = useTranslation();
    const [mode, setMode] = useState('list');
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [addressList, setAddressList] = useState(list);
    const [address, setAddress] = useState({name: '', mobile: '', address: '', province: '', district: '',  subDistrict: '', postalCode: ''});
    const [error, setError] = useState({name: false, mobile: false, address: false, province: false, district: false,  subDistrict: false, postalCode: false});
    const [district, setDistrict] = useState([]);
    const [subDistrict, setSubDistrict] = useState([]);

    console.log('[AddressSelection]');

    useEffect(() => {
        console.log('AddressSelection');
    }, []);

    const handleClose = () => {
        onClose();
    };

    const handleBack = () => {
        setMode('list');
    };

    const handleClickCreate = () => {
        setAddress({name: '', mobile: '', address: '', province: '', district: '',  subDistrict: '', postalCode: ''});
        setMode('add');
    };

    const handleClickSelect = (item) => {
        let errors = storeContext.errors;
        let idx = errors.indexOf('deliverType');
        if(idx>-1){
            errors.splice(idx, 1);
        }

        let result = true;
        if(!item.address){
            result = false;
        }

        if(!item.province || item.province==='NONE'){
            result = false;
        }

        if(!item.district || item.district==='NONE'){
            result = false;
        }

        if(!item.subDistrict || item.subDistrict==='NONE'){
            result = false;
        }

        if(!item.postalCode){
            result = false;
        }

        if(result){

            let _profile = {
                key: item.key,
                name: item.name,
                mobile: item.mobile,
                address: item.address,
                province: item.province,
                district: item.district,
                subDistrict: item.subDistrict,
                postalCode: item.postalCode,
                addressInfo: item.addressInfo
            };

            let deliverPrice = new DeliverUtil().calculateDeliverPrice('PARCEL', shop, 0, _profile.district);
            setStoreContext({...storeContext, deliverType: 'PARCEL', deliverPrice: deliverPrice, profile: _profile, errors: errors});
            onClose();
        } else {
            setAddress({...address, ...item});
            setMode('enhance');
        }

    };

    const handleClickEdit = (item) => {
        if(item){
            if(item.province){
                const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/district/'+item.province;
                httpClient.get(url)
                    .then(res => {
                        if(res.data){
                            setDistrict(res.data);
                        }
                    });
            }
            if(item.district){
                const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/subdistrict/'+item.district;
                httpClient.get(url)
                    .then(res => {
                        if(res.data){
                            setSubDistrict(res.data);
                        }
                    });
            }
        }
        setAddress({...item});
        setMode('edit');
    };

    const handleChange = ({target})=>{
        let newProfile = {...address, [target.name]: target.value};
        setAddress(newProfile);
    };

    const handleReloadList = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAddressList(res.data);
                } else {
                    setAddressList([]);
                }
            });
    };

    const handleSave = () => {
        if(_validation()){

            let p = PROVINCES.find(obj => {
                return ''+obj.id === ''+address.province;
            });
            let d = district.find(obj => {
                return ''+obj.id === ''+address.district;
            });
            let s = subDistrict.find(obj => {
                return ''+obj.id === ''+address.subDistrict;
            });

            let addressInfo = address.address;
            if(p){
                addressInfo += ' '+p.name_th;
            }
            if(d && s){
                addressInfo += ', '+d.name_th+', '+s.name_th+' '+address.postalCode;
            }

            let _contact = {
                key: address.key,
                name: address.name,
                mobile: address.mobile,
                address: address.address,
                province: address.province,
                district: address.district,
                subDistrict: address.subDistrict,
                postalCode: address.postalCode,
                addressInfo: addressInfo
            };

            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
            let newAddress = {..._contact};
            httpClient.post(url, newAddress)
                .then(res => {
                    let data = res.data;
                    if(mode === 'edit'){
                        handleReloadList();
                        setMode('list');
                        if(storeContext.deliverType === 'PARCEL' && storeContext.profile && storeContext.profile.key === data.key){
                            setStoreContext({...storeContext, deliverType: 'PARCEL', profile: data});
                        }
                    } else {
                        if(storeContext.deliverType === 'PARCEL'){
                            setStoreContext({...storeContext, deliverType: 'PARCEL', profile: data});
                            onClose();
                        } else {
                            handleReloadList();
                            setMode('list');
                        }
                    }
                });

        }

    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address/'+address.key;
        httpClient.delete(url, { method: 'DELETE' })
            .then(res => {
                handleReloadList();
                setMode('list');
                if(storeContext.profile && storeContext.profile.key === address.key){
                    setStoreContext({...storeContext, deliverType: '', profile: {name: '', mobile: ''}});
                }
            });
    };

    function _validation() {
        let result = true;
        let _error = {name: false, mobile: false, address: false, province: false, district: false, subDistrict: false};
        if(!address.name){
            result = false;
            _error.name = true;
        }
        if(!address.mobile ||
            address.mobile.length !== 10 ||
            !address.mobile.startsWith('0')
        ){
            result = false;
            _error.mobile = true;
        }

        if(!address.address){
            result = false;
            _error.address = true;
        }

        if(!address.province || address.province==='NONE'){
            result = false;
            _error.province = true;
        }

        if(!address.district || address.district==='NONE'){
            result = false;
            _error.district = true;
        }

        if(!address.subDistrict || address.subDistrict==='NONE'){
            result = false;
            _error.subDistrict = true;
        }

        if(!address.postalCode){
            result = false;
            _error.postalCode = true;
        }

        if(!result){
            setError(_error);
        }

        return result;
    }

    const handleProvinceChange = ({ target }) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/district/'+target.value;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setDistrict(res.data);
                }
            });
        setDistrict([]);
        setSubDistrict([]);
        setAddress({ ...address, [target.name]: target.value, district: 'NONE', subDistrict: 'NONE'});
    };

    const handleDistrictChange = ({ target }) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/subdistrict/'+target.value;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setSubDistrict(res.data);
                }
            });
        setSubDistrict([]);
        setAddress({ ...address, [target.name]: target.value, subDistrict: 'NONE'});

    };

    const handleSubDistrictChange = ({ target }) => {
        let sd = subDistrict.find(obj => {
            return ''+obj.id === target.value
        });
        let pc = '';
        if(sd){
            pc = sd.zip_code;
        }
        setAddress({ ...address, [target.name]: target.value, postalCode: pc });
    };

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    {mode !== 'list' && <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleBack}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>}
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {mode === 'list'?t('address.selectAddress'):t('address.specify')}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div>
                {mode === 'list' &&
                    <>
                        <Box display="flex" justifyContent="center" mb={1} mt={2} pl={2} pr={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth={true}
                                disableElevation
                                startIcon={<AddIcon />}
                                onClick={handleClickCreate}>
                                {t('address.addAddress')}
                            </Button>
                        </Box>
                        <CardContent>
                            <Box>
                                {addressList && addressList.map(item => {

                                    let validData = _validationItem(item);

                                    return (
                                        <Box key={item._id} mb={2}>
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="edit" onClick={e=>handleClickEdit(item)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton sx={{border: '1px solid #e0e0e0', borderRadius: '3px'}} onClick={e=>handleClickSelect(item)}>
                                                    <ListItemAvatar>
                                                        {!validData && <ContactSupportIcon color={"default"} sx={{ fontSize: 30 }} />}
                                                        {validData && <HomeIcon color={"default"} sx={{ fontSize: 30 }} />}
                                                    </ListItemAvatar>
                                                    <ListItemText primary={item.name}
                                                                  secondaryTypographyProps={{ component: 'div' }}
                                                                  secondary={<Stack>
                                                                      <Typography><NumberFormat value={item.mobile} displayType={'text'} format="###-###-####" /></Typography>
                                                                      <Typography>{item.addressInfo}</Typography>
                                                                  </Stack>} />
                                                </ListItemButton>
                                            </ListItem>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </CardContent>
                    </>
                }
                {['add','edit', 'enhance'].indexOf(mode) > -1 && <Box mt={2}>
                        <CardContent>
                            <form autoComplete="false" noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField label={t('address.contact')}
                                                   fullWidth={true}
                                                   name="name"
                                                   value={address.name}
                                                   type="text"
                                                   error={error.name}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   required
                                                   onChange={handleChange}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField label={t('address.mobile')}
                                                   fullWidth={true}
                                                   name="mobile"
                                                   value={address.mobile}
                                                   type="tel"
                                                   InputProps={{
                                                       inputComponent: NumberFormatCustom,
                                                   }}
                                                   error={error.mobile}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   required
                                                   onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined"
                                                     fullWidth={true}
                                                     error={error.province}>
                                            <InputLabel id="province-label">{t('address.province')}</InputLabel>
                                            <Select
                                                native
                                                labelId="province-label"
                                                label={t('address.province')}
                                                name="province"
                                                value={address.province}
                                                error={error.province}
                                                onChange={handleProvinceChange}>
                                                <>
                                                    <option key={'NONE'} value={'NONE'}>
                                                        {t('address.pleaseSelectProvince')}
                                                    </option>
                                                    {
                                                        PROVINCES.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {getProvinceI18n(item, i18n)}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined"
                                                     fullWidth={true}
                                                     error={error.district}>
                                            <InputLabel id="district-label">{t('address.district')}</InputLabel>
                                            <Select
                                                native
                                                labelId="district-label"
                                                label={t('address.district')}
                                                name="district"
                                                value={address.district}
                                                error={error.district}
                                                onChange={handleDistrictChange}>
                                                <>
                                                    <option key={'NONE'} value={'NONE'}>
                                                        {t('address.pleaseSelectDistrict')}
                                                    </option>
                                                    {
                                                        district.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {getProvinceI18n(item, i18n)}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined"
                                                     fullWidth={true}
                                                     error={error.subDistrict}>
                                            <InputLabel id="subDistrict-label">{t('address.subDistrict')}</InputLabel>
                                            <Select
                                                native
                                                labelId="subDistrict-label"
                                                label={t('address.subDistrict')}
                                                name="subDistrict"
                                                value={address.subDistrict}
                                                error={error.subDistrict}
                                                onChange={handleSubDistrictChange}>
                                                <>
                                                    <option key={'NONE'} value={'NONE'}>
                                                        {t('address.pleaseSelectSubDistrict')}
                                                    </option>
                                                    {
                                                        subDistrict.map((item) => (
                                                            <option key={item.id} value={item.id}>
                                                                {getProvinceI18n(item, i18n)}
                                                            </option>
                                                        ))
                                                    }
                                                </>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <fieldset style={{
                                            border: error.postalCode?'1px solid #d32f2f':'1px solid rgba(0, 0, 0, 0.23)',
                                            paddingTop: '0px',
                                            borderRadius: '4px',
                                            fontSize: '12px',
                                            color: error.postalCode?'red': 'rgba(0, 0, 0, 0.6)'
                                        }}>
                                            <legend>{t('address.postalCode')}</legend>
                                            <Box m={1}>
                                                <Typography>
                                                    {address.postalCode}&nbsp;
                                                </Typography>
                                            </Box>
                                        </fieldset>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField label={t('address.title')}
                                                   fullWidth={true}
                                                   name="address"
                                                   value={address.address}
                                                   type="text"
                                                   error={error.address}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   required
                                                   onChange={handleChange}/>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                </Box>}
            </div>
            {['add','edit', 'enhance'].indexOf(mode) > -1 && <DialogActions>
                <Box mb={4} style={{width: '100%'}}>
                    <Button variant="contained"
                            size="large"
                            fullWidth={true}
                            onClick={handleSave}
                            disableElevation
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.ok')}</span>
                    </Button>
                    {mode === 'edit' && <Box mt={2}>
                        <Button variant="contained"
                                disableElevation
                                size="large"
                                color={"secondary"}
                                fullWidth={true}
                                onClick={handleDelete}
                                style={{zIndex: 99, fontWeight: 'bold'}}>
                            <span>{t('common.delete')}</span>
                        </Button>
                    </Box>}
                </Box>
            </DialogActions>}
        </Dialog>
    )
};