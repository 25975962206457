import React from 'react';
import Box from '@mui/material/Box';
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import ListItemButton from "@mui/material/ListItemButton";

export default function Documents() {
    const { t } = useTranslation();

    return (
        <Box >
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItemButton alignItems="flex-start" onClick={e=>{window.location.href = `/documents/menu`}} >
                    <ListItemAvatar>
                        <img alt="Remy Sharp" style={{maxWidth: '60px'}} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/user-guide.png`} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={t('document.packages.menuOnly')}
                        secondary={
                            <React.Fragment>
                                {t('document.packages.menuOnlyDescription')}
                            </React.Fragment>
                        }
                    />
                </ListItemButton>
                <Divider variant="inset" component="li" />
                <ListItemButton alignItems="flex-start" onClick={e=>{window.location.href = `/documents/standard`}}>
                    <ListItemAvatar>
                        <img alt="Travis Howard" style={{maxWidth: '60px'}} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/user-guide-advance.png`} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={t('document.packages.normal')}
                        secondary={
                            <React.Fragment>
                                {t('document.packages.normalDescription')}
                            </React.Fragment>
                        }
                    />
                </ListItemButton>
            </List>
        </Box>
    )
};