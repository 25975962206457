import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FooterMenuSlide from '../FooterMenuSlide/index';
import Divider from "@mui/material/Divider";

export default function Footer() {
    const { t } = useTranslation();

    return (
        <footer style={{backgroundColor: '#212121', color: '#9aa3ae'}}>
            <FooterMenuSlide />
            <Divider style={{'margin': '10px', borderColor: '#9aa3ae'}} />
            <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                <Typography variant="body1" component="div" sx={{ flexGrow: 1 }} align="center">{t('copyRight')}</Typography>
            </Box>
        </footer>
    )
};