import React, {useState, useEffect, useCallback} from 'react';
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import qs from 'qs';
import liff from "@line/liff";


const lineAuthentication = async(cb) => {
    const liffId = process.env.REACT_APP_LINE_LIFF_ID;
    try{
        // withLoginOnExternalBrowser = false (User don't have LINE App should not redirect to login's LINE)
        await liff.init({ liffId: liffId, withLoginOnExternalBrowser: false }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            const idToken = liff.getIDToken();
            const accessToken = liff.getAccessToken();
            return cb({idToken: idToken, accessToken: accessToken});
        }else{
            // external browser
            console.log('no logged in');
            return cb(null);
        }
    }catch(e){}
};

export default function Login(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [state, setState] = useState(true);

    const handleLogin = async(event) => {
        const liffId = process.env.REACT_APP_LINE_LIFF_ID;
        const cid = localStorage.getItem('cid');
        await liff.init({ liffId: liffId, withLoginOnExternalBrowser: false }).catch(err=>{throw err});
        if(cid){
            const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login?cid=${cid}`;
            liff.login({ redirectUri: redirectUri });
        } else {
            const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login`;
            liff.login({ redirectUri: redirectUri });
        }

    };

    const checkLoggedInProcess = useCallback(async (next) => {
        const liffId = process.env.REACT_APP_LINE_LIFF_ID;
        await liff.init({liffId: liffId, withLoginOnExternalBrowser: false}).catch(err => {
            throw err
        });
        if (liff.isLoggedIn()) {
            history.replace(`/hm/c/orders`);
        } else {
            next();
        }
    }, [history]);

    const tokenProcess = useCallback((mode, cid, rate, cart, reward, next) => {
        lineAuthentication(lineToken=> {
            if(lineToken){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({accessToken: lineToken.accessToken, idToken: lineToken.idToken, redirectUri: process.env.REACT_APP_BASE_URL+`/hm/login?cid=`+cid, cid: cid})
                };
                const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
                fetch(url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if(data.token){
                            localStorage.setItem('token', data.token);
                            let customer = {
                                displayName: data.displayName,
                                pictureUrl: data.pictureUrl
                            };
                            localStorage.setItem('customer', JSON.stringify(customer));
                            // if the item doesn't exist, return null
                            if(cid && mode && rate){
                                history.replace(`/hm/${mode}/${cid}/review?rate=${rate}`);
                            } else if(cid && mode && cart){
                                history.replace(`/hm/${mode}/${cid}/c`);
                            } else if(cid && mode && reward){
                                history.replace(`/hm/${mode}/${cid}/reward`);
                            } else if(cid && mode){
                                history.replace(`/hm/${mode}/${cid}`);
                            } else {
                                history.replace(`/hm/c/orders`);
                            }

                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('customer');
                            next();
                        }
                    });
            } else {
                next();
            }
        });
    }, [history]);

    useEffect(() => {
        console.log('[login]');
        const queryString = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        const cid = queryString.cid;
        const rate = queryString.rate;
        const cart = queryString.cart;
        const mode = queryString.mode;
        const reward = queryString.reward;
        tokenProcess(mode, cid, rate, cart, reward, function (){
            setState(false);
        });
    }, [history, props, tokenProcess, checkLoggedInProcess]);

    return (
        <div>
            {state &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!state &&
            <div>
                <Box m={2}>
                    <Box display="flex" justifyContent="center" p={4} sx={{background: '#0fa489'}}>
                        <img alt="FoodKub" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/foodkub_white.png`} width="100%" />
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                    <Button onClick={handleLogin}>{t('login.retry')}</Button>
                </Box>
            </div>
            }
        </div>
    )
}