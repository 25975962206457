import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {httpClient} from "../../../core/HttpClient";
import {useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import {ListItem} from "@mui/material";
import {getTextI18n} from "../../TextI18n";
import Button from "@mui/material/Button";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";


function RewardItemComponent(props) {
    return (
        <Box key={`item-${props.value._id}`}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar variant="square" sx={{ width: 96 , height: 96}} >
                        <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={
                            props.value.images[0]?
                                `${process.env.REACT_APP_CDN_BASE_URL}/${props.value.images[0].permission}/merchant/m/${props.value.images[0].name}`: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`
                        }
                             alt={props.value.name.i18n['en']}/>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText style={{marginLeft: '10px'}}>
                    <React.Fragment>
                        <Box display="flex" alignItems="center">
                            <Typography variant="h6" style={{fontSize: '1.15rem'}}>
                                {getTextI18n(props.value.name)}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" >
                            <Box display="flex" alignItems="center">
                                <Box mr={1}>
                                    <Typography color="caption">
                                        {getTextI18n(props.value.description)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{marginTop: '5px'}} display="flex" justifyContent="space-between" alignItems="center">
                            <Button color={"success"}
                                    variant="contained"
                                    disableElevation
                                    fullWidth={true}
                                    disabled={props.remaining < props.value.point}>
                                {props.value.point} {props.t('reward.points')}
                            </Button>
                        </Box>
                    </React.Fragment>
                </ListItemText>
            </ListItem>
            {props.divider &&
                <Divider />
            }
        </Box>
    );
}

export default function RewardItems({rewardPoint}) {
    const { t } = useTranslation();
    const { cid } = useParams();
    const [rewardItems, setRewardItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        console.log('[RewardItems]');
        let tasks = [
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/reward/items'),
        ];

        Promise.all(tasks).then(rs=> {
            if (rs[0] && rs[0].data) {
                setRewardItems(rs[0].data);
            }
            setLoading(false);
        });
    }, [cid]);

    return (
        <Box>
            {/*<Box m={1}>*/}
            {/*    <Typography variant={"h6"}>*/}
            {/*        {t('reward.items')}*/}
            {/*    </Typography>*/}
            {/*</Box>*/}
            <Divider style={{'marginBottom': '5px', 'marginTop': '5px'}} />
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <Box m={1}>
                    {rewardItems.length > 0 &&
                        <Box mt={2}>
                            <List>
                                {rewardItems.map((value, index) => <RewardItemComponent
                                    key={`item-${index}`}
                                    id={value._id}
                                    remaining={rewardPoint.remaining}
                                    divider={index !== rewardItems.length - 1}
                                    t={t}
                                    index={index} value={value}/>)}
                            </List>
                        </Box>
                    }
                    {rewardItems.length === 0 &&
                        <Box display="flex" justifyContent="center" mt={4}>
                            <Typography variant={"caption"}>
                                {t('reward.noItems')}
                            </Typography>
                        </Box>
                    }
                </Box>
            }
        </Box>
    )
};