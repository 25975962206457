import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import NumberFormat from 'react-number-format';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import {httpClient} from "../../../core/HttpClient";
import {TextI18n, getTextI18n} from "../../TextI18n";
import ListItemButton from "@mui/material/ListItemButton";

let MyAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo.png`;
    let name = '';
    if(value && value.name) {
        name = getTextI18n(value.name);
    }
    if(value && value.image) {
        imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/s/${value.image.name}`;
    }
    return (<Avatar
        variant="square"
        sx={{ width: 72, height: 72 }}
        alt={name}
        src={imageUrl}
    />)
};

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursorKey === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch, cancelToken) {
    dispatch({ type: "FETCH_START" });
    try {
        console.log('fetchHits', query.cursor);
        let url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/following?cursor='+query.cursor;
        httpClient.get(url,
            {cancelToken: cancelToken}
        ).then(res => {
            if(Array.isArray(res.data)){
                dispatch({ type: "FETCH_SUCCESS", payload: res.data, cursorKey: query.cursor });
            } else {
                dispatch({ type: "FETCH_FAILURE" });
            }
        }).catch(e=>{
            console.log('error', e);
            dispatch({ type: "FETCH_FAILURE" });
        });
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

function init() {
    return {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null,
    };
}

export default function CustomerShopHistory() {
    const { t } = useTranslation();
    const history = useHistory();
    const [query, setQuery] = useState({cursor: ''});
    const [{ hits, hasNext, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null,
    }, init);

    const debouncedFetchHits = useRef(
        _.debounce(
            (page, cancelToken) => fetchHits(page, dispatch, cancelToken),
            300
        )
    ).current;

    useEffect(() => {
        console.log('CustomerShopHistory');
        const { token } = httpClient.CancelToken.source();
        debouncedFetchHits(query, token);

    }, [debouncedFetchHits, query]);


    const handleFetchData = (cursor) =>{
        console.log('handleFetchData');
        setQuery({...query, cursor: cursor}); // trig to reload data by useReducer
    };

    const handleClickItem = (value)=>{
        history.push(`/hm/o/${value.cid}`);
    };


    return (
        <div>
            <Box display="flex" justifyContent="space-between" p={1} mt={1}>
                <Typography color="text.primary">{t('customer.following')}</Typography>
            </Box>
            <Divider />
            <Box>
                {(!hits[0] || (hits[0] && hits[0].items.length === 0)) && !isLoading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <Typography variant="body1" color="textSecondary"
                                sx={{fontSize: '0.85rem'}}>{t('customer.followingEmpty')}</Typography>
                </Box>
                }
                {hits[0] && hits[0].items.length > 0 &&
                <InfiniteScroll
                    dataLength={hits.length} //This is important field to render the next data
                    next={e=>handleFetchData(cursor)}
                    hasMore={hasNext}
                >
                    <Box pb={12}>
                        {hits.map((hit,index) => (
                            hit.items.map((value, index2) => (
                                <ListItemButton key={`item-${index}-${index2}`}
                                                divider={true}
                                                onClick={e=>handleClickItem(value)} >
                                    <ListItemAvatar>
                                        <MyAvatar value={value} />
                                    </ListItemAvatar>
                                    <ListItemText sx={{paddingLeft: '10px'}}>
                                        <TextI18n value={value.name}></TextI18n>
                                        <Box>
                                            <Box display="flex" justifyContent="space-between">
                                                <Box display="flex">
                                                    <Box mr={1} display="flex">
                                                        <Typography noWrap={true} color="textSecondary" sx={{fontSize: '0.85rem'}}>
                                                            {t('common.status')}
                                                        </Typography>
                                                    </Box>
                                                    {value.active === false && value.state !== 'disabled' &&
                                                        <Typography variant="body1" color="secondary" sx={{fontSize: '0.85rem'}}>
                                                            {t('shop.status.temporarilyClosed')}
                                                        </Typography>
                                                    }
                                                    {value.state === 'open' && value.active &&
                                                        <Typography variant="body1" color="primary" sx={{fontSize: '0.85rem'}}>
                                                            {t('shop.status.open')}
                                                        </Typography>
                                                    }
                                                    {value.state === 'close' && value.active &&
                                                        <Typography variant="body1" color="secondary" sx={{fontSize: '0.85rem'}}>
                                                            {t('shop.status.close')}
                                                        </Typography>
                                                    }
                                                    {value.state === 'disabled' &&
                                                        <Typography variant="body1" color="secondary" sx={{fontSize: '0.85rem'}}>
                                                            {t('shop.status.disabled')}
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography noWrap={true} color="textSecondary" sx={{fontSize: '0.85rem'}}>
                                                    {t('common.follower')} <NumberFormat value={value.royalties} displayType={'text'} thousandSeparator={true} /> {t('common.people')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </ListItemText>
                                </ListItemButton>
                            ))
                        ))}
                    </Box>
                </InfiniteScroll>
                }
            </Box>
            {isLoading &&
                <Box display="flex" justifyContent="center" mt={2}>
                    <CircularProgress size={20}/>
                </Box>
            }
        </div>
    )
};