import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Typography from "@mui/material/Typography";
import {TextI18nTruncate} from "../TextI18nTruncate";
import NumberFormat from "react-number-format";
import {useTranslation} from "react-i18next";
import {getCache} from "../../core/LocalStorageUtil";
import {useParams} from "react-router-dom";
import AvatarGroup from "@mui/material/AvatarGroup";
import Avatar from "@mui/material/Avatar";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import {CountNumber} from "../CountNumber";
import {httpClient} from "../../core/HttpClient";
import Chip from "@mui/material/Chip";

const shapeStyles = { backgroundColor: 'orange.main', padding: '0 8px 0 8px', color: '#fff', fontSize: '0.8rem' };

const selectedBox = (cart, value) => {
    let selectedValue = 0;

    if(cart && cart.products[value._id]){
        selectedValue = cart.products[value._id].qty;
    }

    return (
        <div>
            <Box hidden={selectedValue<1} sx={shapeStyles}>{selectedValue}</Box>
        </div>
    )
};

export default function TopRecommend({productGroups, likeProduct, mode, shop, onClick, onLikeChange}) {
    const { t } = useTranslation();
    const { cid } = useParams();
    const [recommends, setRecommends] = useState([]);
    let cacheData = getCache(cid);
    let cart = cacheData.cart;

    useEffect(() => {
        let list = [];
        let ids = [];
        if(shop.recommends){
            ids = shop.recommends.map(function(item) {
                return item['productId'];
            });
        }
        if(productGroups){
            productGroups.forEach(pg=>{
                pg.products.forEach(p=>{
                    if(ids.indexOf(p._id) > -1){
                        if(list.length < 9){
                            list.push(p);
                        }
                    }
                });
            });
        }

        list.sort((a, b)=>{
            let as = (!a.open || a.remaining < 1)?0:a.like;
            let bs = (!b.open || b.remaining < 1)?0:b.like;
            if ( as > bs ){
                return -1;
            } else if(as < bs){
                return 1;
            } else {
                return 0;
            }
        });
        setRecommends(list);
    },[productGroups, shop]);

    const handleClickOpen = (product) => {
        onClick(product)
    };

    const handleClickLike = (e,product) => {
        e.stopPropagation();

        let _like = product.like || 0;
        let _liked = false;
        if(likeProduct){
            if(likeProduct[product._id]){
                _like--;
            } else {
                _like++;
                _liked = true;
            }
            likeProduct[product._id] = _liked;
        }
        if(onLikeChange){
            onLikeChange({mode: 2, product: product, like: _liked, likeCount: _like});
        }

        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/like/product';
        httpClient.put(url, {accountId: product._account, productId: product._id, liked: _liked})
            .then(res => {
                // done
            }).catch(e=>{});
    };

    return (
        <Box>
            {recommends.length > 0 &&
                <Box sx={{backgroundColor: '#FFFFFF'}} pt={1} pb={2}>
                    <Box ml={1} mb={1} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                        <Typography variant={"h6"}>{t('review.top')}</Typography>
                        <Box ml={1}>
                            <AvatarGroup total={shop.recommendTotal} max={shop.recommendCustomers.length+1} sx={{
                                '& .MuiAvatar-root': { width: 26, height: 26, fontSize: 8 },
                            }}>
                                {shop && shop.recommendCustomers &&
                                    shop.recommendCustomers.map((item, i) => (
                                        <Avatar key={`customer-avatar-${i}`} alt="Customers" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${item}`} />
                                    ))
                                }
                            </AvatarGroup>
                        </Box>
                    </Box>
                    <Swiper
                        spaceBetween={8}
                        slidesPerView={recommends.length>2?2.2:recommends.length}
                        style={{zIndex: 0, WebkitTransform: 'translateZ(0)',
                            WebkitBackfaceVisibility: 'hidden'}}
                    >
                        {recommends.map((value, index) => (
                            <SwiperSlide key={`item-${value._id}-${index}`} style={{background: 'white', borderRadius: '4px', WebkitTransform: 'translateZ(0)',
                                WebkitBackfaceVisibility: 'hidden', border: '1px solid #e0e0e0', height: 'auto'}} onClick={e=>handleClickOpen(value)}>
                                <Box display={"flex"} justifyContent={"space-between"} flexDirection={"column"} sx={{height: '100%'}}>
                                    <Box sx={{opacity: (!value.open || value.remaining < 1)?0.35:1}}>
                                        <Box sx={{position: 'absolute'}}><Chip variant="outlined"
                                                                               icon={<ThumbUpAltIcon color="primary" fontSize="small" /> }
                                                                               label={`Recommend ${index+1}`} size="small" color="secondary" style={{borderRadius: '4px', backgroundColor: '#fdeded'}} ></Chip></Box>
                                        <Box>
                                            <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={
                                                value.images[0]?
                                                    `${process.env.REACT_APP_CDN_BASE_URL}/${value.images[0].permission}/merchant/m/${value.images[0].name}`: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo_16_9.png`
                                            } alt={value.name.i18n['en']}/>
                                        </Box>
                                        <Box display="flex" alignItems="center" mr={1} ml={1}>
                                            <Typography variant="h6" style={{fontSize: '1.15rem'}}>
                                                <TextI18nTruncate value={value.name} lines={2}></TextI18nTruncate>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{background: 'white', opacity: (!value.open || value.remaining < 1)?0.35:1}} pl={1} pr={1} pb={1}>
                                        <Box display="flex" justifyContent="space-between" >
                                            <Box display="flex" alignItems="center">
                                                <Box mr={1}>
                                                    <Typography color="caption">
                                                        {t('product.price')}
                                                    </Typography>
                                                </Box>
                                                <Typography>
                                                    <strong><NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} /></strong>
                                                </Typography>
                                                <Box ml={1}>
                                                    <Typography color="caption">
                                                        {t('common.baht')}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            {mode !== 'v' && selectedBox(cart, value)}
                                            {(!value.open || value.remaining < 1) &&
                                                <Typography variant="subtitle2" color="secondary">{t('product.outOfStock')}</Typography>
                                            }
                                        </Box>
                                        <Box sx={{marginTop: '5px'}} display="flex" justifyContent="space-between" alignItems="center">
                                            <Box>
                                                {value.recommend &&
                                                    <Box mr={1} sx={{ background: 'red', color: 'white', paddingLeft: '3px', paddingRight: '3px', borderRadius: '3px', fontSize:'0.7rem'}}>
                                                        {t('product.recommend')}
                                                    </Box>
                                                }
                                                {(!value.open || value.remaining < 1) &&
                                                    <Box><Typography variant="subtitle2" color="secondary">{t('product.outOfStock')}</Typography></Box>
                                                }
                                            </Box>
                                            <Box display="flex" justifyContent="end" alignItems="center" onClick={e=>handleClickLike(e, value)}>
                                                {(likeProduct && likeProduct[value._id]) ? <ThumbUpAltIcon color="primary" fontSize="small" /> : <ThumbUpOutlinedIcon color="default" fontSize="small" /> }

                                                <Typography variant="caption" style={{marginLeft: '3px'}}>
                                                    <CountNumber value={value.like} />
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </SwiperSlide>
                        ))}

                    </Swiper>
                </Box>
            }
        </Box>
    )
};