import React, {useState, useContext} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {StoreContext} from "../../core/Context";
import {httpClient} from "../../core/HttpClient";
import CartUtil from "../../core/CartUtil";

const useStyles = makeStyles({
    root: {},
    paymentButton: {
        padding: '1rem'
    }
});

export default function ConfirmMobileOrder({shop}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const history = useHistory();
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleConfirmOrder = () => {
        if(_validation()){
            setLoading(true);
            let cacheData = JSON.parse(localStorage.getItem('cache'));
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/order/submit';
            let data = {
                cart: cacheData.cart,
                contactProfile:  storeContext.profile,
                deliverType:  storeContext.deliverType,
            };
            httpClient.post(url, data)
                .then(res => {
                    if(res.data){
                        new CartUtil(cid).clearCart();
                        history.push({
                            pathname: `/hm/m/${cid}/p/${res.data.uid}`
                        });
                        setLoading(false);
                    }
                }).catch(e=>{
                setLoading(false);
            });
        }
    };

    const handleCloseError = () => {
        setError(false)
    };

    function _validation() {

        let list = [];

        if(['DINE-IN','TAKEAWAY'].indexOf(storeContext.deliverType) === -1){
            list.push('deliverType');
        }
        if(!storeContext.profile || !storeContext.profile.name || !storeContext.profile.mobile){
            list.push('profile');
        }

        setStoreContext({...storeContext, errors: list});
        setError(list.length>0);
        return list.length===0;
    }

    return (
        <div>
            <Box mb={2}>
                <Button
                    style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth={true}
                    size="large"
                    className={classes.confirmOrderButton}
                    onClick={handleConfirmOrder}
                    disabled={storeContext.paymentMethod === ''}
                >
                    {t('common.confirmOrder')}
                </Button>
            </Box>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
            <Snackbar open={error}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error">
                    {t('common.message.invalidRequireValue')}
                </Alert>
            </Snackbar>
        </div>
    );
}
