import React, {useState, useContext} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import NumberFormat from 'react-number-format';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {AttachmentPaySlip} from "../Upload/AttachmentPaySlip";
// import ContactDialog from '../../components/Profile/ContactDialog'
import {Context, StoreContext, useOnlineContext} from "../../core/Context";
import {httpClient} from "../../core/HttpClient";
import {QRCode} from "react-qrcode-logo";
import dayjs from 'dayjs';
import locale_th from 'dayjs/locale/th';
import CartUtil from "../../core/CartUtil";
import CartRequestPaymentDialog from "../Cart/CartRequestPaymentDialog";
import PriceUtil from "../../core/PriceUtil";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles({
    root: {},
    paymentButton: {
        padding: '1rem'
    },
    dialog: {
        "& .MuiDialog-container .MuiDialog-paper": {
            margin: "0px",
            width: "calc(100% - 14px)",
            maxWidth: "calc(100% - 14px)",
            borderRadius: 0
        },
    },
    BBL: {backgroundColor: '#264091', padding: '5px', borderRadius: '6px'},
    KBANK: {backgroundColor: '#039029', padding: '5px', borderRadius: '6px'},
    KTB: {backgroundColor: '#06a4e7', padding: '5px', borderRadius: '6px'},
    TTB: {backgroundColor: '#ffffff', padding: '5px', borderRadius: '6px'},
    SCB: {backgroundColor: '#462279', padding: '5px', borderRadius: '6px'},
    BAY: {backgroundColor: '#fec523', padding: '5px', borderRadius: '6px'},
    KKP: {backgroundColor: '#635f98', padding: '5px', borderRadius: '6px'},
    CIMBT: {backgroundColor: '#ff0000', padding: '5px', borderRadius: '6px'},
    TISCO: {backgroundColor: '#1369b0', padding: '5px', borderRadius: '6px'},
    UOBT: {backgroundColor: '#07367a', padding: '5px', borderRadius: '6px'},
    GHB: {backgroundColor: '#ff3a00', padding: '5px', borderRadius: '6px'},
    GSB: {backgroundColor: '#eb077e', padding: '5px', borderRadius: '6px'},
    ISBT: {backgroundColor: '#073e04', padding: '5px', borderRadius: '6px'}
});

let CART_REQUEST_TIME = process.env.REACT_APP_CART_REQUEST_TIME;

const renderGrandTotalPriceAfterDiscount = (deliverType, total, discount, deliveryPrice) => {
    let result = new PriceUtil().getGrandTotalPriceAfterDiscount(deliverType, total, discount, true, deliveryPrice);
    return (
        <NumberFormat
            value={result}
            displayType={'text'}
            thousandSeparator={true} prefix={'฿'}/>);
};

export default function ConfirmOrder({shop}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const [onlineContext] = useOnlineContext();
    const history = useHistory();
    const [context] = useContext(Context);
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [error, setError] = useState(0);
    const [open, setOpen] = useState(false);
    const [openRequestPayment, setOpenRequestPayment] = useState(false);
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [openContact, setOpenContact] = useState(false);
    const [attachment, setAttachment] = useState(null);
    const [qrPayment, setQRPayment] = useState({
        qrValue: '',
        accountName: '',
        accountNumber: ''
    });

    const handleConfirmOrder = () => {
        if(shop.state === "close" && shop.orderingType !== 'preorder'){
            setError(2);
            new CartUtil(cid).clearCart();
            history.push({
                pathname: `/hm/o/${cid}`
            });
            return;
        }

        if(_validation()){
            setLoading(true);
            let url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/order/validate';
            httpClient.get(url)
                .then(res => {
                    if(res.status === 200){
                        if(storeContext.paymentMethod === 'qrPayment'){
                            let grandTotal = new PriceUtil().getGrandTotalPriceAfterDiscount(storeContext.deliverType, context.paymentAmount, shop.discount, true, context.deliveryPrice);
                            let data = {
                                amount: grandTotal
                            };

                            const url = process.env.REACT_APP_API_BASE_URL + '/secure/account/payment/qr';
                            httpClient.post(url, data)
                                .then(res => {
                                    if(res.data){
                                        setQRPayment(res.data);
                                    }
                                    setLoading(false);
                                    if(onlineContext === 1 || dayjs().diff(onlineContext, 'minute', false) < CART_REQUEST_TIME){
                                        setAttachment(null);
                                        setOpen(true);
                                    } else {
                                        setOpenRequestPayment(true);
                                        let url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/order/request';
                                        let data = {};
                                        httpClient.post(url, data)
                                            .then(res => {
                                                // done
                                            }).catch(e=>{
                                            console.log(e);
                                        });
                                    }
                                }).catch(e=>{
                                console.log(e);
                                setLoading(false);
                            });
                        } else {
                            if(onlineContext === 1 || dayjs().diff(onlineContext, 'minute', false) < CART_REQUEST_TIME){
                                setAttachment(null);
                                setOpen(true);
                                setLoading(false);
                            } else {
                                setOpenRequestPayment(true);
                                let url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/order/request';
                                let data = {};
                                httpClient.post(url, data)
                                    .then(res => {
                                        // done
                                        setLoading(false);
                                    }).catch(e=>{
                                    console.log(e);
                                    setLoading(false);
                                });
                            }
                        }
                    } else {
                        if(res.data && res.data.code === "DTA_011"){
                            setError(3);
                        } else {
                            setError(9);
                        }
                        setLoading(false);
                    }
                }).catch(e=>{
                console.log(e);
                setLoading(false);
            });

        }
    };

    const handleCopy = () => {
        setCopied(true);
    };

    const handleCloseCopied = () => {
        setCopied(false);
    };

    const handleConfirmPayment = () => {
        console.log('storeContext.paymentMethod', storeContext.paymentMethod);
        if(['fundsTransfer', 'qrPayment'].indexOf(storeContext.paymentMethod) > -1 && attachment){
            let paymentData = {
                method: storeContext.paymentMethod,
                attachment: attachment
            };
            submitConfirmOrder(paymentData);
        } else if(storeContext.paymentMethod === 'cash') {
            let paymentData = {
                method: storeContext.paymentMethod
            };
            submitConfirmOrder(paymentData);
        } else {
            let paymentData = {
                method: null
            };
            submitConfirmOrder(paymentData);
        }
    };

    const handleCloseError = () => {
        setError(0)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseRequestPayment = (value) => {
        console.log('handleCloseRequestPayment', value);
        setOpenRequestPayment(false);
        if(value){
            setOpen(true);
        }
    };

    function _validation() {
        let availablePayment = false;
        if(shop && shop.payment){
            availablePayment = (shop.payment.cash || shop.payment.fundsTransfer || shop.payment.qrPayment);
        }

        let list = [];

        if(!storeContext.deliverType){
            list.push('deliverType');
        }

        if(availablePayment){
            if(!storeContext.paymentMethod){
                list.push('paymentMethod');
            }
        }

        if(shop.deliveryType === 'round'){
            if(storeContext.deliverType === 'DELIVERY'){
                if(!storeContext.deliveryTime){
                    list.push('deliverTime');
                }
            } else {
                if(!storeContext.pickupTime){
                    list.push('deliverTime');
                }
            }
        } else if(shop.state === 'close' || storeContext.deliverType === 'PICKUP'){
            if(storeContext.deliverType === 'DELIVERY'){
                if(!storeContext.deliveryTime){
                    list.push('deliverTime');
                }
            } else {
                if(!storeContext.pickupTime){
                    list.push('deliverTime');
                }
            }
        } else if(shop.state === 'close' && shop.orderingType === 'preorder'){
            if(storeContext.deliverType === 'DELIVERY'){
                if(!storeContext.deliveryTime){
                    list.push('deliverTime');
                }
            } else {
                if(!storeContext.pickupTime){
                    list.push('deliverTime');
                }
            }
        }


        if(storeContext.deliverType === 'DELIVERY' && storeContext.outOfDelivery){
            list.push('outOfDelivery');
        }

        setStoreContext({...storeContext, errors: list});
        if(list.length>0){
            setError(1);
        }

        return list.length===0;
    }

    function submitConfirmOrder(paymentData) {
        setLoading(true);
        setOpen(false);
        let cacheData = JSON.parse(localStorage.getItem('cache'));

        let deliverDateTime = null;
        let deliverDate = null;
        let deliverTime = null;
        if(storeContext.deliverType === 'DELIVERY'){
            deliverDate = storeContext.deliveryDate;
            deliverTime = storeContext.deliveryTime;
        } else {
            deliverDate = storeContext.pickupDate;
            deliverTime = storeContext.pickupTime;
        }
        if(deliverDate && deliverDate.value){
            if(deliverTime && deliverTime.value){
                let hour = deliverTime.value.hour;
                let minute = deliverTime.value.minute;
                let ddt = dayjs(deliverDate.value).startOf('day').add(hour, 'hours').add(minute, 'minutes').locale(locale_th).toDate();
                deliverDateTime = ddt;
            }
        }

        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/order/confirm';
        let data = {
            cart: cacheData.cart,
            contactProfile:  storeContext.profile,
            deliverType: storeContext.deliverType,
            deliverDateTime: deliverDateTime,
            payment: {
                method: paymentData.method,
                attachment: paymentData.attachment
            }
        };

        if(['fundsTransfer', 'qrPayment'].indexOf(paymentData.method) > -1){
            data.payment.payDate = new Date();
        }

        httpClient.post(url, data)
            .then(res => {
                if(res.status === 200){
                    if(res.data){
                        new CartUtil(cid).clearCart();
                        history.push({
                            pathname: `/hm/o/${cid}`
                        });
                    }
                } else {
                    if(res.data && res.data.code === "DTA_011"){
                        setError(3);
                    } else {
                        setError(9);
                    }
                }
                setLoading(false);
            }).catch(e=>{
                setLoading(false);
                setOpen(true);
            });
    }

    // console.log('context', context);

    return (
        context.discount?
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant={"h6"}>
                    {t('payment.amount')}
                </Typography>
                <Box display={"flex"} alignItems={"baseline"}>
                    <Typography variant={"h6"}>
                        {renderGrandTotalPriceAfterDiscount(storeContext.deliverType, context.paymentAmount, shop.discount, context.deliveryPrice)}
                    </Typography>
                </Box>
            </Box>
            <Box mb={2}>
                <Button
                    style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth={true}
                    size="large"
                    onClick={handleConfirmOrder}
                    disabled={storeContext.paymentMethod === ''}
                >
                    {t('common.orderNow')}
                </Button>
            </Box>
            {openRequestPayment &&
            <CartRequestPaymentDialog online={onlineContext} open={openRequestPayment} onClose={handleCloseRequestPayment} />
            }
            <Dialog open={open}
                    className={classes.dialog}
                    sx={{marginTop: '8px', marginBottom: '8px'}}
                    scroll="body"
                    fullWidth={true}
                    // fullScreen={true}
                    onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        backgroundColor: '#FFFFFF',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {storeContext.paymentMethod === 'cash' &&
                <>
                    <Box display="flex" justifyContent="center" alignItems="center" m={2} mt={4}>
                        <Box sx={{color: 'red', border: '1px solid red', borderRadius: '5px'}} p={2}>
                            ** {['DELIVERY','PARCEL'].indexOf(storeContext.deliverType) > -1?t('payment.cashDescription'):t('payment.cashDescriptionPickup')} **
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} ml={2} mr={2}>
                        <Typography variant={"h6"}>
                            {t('payment.amount')}
                        </Typography>
                        <Typography variant={"h6"}>
                            {renderGrandTotalPriceAfterDiscount(storeContext.deliverType, context.paymentAmount, shop.discount, context.deliveryPrice)}
                        </Typography>
                    </Box>
                </>
                }
                {storeContext.paymentMethod === 'fundsTransfer' &&
                <>
                    {storeContext.fundsTransferList && storeContext.fundsTransferList[0] &&
                        <Box>
                            <Box mt={4} mb={2} display={"flex"} justifyContent={"center"}>
                                <img style={{width: '72px'}} className={classes[storeContext.fundsTransferList[0].bank]} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/banks/${storeContext.fundsTransferList[0].bank}.svg`} alt={storeContext.fundsTransferList[0].code} />
                            </Box>
                            <Box mb={2} display={"flex"} justifyContent={"center"}>
                                <Typography>
                                    {t(`banks.${storeContext.fundsTransferList[0].bank}`)}
                                </Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={"center"} style={{color: 'gray'}}>
                                <Typography variant={"body1"}>
                                    {t(`payment.accountNumber`)}
                                </Typography>
                            </Box>
                            <CopyToClipboard text={storeContext.fundsTransferList[0].accountNumber} onCopy={() => handleCopy()}>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                    <Typography variant="h6" noWrap={true}>
                                        <strong><NumberFormat value={storeContext.fundsTransferList[0].accountNumber} displayType={'text'} format="###-#-#####-#####" /></strong>
                                    </Typography>
                                    <IconButton id='fundsTransfer' edge="end" aria-label="copy">
                                        <ContentCopyIcon />
                                    </IconButton>
                                </Box>
                            </CopyToClipboard>
                            <Box mt={1} display={"flex"} justifyContent={"center"} style={{color: 'gray'}}>
                                <Typography variant={"body1"}>
                                    {t(`payment.accountName`)}
                                </Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={"center"}>
                                <Typography variant="h6">
                                    {storeContext.fundsTransferList[0].accountName}
                                </Typography>
                            </Box>
                        </Box>
                    }
                    <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                        <Typography variant="caption" display="block" gutterBottom style={{color: 'gray'}}>
                            {t('payment.fundsTransferDetail')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} ml={2} mr={2}>
                        <Typography variant={"h6"}>
                            {t('payment.amount')}
                        </Typography>
                        <Typography variant={"h6"}>
                            {renderGrandTotalPriceAfterDiscount(storeContext.deliverType, context.paymentAmount, shop.discount, context.deliveryPrice)}
                        </Typography>
                    </Box>
                    <Box m={2}>
                        <AttachmentPaySlip value={attachment} onChange={setAttachment} />
                    </Box>
                </>
                }
                {storeContext.paymentMethod === 'qrPayment' &&
                <>
                    <Box>
                        <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/thai_qr_payment_header.jpg`} style={{width: '100%'}} alt={"qrPayment"} />
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <QRCode value={qrPayment.qrValue}
                                size={230}
                                ecLevel={"L"}
                                logoImage={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/thai_qr_payment_logo_60.png`} />
                    </Box>
                    <Box display="flex" justifyContent="center" flexDirection={"column"} alignItems="center" m={2}>
                            {qrPayment.qrPaymentType === 'promptPayAccount' &&
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Typography variant="body1" display="block" gutterBottom>
                                        {t('payment.accountNumber')} : {qrPayment.accountNumber}
                                    </Typography>
                                </Box>
                            }
                            {qrPayment.qrPaymentType === 'qrPaymentAccount' &&
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Typography variant="body1" display="block" gutterBottom>
                                        {t('payment.accountRef')} : {qrPayment.accountNumber}
                                    </Typography>
                                </Box>
                            }
                            <Box display="flex" justifyContent="center" alignItems="center" >
                                <Typography variant="body1" display="block" gutterBottom>
                                    {t('payment.accountName')} : {qrPayment.accountName}
                                </Typography>
                            </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                        <Typography variant="caption" display="block" gutterBottom style={{color: 'gray'}}>
                            {t('payment.promptPayDetail')}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} ml={2} mr={2}>
                        <Typography variant={"h6"}>
                            {t('payment.amount')}
                        </Typography>
                        <Typography variant={"h6"}>
                            {renderGrandTotalPriceAfterDiscount(storeContext.deliverType, context.paymentAmount, shop.discount, context.deliveryPrice)}
                        </Typography>
                    </Box>
                    <Box m={2}>
                        <AttachmentPaySlip value={attachment} onChange={setAttachment} />
                    </Box>
                </>
                }
                <Box m={2}>
                    <Button variant="contained"
                            color="success"
                            style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                            size="large"
                            onClick={handleConfirmPayment}
                            fullWidth={true}
                            disabled={['fundsTransfer', 'qrPayment'].indexOf(storeContext.paymentMethod) > -1 && attachment === null}>
                        {t('payment.confirmOrder')}
                    </Button>
                </Box>
                <Box m={2}>
                    <Button variant="contained"
                            color="grey"
                            disableElevation={true}
                            onClick={handleClose}
                            fullWidth={true}>
                        {t('common.cancel')}
                    </Button>
                </Box>
            </Dialog>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
            <Snackbar open={copied}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseCopied}>
                <Alert onClose={handleCloseCopied} severity="info">
                    {t('payment.copiedBankAccount')}
                </Alert>
            </Snackbar>
            <Snackbar open={error > 0}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="error">
                    {error === 1 && t('common.message.invalidRequireValue')}
                    {error === 2 && t('common.message.shopWasClosed')}
                    {error === 3 && t('order.limit')}
                    {error === 9 && t('common.message.somethingWrong')}
                </Alert>
            </Snackbar>
        </div>:null
    );
}
