import React, {useCallback, useEffect, useState} from 'react';
import { Route, useParams } from 'react-router-dom';
import { Context, StoreContext, OnlineContext } from "../../core/Context";
import {httpClient} from "../../core/HttpClient";
import AlertMessage from "../../components/AlertMessage";
import Box from "@mui/material/Box";
import OrdersTracking from "../../components/OrdersTracking";
import {getCache, saveCache} from "../../core/LocalStorageUtil";
import "../../core/Firebase";
import {getDatabaseInstance} from "../../core/Firebase";
import {getAuth, signInAnonymously} from "firebase/auth";
import {getDatabase, goOffline, goOnline, onValue, ref, remove} from "firebase/database";
import Base64MongoId from "base64-mongo-id";
import dayjs from "dayjs";
import {useIdleTimer} from "react-idle-timer";
import jwt_decode from "jwt-decode";
import liff from "@line/liff";
import CircularProgress from "@mui/material/CircularProgress";

// const liff = window.liff;

const lineAuthentication = async(cb) => {
    const liffId = process.env.REACT_APP_LINE_LIFF_ID;
    try{
        // withLoginOnExternalBrowser = false (User don't have LINE App should not redirect to login's LINE)
        await liff.init({ liffId: liffId, withLoginOnExternalBrowser: false }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            const idToken = liff.getIDToken();
            const accessToken = liff.getAccessToken();
            return cb({idToken: idToken, accessToken: accessToken});
        }else{
            // external browser
            console.log('no logged in');
            return cb(null);
        }
    }catch(e){}
};

const OrderingLayout = ({ children}) => {
    const { cid } = useParams();
    const [context, setContext] = useState({cid: cid});
    const [onlineContext, setOnlineContext] = useState(-1);
    const [currentMode, setCurrentMode] = useState('v');
    const [accountStatus, setAccountStatus] = useState({status: '', verified: 1, expiredDay: 0});
    const [storeContext, setStoreContext] = useState({
        deliverType: '',
        profile: {name: '', mobile: '', landmark: ''},
        errors: []
    });
    const [loading, setLoading] = useState(true);
    let cacheData = getCache(cid);
    let visited = cacheData.visited;
    if(!visited){
        cacheData.visited = true;
        saveCache(cacheData);
    }

    const tokenProcess = useCallback((mode, next) => {
        lineAuthentication(lineToken=> {
            if(lineToken && cid){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({accessToken: lineToken.accessToken, idToken: lineToken.idToken, redirectUri: process.env.REACT_APP_BASE_URL+`/hm/login?cid=`+cid, cid: cid})
                };
                const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
                fetch(url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if(data.token){
                            localStorage.setItem('token', data.token);
                            let customer = {
                                displayName: data.displayName,
                                pictureUrl: data.pictureUrl
                            };
                            localStorage.setItem('customer', JSON.stringify(customer));
                            next();
                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('customer');
                            next();
                        }
                    });
            } else {
                // don't have LINE should not to login page
                // window.location = `/hm/login`;
                next();
            }
        });
    }, [cid]);

    useEffect(() => {
        console.log('[OrderingLayout]');

        // save path history for refresh token
        let mode = 'v';
        let urlPath = window.location.pathname;
        if(urlPath){
            if(urlPath.startsWith('/hm')){
                mode = urlPath.charAt(4);
                localStorage.setItem('mode', mode);
            }
        }

        setCurrentMode(mode);

        // get customer token by line
        let token = localStorage.getItem('token');
        if(!token){
            tokenProcess(mode, function (){
                setLoading(false);
            });
        } else {
            let decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000){
                console.log('expired');
                tokenProcess(mode, function (){
                    setLoading(false);
                });
            } else {
                console.log('not expired');
                const liffId = process.env.REACT_APP_LINE_LIFF_ID;
                liff.init({ liffId: liffId, withLoginOnExternalBrowser: false }).then(()=>{
                    setLoading(false);
                }).catch(err=>{throw err});
            }
        }

        if(cid){
            try{
            const app = getDatabaseInstance(cid);
            const auth = getAuth(app);
            signInAnonymously(auth)
                .then(() => {
                    const db = getDatabase(app);
                    let accountId = Base64MongoId.toHex(cid);
                    let key = 'accounts/'+accountId+'/online';
                    const statusRef = ref(db, key);
                    onValue(statusRef, (snapshot) => {
                        const data = snapshot.val();
                        if (data) {
                            if(data.users){
                                setOnlineContext(1); // 1 = online

                                // remove old data more than 24 hours
                                Object.keys(data.users).forEach((key) => {
                                    let h = dayjs().diff(data.users[key], 'hours', true);
                                    if(h > 24){
                                        let removeKey = 'accounts/'+accountId+'/online/users/'+key;
                                        remove(ref(db, removeKey)).catch();
                                    }
                                });
                            } else {
                                setOnlineContext(data.last);
                            }
                        }
                    });

                    // update visitor
                    if(!visited){
                        if(auth.currentUser && auth.currentUser.uid){
                            const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/view';
                            httpClient.put(url, {cid: cid, uid: auth.currentUser.uid})
                                .then(res => {
                                    // done
                                }).catch(e=>{});

                            const reachUrl = process.env.REACT_APP_API_BASE_URL + '/private/customer/reach';
                            httpClient.put(reachUrl, {cid: cid, uid: auth.currentUser.uid})
                                .then(res => {
                                    // done
                                }).catch(e=>{});
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });

            } catch (e){
                window.location = `/error`;
            }
        }

        localStorage.setItem('cid', cid);

        // current account
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/status';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAccountStatus({status: res.data.status, verified: res.data.verified, expiredDay: res.data.expiredDay});
                }
            }).catch(e=>{
            console.log(e);
        });

    },[cid, visited, tokenProcess]);

    const onAction = (event) => {
        if(['visibilitychange','focus'].indexOf(event.type) > -1){
            console.log('visibilityState', document.visibilityState);
            if(document.visibilityState === 'hidden'){
                const app = getDatabaseInstance(cid);
                const db = getDatabase(app);
                goOffline(db);
            } else if(document.visibilityState === 'visible'){
                const app = getDatabaseInstance(cid);
                const db = getDatabase(app);
                goOnline(db);
            }
        }
    }

    useIdleTimer({
        onAction,
        timeout: 10_000,
        throttle: 500
    });

    return (
        <div>
            <Box>
                <OrdersTracking accountStatus={accountStatus} />
            </Box>
            {currentMode !== 'v' &&
            <AlertMessage status={accountStatus} />
            }
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <Context.Provider value={[context, setContext]}>
                    <StoreContext.Provider value={[storeContext, setStoreContext]}>
                        <OnlineContext.Provider value={[onlineContext]}>
                            {children}
                        </OnlineContext.Provider>
                    </StoreContext.Provider>
                </Context.Provider>
            }
        </div>
    );
};

const OrderingLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                return <OrderingLayout><Component {...props} /></OrderingLayout>
            }}
        />
    );
};

export default OrderingLayoutRoute;