import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import liff from "@line/liff";

// const liff = window.liff;
export default function LandingPage(props) {
    const history = useHistory();

    console.log('LandingPage');

    useEffect(() => {
        console.log('[LandingPage]');
        try {
            const liffId = process.env.REACT_APP_LINE_LIFF_ID;
            liff.init({liffId: liffId, withLoginOnExternalBrowser: true}).catch(err => {
                throw err
            });

            // Not working below
            // const queryString = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            // const code = queryString.code;
            // console.log('code', code);
            // if(code){
            //     const requestOptions = {
            //         method: 'POST',
            //         headers: { 'Content-Type': 'application/json' },
            //         body: JSON.stringify({code: code, redirectUri: process.env.REACT_APP_BASE_URL+`/hm/login`, cid: ''})
            //     };
            //     const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
            //     fetch(url, requestOptions)
            //         .then(results => results.json())
            //         .then(data => {
            //             if(data.token){
            //                 localStorage.setItem('token', data.token);
            //                 let customer = {
            //                     displayName: data.displayName,
            //                     pictureUrl: data.pictureUrl
            //                 };
            //                 localStorage.setItem('customer', JSON.stringify(customer));
            //                 history.replace(`/hm/c/orders`);
            //
            //             } else {
            //                 const liffId = process.env.REACT_APP_LINE_LIFF_ID;
            //                 liff.init({liffId: liffId, withLoginOnExternalBrowser: true}).catch(err => {
            //                     throw err
            //                 });
            //             }
            //         });
            // } else {
            //     const liffId = process.env.REACT_APP_LINE_LIFF_ID;
            //     liff.init({liffId: liffId, withLoginOnExternalBrowser: true}).catch(err => {
            //         throw err
            //     });
            // }

        } catch (e){
            console.log(e);
        }
    }, [history, props]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
        </div>
    );
}
