import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeliveryView from "../../Location/DeliveryView";
import FoodOrderItems from '../FoodOrderItems';
import {getTextI18n} from "../../TextI18n";
import {DateTime} from "../../DateTime";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import ReplayIcon from '@mui/icons-material/Replay';
import {useHistory} from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import FoodOrderStatus from "../FoodOrderStatus";
import {DeliverTypeIcon} from "../../DeliverTypeIcon";
import UpdateIcon from "@mui/icons-material/Update";
// import OrdersChat from "../../OrdersChat";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from '@mui/icons-material/Phone';
import AlertTitle from "@mui/material/AlertTitle";
import FoodOrderPayment from "../FoodOrderPayment";
import {getUserName} from "../../TextFormat";
import TransactionOrderItems from "../../Transaction/TransactionOrderItems";

export default function FoodOrderTimeLine({mode, cid, items, transaction}) {
    const { t } = useTranslation();
    const history = useHistory();

    const handleReordering = () => {
        history.push(`/hm/${mode}/${cid}`);
    };

    const handleTel = (mobile) => {
        const link = document.createElement('a');
        link.id = 'phone';
        link.href = 'tel:'+mobile;
        link.click();
    }

    const CardOrder = ({item}) =>(
        <Box m={1} mb={4}>
            <>
                {item.state === 'closed' &&
                <>
                    <Box mb={1} mt={2}>
                        {item.status === 'completed' && <Alert severity="success"><AlertTitle>{t('order.customerReceived')}</AlertTitle></Alert>}
                        {item.status !== 'completed' && <Alert severity="error"><AlertTitle>{t('order.canceled')}</AlertTitle>
                            {t(`cancelReasonCode.${item.cancellationReasonCode}`)}
                        </Alert>}
                    </Box>
                    {cid && mode === 'o' &&
                    <Box display="flex" justifyContent="center" mb={2}>
                        <Button variant={"outlined"} fullWidth={true} onClick={handleReordering} startIcon={<ReplayIcon />}>{t('common.orderAgain')}</Button>
                    </Box>
                    }
                </>
                }
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        {item.state !== 'closed' &&
                        <Box mt={2} mb={1}>
                            <FoodOrderStatus value={item} />
                        </Box>
                        }
                        <Card>
                            {item.state !== 'closed' && !item.preorder &&
                                <Box mt={2} ml={2} mr={2}>
                                    <Box p={2}
                                         sx={{borderRadius: '4px', border: 1}}>
                                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                            {item.queueNo === 0 &&
                                                <Typography variant={"h6"}
                                                            sx={{fontSize: '22px', color: '#8f8f8f'}}>
                                                    <strong>{t('order.queueWaiting')}</strong>
                                                </Typography>
                                            }
                                            {item.queueNo > 0 &&
                                                <Typography variant={"h6"} sx={{fontSize: '32px'}}>
                                                    <strong>{t('order.queue')} {item.queueNo}</strong>
                                                </Typography>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            }
                            {item.preorder &&
                                <Box p={1} pb={0}>
                                    <Alert icon={false} severity="info">
                                        <List sx={{padding: 0}}>
                                            <ListItem sx={{padding: 0}}>
                                                <ListItemAvatar>
                                                    <UpdateIcon sx={{fontSize: 40}} />
                                                </ListItemAvatar>
                                                <ListItemText primary={t('cart.preorders')} secondary={
                                                    <Typography variant="body1">
                                                        <DateTime value={item.deliverDateTime}
                                                                  format="DD MMM YYYY HH:mm"></DateTime>
                                                    </Typography>
                                                } />
                                            </ListItem>
                                        </List>
                                    </Alert>
                                </Box>
                            }
                            <Box m={2}>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="body1">
                                        {t('order.number')}
                                    </Typography>
                                    <Typography variant="body1">
                                        {item.transactionNo}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="body1">
                                        {t('order.date')}
                                    </Typography>
                                    <Typography variant="body1">
                                        <DateTime value={item.orderDate}
                                                  format="DD MMM YYYY HH:mm"></DateTime>
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="body1">
                                        {t('deliver.method')}
                                    </Typography>
                                    <Box display={"flex"} alignItems={"center"}>
                                        <DeliverTypeIcon type={item.deliverType} />
                                        <Typography variant="body1" ml={1}>
                                            {t(item.deliverType)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Divider />
                            <List >
                                <ListItem secondaryAction={
                                    <Box display={"flex"}>
                                        <IconButton color={"primary"} edge="end" aria-label="tel" onClick={e=>handleTel(item._account.phone)} >
                                            <Avatar sx={{background: "#1abc9c", width: 32, height: 32 }}>
                                                <PhoneIcon fontSize={"small"} />
                                            </Avatar>
                                        </IconButton>
                                        {/*<OrdersChat to={"merchant"} type={"buttonIcon"} />*/}
                                    </Box>
                                }>
                                    <ListItemAvatar>
                                        <Avatar variant={"square"} src={
                                            item._account.image?
                                                `${process.env.REACT_APP_CDN_BASE_URL}/public/merchant/s/${item._account.image.name}`: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
                                        }></Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={t('shop.title')} secondary={
                                            <Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                                {getTextI18n(item._account.name)}
                                            </Typography>
                                        } />

                                </ListItem>
                            </List>
                            <Divider />
                            {item.rider &&
                            <>
                                <List>
                                    <ListItem alignItems="flex-start"
                                              secondaryAction={
                                                  <Box display={"flex"}>
                                                      <IconButton color={"primary"} edge="end" aria-label="tel" onClick={e=>handleTel(item.rider.mobile)} >
                                                          <Avatar sx={{background: "#1abc9c", width: 32, height: 32 }}>
                                                              <PhoneIcon fontSize={"small"} />
                                                          </Avatar>
                                                      </IconButton>
                                                      {/*<OrdersChat to={"rider"} type={"buttonIcon"} />*/}
                                                  </Box>
                                              }>
                                        <ListItemAvatar>
                                            <Avatar alt="Rider" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${item.rider._id}`} />
                                        </ListItemAvatar>
                                        <ListItemText primary={t('rider.title')} secondary={
                                            <Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                                {t('rider.name')}: {getUserName(item.rider)}
                                            </Typography>
                                        }>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                                {item.deliver && item.deliver.attachment &&
                                    <Box m={2} mt={0} style={{textAlign: 'center'}}>
                                        <Box display="flex" justifyContent="space-between" mb={1}>
                                            <Typography variant="body1">
                                                {t('order.deliverDate')}
                                            </Typography>
                                            <Typography variant="body1">
                                                <DateTime value={item.deliver.dateTime}
                                                          format="DD MMM YYYY HH:mm"></DateTime>
                                            </Typography>
                                        </Box>
                                        <img
                                            style={{width: '100%'}}
                                            alt={item.deliver.attachment.originName}
                                            src={`${process.env.REACT_APP_CDN_BASE_URL}/${item.deliver.attachment.key}`}
                                        />
                                    </Box>
                                }
                                <Divider />
                            </>
                            }
                            {item.deliverType === 'DELIVERY' &&
                                <>
                                    <Grid item xs={12} md={12}>
                                        <DeliveryView profile={item.contactProfile} customer={item.customer} />
                                    </Grid>
                                    <Divider />
                                </>
                            }
                            <Box>
                                {transaction && <TransactionOrderItems value={item} />}
                                {!transaction && <FoodOrderItems value={item}></FoodOrderItems>}
                            </Box>
                            <Divider />
                            <FoodOrderPayment value={item} />
                        </Card>
                    </Grid>

                </Grid>
            </>
        </Box>
    );

    return (
        <Box>
            {items.map(item => (
                <Box key={item._id} mb={2}>
                    <CardOrder item={item} />
                </Box>
            ))}
            {items.length === 0 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <Typography variant="body1" color="textSecondary"
                            sx={{fontSize: '0.85rem'}}>{t('common.notFound')}</Typography>
            </Box>
            }
        </Box>
    )
};