import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Route, useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import StoreIcon from '@mui/icons-material/Store';
import L from 'leaflet';
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SettingDialog from '../../components/Profile/SettingDialog'
import AlertMessage from '../../components/AlertMessage'
import { Context } from "../../core/Context";

import 'leaflet/dist/leaflet.css';
import './style.css'

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {httpClient} from "../../core/HttpClient";

let DefaultIcon = L.icon({
    iconUrl: icon,
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    }
});

let DEFAULT_ZOOM = 17;
let DEFAULT_LOCATION = {coordinates: null, gps: 'UNDEFINED', zoom: DEFAULT_ZOOM, type: 'NO_LOCATION', address: null};

const InsideLayout = ({ title, backTo, children}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const history = useHistory();
    const [context, setContext] = useState({shop: {}, location: DEFAULT_LOCATION, openMap: false, success: false, fail: false, warning: false});
    const [accountStatus, setAccountStatus] = useState({status: '', verified: 1});
    const [openSetting, setOpenSetting] = useState(false);

    useEffect(() => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/location';
        httpClient.get(url)
            .then(res => {
                if (res.data) {
                    setAccountStatus({status: res.data.status, verified: res.data.verified});
                }
            });
    },[]);

    const handleBack = () => {
        let _cid = cid || localStorage.getItem('cid');
        if(backTo === 'history'){
            history.push(`/h/${_cid}`);
        } else if(backTo === 'ordering') {
            history.push(`/hm/o/${_cid}`);
        } else {
            history.push(`/hm/${_cid}`);
        }
    };

    const handleSetting = (value) => {
        setOpenSetting(value);
    };

    return (
        <div>
            <CssBaseline />
            <AlertMessage status={accountStatus} />
            <header className={classes.header}>
                <AppBar elevation={0}
                        color="light"
                        position="static"
                        sx={{borderBottom: '1px solid #e7e7e7'}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit"
                                    onClick={handleBack}
                                    aria-label="menu" sx={{ mr: 2 }}>
                            {(cid || localStorage.getItem('cid')) && backTo === 'store' &&
                            <StoreIcon />
                            }
                            {(cid || localStorage.getItem('cid')) && ['history','home', 'ordering'].indexOf(backTo) > -1 &&
                            <KeyboardBackspaceIcon />
                            }
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            <Box display="flex" justifyContent="center">
                                {t(title)}
                            </Box>
                        </Typography>
                    </Toolbar>
                </AppBar>
            </header>
            <div>
                <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
            </div>
            <SettingDialog open={openSetting} onClose={(e) => handleSetting(false)} />
        </div>
    );
};

const InsideLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                return <InsideLayout {...rest}><Component {...props} /></InsideLayout>
            }}
        />
    );
};

export default InsideLayoutRoute;