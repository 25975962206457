import React from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import AppBar from "@mui/material/AppBar";

export default function AlertMessage({status}) {
    const { t } = useTranslation();
    console.log('[AlertMessage]');

    return (
        <div>
            <Box style={{
                width: '100%',
                top: 0,
                zIndex: 998
            }}>
            {status && status.verified === 0 &&
            <Box style={{height: '48px'}}>
                <AppBar component="nav">
                    <Alert icon={<WarningAmberIcon fontSize="inherit" />} severity="error" style={{
                        borderRadius: 0
                    }}>
                        <div>{t('common.message.notVerify')}</div>
                    </Alert>
                </AppBar>
            </Box>
            }
            {status && status.verified === 1 && status.expiredDay > 7 &&
            <Box style={{height: '48px'}}>
                <AppBar component="nav">
                    <Alert icon={<WarningAmberIcon fontSize="inherit" />} severity="warning" style={{
                        borderRadius: 0
                    }}>
                        <div>{t('common.message.expired')}</div>
                    </Alert>
                </AppBar>
            </Box>
            }
            </Box>
        </div>
    );
}