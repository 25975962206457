import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import Box from "@mui/material/Box";
import Lottie from "lottie-react";
import cartAnimation from "../../../lotties/cart.json";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import {httpClient} from "../../../core/HttpClient";

let CART_REQUEST_TIME = process.env.REACT_APP_CART_REQUEST_TIME;
const CART_REQUEST_TIME_OUT = 50;

export default function CartRequestPaymentDialog({online, open, onClose}) {
    const { t } = useTranslation();
    const [count, setCount] = useState(0);

    useEffect(() => {
        console.log('[CartRequestPaymentDialog]', count);
        if(count < CART_REQUEST_TIME_OUT){
            if(online === 1){
                onClose(true);
            } else {
                let onlineMinute = dayjs().diff(online, 'minute', false);
                if(onlineMinute < CART_REQUEST_TIME){
                    onClose(true);
                } else {
                    const timer = setTimeout(() => setCount(count+1), 1e3);
                    return () => clearTimeout(timer);
                }
            }

        }

    }, [count, online, onClose]);

    const handleRetry = ()=>{
        setCount(0);
        let url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/order/request';
        let data = {};
        httpClient.post(url, data)
            .then(res => {
                // done
            }).catch(e=>{
            console.log(e);
        });
    };
    const handleClose = ()=>{
        onClose(false);
    };

    return (
        <>
        <Dialog open={open}>
            <Box m={2} >
                {count < CART_REQUEST_TIME_OUT &&
                    <>
                        <Box>
                            <Lottie animationData={cartAnimation} loop={true}/>
                        </Box>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Typography variant={"body1"} >{t('cart.requesting')}</Typography>
                        </Box>
                    </>

                }
                {count >= CART_REQUEST_TIME_OUT &&
                    <>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <img alt="cart-fail" src="https://cdn.foodkub.com/public/assets/cart_fail_4.jpg" style={{width: '100%'}} />
                        </Box>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Typography variant={"body1"} >{t('cart.requestFail')}</Typography>
                        </Box>
                        <Box mt={2}>
                            <Button fullWidth variant={"outlined"} color={"primary"} onClick={handleRetry}>
                                {t('cart.retryRequest')}
                            </Button>
                        </Box>
                    </>

                }
                <Box mt={2}>
                    <Button disableElevation fullWidth variant={"contained"} color={"grey"} onClick={handleClose}>
                        {t('common.cancel')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
        </>
    )
};