import React, { useState, useEffect, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import NumberFormat from 'react-number-format';
import CartUtil from "../../../core/CartUtil";
import {TextI18n} from "../../TextI18n";
import {TextI18nTruncate} from "../../TextI18nTruncate";
import ProductControlDialog from "../ProductControlDialog";
import {ProductEdit} from "../ProductEdit";
import {getCache} from "../../../core/LocalStorageUtil";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import {CardActionArea} from "@mui/material";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import {CountNumber} from "../../CountNumber";
import {httpClient} from "../../../core/HttpClient";
import TopRecommend from "../../TopRecommend";
import liff from "@line/liff";

const useStyles = makeStyles({
    root: {},
    link: {
        color: '#1abc9c'
    },
    list: {
        paddingTop: 0
    },
    listContainer: {
        backgroundColor: '#f7f7f7',
        minHeight: '100vh'
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    qty: {
        minWidth: '1.5rem',
        textAlign: 'center'
    },
    avatar: {
        backgroundColor: 'none'
    }
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function ProductGrid({list, shop, mode, partner}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const [open, setOpen] = useState({add: false, edit: false});
    const [item, setItem] = useState({index: -1, remark: '', optional: {total: 0}, qty: 1});
    const [selectProduct, setSelectProduct] = useState({qty: 1});
    const [productGroups] = useState(list);
    const [likeProduct, setLikeProduct] = useState(shop.likeProduct || {});
    let cacheData = getCache(cid);
    let cart = cacheData.cart;

    useEffect(() => {
        console.log('[ProductGrid]');
        // Trigger Selected Product.
        let product = JSON.parse(localStorage.getItem('pid'));
        if(product){
            let cartQty = 0;
            if(cart && cart.products[product._id]){
                cartQty = cart.products[product._id].qty;
                setOpen({add: false, edit: true});
            }else{
                setItem({index: -1, remark: '', optional: {total: 0}, qty: 1});
                setOpen({add: true, edit: false});
            }
            let oos = !product.open ||  product.remaining-cartQty < 1;
            setSelectProduct({...product, qty:1, oos: oos, cartQty: cartQty});
            localStorage.removeItem('pid');
        }
    }, [cart, partner]);

    const handleClickOpen = (product) => {
        if(mode !=='v' && (!product.open || product.remaining < 1)){
            return;
        }

        let cartQty = 0;
        if(mode === 'v'){
            setOpen({add: true, edit: false});
        } else {
            if(cart && cart.products[product._id]){
                cartQty = cart.products[product._id].qty;
                setOpen({add: false, edit: true});
            }else{
                setItem({index: -1, remark: '', optional: {total: 0}, qty: 1});
                setOpen({add: true, edit: false});
            }
        }

        let oos = !product.open || product.remaining-cartQty < 1;
        setSelectProduct({...product, qty:1, oos: oos, cartQty: cartQty});
    };

    const handleClose = () => {
        setOpen({add: false, edit: false});
    };

    const handleChange = (value) => {
        console.log('handleChange');
        if(value.mode === -1){
            // remove
            new CartUtil(cid).removeCartItem(value.product, value.item);
            setOpen({add: false, edit: false});
        } else if(value.mode === 0){
            // update
            new CartUtil(cid).updateCart(value.product, value.item);
            setOpen({add: false, edit: false});
        } else if(value.mode === 1){
            // add
            new CartUtil(cid).addToCart(value.product, value.item);
            setOpen({add: false, edit: false});
        } else if(value.mode === 2){
            // like
            let token = localStorage.getItem('token');
            if(token){
                let _like = value.likeCount || 0;
                let _liked = value.like;
                let _likeProduct = {...likeProduct, [value.product._id]: _liked};
                setLikeProduct(_likeProduct);

                // update product like
                productGroups.forEach(pg=>{
                    pg.products.forEach(p=>{
                        if(value.product._id === p._id){
                            p.like = _like;
                        }
                    });
                });
            } else {
                let ua = navigator.userAgent || navigator.vendor || window.opera;
                let isInAppBrowser =  (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
                    || (ua.indexOf("Instagram") > -1);
                // Facebook, Instagram In-App Browser detect.
                if(isInAppBrowser && ["ios","android"].indexOf(liff.getOS()) > -1){
                    // window.location.href = `line://app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}`;
                    window.location.href = `https://line.me/R/app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}`;
                } else {
                    // user liff.login because open Line App to login (if use window.location.href = url; will go to Line web login page)
                    // Note: LINE Liff login (Callback URL must start with same prefix of liff Endpoint URL (/hm)
                    const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login?cid=${cid}&mode=${mode}`;
                    liff.login({ redirectUri: redirectUri});
                }
            }

        }
    };

    const handleSelectProductEdit = ({value, index}) => {
        console.log('handleSelectProductEdit');
        let cartQty = 0;
        if(cart && cart.products[value._id]){
            cart.products[value._id].items.forEach((item,i)=>{
                if(index !== i){
                    cartQty+=item.qty;
                }
            })
        }
        let newSelectProduct = {...selectProduct, qty: value.qty, oos: false, cartQty: cartQty};
        setSelectProduct(newSelectProduct);
        setItem({index: index, remark: value.remark, optional: value.optional, qty: value.qty});
        setOpen({add: true, edit: false});
    };

    const handleAddAnother = () => {
        setItem({index: -1, remark: '', optional: {total: 0}, qty: 1});
        setOpen({add: true, edit: false});
    };

    const handleClickLike = (e,product) => {
        e.stopPropagation();

        let token = localStorage.getItem('token');
        if(token){
            let _like = product.like || 0;
            let _liked = false;
            if(likeProduct[product._id]){
                _like--;
            } else {
                _like++;
                _liked = true;
            }
            setLikeProduct({...likeProduct, [product._id]: _liked});
            product.liked = _liked;
            product.like = _like;

            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/like/product';
            httpClient.put(url, {accountId: product._account, productId: product._id, liked: _liked})
                .then(res => {
                    // done
                }).catch(e=>{});
        } else {
            let ua = navigator.userAgent || navigator.vendor || window.opera;
            let isInAppBrowser =  (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
                || (ua.indexOf("Instagram") > -1);
            // Facebook, Instagram In-App Browser detect.
            if(isInAppBrowser && ["ios","android"].indexOf(liff.getOS()) > -1){
                // window.location.href = `line://app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}`;
                window.location.href = `https://line.me/R/app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}`;
            } else {
                // user liff.login because open Line App to login (if use window.location.href = url; will go to Line web login page)
                // Note: LINE Liff login (Callback URL must start with same prefix of liff Endpoint URL (/hm)
                const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login?cid=${cid}&mode=${mode}`;
                liff.login({ redirectUri: redirectUri});
            }
        }

    };

    return (
        <div className={classes.listContainer}>
            <TopRecommend productGroups={productGroups} likeProduct={likeProduct} mode={mode} shop={shop}
                          onClick={
                              value=>{
                                  handleClickOpen(value);
                              }
                          }
                          onLikeChange={handleChange} />
            <Card sx={{borderRadius: '5px 5px 0 0', border: 0}} variant={"outlined"}>
            <>
            {productGroups.length === 0 &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Typography>
                    {t('product.notFound')}
                </Typography>
            </Box>
            }
            {productGroups.length > 0 &&
            <Box className={classes.listContainer}>
                <Box pt={2} pb={1} display={"flex"} justifyContent={"center"}>
                    {/*<Typography variant={"h6"}>*/}
                    {/*    == {t('product.all')} ==*/}
                    {/*</Typography>*/}
                    {/*<Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />*/}
                </Box>
                <Box pb={11} pl={1} pr={1}>
                    {productGroups.map((productGroup, sectionId) => (
                        <Box mb={2} key={`section-${sectionId}`}>
                            <Box pb={1} pt={1}>
                                <Typography variant="h6" display="block" color={"black"}>
                                    <TextI18n value={productGroup.name}></TextI18n>
                                </Typography>
                            </Box>
                            <Grid container spacing={1}>
                                {productGroup.products.map((value, index) => (
                                <Grid key={`item-${value._id}`} xs={6} md={4} item >
                                    <Card variant={"outlined"} sx={{height: '100%'}}>
                                        <CardActionArea onClick={e=>{handleClickOpen(value)}} sx={{height: '100%', opacity: (!value.open || value.remaining < 1)?0.35:1}} >
                                            <Box display={"flex"}  justifyContent="space-between" flexDirection={"column"} sx={{height: '100%'}}>
                                                <Box>
                                                    <CardMedia
                                                        component="img"
                                                        image={
                                                            value.images[0]?
                                                                `${process.env.REACT_APP_CDN_BASE_URL}/${value.images[0].permission}/merchant/m/${value.images[0].name}`: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`
                                                        }
                                                        alt="Food"
                                                    />
                                                    <Box ml={1} mr={1} mt={1}>
                                                        <Typography gutterBottom variant="h6" style={{fontSize: '1.15rem'}} component="div">
                                                            <TextI18nTruncate value={value.name} lines={2}></TextI18nTruncate>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box ml={1} mr={1} mb={1}>
                                                    <Box display="flex">
                                                        <Box mr={1}>
                                                            <Typography color="caption">
                                                                {t('product.price')}
                                                            </Typography>
                                                        </Box>
                                                        <Typography>
                                                            <strong><NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} /></strong>
                                                        </Typography>
                                                        <Box ml={1}>
                                                            <Typography color="caption">
                                                                {t('common.baht')}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box sx={{marginTop: '5px'}} display="flex" justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                            {value.recommend &&
                                                                <Box mr={1} sx={{ background: 'red', color: 'white', paddingLeft: '3px', paddingRight: '3px', borderRadius: '3px', fontSize:'0.7rem'}}>
                                                                    {t('product.recommend')}
                                                                </Box>
                                                            }
                                                            {(!value.open || value.remaining < 1) &&
                                                                <Box><Typography variant="subtitle2" color="secondary">{t('product.outOfStock')}</Typography></Box>
                                                            }
                                                        </Box>
                                                        <Box display="flex" justifyContent="end" alignItems="center" onClick={e=>handleClickLike(e, value)}>
                                                            {likeProduct[value._id] ? <ThumbUpAltIcon color="primary" fontSize="small" /> : <ThumbUpOutlinedIcon color="default" fontSize="small" /> }

                                                            <Typography variant="caption" style={{marginLeft: '3px'}}>
                                                                <CountNumber value={value.like} />
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                                ))}
                            </Grid>
                        </Box>
                    ))}
                </Box>
            </Box>
            }
            </>
            </Card>
            <ProductControlDialog
                shop={shop}
                mode={mode}
                product={selectProduct}
                item={item}
                likeProduct={likeProduct}
                open={open.add}
                onChange={handleChange}
                onClose={handleClose}
            />
            <Dialog fullScreen
                    open={open.edit}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    style={{top: 'auto'}} >
                <ProductEdit value={selectProduct}
                             cid={cid}
                             onClose={handleClose}
                             onEdit={handleSelectProductEdit} />
                <div style={
                    {
                        position: 'fixed',
                        top: 'auto',
                        bottom: 0,
                        left:0,
                        right:0,
                        padding: '8px',
                        background: '#fff',
                        borderRadius: '4px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                        overflow: 'hidden',
                        zIndex: 999
                    }
                }>
                <Button style={{fontSize: '1.2rem', marginBottom: '1rem'}}
                        variant="contained"
                        onClick={handleAddAnother}
                        color="primary"
                        size="large"
                        fullWidth={true}
                        disableElevation autoFocus>
                    <span>{t('cart.addAnother')}</span>
                </Button>
                </div>
            </Dialog>
        </div>
    )
}