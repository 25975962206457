import React, {useState, useEffect, forwardRef} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import {CopyToClipboard} from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {AttachmentPaySlip} from "../../Upload/AttachmentPaySlip";
import {QRCode} from "react-qrcode-logo";
import {makeStyles} from "@mui/styles";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {httpClient} from "../../../core/HttpClient";
import Slide from "@mui/material/Slide";
import {useHistory} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
    root: {},
    paymentButton: {
        padding: '1rem'
    },
    BBL: {backgroundColor: '#264091', padding: '5px', borderRadius: '6px'},
    KBANK: {backgroundColor: '#039029', padding: '5px', borderRadius: '6px'},
    KTB: {backgroundColor: '#06a4e7', padding: '5px', borderRadius: '6px'},
    TTB: {backgroundColor: '#ffffff', padding: '5px', borderRadius: '6px'},
    SCB: {backgroundColor: '#462279', padding: '5px', borderRadius: '6px'},
    BAY: {backgroundColor: '#fec523', padding: '5px', borderRadius: '6px'},
    KKP: {backgroundColor: '#635f98', padding: '5px', borderRadius: '6px'},
    CIMBT: {backgroundColor: '#ff0000', padding: '5px', borderRadius: '6px'},
    TISCO: {backgroundColor: '#1369b0', padding: '5px', borderRadius: '6px'},
    UOBT: {backgroundColor: '#07367a', padding: '5px', borderRadius: '6px'},
    GHB: {backgroundColor: '#ff3a00', padding: '5px', borderRadius: '6px'},
    GSB: {backgroundColor: '#eb077e', padding: '5px', borderRadius: '6px'},
    ISBT: {backgroundColor: '#073e04', padding: '5px', borderRadius: '6px'}
});

export default function MobilePaymentChannel({cid, mobileOrder}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bookBanks, setBookBanks] = useState([]);
    const [attachment, setAttachment] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [qrPayment, setQRPayment] = useState({
        qrValue: '',
        accountName: '',
        accountNumber: ''
    });

    useEffect(() => {
        console.log('[MobilePaymentChannel]');
        if(mobileOrder && mobileOrder._account.payment){
            setBookBanks(mobileOrder._account.payment.fundsTransferList);
        }

        if(mobileOrder._account.payment.qrPayment){
            setLoading(true);
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/account/payment/qr';
            let data = {
                amount: mobileOrder.grandTotal
            };

            httpClient.post(url, data)
                .then(res => {
                    if(res.data){
                        setQRPayment(res.data);
                    }
                    setLoading(false);
                }).catch(e=>{
                setLoading(false);
            });
        }

    }, [mobileOrder]);

    function _validation() {
        let list = [];

        if(!paymentMethod){
            list.push('paymentMethod');
        }

        return list.length===0;
    }

    const handleSelectPayment = (payment) => {
        setPaymentMethod(payment);
        setAttachment(null);
    }

    const handleConfirmPayment = () => {
        console.log('handleConfirmPayment');
        if(_validation()){
            setLoading(true);
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/order/payment';

            let data = {...mobileOrder, payment: {
                    method: paymentMethod,
                    payDate: new Date(),
                    status: 'pending',
                    attachment: attachment
            }};

            httpClient.post(url, data)
                .then(res => {
                    if(res.data){
                        setQRPayment(res.data);
                    }
                    history.push(`/hm/m/${cid}/${mobileOrder.uid}/i`);
                }).catch(e=>{
                console.log(e);
                setLoading(false);
            });
        }

    };

    const handleCopy = () => {
        setCopied(true);
    };

    const handleCloseCopied = () => {
        setCopied(false);
    };

    const handleClose = () => {
        setPaymentMethod('');
    };

    return (
        <Box>

            {mobileOrder._account.payment.qrPayment &&
                <Box mb={2}>
                    <Button variant="outlined" fullWidth={true} size={"large"} startIcon={<QrCodeScannerIcon/>}
                            disabled={loading}
                            onClick={e => handleSelectPayment('qrPayment')}>
                        {t('mobileOrder.payments.promptPay')}
                    </Button>
                </Box>
            }
            {mobileOrder._account.payment.fundsTransfer &&
            <Box>
                <Button variant="outlined" fullWidth={true} size={"large"} startIcon={<AccountBalanceIcon />}
                    onClick={e=>handleSelectPayment('fundsTransfer')}>
                    {t('mobileOrder.payments.fundsTransfer')}
                </Button>
            </Box>
            }

            <Dialog open={['qrPayment', 'fundsTransfer'].indexOf(paymentMethod) > -1}
                    scroll="body"
                    TransitionComponent={Transition}
                    fullScreen
                    fullWidth={true}
                    sx={{marginTop: '8px', marginBottom: '8px'}}
                    onClose={handleClose}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        backgroundColor: '#FFFFFF',
                    }}
                >
                    <CloseIcon />
                </IconButton>
                {paymentMethod === 'fundsTransfer' &&
                    <>

                        {bookBanks && bookBanks[0] &&
                            <Box>
                                <Box mt={4} mb={2} display={"flex"} justifyContent={"center"}>
                                    <img style={{width: '72px'}} className={classes[bookBanks[0].bank]} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/banks/${bookBanks[0].bank}.svg`} alt={bookBanks[0].code} />
                                </Box>
                                <Box mb={2} display={"flex"} justifyContent={"center"}>
                                    <Typography>
                                        {t(`banks.${bookBanks[0].bank}`)}
                                    </Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"center"} style={{color: 'gray'}}>
                                    <Typography variant={"body1"}>
                                        {t(`payment.accountNumber`)}
                                    </Typography>
                                </Box>
                                <CopyToClipboard text={bookBanks[0].accountNumber} onCopy={() => handleCopy()}>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <Typography variant="h6" noWrap={true}>
                                            <strong><NumberFormat value={bookBanks[0].accountNumber} displayType={'text'} format="###-#-#####-#####" /></strong>
                                        </Typography>
                                        <IconButton id='fundsTransfer' edge="end" aria-label="copy">
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Box>
                                </CopyToClipboard>
                                <Box mt={1} display={"flex"} justifyContent={"center"} style={{color: 'gray'}}>
                                    <Typography variant={"body1"}>
                                        {t(`payment.accountName`)}
                                    </Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"center"}>
                                    <Typography variant="h6">
                                        {bookBanks[0].accountName}
                                    </Typography>
                                </Box>
                            </Box>
                        }
                        <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                            <Typography variant="caption" display="block" gutterBottom style={{color: 'gray'}}>
                                {t('payment.fundsTransferDetail')}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} ml={2} mr={2}>
                            <Typography variant={"h6"}>
                                {t('payment.amount')}
                            </Typography>
                            <Typography variant={"h6"}>
                                <NumberFormat value={mobileOrder.grandTotal} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                            </Typography>
                        </Box>
                        <Box m={2}>
                            <AttachmentPaySlip value={attachment} onChange={setAttachment} />
                        </Box>
                    </>
                }
                {paymentMethod === 'qrPayment' &&
                    <>
                        <Box>
                            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/thai_qr_payment_header.jpg`} style={{width: '100%'}} alt={"qr payment"} />
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <QRCode value={qrPayment.qrValue}
                                    size={230}
                                    ecLevel={"L"}
                                    logoImage={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/thai_qr_payment_logo_60.png`} />
                        </Box>
                        {qrPayment.qrPaymentType === 'promptPayAccount' &&
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="body1" display="block" gutterBottom>
                                    {t('payment.accountNumber')} : {qrPayment.accountNumber}
                                </Typography>
                            </Box>
                        }
                        {qrPayment.qrPaymentType === 'qrPaymentAccount' &&
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="body1" display="block" gutterBottom>
                                    {t('payment.accountRef')} : {qrPayment.accountNumber}
                                </Typography>
                            </Box>
                        }
                        <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                            <Typography variant="body1" display="block" gutterBottom>
                                {t('payment.accountName')} : {qrPayment.accountName}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" m={2}>
                            <Typography variant="caption" display="block" gutterBottom style={{color: 'gray'}}>
                                {t('payment.promptPayDetail')}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} ml={2} mr={2}>
                            <Typography variant={"h6"}>
                                {t('payment.amount')}
                            </Typography>
                            <Typography variant={"h6"}>
                                <NumberFormat value={mobileOrder.grandTotal} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                            </Typography>
                        </Box>
                        <Box m={2}>
                            <AttachmentPaySlip value={attachment} onChange={setAttachment} />
                        </Box>
                    </>
                }
                <Box m={2}>
                    <Button variant="contained"
                            color="success"
                            style={{fontSize: '1.2rem', paddingTop: '12px', paddingBottom: '12px'}}
                            size="large"
                            onClick={handleConfirmPayment}
                            fullWidth={true}
                            disabled={loading || (['fundsTransfer', 'qrPayment'].indexOf(paymentMethod) > -1 && attachment === null)}>
                        {t('payment.confirmOrder')}
                    </Button>
                </Box>
                <Box m={2}>
                    <Button variant="contained"
                            color="grey"
                            disableElevation={true}
                            onClick={handleClose}
                            fullWidth={true}>
                        {t('common.cancel')}
                    </Button>
                </Box>
            </Dialog>
            <Snackbar open={copied}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseCopied}>
                <Alert onClose={handleCloseCopied} severity="info">
                    {t('payment.copiedBankAccount')}
                </Alert>
            </Snackbar>
        </Box>
    );
}
