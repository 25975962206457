import React from 'react';
// import { useTranslation } from "react-i18next";

export function getTextI18n(data) {
    if(data && data.i18n){
        return data.i18n['th'] || data.i18n['en'];
    } else {
        return '';
    }
}

export function TextI18n(props) {
    return(
        <span style={{wordWrap: 'break-word'}}>
            {props.value &&
                getTextI18n(props.value)
            }
        </span>
    )
}

export function getProvinceI18n(data, i18n) {
    const currentLang = i18n.language.split('-')[0] || 'en';
    if(data){
        let name = 'name_'+currentLang;
        return data[name];
    } else {
        return '';
    }
}