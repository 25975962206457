import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import HighlightOff from '@mui/icons-material/HighlightOff';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import NumberFormat from 'react-number-format';
import {TextI18n, getTextI18n} from "../../TextI18n";
import {TextI18nTruncate} from "../../TextI18nTruncate";
import CollectionsIcon from '@mui/icons-material/Collections';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import {CountNumber} from "../../CountNumber";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import {httpClient} from "../../../core/HttpClient";
// import Chip from "@mui/material/Chip";
// import Avatar from "@mui/material/Avatar";

const useStyles = makeStyles({
    root: {
    },
    media: {
        height: 0,
        paddingTop: '100%', // 1.91:1 Facebook OG Image
    },
    qty: {
        width: '2.5rem'
    }
});

const modalStyle = {
    margin: '10px',
    bgcolor: 'background.paper',
    border: '0px',
    boxShadow: 24,
    p: 1
};

const _calculateTotalOptional = (optional)=>{
    let total = 0;
    const keys = Object.keys(optional);
    keys.forEach(key=>{
        if(key !== 'total'){
            total += optional[key].price;
        }
    });
    return total;
};

export function ProductView({mode, edit, disableOrdering, product, item, likeProduct,
                                onLikeChange, onRemarkChange, onOptionalChange, onIncrease, onDecrease, onClose}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [remark, setRemark] = useState('');
    const [optional, setOptional] = useState({total: 0});
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({qty: item.qty});
    const [like, setLike] = useState(false);
    const [likeCount, setLikeCount] = useState(product.like);

    console.log('ProductView');

    useEffect(() => {
        console.log('[ProductView]', item.remark);
        if(item.remark){
            setRemark(item.remark);
        }
        if(item.optional){
            setOptional(item.optional);
        }
        if(likeProduct[product._id]){
            setLike(true);
        }
    },[item, product, likeProduct]);

    const handleIncrease = ()=>{
        if(product.remaining <= state.qty){
            return;
        }
        let newState = {...state, qty: ++state.qty};
        setState(newState);
        onIncrease();
    };

    const handleDecrease = ()=>{
        let newState = {...state, qty: --state.qty};
        setState(newState);
        onDecrease();
    };

    const handleChange = ({target})=>{
        setRemark(target.value);
        onRemarkChange(target.value);
    };

    const handleToggle = (opt, value) => () => {
        if(opt.multipleSelect){
            let max = opt.maxSelect || 0;
            if(optional[opt._id]){
                const currentIndex = optional[opt._id].checked.indexOf(value._id);
                const newOptional = {...optional};

                if (currentIndex === -1) {
                    if(max > 0 && optional[opt._id].checked.length >= max){
                        let removeIndex = optional[opt._id].checked.length-1
                        newOptional[opt._id].checked.splice(removeIndex, 1);
                        let removeObject = newOptional[opt._id].checkedOption[removeIndex];
                        newOptional[opt._id].checkedOption.splice(removeIndex, 1);
                        newOptional[opt._id].price -= removeObject.price;
                    }

                    newOptional[opt._id].checked.push(value._id);
                    newOptional[opt._id].checkedOption.push(value);
                    newOptional[opt._id].price += value.price;
                } else {
                    newOptional[opt._id].checked.splice(currentIndex, 1);
                    newOptional[opt._id].checkedOption.splice(currentIndex, 1);
                    newOptional[opt._id].price -= value.price;
                }

                newOptional.total = _calculateTotalOptional(newOptional);
                setOptional(newOptional);
                onOptionalChange(newOptional);

            } else {
                const newOptional = {...optional};
                newOptional[opt._id] = {
                    name: opt.name,
                    checked: [value._id],
                    checkedOption: [value],
                    price: value.price
                };
                newOptional.total = _calculateTotalOptional(newOptional);
                setOptional(newOptional);
                onOptionalChange(newOptional);
            }
        } else {
            const newOptional = {...optional};
            newOptional[opt._id] = {
                name: opt.name,
                checked: [value._id],
                checkedOption: [value],
                price: value.price
            };
            newOptional.total = _calculateTotalOptional(newOptional);
            setOptional(newOptional);
            onOptionalChange(newOptional);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickLike = (e,product) => {
        e.stopPropagation();

        let _likeCount = likeCount;
        let _liked = !like;
        if(_liked){
            _likeCount++;
        } else {
            _likeCount--;
        }
        setLike(_liked);
        setLikeCount(_likeCount);

        onLikeChange({product: product, like: _liked, likeCount: _likeCount})

        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/like/product';
        httpClient.put(url, {accountId: product._account, productId: product._id, liked: _liked})
            .then(res => {
                // done
            }).catch(e=>{});
    };

    return(
        <div>
            <div>
                <Card sx={{borderRadius: '0px'}} className={classes.root}>
                    <IconButton
                        style={{cursor: 'pointer', position: 'absolute', left: 20, top: 10, backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', padding: 5}}
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close">
                        <ArrowBackIcon />
                    </IconButton>
                    {product.images && product.images[0] &&
                        <CardMedia
                            className={classes.media}
                            image={`${process.env.REACT_APP_CDN_BASE_URL}/${product.images[0].permission}/merchant/o/${product.images[0].name}`}
                            title={getTextI18n(product.name)} />
                    }
                    {(!product.images || !product.images[0]) &&
                        <CardMedia
                            className={classes.media}
                            image={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`}
                        />
                    }
                </Card>
            </div>
            {product.images && product.images[0] &&
                <Box style={{position: 'absolute', width: '100%', marginTop: -55}}>
                    <Box display="flex" justifyContent="end" alignItems="center">
                        {
                            <IconButton
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                    color: 'white',
                                    padding: 5,
                                    margin: 10
                                }}
                                edge="start"
                                color="inherit"
                                onClick={handleOpen}
                                aria-label="close">
                                <CollectionsIcon/>
                            </IconButton>
                        }
                    </Box>
                </Box>
            }
            <Box pb={12}>
                <CardContent>
                    <Box display="flex" alignItems="center" style={{fontSize: '1.15rem'}} mb={1}>
                        <b><TextI18n value={product.name}></TextI18n></b>
                    </Box>
                    <Box display="flex" justifyContent={"space-between"} alignItems="center">
                        <Box display="flex" alignItems="center"
                             style={{width: '100%'}}>
                            {product.recommend &&
                                <Box mr={1} sx={{ background: 'red', color: 'white', paddingLeft: '3px', paddingRight: '3px', borderRadius: '3px', fontSize:'0.7rem'}}>
                                    {t('product.recommend')}
                                </Box>
                            }
                            {(!product.open || product.remaining < 1) &&
                                <Box><Typography variant="subtitle2" color="secondary">{t('product.outOfStock')}</Typography></Box>
                            }

                            <Typography>{t('product.price')}</Typography>
                            <Box display={"flex"} ml={1}>
                                <Box>
                                    <Typography><strong><NumberFormat value={product.price} displayType={'text'}
                                                                      thousandSeparator={true}/></strong></Typography>
                                </Box>
                                <Box ml={1}>
                                    <Typography color="caption">
                                        {t('common.baht')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="end" alignItems="center" onClick={e=>handleClickLike(e, product)}>
                            {like ? <ThumbUpAltIcon color="primary" fontSize="small" /> : <ThumbUpOutlinedIcon color="default" fontSize="small" /> }
                            <Typography variant="caption" style={{marginLeft: '3px'}}>
                                <CountNumber value={likeCount} />
                            </Typography>
                        </Box>
                    </Box>
                    <Box mt={1}>
                        <Typography component={'span'} variant="subtitle1" color="textSecondary">
                            <TextI18nTruncate value={product.description} lines={2}></TextI18nTruncate>
                        </Typography>
                    </Box>
                    {(!product.open || product.remaining < 1) &&
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="subtitle2" color="secondary">{t('product.outOfStock')}</Typography>
                        </Box>
                    }
                    <Divider style={{'marginBottom': '10px', 'marginTop': '10px', height: '0'}} />
                    <Box pb={2}>
                        <Typography variant="body1">
                            <TextI18n value={product.detail}></TextI18n>
                        </Typography>
                    </Box>
                    {(!product.oos && !disableOrdering) &&
                    <>
                        {product.options.map((opt, index) => (
                            <Box pb={2} key={`opt_${opt._id}`}>
                                <Card className={classes.root} variant="outlined">
                                    <CardContent style={{paddingBottom: '10px'}}>
                                        <Typography variant="body1">
                                            {opt.optionalRequire &&
                                            <span style={{ color: 'red', 'marginRight': '0.5rem' }}>*</span>
                                            }
                                            <strong><TextI18n value={opt.name}></TextI18n></strong>
                                            {opt.maxSelect > 0 &&
                                                <Box sx={{marginTop: '-8px'}}>
                                                    <Typography variant={"caption"}>({t('product.maxSelectInfo', {max: opt.maxSelect})})</Typography>
                                                </Box>
                                            }
                                        </Typography>
                                        <form autoComplete="false">
                                            <List style={{padding: '0'}}>
                                                {opt.items.map((opti, itemIndex) => (
                                                    <ListItem key={'option_'+index+'_'+itemIndex} style={{padding: '0'}} button onClick={handleToggle(opt, opti)}>
                                                        {mode !== 'v' &&
                                                        <ListItemIcon style={{minWidth: '0'}}>
                                                            {opt.multipleSelect &&
                                                            <Checkbox
                                                                checked={optional[opt._id]?optional[opt._id].checked.indexOf(opti._id) !== -1: false}
                                                                name={opti._id}
                                                                color="primary"
                                                                tabIndex={-1}
                                                                disableRipple
                                                                style={{paddingLeft: '0'}}
                                                                inputProps={{ 'aria-labelledby': opti._id }}
                                                            />
                                                            }
                                                            {!opt.multipleSelect &&
                                                            <Radio
                                                                checked={optional[opt._id]?optional[opt._id].checked.indexOf(opti._id) !== -1: false}
                                                                value={opti._id}
                                                                name={opt._id}
                                                                style={{paddingLeft: '0'}}
                                                                inputProps={{ 'aria-label': opt._id }}
                                                            />
                                                            }
                                                        </ListItemIcon>
                                                        }
                                                        {mode === 'v' &&
                                                        <Box mr={1}><b>&#8226;</b></Box>
                                                        }
                                                        <ListItemText id={opti._id} primary={getTextI18n(opti.name)} style={{ overflow: "hidden", wordWrap: 'break-word', paddingRight: '0.5rem' }} />
                                                        {opt.showPrice &&
                                                        <Box style={{minWidth: '60px', textAlign: 'right'}}>
                                                            <Typography variant="body1"
                                                                        color="inherit" style={{whiteSpace: 'nowrap'}}>{t('common.bahtSign')}<NumberFormat value={opti.price || 0} displayType={'text'} thousandSeparator={true} /></Typography>
                                                        </Box>
                                                        }
                                                    </ListItem>
                                                ))
                                                }
                                            </List>
                                        </form>
                                    </CardContent>
                                </Card>
                            </Box>
                        ))
                        }
                        {mode !== 'v' &&
                        <>
                            <Box pb={2}>
                                <form autoComplete="false">
                                    <TextField label={t('cart.remark')}
                                               fullWidth={true}
                                               multiline
                                               rows={2}
                                               name="remark"
                                               value={remark}
                                               type="text"
                                               onChange={handleChange}/>
                                </form>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <IconButton aria-label="remove" disabled={state.qty === (edit ? 0 : 1)}
                                            onClick={handleDecrease}>
                                    <RemoveCircleIcon sx={{fontSize: 40}} />
                                </IconButton>
                                <Box display="flex" justifyContent="center" alignItems="center"
                                     className={classes.qty}>
                                    <Typography style={{fontSize: '1.4rem'}}><strong>{state.qty}</strong></Typography>
                                </Box>
                                <IconButton aria-label="add" onClick={handleIncrease}
                                            disabled={product.remaining - product.cartQty <= state.qty}>
                                    <AddCircleIcon sx={{fontSize: 40}} />
                                </IconButton>
                            </Box>
                        </>
                        }
                    </>
                    }
                </CardContent>
            </Box>
            <Modal
                open={open}
                style={{overflow:'scroll'}}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Box display={"flex"} alignItems="center" justifyContent={"space-between"}>
                        <Typography variant="h6">
                            {t('common.images')}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="default"
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <HighlightOff fontSize="large" style={{background: 'white', borderRadius: '18px'}} />
                        </IconButton>
                    </Box>
                    {product.images.map((image, i) => (
                        <img key={i}
                             style={{width: '100%', marginTop: '8px'}}
                             src={`${process.env.REACT_APP_CDN_BASE_URL}/${image.permission}/merchant/o/${image.name}`}
                             alt={getTextI18n(product.name)}
                        />
                    ))
                    }
                </Box>
            </Modal>
        </div>
    )
}