import React, {useState, useEffect, useContext, useCallback} from 'react';
import { useTranslation } from "react-i18next";
import {useHistory, useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import CartList from "./../CartList";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {httpClient} from "../../../core/HttpClient";
import ConfirmMobileOrder from "../../ConfirmMobileOrder";
import {getCache, saveCache} from "../../../core/LocalStorageUtil";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {StoreContext} from "../../../core/Context";
import TakeOrderSelection from "../../TakeOrderSelection";
import ContactProfile from "../../ContactProfile";
import liff from "@line/liff";
import qs from "qs";

const shapeStyles = { backgroundColor: 'orange.main', padding: '0 8px 0 8px', color: '#fff', fontSize: '0.8rem' };

export default function CartMobileOrder(props) {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const [state, setState] = useState({name: {}, image:{}, verified: true, deliveryAreas: [], orderingType: 'inorder'});
    const [initial, setInitial] = useState(0);
    const [storeContext] = useContext(StoreContext);
    let cacheData = getCache(cid);
    let cart = cacheData.cart;
    const loadCart = qs.parse(props.location.search, { ignoreQueryPrefix: true }).cart;
    const loadCartFunction = useCallback(() => {
        return new Promise((resolve, reject) => {
            if(loadCart){
                httpClient.get(process.env.REACT_APP_API_BASE_URL + `/secure/customer/cart/${cid}`).then(res => {
                    if(res.data){
                        resolve(res.data);
                    } else {
                        resolve({status: 202});
                    }
                }).catch(e=>{
                    resolve({status: 202});
                });
            } else {
                resolve({status: 202})
            }
        });
    }, [cid, loadCart]);

    useEffect(() => {
        console.log('[CartMobileOrder]');
        if(localStorage.getItem('token')){
            let tasks = [
                httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account'),
                httpClient.get(process.env.REACT_APP_API_BASE_URL + `/secure/customer/profile`),
                loadCartFunction(),
            ];
            Promise.all(tasks)
                .then(rs=>{
                    if(rs){
                        if(rs[0].data){
                            setState(rs[0].data);
                        }

                        if(!rs[1].data){
                            if (!liff.isLoggedIn()) {
                                history.push(`/hm/1/${cid}`);
                            }
                        }

                        if(rs[2].result){
                            saveCache(rs[2].result);
                        }
                    }

                    setInitial(1);
                });
        } else {
            if (!liff.isLoggedIn()) {
                let ua = navigator.userAgent || navigator.vendor || window.opera;
                let isInAppBrowser =  (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
                    || (ua.indexOf("Instagram") > -1);
                // Facebook, Instagram In-App Browser detect.
                if(isInAppBrowser && ["ios","android"].indexOf(liff.getOS()) > -1){
                    window.location.href = `https://line.me/R/app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/m/${cid}/c?cart=true`;
                } else {
                    const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login?cid=${cid}&mode=m`;
                    liff.login({ redirectUri: redirectUri});
                }
            } else {
                window.location.href = `${process.env.REACT_APP_BASE_URL}/hm/m/${cid}`;
            }
        }
    }, [cid, history, loadCartFunction]);

    const handleBack = () => {
        let _cid = cid || localStorage.getItem('cid');
        history.push(`/hm/m/${_cid}`);
    };

    return (
        <div>
            {initial === 0 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {initial === 1 &&
            <>
                <header>
                    <AppBar elevation={0}
                            color="light"
                            position="static"
                            sx={{borderBottom: '1px solid #e7e7e7'}}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit"
                                        onClick={handleBack}
                                        aria-label="menu" sx={{ mr: 2 }}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                <Box display="flex" justifyContent="center">
                                    {t('cart.myCart')}
                                </Box>
                            </Typography>
                            {cart &&
                                <Box sx={shapeStyles}>{cart.qty}</Box>
                            }
                        </Toolbar>
                    </AppBar>
                </header>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <CartList mode="m" shop={state} deliver={storeContext.deliverPrice || {price: 0, additionalPrice: 0}} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CardContent>
                            <Box>
                                <Box display={"flex"} alignItems={"center"}><Typography variant="h6" component="div">
                                    {t('takeOrders.selectMethod')}
                                </Typography>{storeContext.errors.indexOf('deliverType') > -1 && <Box ml={1}><Typography variant="subtitle1" color={"red"} component="div">
                                    ({t('common.message.pleaseSelect')})
                                </Typography></Box>}
                                </Box>
                                <Box mb={2}>
                                    <TakeOrderSelection shop={state} />
                                </Box>
                            </Box>
                            <Box mt={1} mb={4}>
                                <Box display={"flex"} alignItems={"center"} mb={1} >
                                    <Typography variant="h6" component="div">
                                        {t('profile.contact')}
                                    </Typography>{storeContext.errors.indexOf('profile') > -1 && <Box ml={1}><Typography variant="subtitle1" color={"red"} component="div">
                                    ({t('common.message.pleaseSelect')})
                                </Typography></Box>}
                                </Box>
                                <ContactProfile />
                            </Box>
                            {!state.verified &&
                                <Box mb={1}>
                                    <Alert icon={<WarningAmberIcon fontSize="inherit" />} severity="error">
                                        <div>{t('common.message.unverified')}</div>
                                    </Alert>
                                </Box>
                            }
                            <Box mt={2}>
                                <ConfirmMobileOrder shop={state}/>
                            </Box>
                        </CardContent>
                    </Grid>
                </Grid>
            </>
            }
        </div>
    );
}
