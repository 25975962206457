import React, {forwardRef, useContext, useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CardContent from '@mui/material/CardContent';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from "@mui/material/Stack";
import NumberFormat from "react-number-format";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import {StoreContext} from "../../../core/Context";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {httpClient} from "../../../core/HttpClient";
import DeliverUtil from "../../../core/DeliverUtil";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

export default function ContactProfileSelection({list, open, shop, onClose}) {
    const { t } = useTranslation();
    const [mode, setMode] = useState('list');
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [addressList, setAddressList] = useState(list);
    const [contactProfile, setContactProfile] = useState({name: '', mobile: ''});
    const [error, setError] = useState({name: false, mobile: false});
    console.log('ContactProfileSelection');

    useEffect(() => {
        console.log('[ContactProfileSelection]');
    }, []);

    const handleClose = () => {
        onClose();
    };

    const handleBack = () => {
        setMode('list');
    };

    const handleClickCreate = () => {
        setContactProfile({name: '', mobile: ''});
        setMode('add');
    };

    const handleClickSelect = (item) => {
        let _profile = {
            key: item.key,
            name: item.name,
            mobile: item.mobile
        };

        let errors = storeContext.errors;
        let idx = errors.indexOf('deliverType');
        if(idx>-1){
            errors.splice(idx, 1);
        }

        let deliverPrice = new DeliverUtil().calculateDeliverPrice('PICKUP', shop, 0, null);
        setStoreContext({...storeContext, deliverType: 'PICKUP', deliverPrice: deliverPrice, profile: _profile});
        onClose();
    };

    const handleClickEdit = (item) => {
        console.log('handleClickEdit');
        setContactProfile({...item});
        setMode('edit');
    };

    const handleChange = ({target})=>{
        let newProfile = {...contactProfile, [target.name]: target.value};
        setContactProfile(newProfile);
    };

    const handleReloadList = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAddressList(res.data);
                } else {
                    setAddressList([]);
                }
            });
    };

    const handleSave = () => {
        if(_validation()){
            let _contact = {
                key: contactProfile.key,
                name: contactProfile.name,
                mobile: contactProfile.mobile
            };

            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
            let newAddress = {..._contact};
            httpClient.post(url, newAddress)
                .then(res => {
                    let data = res.data;
                    if(mode === 'edit'){
                        handleReloadList();
                        setMode('list');
                        if(storeContext.deliverType === 'PICKUP' && storeContext.profile && storeContext.profile.key === data.key){
                            setStoreContext({...storeContext, deliverType: 'PICKUP', profile: data});
                        }
                    } else {
                        if(storeContext.deliverType === 'PICKUP'){
                            setStoreContext({...storeContext, deliverType: 'PICKUP', profile: data});
                            onClose();
                        } else {
                            handleReloadList();
                            setMode('list');
                        }
                    }
                });

        }

    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address/'+contactProfile.key;
        httpClient.delete(url, { method: 'DELETE' })
            .then(res => {
                handleReloadList();
                setMode('list');
                if(storeContext.profile && storeContext.profile.key === contactProfile.key){
                    setStoreContext({...storeContext, deliverType: '', profile: {name: '', mobile: ''}});
                }
            });
    };

    function _validation() {
        let result = true;
        let error = {name: false, mobile: false};
        if(!contactProfile.name){
            result = false;
            error.name = true;
        }
        if(!contactProfile.mobile ||
            contactProfile.mobile.length !== 10 ||
            !contactProfile.mobile.startsWith('0')
        ){
            result = false;
            error.mobile = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    {mode !== 'list' && <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleBack}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>}
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {mode === 'list'?t('profile.selection'):t('profile.specify')}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>

            <div style={{marginTop: '10px'}}>
                {mode === 'list' &&
                <>
                <Box display="flex" justifyContent="center" mb={1} mt={1} pl={2} pr={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth={true}
                        disableElevation
                        startIcon={<AddIcon />}
                        onClick={handleClickCreate}>
                        {t('profile.add')}
                    </Button>
                </Box>
                <CardContent>
                    <Box>
                        {addressList && addressList.map(item => {
                            return (
                                <Box key={item._id} mb={2}>
                                    <ListItem
                                        secondaryAction={
                                            <IconButton edge="end" aria-label="edit" onClick={e=>handleClickEdit(item)}>
                                                <EditIcon />
                                            </IconButton>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton sx={{border: '1px solid #e0e0e0', borderRadius: '3px'}} onClick={e=>handleClickSelect(item)}>
                                            <ListItemAvatar>
                                                <AccountBoxIcon color={"default"} sx={{ fontSize: 30 }} />
                                            </ListItemAvatar>
                                            <ListItemText primary={item.name}
                                                          secondary={<Stack>
                                                              <Box><NumberFormat value={item.mobile} displayType={'text'} format="###-###-####" /></Box>
                                                          </Stack>}
                                                          secondaryTypographyProps={{ component: 'div' }} />
                                        </ListItemButton>
                                    </ListItem>
                                </Box>
                            )
                        })}
                    </Box>
                </CardContent>
                </>
                }
                {['add','edit'].indexOf(mode) > -1 && <Box>
                    <CardContent>
                        <form autoComplete="false" noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField label={t('address.contact')}
                                               fullWidth={true}
                                               name="name"
                                               value={contactProfile.name}
                                               type="text"
                                               error={error.name}
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               required
                                               onChange={handleChange}/>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField label={t('address.mobile')}
                                               fullWidth={true}
                                               name="mobile"
                                               value={contactProfile.mobile}
                                               type="tel"
                                               InputProps={{
                                                   inputComponent: NumberFormatCustom,
                                               }}
                                               error={error.mobile}
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               required
                                               onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                    <Box m={2}>
                        <Button variant="contained"
                                disableElevation
                                size="large"
                                fullWidth={true}
                                onClick={handleSave}
                                style={{zIndex: 99, fontWeight: 'bold'}}>
                            <span>{t('common.ok')}</span>
                        </Button>
                    </Box>
                    {mode === 'edit' && <Box m={2}>
                        <Button variant="contained"
                                disableElevation
                                size="large"
                                color={"secondary"}
                                fullWidth={true}
                                onClick={handleDelete}
                                style={{zIndex: 99, fontWeight: 'bold'}}>
                            <span>{t('common.delete')}</span>
                        </Button>
                    </Box>}
                </Box>}
            </div>
        </Dialog>
    )
};