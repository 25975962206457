import React, {useContext, useEffect, useState} from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Box from "@mui/material/Box";
import {ListItem} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {httpClient} from "../../core/HttpClient";
import {StoreContext} from "../../core/Context";
import NumberFormat from "react-number-format";
import ListItemButton from "@mui/material/ListItemButton";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import ContactProfileSelection from "./ContactProfileSelection";

export default function ContactProfile({shop}) {
    console.log('ContactProfile');
    const { t } = useTranslation();
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [open, setOpen] = useState(false);
    const [addressList, setAddressList] = useState({show: false, loading: false, list: []});

    useEffect(() => {
        console.log('[ContactProfile]');
        if(!storeContext.profile.name || !storeContext.profile.mobile){
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';

            httpClient.get(url)
                .then(res => {
                    if(res.data && res.data[0]){
                        let _profile = {key: res.data[0].key, name: res.data[0].name, mobile: res.data[0].mobile}
                        setStoreContext({...storeContext, profile: _profile})
                    }
                });
        }
    },[setStoreContext, storeContext, storeContext.profile.name, storeContext.profile.mobile]);

    const handleClick = () => {
        setAddressList({show: true, loading: true, list: []});
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';

        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAddressList({show: true, loading: false, list: res.data});
                } else {
                    setAddressList({show: true, loading: false, list: []});
                }
                setOpen(true);
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    if(storeContext && storeContext.profile){
        return (<>
            <Box>
                <ListItem disablePadding>
                    <ListItemButton sx={{border: !storeContext.profile.name?'1px solid #c3c4c3':'1px solid rgba(26, 188, 156, 0.5)', borderRadius: '4px'}} onClick={handleClick}>
                        <ListItemAvatar>
                            <AccountBoxIcon color={!storeContext.profile.name?"default":"primary"} sx={{ fontSize: 30 }} />
                        </ListItemAvatar>
                        {storeContext.profile.mobile &&
                        <ListItemText primary={storeContext.profile.name}
                                      secondaryTypographyProps={{ component: 'div' }}
                                      secondary={<Stack>
                                          <Typography><NumberFormat value={storeContext.profile.mobile} displayType={'text'} format="###-###-####" /></Typography>
                                      </Stack>} />
                        }
                        {!storeContext.profile.name &&
                            <ListItemText primary={<Typography>{t('profile.specify')}</Typography>} />
                        }
                    </ListItemButton>
                </ListItem>
            </Box>
            <Box>
                {open &&
                    <ContactProfileSelection list={addressList.list}
                                         open={open}
                                         shop={shop}
                                         onClose={handleClose} />
                }
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={addressList.loading} >
                    <CircularProgress color="inherit" size={20}/>
                </Backdrop>
            </Box>
        </>);
    } else {
        return (<Box></Box>);
    }
};