import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import NumberFormat from 'react-number-format';
import {TextI18nTruncate} from "../../TextI18nTruncate";
import {getCache} from "../../../core/LocalStorageUtil";
import {getTextI18n} from "../../TextI18n";

const shapeStyles = { backgroundColor: 'orange.main', padding: '0 8px 0 8px', color: '#fff', fontSize: '0.8rem' };

const useStyles = makeStyles({
    root: {},
    qty: {
        minWidth: '1.5rem',
        textAlign: 'center'
    }
});

const selectedBox = (value) => {
    return (
        <div>
            <Box sx={shapeStyles}>{value}</Box>
        </div>
    )
};

const itemInfoBox = (item, product, t) => {
    let optionalInfo = '';
    if(item.optional){
        Object.keys(item.optional).forEach(key=>{
            if(key !== 'total'){
                if(item.optional[key].checkedOption){
                    item.optional[key].checkedOption.forEach(checkedOption=>{
                        if(checkedOption){
                            if(optionalInfo !== ''){
                                optionalInfo += ', ';
                            }
                            optionalInfo += getTextI18n(checkedOption.name);
                        }

                    })
                }

            }
        });
    }

    if(item.remark){
        if(optionalInfo !== ''){
            optionalInfo += ', ';
        }
        optionalInfo += item.remark;
    }

    return (
        <Box sx={{ color: 'text.secondary' }}>
            <span>{optionalInfo?optionalInfo:''}</span>
        </Box>
    )
};

export function ProductEdit({cid, value, onClose, onEdit}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [product, setProduct] = useState({items:[]});
    const [selectedProduct, setSelectedProduct] = useState({qty: 0, items:[]});

    console.log('[ProductEdit]');

    useEffect(() => {
        console.log('ProductEdit');
        setProduct(value);
        let cacheData = getCache(cid);
        let cart = cacheData.cart;
        if(cart.products[value._id]){
            setSelectedProduct({qty: cart.products[value._id].qty, items: cart.products[value._id].items, options: value.options});
        }
    },[value, cid]);

    return(
        <Box className={classes.root} pb={8}>
            <Card elevation={0}>
                <Box pl={2} pr={2} pt={2}>
                    <Box>
                        <Typography component={'span'} variant="h6"  style={{fontSize: '1.15rem'}}>
                            <TextI18nTruncate value={product.name} lines={2}></TextI18nTruncate>
                        </Typography>
                    </Box>
                    <Box>
                        <Typography component={'span'} variant="subtitle1" color="textSecondary">
                            <TextI18nTruncate value={product.description} lines={2}></TextI18nTruncate>
                        </Typography>
                    </Box>
                </Box>
                <Box mb={1}>
                    <List dense={false} sx={{paddingTop: 0}}>
                    { selectedProduct.items.map((item, index) => {
                        return (
                            <ListItem divider={index !== selectedProduct.items.length-1?true:false}
                                      button
                                      onClick={e => onEdit({value: item, index: index})}
                                      key={`i-${index}-${item._id}`}>
                                <Box style={{width: '100%'}}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        {itemInfoBox(item, selectedProduct, t)}
                                        <Box pr={1}>
                                            <NumberFormat value={selectedProduct.price * item.qty} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <div>
                                            <Link sx={{fontSize: '0.85rem'}}><EditIcon />{t('common.edit')}</Link>
                                        </div>
                                        {selectedBox(item.qty)}
                                    </Box>
                                </Box>
                            </ListItem>
                        )
                    })}
                </List>
                </Box>
            </Card>
        </Box>
    )
}