import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Button from "@mui/material/Button";

export default function Registration() {
    const { t } = useTranslation();

    const handleRegister = () => {
        const url = `${process.env.REACT_APP_MERCHANT_LIFF_URL}`;
        window.location.href = url;
    };

    return (
        <Box p={2} pt={0} pb={0}>
            <Box p={2} style={{margin: '1rem 0 1rem 0', backgroundColor: 'white', color: '#1B1A17'}}>
                <div style={{textAlign: 'center'}}>
                    <Typography variant="h4" component="h6"><b>{t('pricing.title')}</b></Typography>
                </div>
                <div style={{textAlign: 'center'}}>
                    <h3 style={{color: '#696969', marginTop: 0}}>{t('pricing.detail')}</h3>
                </div>
                <Box mt={2}>
                    <Button disableElevation={true} variant={"contained"} size="large" color={"success"} fullWidth={true}
                        onClick={handleRegister}>
                        <b style={{color: 'white', fontSize: '1.2em'}}>{t('account.register')}</b>
                    </Button>
                </Box>
                <Box mt={2} display={"flex"} alignItems={"center"}>
                    <Box mr={1}>
                        <CardGiftcardIcon color={"success"} />
                    </Box>
                    <Typography variant={"body1"}>
                        * {t('pricing.promo')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
};