import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from "@mui/material/ListItemButton";
import {StoreContext} from "../../../core/Context";


export default function PaymentChannel({shop}) {
    const { t } = useTranslation();
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [paymentList, setPaymentList] = useState([]);
    const [bookBanks, setBookBanks] = useState([]);

    useEffect(() => {
        console.log('[PaymentChannel]');
        if(shop && shop.payment){
            let list = [];
            if(shop.payment.cash){
                if(shop.deliveryPostpaid) {
                    list.push({method: 'cash', value: 'cash'});
                }
            }
            if(shop.payment.fundsTransfer){
                if(shop.deliveryFundsTransfer) {
                    list.push({method: 'fundsTransfer', value: 'fundsTransfer'});
                }
            }
            if(shop.payment.qrPayment){
                if(shop.deliveryQrPayment){
                    list.push({method: 'qrPayment', value: 'qrPayment'});
                }
            }

            setBookBanks(shop.payment.fundsTransferList);
            setPaymentList(list);
        }

    }, [shop]);

    const handleSelectPayment = (item)=>{
        let errors = storeContext.errors;
        let idx = errors.indexOf('paymentMethod');
        if(idx>-1){
            errors.splice(idx, 1);
        }
        setStoreContext({...storeContext, paymentMethod: item.value, errors: errors, fundsTransferList: bookBanks});
    };

    return (
        <Box>
                <Box mt={2}>
                    <Card sx={{borderRadius: '0px'}}>
                        <CardHeader style={{paddingBottom: '5px'}}
                                    title={<Box display={"flex"} alignItems={"center"}><Typography variant="h6"
                                                                                                   component="div">
                                        {t('payment.selectMethod')}
                                    </Typography>{storeContext.errors.indexOf('paymentMethod') > -1 &&
                                        <Box ml={1}><Typography variant="subtitle1" color={"red"} component="div">
                                            ({t('common.message.pleaseSelect')})
                                        </Typography></Box>}</Box>}/>
                        <Box mb={2}>
                            {paymentList.length > 0 &&
                                <RadioGroup aria-label="payment method" name="paymentMethod">
                                    <List>
                                        {paymentList.map((item, index) => (
                                            <ListItemButton
                                                key={`payment-${index}`}
                                                dense
                                                divider={paymentList.length !== index + 1}
                                                onClick={(e) => handleSelectPayment(item)}
                                            >
                                                <ListItemIcon>
                                                    <Radio value={item.method}
                                                           checked={storeContext.paymentMethod === item.method}/>
                                                </ListItemIcon>
                                                <ListItemText id={item.method}
                                                              primary={t(`payment.${item.value}`)}
                                                              secondary={t(`payment.${item.value}Description`)}
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </RadioGroup>
                            }
                            {paymentList.length === 0 &&
                                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={2}>
                                    <Typography variant={"caption"} color={"secondary"}>
                                        * {t('payment.paymentChannelNotFound')}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Card>
                </Box>
        </Box>
    );
}
