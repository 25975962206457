let pointInPolygon = require('point-in-polygon');
export default class MapUtil {

    isOutSideCircle(shop, lat, lng) {
        if(!shop){
            return true;
        }

        if(!lat || !lng){
            return true;
        }

        const cd = _distance(lat, shop.lat, lng, shop.lng);
        if(cd > shop.distance){
            return true;
        } else {
            return false;
        }
    }

    isOutSidePolygon(shop, lat, lng) {
        if(!shop){
            return true;
        }

        if(!shop.deliveryAreas){
            return true;
        }

        if(!lat || !lng){
            return true;
        }

        let result = !pointInPolygon([ lat, lng ], shop.deliveryAreas[0]);
        return result;
    }

    getDistance(shop, lat, lng) {
        if(!shop){
            return 0;
        }

        if(!lat || !lng){
            return 0;
        }

        return _distance(lat, shop.lat, lng, shop.lng);
    }

}

function _distance(lat1, lat2, lon1, lon2)
{

    // The math module contains a function
    // named toRadians which converts from
    // degrees to radians.
    lon1 =  lon1 * Math.PI / 180;
    lon2 = lon2 * Math.PI / 180;
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;

    // Haversine formula
    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;
    let a = Math.pow(Math.sin(dlat / 2), 2)
        + Math.cos(lat1) * Math.cos(lat2)
        * Math.pow(Math.sin(dlon / 2),2);

    let c = 2 * Math.asin(Math.sqrt(a));

    // Radius of earth in kilometers. Use 3956
    // for miles
    let r = 6371;

    // calculate the result
    return(c * r);
}