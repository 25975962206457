import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import CircleIcon from "@mui/icons-material/Circle";
import {TimeFromNow} from "../TimeFromNow";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";
import {useOnlineContext} from "../../core/Context";

let CART_REQUEST_TIME = process.env.REACT_APP_CART_REQUEST_TIME;

export function LastActive() {
    const { t } = useTranslation();
    let [status, setStatus] = useState('offline');
    const [onlineContext] = useOnlineContext();

    useEffect(() => {
        console.log('[LastActive]');
        if(onlineContext === 1 || dayjs().diff(onlineContext, 'minute', true) < CART_REQUEST_TIME){
            setStatus('online');
        }
    }, [onlineContext]);

    return(
        <Typography variant="caption">
            <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                <CircleIcon sx={{fontSize: 12}} color={status==='online'?'success':'secondary'} />
                {onlineContext === 1 &&
                    <Box sx={{marginLeft: '3px', marginRight: '3px'}}>
                        {t('shop.onlineNow')}
                    </Box>
                }
                {onlineContext < 1 &&
                    <>
                        <Box sx={{marginLeft: '3px', marginRight: '3px'}}>
                            {t('common.verifying')}
                        </Box>
                    </>
                }
                {onlineContext > 1 &&
                    <>
                        <Box sx={{marginLeft: '3px', marginRight: '3px'}}>
                            {t('shop.lastActive')}
                        </Box>
                        <TimeFromNow value={onlineContext} />
                    </>
                }

            </Box>
        </Typography>
    )
}