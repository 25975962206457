import React, {useState, useRef, forwardRef, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import {isDesktop} from 'react-device-detect';
import L from 'leaflet';
import {MapContainer, TileLayer, Marker, useMapEvents, Polygon} from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './style.css'
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import {StoreContext} from "../../../core/Context";
import MapUtil from "../../../core/MapUtil";
import {httpClient} from "../../../core/HttpClient";
import DeliverUtil from "../../../core/DeliverUtil";


const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

const blueIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=caution|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    }),
    redIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LocationMarker = (props) => {
    const [icon, setIcon] = useState(props.ood?blueIcon:redIcon);
    const [position, setPosition] = useState(props.position);
    const markerRef = useRef(null);
    const map = useMapEvents({
        move() {
            const marker = markerRef.current;
            if (marker != null) {
                marker.setLatLng(map.getCenter());
            }
        },
        moveend() {
            let center = map.getCenter();
            if(position){
                let ood = new MapUtil().isOutSidePolygon(props.shop, center.lat, center.lng);
                if(ood){
                    setIcon(blueIcon);
                } else {
                    setIcon(redIcon);
                }
                setPosition([center.lat, center.lng]);
            } else {
                setIcon(blueIcon);
                setPosition([center.lat, center.lng]);
            }

        }
    });

    return position === null ? null : (
        <Marker position={position} ref={markerRef} icon={icon}></Marker>
    )
};

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

const MemoizedMap = React.memo(({profile,outOfDelivery, shop, setMap})=>{
    let coordinates = {
        lat: shop.lat,
        lng: shop.lng,
        ood: outOfDelivery
    };
    if(profile && profile.lat && profile.lng){
        coordinates.lat = profile.lat;
        coordinates.lng = profile.lng;
    }

    console.log('shop', shop);

    return(<MapContainer center={[coordinates.lat, coordinates.lng]}
                         zoom={15}
                         {...interactionOptions}
                         ref={setMap}
                         scrollWheelZoom={false}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker effectOn={this}
                        shop={shop}
                        ood={coordinates.ood}
                        position={[coordinates.lat, coordinates.lng]} />
        {shop && shop.deliveryAreas &&
            <>
                <Polygon
                    positions={shop.deliveryAreas}
                    color="#1abc9c"
                    fillColor="#1abc9c" />
            </>
        }

    </MapContainer>)
});

export default function LocationEditor({open, shop, onClose}) {
    const { t } = useTranslation();
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [contactProfile, setContactProfile] = useState({name: '', mobile: '', landmark: ''});
    const [error, setError] = useState({name: false, mobile: false, landmark: false});
    const [map, setMap] = useState(null);

    console.log('LocationEditor');

    useEffect(() => {
        console.log('[LocationEditor]', storeContext.profile);
        if(storeContext.profile){
            setContactProfile({...storeContext.profile});
        }

    }, [storeContext]);

    const handleChange = ({target})=>{
        let newContactProfile = {...contactProfile, [target.name]: target.value};
        setContactProfile(newContactProfile);
    };

    const handleClose = () => {
        let newContactProfile = {...contactProfile};
        setContactProfile(newContactProfile);
        setError({name: false, mobile: false, landmark: false});
        onClose();
    };

    const handleSave = () => {
        if(_validation()){
            let c = map.getCenter();
            let ood = new MapUtil().isOutSidePolygon(shop, c.lat, c.lng);
            let _profile = {
                key: contactProfile.key,
                name: contactProfile.name,
                mobile: contactProfile.mobile,
                landmark: contactProfile.landmark,
                lat: c.lat,
                lng: c.lng
            };

            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
            let newAddress = {..._profile};
            httpClient.post(url, newAddress)
                .then(res => {
                    let data = res.data;
                    const distance = new MapUtil().getDistance(shop, _profile.lat, _profile.lng);
                    let deliverPrice = new DeliverUtil().calculateDeliverPrice('DELIVERY', shop, distance, null);
                    setStoreContext({...storeContext, deliverType: 'DELIVERY', deliverPrice: deliverPrice, profile: data, outOfDelivery: ood, errors: []});
                    // setStoreContext({...storeContext, deliverType: 'DELIVERY', profile: data, outOfDelivery: ood});
                    onClose();
                });
        }

    };

    function _validation() {
        let result = true;
        let _error = {name: false, mobile: false, landmark: false};
        if(!contactProfile.name){
            result = false;
            _error.name = true;
        }
        if(!contactProfile.mobile ||
            contactProfile.mobile.length !== 10 ||
            !contactProfile.mobile.startsWith('0')
        ){
            result = false;
            _error.mobile = true;
        }

        if(!contactProfile.landmark){
            result = false;
            _error.landmark = true;
        }

        if(!result){
            setError(_error);
        }

        return result;
    }

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('address.specifyLocation')}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MemoizedMap shop={shop} profile={storeContext.profile} outOfDelivery={storeContext.outOfDelivery} setMap={setMap} />
            <div style={{height: '300px', marginTop: '10px'}}>
                <CardContent>
                    <form autoComplete="false" noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.contact')}
                                           fullWidth={true}
                                           name="name"
                                           value={contactProfile.name}
                                           type="text"
                                           error={error.name}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.mobile')}
                                           fullWidth={true}
                                           name="mobile"
                                           value={contactProfile.mobile}
                                           type="tel"
                                           InputProps={{
                                               inputComponent: NumberFormatCustom,
                                           }}
                                           error={error.mobile}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.landmark')}
                                           fullWidth={true}
                                           name="landmark"
                                           value={contactProfile.landmark}
                                           type="text"
                                           error={error.landmark}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange}/>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </div>
            <DialogActions>
                <Box mb={4} style={{width: '100%'}}>
                    <Button variant="contained"
                            size="large"
                            fullWidth={true}
                            onClick={handleSave}
                            disableElevation
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.ok')}</span>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
};