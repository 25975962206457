import React, {forwardRef} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import {LinearProgress, Step, StepLabel, Stepper} from "@mui/material";
import StepContext from "@mui/material/Step/StepContext";

const steps = [
    'orders',
    'confirm',
    'prepare',
    'deliver'
];

// const CustomisedConnector = styled(StepConnector)(({theme }) => ({
//     [`&.${stepConnectorClasses.active}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             backgroundColor: "#1abc9c"
//         },
//     },
//     [`&.${stepConnectorClasses.completed}`]: {
//         [`& .${stepConnectorClasses.line}`]: {
//             backgroundColor: "#1abc9c",
//         },
//     },
//     [`& .${stepConnectorClasses.line}`]: {
//         height: 2,
//         border: "10px",
//         backgroundColor: "#9e9e9e",
//         borderRadius: 1,
//     },
// }));

const CustomisedConnector = forwardRef((props, ref) => {
    const {
        disabled,
        completed
    } = React.useContext(StepContext);

    if(completed){
        return <LinearProgress variant="determinate" style={{
            height: '3px',
            position: 'absolute',
            top: '12px',
            left: 'calc(-50% + 20px)',
            right: 'calc(50% + 20px)'
        }} value={100} />;
    } else if(disabled){
        return <LinearProgress variant="determinate" style={{
            height: '3px',
            position: 'absolute',
            top: '12px',
            left: 'calc(-50% + 20px)',
            right: 'calc(50% + 20px)',
            opacity: '0.6'
        }} value={100} color={"default"} />;
    } else {
        return <LinearProgress style={{
            height: '3px',
            position: 'absolute',
            top: '12px',
            left: 'calc(-50% + 20px)',
            right: 'calc(50% + 20px)'}} />;
    }

});

export default function FoodOrderStatus({value}) {
    console.log('FoodOrderStatus');
    const { t } = useTranslation();

    return (
        <Box>
            <Stepper activeStep={value.timeLine.step}
                     alternativeLabel
                     connector={<CustomisedConnector />}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{t(`timeLines.${label}`)}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
