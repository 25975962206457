import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ContactUs() {
    const { t } = useTranslation();

    return (
        <div>
            <Box p={1} m={1} style={{background: 'white', color: 'rgba(0, 0, 0, 0.87)'}}>
                <Box mb={2}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}><b>{t('contactUs')}</b></Typography>
                </Box>
                <Typography variant="body1" component="div">{t('company.name')}</Typography>
                <Typography variant="body1" component="div">{t('company.address1')}</Typography>
                <Typography variant="body1" component="div">{t('company.address2')}</Typography>
                <Typography variant="body1" component="div">{t('company.tel')}</Typography>
                <Typography variant="body1" component="div">{t('company.lineId')}</Typography>
                <Typography variant="body1" component="div" mb={1}>{t('company.email')}</Typography>
                <Typography variant="body1" component="div">{t('company.openTime')}</Typography>
                <Typography variant="body1" component="div">{t('company.time')}</Typography>
            </Box>
        </div>
    )
};