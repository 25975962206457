import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CustomerOrdering from '../CustomerOrdering'
import CustomerOrdersHistory from '../CustomerOrdersHistory'

function TabPanel(props) {
    const {children, value, index, classes, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Container disableGutters={true}>
                    <Box>
                        {children}
                    </Box>
                </Container>
            )}
        </div>
    );
}

export default function CustomerOrders() {
    const { t } = useTranslation();
    const search = useLocation().search;
    const mode = new URLSearchParams(search).get('m');
    const [value, setValue] = React.useState(mode==='history'?1:0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        value={value}
                        onChange={handleChange}
                        aria-label="orders">
                        <Tab label={t('menu.ordering')} />
                        <Tab label={t('menu.orderHistory')} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <CustomerOrdering />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <CustomerOrdersHistory />
                </TabPanel>
            </Box>
        </div>
    )
};