import React, {useState, useEffect, useCallback} from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import StarsIcon from '@mui/icons-material/Stars';
import CircularProgress from '@mui/material/CircularProgress';
import ShopInfo from '../Shop/ShopInfo'
import {httpClient} from "../../core/HttpClient";
import {TextI18n} from "../TextI18n";

export default function Home() {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const [state, setState] = useState({image:{}});
    const [loading, setLoading] = useState(true);
    const [expired, setExpired] = useState(false);

    const loyalistFunction = useCallback(() => {
        return new Promise( (resolve, reject) => {

            const token = localStorage.getItem('token');
            if (token) {
                let url =  process.env.REACT_APP_API_BASE_URL + '/secure/customer/loyalist';
                httpClient.get(url)
                    .then(res => {
                        if(res.data){
                            resolve(res.data);
                        } else {
                            resolve({
                                "customer": {_id: '', membership: false, point: 0},
                                "loyalty": false
                            });
                        }
                    }).catch(e=>{
                    resolve({
                        "customer": {_id: '', membership: false, point: 0},
                        "loyalty": false
                    });
                });
            }
            else {
                resolve({
                    "customer": {_id: '', membership: false, point: 0},
                    "loyalty": false
                });
            }
        });
    }, []);

    console.log('[Home]');
    useEffect(() => {

        let tasks = [
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account'),
            loyalistFunction(),
        ];
        Promise.all(tasks)
            .then(rs=>{
                let shopInfo = {};
                if(rs[0] && rs[0].data){
                    if(rs[0].data.expired){
                        setExpired(true);
                    }
                    shopInfo = rs[0].data;
                }

                if(rs[1]){
                    shopInfo.loyalty = rs[1].loyalty;
                    shopInfo.customer = rs[1].customer;
                    if(rs[1].likes){
                        let likeProduct = {};
                        rs[1].likes.forEach(like=>{
                            likeProduct[like] = true;
                        });
                        shopInfo.likeProduct = likeProduct;
                    }
                }

                setState(shopInfo);
                setLoading(false);
            });
    }, [loyalistFunction]);

    const handleClick = (prefix) => {
        let _cid = cid || localStorage.getItem('cid');
        history.push(`/${prefix}/${_cid}`);
    };

    return (
        <div>
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <Box>
                {state.status === 'denied' &&
                <Grid item xs={12} md={7}>
                    <Box p={2}>
                        <Alert severity="error" style={{borderRadius: 0}} >{t('common.message.reject')}</Alert>
                    </Box>
                </Grid>
                }
                {state.status === 'activated' &&
                <Grid container spacing={1}>
                    <Grid item xs={12} md={5}>
                        <ShopInfo shop={state} time={true}/>
                    </Grid>
                    {!expired &&
                    <Grid item xs={12} md={7}>
                        <Box p={1}>
                            <Button variant="contained"
                                    fullWidth={true}
                                    size="large"
                                    disableElevation
                                    onClick={e => handleClick('hm/sm')}
                                    startIcon={<MenuBookIcon />}>
                                <b>{t('menu.onlineMenu')}</b>
                            </Button>
                        </Box>
                        {(state.delivery || state.pickup) &&
                        <Box p={1}>
                            <Button variant="contained"
                                    fullWidth={true}
                                    size="large"
                                    disableElevation
                                    onClick={e => handleClick('hm/dr')}
                                    startIcon={<FastfoodIcon />}>
                                <b>{t('menu.onlineOrders')}</b>
                            </Button>
                        </Box>
                        }
                        {state.reward &&
                        <Box p={1}>
                            <Button variant="contained"
                                    fullWidth={true}
                                    size="large"
                                    disableElevation
                                    onClick={e => handleClick('hm/rw')}
                                    startIcon={<StarsIcon />}>
                                <b>{t('menu.reward')}</b>
                            </Button>
                        </Box>
                        }
                    </Grid>
                    }
                    {expired &&
                    <Grid item xs={12} md={7}>
                        <Box p={2}>
                            <Alert severity="warning" style={{borderRadius: 0}}>
                                <TextI18n value={state.name}></TextI18n>
                            </Alert>
                        </Box>
                    </Grid>
                    }
                </Grid>
                }
            </Box>
            }
        </div>
    );
}
