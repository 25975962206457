import React, {useEffect, useCallback} from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import jwt_decode from "jwt-decode";
import {useHistory} from "react-router-dom";
import liff from "@line/liff";
// const liff = window.liff;

const lineAuthentication = async(cb) => {
    console.log('lineAuthentication');
    const liffId = process.env.REACT_APP_LINE_LIFF_ID;
    try{
        // withLoginOnExternalBrowser = false (User don't have LINE App should not redirect to login's LINE)
        await liff.init({ liffId: liffId, withLoginOnExternalBrowser: false }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            const idToken = liff.getIDToken();
            const accessToken = liff.getAccessToken();
            return cb({idToken: idToken, accessToken: accessToken});
        }else{
            // external browser
            console.log('no logged in');
            return cb(null);
        }
    }catch(e){}
};

export default function Refresh() {
    const history = useHistory();
    const cid = localStorage.getItem('cid');
    const mode = localStorage.getItem('mode');

    const tokenProcess = useCallback((mode) => {
        lineAuthentication(lineToken=> {
            if(lineToken && cid){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({accessToken: lineToken.accessToken, idToken: lineToken.idToken, redirectUri: process.env.REACT_APP_BASE_URL+`/hm/login?cid=`+cid, cid: cid})
                };
                const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
                fetch(url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if(data.token){
                            localStorage.setItem('token', data.token);
                            let customer = {
                                displayName: data.displayName,
                                pictureUrl: data.pictureUrl
                            };
                            localStorage.setItem('customer', JSON.stringify(customer));
                            history.push(`/hm/${mode}/${cid}`);
                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('customer');
                            window.location = `/hm/login`;
                        }
                    });
            } else {
                window.location = `/hm/login`;
            }
        });
    }, [cid, history]);

    useEffect(() => {
        console.log('[Refresh]');

        // get customer token by line
        let token = localStorage.getItem('token');
        if(!token){
            tokenProcess(mode);
        } else {
            let decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000) {
                console.log('expired');
                tokenProcess(mode);
            } else {
                console.log('not expired');
                history.push(`/hm/${mode}/${cid}`);
            }
        }
    }, [tokenProcess, mode, cid, history]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
        </div>
    )
}