import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Lottie from "lottie-react";
import chickyAnimation from "../../lotties/chicky.json";

export default function ErrorPage() {
    const { t } = useTranslation();
    useEffect(() => {
        console.log('[ErrorPage]');

    }, []);

    return (
        <div>
            <Box justifyContent={"center"} m={2}>
                <Lottie animationData={chickyAnimation} loop={true} />
                <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
            </Box>
        </div>
    );
}
