import React, {forwardRef, useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import LocationStatic from '../LocationStatic';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppBar from "@mui/material/AppBar";
import LocationView from "../LocationView";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeliveryView({profile, customer}) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleViewMap = () => {
        setOpen(true);
    };

    const handleClose = () => {
        console.log('handleClose');
        setOpen(false);
    };

    return (
        <div>
            {profile &&
            <>
                <Box display="flex" justifyContent="center" mt={1}>
                    <Typography variant="h6" component="div">
                        {t('address.delivery')}
                    </Typography>
                </Box>
                <Box style={{margin: '16px',
                    border: '1px solid #e7e7e7',
                    borderRadius: '5px'}}>
                    <Box style={{height: '156px'}}>
                        {!open &&
                            <LocationStatic lat={profile.lat} lng={profile.lng} zoom={15}/>
                        }
                    </Box>
                    <Box pb={1} pt={2} pl={2} pr={2}>
                        <List dense={true}>
                            <ListItem alignItems="flex-start" sx={{padding: 0}}>
                                <ListItemAvatar>
                                    <Avatar alt="Customer" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${customer}`} />
                                </ListItemAvatar>
                                <ListItemText primary={<Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                    {t('address.contactShort')} : {profile.name}
                                </Typography>} secondary={
                                    <Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                        {t('address.mobileShort')} : {profile.mobile && <NumberFormat value={profile.mobile} displayType={'text'} format="###-###-####" />}{!profile.mobile && <span>{t('common.notYetSpecify')}</span>}
                                    </Typography>
                                }></ListItemText>
                            </ListItem>
                        </List>
                        {profile.landmark &&
                            <Box>
                                <Typography variant="body2" component="div" style={{flexGrow: 1}}>
                                    {t('address.landmarkAlt')} : {profile.landmark}
                                </Typography>
                            </Box>
                        }
                    </Box>
                    <Box>
                        <Button variant={"contained"}
                                disableElevation={true}
                                color={"grey"}
                                fullWidth={true}
                                onClick={handleViewMap}
                                sx={{borderRadius: 0}}>
                            {t('cart.mapView')}
                        </Button>
                    </Box>
                </Box>
            </>
            }
            {open &&
            <Dialog fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}>
                <AppBar elevation={0} color="default">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={handleClose}
                        >
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Typography variant="body">
                            {t('address.delivery')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <LocationView lat={profile.lat} lng={profile.lng} zoom={15} t={t} profile={profile} />
            </Dialog>
            }
        </div>
    );
}
