import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import ReactMarkdown from "react-markdown";

export default function Terms() {
    const [content, setContent] = useState("");

    useEffect(() => {

        let url = `/terms.md`;
        fetch(url)
            .then((res) => res.text())
            .then((text) => setContent(text));
    }, []);

    return (
        <div>
            <Box p={1} m={1} style={{background: 'white', color: 'rgba(0, 0, 0, 0.87)'}}>
                <ReactMarkdown children={content} />
            </Box>
        </div>
    )
};