import React, { useEffect } from 'react';
import RecommendNearly from "./RecommendNearly";

export default function RecommendPage() {

    console.log('LandingPage');

    useEffect(() => {

    }, []);

    return (
        <div>
            <RecommendNearly />
        </div>
    );
}
