import React from 'react';

function nFormatter(num, digits) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "B" },
        { value: 1e12, symbol: "T" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export function getNFormat(num) {
    return nFormatter(num, 1);
}

export function NFormatter(props) {
    let _value = props.value;
    if(props.suffix){
        _value--;
    }
    let numFormatted = nFormatter(_value, 1);
    return(
        <span style={{wordWrap: 'break-word'}}>
            {numFormatted} {props.suffix}
        </span>
    )
}