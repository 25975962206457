import axios from 'axios'

axios.interceptors.request.use(async (config)=> {

    const jwtToken = localStorage.getItem('token');
    if (jwtToken) {
        config.headers = {...config.headers, 'Authorization': `Bearer ${jwtToken}` }
    }

    const cid = localStorage.getItem('cid');
    if (cid) {
        config.headers = {...config.headers, 'client-id': cid }
    }

    return config;
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        window.location.href = '/refresh';
    }
    return error;
});

export const httpClient = axios;