import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import {Context} from "../../../core/Context";
import Divider from "@mui/material/Divider";

export default function CartDiscount({mode}) {
    const { t } = useTranslation();
    const [context] = useContext(Context);

    return (
        <div>
            {mode === 'm' &&
                <>
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         style={{width: '100%'}}>
                        <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
                            <span>{t('discount.title')}</span>
                        </Box>
                        <Typography variant={"body1"}>
                            <NumberFormat
                                value={0}
                                displayType={'text'}
                                thousandSeparator={true} prefix={'฿'}/>
                        </Typography>
                    </Box>
                    <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                </>
            }
            {mode === 'o' && context.discount &&
            <>
                {(context.discount.food + context.discount.delivery) === 0 &&
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%'}}>
                            <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
                                <span>{t('discount.title')}</span>
                            </Box>
                            <Typography variant={"body1"}>
                                <NumberFormat
                                    value={0}
                                    displayType={'text'}
                                    thousandSeparator={true} prefix={'฿'}/>
                            </Typography>
                        </Box>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    </>
                }
                {context.discount.food > 0 &&
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant={"body1"} color={"green"}>
                                {t('discount.food')}
                            </Typography>
                            <Typography variant={"body1"} color={"green"}>
                                <NumberFormat value={context.discount.food} displayType={'text'} thousandSeparator={true} prefix={'- ฿'}/>
                            </Typography>
                        </Box>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    </>
                }
                {context.discount.delivery > 0  &&
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant={"body1"} color={"green"}>
                                {t('discount.delivery')}
                            </Typography>
                            <Typography variant={"body1"} color={"green"}>
                                <NumberFormat value={context.discount.delivery} displayType={'text'} thousandSeparator={true} prefix={'- ฿'}/>
                            </Typography>
                        </Box>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    </>
                }
            </>
            }
        </div>
    );
}
