import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {},
    footer: {
        background: '#ebebeb'
    }
});

export default function FooterPoweredBy() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.footer}>
            <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                <Typography variant="body1" component="div" sx={{ flexGrow: 1 }} align="center">{t('poweredBy')}</Typography>
            </Box>
        </div>
    );
}
