import React, {useState, useEffect, useReducer, useRef} from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../../core/HttpClient";
import {TextI18n, getTextI18n} from "../../TextI18n";
import {DateTime} from "../../DateTime";
import InfiniteScroll from "react-infinite-scroll-component";
import _ from "lodash";

let MyAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo.png`;
    let name = '';
    if(value && value.name) {
        name = getTextI18n(value.name);
    }
    if(value && value.image) {
        imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/s/${value.image.name}`;
    }
    return (<Avatar
        variant={"square"}
        alt={name}
        src={imageUrl}
    />)
};

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_RELOAD":
            return {
                ...state,
                isLoading: true,
                hasError: false,
                hasNext: false,
                cursor: '',
                hits: [],
            };
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursorKey === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch, cancelToken) {
    dispatch({ type: "FETCH_START" });
    try {
        console.log('fetchHits', query.cursor);
        let url = process.env.REACT_APP_API_BASE_URL + '/private/customer/orders/history?c='+query.cursor+'&text='+query.text;
        httpClient.get(url,
            {cancelToken: cancelToken}
        ).then(res => {
            if(Array.isArray(res.data)){
                dispatch({ type: "FETCH_SUCCESS", payload: res.data, cursorKey: query.cursor });
            } else {
                dispatch({ type: "FETCH_FAILURE" });
            }
        }).catch(e=>{
            console.log('error', e);
            dispatch({ type: "FETCH_FAILURE" });
        });
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

function init() {
    return {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null,
        rate: 0,
    };
}

export default function CustomerOrdersHistory() {
    const { t } = useTranslation();
    const history = useHistory();
    const [query, setQuery] = useState({cursor: '', text: ''});
    const [{ hits, hasNext, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null,
        rate: 0
    }, init);

    const debouncedFetchHits = useRef(
        _.debounce(
            (query, cancelToken) => fetchHits(query, dispatch, cancelToken),
            500
        )
    ).current;

    useEffect(() => {
        console.log('[CustomerOrdersHistory]');
        const { token } = httpClient.CancelToken.source();
        debouncedFetchHits(query, token);
    }, [debouncedFetchHits, query]);

    const handleFetchData = (cursor) =>{
        setQuery({...query, cursor: cursor}); // trig to reload data by useReducer
    };

    // const handleFilterChange = (event) =>{
    //     dispatch({ type: "FETCH_RELOAD", payload: [], cursorKey: '' });
    //     setQuery({cursor: '', text: event.target.value});
    // };

    const handleClickItem = (foodOrder)=>{
        history.push(`/hm/c/order/${foodOrder.uid}?m=history`);
    };

    return (
        <div>
            <Box>
                {!isLoading && hits.length === 0 &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <Typography variant="body1" color="textSecondary"
                                sx={{fontSize: '0.85rem'}}>{t('order.noOrderHistory')}</Typography>
                </Box>
                }
                {hits.length > 0 &&
                <InfiniteScroll
                    dataLength={hits.length} //This is important field to render the next data
                    next={e=>handleFetchData(cursor)}
                    hasMore={hasNext}
                >
                    <Box pb={12}>
                        <List className="SortableList">
                            {hits.map((value, index) => (
                                <ListItem key={`item-${value.items._id}`}
                                          divider={index !== hits.length - 1 ? true : false}
                                          onClick={e => handleClickItem(value.items)}
                                          button>
                                    <ListItemAvatar>
                                        <MyAvatar value={value.items.accountInfo} />
                                    </ListItemAvatar>
                                    <ListItemText secondary={<Box display="flex" justifyContent="space-between" component="span">
                                        <Typography noWrap={true} component="span">
                                            {t('common.status')}
                                        </Typography>
                                        <Typography noWrap={true} component="span" color={value.items.status === 'completed'?'green':'red'}>
                                            {t(`order.${value.items.status}`)}
                                        </Typography>
                                    </Box>}>
                                        <TextI18n value={value.items.accountInfo.name}></TextI18n>
                                        <div>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography noWrap={true}>
                                                    <span>
                                                        {value.items.transactionNo}
                                                    </span>
                                                </Typography>
                                                <div>
                                                    <span><DateTime value={value.items.orderDate}
                                                                    format="DD MMM YYYY HH:mm"></DateTime></span>
                                                </div>
                                            </Box>
                                        </div>
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </InfiniteScroll>
                }
                {isLoading &&
                    <Box display="flex" justifyContent="center" mt={4}>
                        <CircularProgress size={20}/>
                    </Box>
                }
            </Box>
        </div>
    );
}
