import React from 'react';

export function getTextMarking(data) {
    if(data && data.length > 1){
        let firstChar = data.substring(0, 1); // => "Tabs1"
        let lastChar = data.substring(data.length - 1); // => "1"
        return `${firstChar}*****${lastChar}`;
    } else {
        return '*****';
    }
}

export function TextMarking(props) {
    return(
        <span style={{wordWrap: 'break-word'}}>
            {getTextMarking(props.value)}
        </span>
    )
}