import React, {forwardRef, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import {useHistory, useParams} from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {Helmet, HelmetProvider} from "react-helmet-async";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Typography from "@mui/material/Typography";
import {httpClient} from "../../core/HttpClient";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import {DialogTitle, FormControlLabel} from "@mui/material";
import Link from "@mui/material/Link";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import StarsIcon from '@mui/icons-material/Stars';
import Badge from "@mui/material/Badge";
import RewardItems from "./RewardItems";
import liff from "@line/liff";

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

export default function RewardPage() {
    const { t } = useTranslation();
    const { cid, mode } = useParams();
    const history = useHistory();

    const [shopInfo, setShopInfo] = useState({});
    const [register, setRegister] = useState({_id: '', firstName: '', lastName: '', mobile: '', gender: 'male'});
    const [rewardPoint, setRewardPoint] = useState({_id: '', remaining: 0, rewards: [], redeems: []});
    const [loading, setLoading] = useState(true);
    const [accept, setAccept] = useState(false);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        console.log('[RegistrationPage]');
        let token = localStorage.getItem('token');

        if(token){
            let tasks = [
                httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account'),
                httpClient.get(process.env.REACT_APP_API_BASE_URL + '/secure/customer/profile'),
                httpClient.get(process.env.REACT_APP_API_BASE_URL + '/secure/reward/point')
            ];

            Promise.all(tasks).then(rs=> {
                let shopInfo = {};
                if (rs[0] && rs[0].data) {
                    shopInfo = rs[0].data;
                }

                if(rs[1] && rs[1].data){
                    setRegister({
                        _id: rs[1].data._id || '',
                        firstName: rs[1].data.firstName || '',
                        lastName: rs[1].data.lastName || '',
                        gender: rs[1].data.gender || 'male',
                        mobile: rs[1].data.mobile || '',
                    });
                }

                if(rs[2] && rs[2].data && rs[2].data._id){
                    setRewardPoint({_id: rs[2].data._id, remaining: 0, rewards: [], redeems: []});
                }

                setShopInfo(shopInfo);
                setLoading(false);
            });
        } else {
            if (!liff.isLoggedIn()) {
                let ua = navigator.userAgent || navigator.vendor || window.opera;
                let isInAppBrowser =  (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
                    || (ua.indexOf("Instagram") > -1);
                // Facebook, Instagram In-App Browser detect.
                if(isInAppBrowser && ["ios","android"].indexOf(liff.getOS()) > -1){
                    window.location.href = `https://line.me/R/app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}?reward=true`;
                } else {
                    const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login?cid=${cid}&mode=${mode}&reward=true`;
                    liff.login({ redirectUri: redirectUri});
                }
            } else {
                window.location.href = `${process.env.REACT_APP_BASE_URL}/hm/${mode}/${cid}`;
            }
        }


    }, [cid, mode]);

    const validation = ()=>{
        let _errors = [];
        if(!register.firstName){
            _errors.push("firstName");
        }
        if(!register.lastName){
            _errors.push("lastName");
        }
        if(!register.mobile ||
            register.mobile.length !== 10 ||
            !register.mobile.startsWith('0')
        ){
            _errors.push("mobile");
        }

        setErrors(_errors);

        return _errors.length===0;
    }

    const handleSave = () => {
        let validate = validation();
        if(validate){
            setLoading(true);
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/reward/register';
            let data = {
                ...register
            };
            httpClient.post(url, data)
                .then(res => {
                    if(res.data){
                        setRewardPoint({_id: res.data._id, remaining: 0, rewards: [], redeems: []});
                    }
                    setLoading(false);
                });
        }
    };

    const handleAccept = () => {
        let _accept = !accept;
        setAccept(_accept);
    };

    const handleAcceptAgreement = () => {
        setAccept(true);
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBack = () => {
        history.push(`/hm/${mode}/${cid}`);
    };

    const handleRewardHistory = () => {

    };

    const handleEditProfile = () => {
        history.push(`/hm/${mode}/${cid}/profile`);
    };

    const handleChange = ({target})=>{
        let _register = {...register, [target.name]: target.value};
        setRegister(_register);
    };

    return (
        <div>
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <Box>
                    {shopInfo.name &&
                        <HelmetProvider>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>{shopInfo.name.i18n.th}</title>
                            </Helmet>
                        </HelmetProvider>
                    }
                    <header>
                        <AppBar elevation={0}
                                color="light"
                                position="static"
                                sx={{borderBottom: '1px solid #e7e7e7'}}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit"
                                            onClick={handleBack}
                                            aria-label="menu" sx={{ mr: 2 }}>
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                                {shopInfo.name &&
                                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                        <Box display="flex" justifyContent="start" alignItems={"center"}>
                                            <Box><Typography
                                                variant={"body1"}>{shopInfo.name.i18n.th}</Typography></Box>
                                        </Box>
                                    </Typography>
                                }
                                {register._id &&
                                    <IconButton edge={"end"}
                                                onClick={handleEditProfile}>
                                        <Avatar alt={`${register.firstName} ${register.lastName}`}
                                                sx={{width: '32px', height: '32px'}}
                                                src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${register._id}`}/>
                                    </IconButton>
                                }
                            </Toolbar>
                        </AppBar>
                    </header>

                    {rewardPoint._id === '' &&
                        <Box>
                            <Box mt={2} mb={2} display={"flex"} justifyContent={"center"}>
                                <Typography variant={"h5"}>{t('reward.register')}</Typography>
                            </Box>
                            <Box m={2} mt={0}>
                                <TextField label={t('reward.firstName')}
                                           fullWidth={true}
                                           required={true}
                                           name="firstName"
                                           value={register.firstName}
                                           type="text"
                                           error={errors.indexOf('firstName') > -1}
                                           onChange={handleChange}/>
                            </Box>
                            <Box m={2} mt={0}>
                                <TextField label={t('reward.lastName')}
                                           fullWidth={true}
                                           required={true}
                                           name="lastName"
                                           value={register.lastName}
                                           type="text"
                                           error={errors.indexOf('lastName') > -1}
                                           onChange={handleChange}/>
                            </Box>
                            <Box m={2} mt={0}>
                                <FormControl variant="outlined"
                                             fullWidth={true}>
                                    <InputLabel id="reward-select-gender-label">{t('reward.gender')}</InputLabel>
                                    <Select
                                        native
                                        labelId="reward-select-gender-label"
                                        label={t('reward.gender')}
                                        name="gender"
                                        onChange={handleChange}
                                        value={register.gender}>
                                        <option value="male">{t('reward.male')}</option>
                                        <option value="female">{t('reward.female')}</option>
                                        <option value="lgbt">{t('reward.lgbt')}</option>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box m={2} mt={0}>
                                <TextField label={t('reward.mobile')}
                                           fullWidth={true}
                                           name="mobile"
                                           value={register.mobile}
                                           type="tel"
                                           InputProps={{
                                               inputComponent: NumberFormatCustom,
                                           }}
                                           error={errors.indexOf('mobile') > -1}
                                           InputLabelProps={{
                                               shrink: register.mobile !== '',
                                           }}
                                           required
                                           onChange={handleChange} />
                            </Box>
                            <Box m={2} mt={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={accept} onChange={handleAccept} name="accept" />
                                    }
                                    label={<Box>
                                        <Link
                                            component="button"
                                            variant="body1"
                                            onClick={() => {
                                                setOpen(true);
                                            }}>
                                            {t('reward.agree')}
                                        </Link>

                                    </Box>}
                                />
                            </Box>
                            <Box m={2} mt={2}>
                                <Button variant="contained"
                                        size="large"
                                        disabled={!accept}
                                        fullWidth={true}
                                        onClick={handleSave}
                                        style={{zIndex: 99, fontWeight: 'bold'}}>
                                    <span>{t('reward.confirm')}</span>
                                </Button>
                            </Box>
                        </Box>
                    }
                    {rewardPoint._id !== '' &&
                        <Box mt={4}>
                            <Box display={"flex"} justifyContent={"center"} mt={1}>
                                <IconButton edge="start" color="inherit"
                                            onClick={handleRewardHistory}
                                            aria-label="menu" sx={{ mr: 2 }}>
                                    <Badge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        badgeContent={
                                            <Box
                                                style={{
                                                    backgroundColor: "white",
                                                    borderRadius: '50%',
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            ><StarsIcon sx={{color: '#ffdd60', }} fontSize={"large"} />
                                            </Box>
                                        }
                                    >
                                        <Box component="span" sx={{ borderRadius: '50%', border: '3px solid #1abc9c', width: 110, height: 110 }}>
                                            <Box display={"flex"} justifyContent={"center"} >
                                                <Typography variant={"h3"} mt={2}>
                                                    <strong><NumberFormat value={rewardPoint.remaining} displayType={'text'}
                                                                          thousandSeparator={true} /></strong>
                                                </Typography>
                                            </Box>
                                            <Box display={"flex"} justifyContent={"center"} >
                                                <Typography variant={"caption"}>
                                                    <strong>{t('reward.points')}</strong>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Badge>
                                </IconButton>
                            </Box>
                            <Box mt={3}>
                                <RewardItems rewardPoint={rewardPoint} />
                            </Box>
                        </Box>
                    }

                </Box>
            }
            <Dialog open={open}
                    onClose={handleClose}>
                <DialogTitle>{t('reward.agreement')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" component="div">
                        1. {t('reward.agreements.1')}
                    </Typography>
                    <Typography variant="body1" component="div">
                        2. {t('reward.agreements.2')}
                    </Typography>
                    <Typography variant="body1" component="div">
                        3. {t('reward.agreements.3')}
                    </Typography>
                    <Typography variant="body1" component="div">
                        4. {t('reward.agreements.4')}
                    </Typography>
                    <Typography variant="body1" component="div">
                        5. {t('reward.agreements.5')}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Box mb={1} style={{width: '100%'}}>
                        <Button variant="contained"
                                size="large"
                                disableElevation={true}
                                fullWidth={true}
                                onClick={handleAcceptAgreement}
                                style={{zIndex: 99, fontWeight: 'bold'}}>
                            <span>{t('reward.accept')}</span>
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};