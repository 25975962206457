import React from 'react';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import CookieConsent from "react-cookie-consent";
import Pricing from './Pricing';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {makeStyles} from "@mui/styles";
import Registration from "./Registration";
import Button from "@mui/material/Button";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import useMediaQuery from '@mui/material/useMediaQuery';
import {AnimatePresence, motion} from "framer-motion"
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme) => ({
    btnFullWidth: {
        display: 'flex',
        flexDirection: 'column',
    },
    heroText: {
        fontFamily: 'Mitr !important',
        color: '#1abc9c'
    },
    highlightText: {
        fontFamily: 'Mitr !important',
        color: '#ff6600'
    }
}));

export default function HomePage() {
    const { t } = useTranslation();
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const classes = useStyles();


    //display: flex;flex-direction: column;

    const handleGoToAppStore = () => {
        console.log('handleGoToAppStore');
        window.location.href = 'https://apps.apple.com/th/app/foodkub-merchant/id6447616328';
    };
    const handleGoToPlayStore = () => {
        console.log('handleGoToPlayStore');
        window.location.href = 'https://play.google.com/store/apps/details?id=com.foodkub.radish';
    };
    const handleRegister = () => {
        const url = `${process.env.REACT_APP_MERCHANT_LIFF_URL}`;
        window.location.href = url;
    };

    return (
        <>
            <Box p={2} display="flex" justifyContent={"center"} alignItems={"center"}>
                <Card sx={{ maxWidth: 345 }}>
                    <CardContent>
                        <Typography variant="body1" color="text.primary">
                            <b>{t('web.promotionTitle')}</b> {t('web.promotion')}
                        </Typography>
                        <Typography variant="caption" color="text.sencondary">
                            {t('web.promotionRemark')}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        height="194"
                        image="https://cdn-stage.foodkub.com/public/assets/web/StarbucksNew.jpg"
                        alt="Starbucks Card"
                    />
                </Card>
            </Box>
            <Box mt={2}>
                <Divider />
            </Box>
            <Grid
                container
                direction={matches?'row':'column-reverse'}
                justifyContent="center"
                alignItems="center"
            >
                <AnimatePresence mode="wait">
                <Grid key={'hero-anime'} item xs={6}>
                    <motion.div
                        key={"anime1"}
                        variants={{
                            offscreen: {
                                y: 200
                            },
                            onscreen: {
                                y: 20,
                                transition: {
                                    type: "spring",
                                    bounce: 0.4,
                                    duration: 0.8,
                                    delay: 0.2
                                }
                            }
                        }}
                        initial="offscreen"
                        animate="onscreen"
                    >
                        <Box p={2} pt={0} sx={{ flexGrow: 1 }}>
                            <h1 className={classes.heroText} style={{fontSize: '2.1em', margin: 0}}>FoodKub Merchant</h1>
                            <h2 style={{marginTop: 0, fontSize: matches?'1.5em':'1.3em'}}>รับออเดอร์หน้าร้าน + ออเดอร์ออนไลน์<br/>ใช้งานง่ายบนมือถือ สะดวก คล่องตัว</h2>
                            <h3 style={{color: '#696969'}}>{t('web.heroSubtitle')}</h3>
                            <Box display="flex" justifyContent={"center"} alignItems={"center"}>
                                <motion.div
                                    key={"anime2"}
                                    variants={{
                                        offscreen: {
                                            opacity: 0,
                                            x: -400
                                        },
                                        onscreen: {
                                            opacity: 1,
                                            x: 0,
                                            transition: {
                                                type: "spring",
                                                bounce: 0.4,
                                                duration: 0.9,
                                                delay: 0.35
                                            }
                                        }
                                    }}
                                    initial="offscreen"
                                    animate="onscreen"
                                >
                                    <Box display={"flex"} justifyContent={"center"} >
                                        <img onClick={handleGoToPlayStore} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/google_play.webp`} alt={"Google Play Store"} style={{width: '120px', marginRight: '10px'}} />
                                        <img onClick={handleGoToAppStore} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/app_store.webp`} alt={"IOS Apple Store"} style={{width: '120px'}} />
                                    </Box>
                                </motion.div>
                            </Box>
                            <Box mt={2} mb={2}>
                                <Button onClick={handleRegister} disableElevation={true} variant={"contained"} size="large" color={"orange"} fullWidth={true}>
                                    <b style={{color: 'white', fontSize: '1.2em'}}>{t('account.register')}</b>
                                </Button>
                                <Box mt={2} display={"flex"} alignItems={"center"}>
                                    <Box mr={1}>
                                        <CardGiftcardIcon color={"success"} />
                                    </Box>
                                    <Typography variant={"body1"}>
                                        * {t('pricing.promo')}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </motion.div>
                </Grid>
                <Grid item xs={6}>
                    <motion.div
                        key={"anime3"}
                        variants={{
                            offscreen: {
                                scale: 0,
                            },
                            onscreen: {
                                scale: 1,
                                transition: {
                                    type: "spring",
                                    bounce: 0.4,
                                    duration: 0.8,
                                    delay: 0.1
                                }
                            }
                        }}
                        initial="offscreen"
                        animate="onscreen"
                    >
                        <Box display={"flex"} justifyContent={"center"} p={2} pt={0} pb={0}>
                            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/phone3NewCropWEBP.webp`} alt="FoodKub Delivery" style={{width: '100%', maxWidth: '540px'}} />
                        </Box>
                    </motion.div>
                </Grid>
                </AnimatePresence>
            </Grid>
            <Box sx={{background: '#f2f2f2'}} id="our-products">
                <div itemScope itemType="https://schema.org/Product">
                    <Grid
                        container
                        direction={matches?'row':'column'}
                        justifyContent="center"
                        alignItems="flex-start"
                    >
                        <AnimatePresence mode="wait">
                            <Grid item xs={6}>
                                <motion.div
                                    key={"anime3"}
                                    variants={{
                                        offscreen: {
                                            scale: 0,
                                        },
                                        onscreen: {
                                            scale: 1,
                                            transition: {
                                                type: "spring",
                                                bounce: 0.4,
                                                duration: 0.8
                                            }
                                        }
                                    }}
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={{ once: true }}
                                >
                                    <Box display={"flex"} justifyContent={"center"} p={2}>
                                        <img itemProp="image"  src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/ss_1-1.webp`} alt="POS มือถือ" style={{width: '50%', maxWidth: '540px'}} />
                                        <img itemProp="image"  src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/ss_2.png`} alt="โต๊ะอาหาร บนมือถือ" style={{width: '50%', maxWidth: '540px'}} />
                                    </Box>
                                </motion.div>
                            </Grid>
                            <Grid key={'hero-anime'} item xs={6} >
                                <Box pt={matches?4:0} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                                    <div itemProp="name" style={{padding: '0rem 2rem 0 2rem', textAlign: 'center'}}>
                                        <Typography variant="h4" component="div">{t('web.mobilePOSTitle')} <b className={classes.highlightText} style={{fontSize: '2.5rem'}}>POS</b></Typography>
                                    </div>
                                </Box>
                                <Box ml={2} mt={2} mb={4}>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.855 10.303c.086.554.145 1.118.145 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.348 0 4.518.741 6.304 1.993l-1.421 1.457c-1.408-.913-3.083-1.45-4.883-1.45-4.963 0-9 4.038-9 9s4.037 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.865-1.902zm-.951-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z" fill="#44cc00"/></svg>
                                        <h3 style={{color: '#696969', margin: '0 5px 10px 5px'}}>{t('web.mobilePOSSubtitle1')} <b style={{fontSize: '1.3rem', color: '#ff6600'}}>{t('web.mobilePOSSubtitle1Suffix')}</b></h3>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.855 10.303c.086.554.145 1.118.145 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.348 0 4.518.741 6.304 1.993l-1.421 1.457c-1.408-.913-3.083-1.45-4.883-1.45-4.963 0-9 4.038-9 9s4.037 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.865-1.902zm-.951-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z" fill="#44cc00"/></svg>
                                        <h3 style={{color: '#696969', margin: '0 5px 10px 5px'}}>{t('web.mobilePOSSubtitle2')} <b style={{fontSize: '1.3rem', color: '#ff6600'}}>{t('web.mobilePOSSubtitle2Suffix')}</b></h3>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.855 10.303c.086.554.145 1.118.145 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.348 0 4.518.741 6.304 1.993l-1.421 1.457c-1.408-.913-3.083-1.45-4.883-1.45-4.963 0-9 4.038-9 9s4.037 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.865-1.902zm-.951-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z" fill="#44cc00"/></svg>
                                        <h3 style={{color: '#696969', margin: '0 5px 10px 5px'}}>{t('web.mobilePOSSubtitle3')} <b style={{fontSize: '1.3rem', color: '#ff6600'}}>{t('web.mobilePOSSubtitle3Suffix')}</b></h3>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.855 10.303c.086.554.145 1.118.145 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.348 0 4.518.741 6.304 1.993l-1.421 1.457c-1.408-.913-3.083-1.45-4.883-1.45-4.963 0-9 4.038-9 9s4.037 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.865-1.902zm-.951-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z" fill="#44cc00"/></svg>
                                        <h3 style={{color: '#696969', margin: '0 5px 10px 5px'}}>{t('web.mobilePOSSubtitle4')} <b style={{fontSize: '1.3rem', color: '#ff6600'}}>{t('web.mobilePOSSubtitle4Suffix')}</b></h3>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.855 10.303c.086.554.145 1.118.145 1.697 0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11c2.348 0 4.518.741 6.304 1.993l-1.421 1.457c-1.408-.913-3.083-1.45-4.883-1.45-4.963 0-9 4.038-9 9s4.037 9 9 9c4.894 0 8.879-3.928 8.99-8.795l1.865-1.902zm-.951-8.136l-9.404 9.639-3.843-3.614-3.095 3.098 6.938 6.71 12.5-12.737-3.096-3.096z" fill="#44cc00"/></svg>
                                        <h3 style={{color: '#696969', margin: '0 5px 10px 5px'}}>{t('web.mobilePOSSubtitle5')} <b style={{fontSize: '1.3rem', color: '#ff6600'}}>{t('web.mobilePOSSubtitle5Suffix')}</b></h3>
                                    </Box>
                                </Box>
                            </Grid>
                        </AnimatePresence>
                    </Grid>
                </div>
            </Box>
            <Box sx={{background: '#f2f2f2'}} display={"flex"} justifyContent={"center"}>
                <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/Kshop-SCB.webp`}
                     alt={"KShop แม่มณี"}
                     style={{verticalAlign: 'bottom', width: '100%', maxWidth: '800px'}} />
            </Box>
            <Box sx={{background: '#f2f2f2'}}>
                <Box display={"flex"} justifyContent={"center"} mt={4}>
                    <div style={{padding: '0rem 2rem 0 2rem', textAlign: 'center'}}>
                        <Typography variant="h4" component="div">{t('web.qrKshopTitle')}</Typography>
                    </div>
                </Box>
                <Box display={"flex"} justifyContent={"center"} mb={2} pl={2} pr={2}>
                    <h3 style={{color: '#696969'}}>{t('web.qrKshopSubtitle')}</h3>
                </Box>
            </Box>
            <div style={{backgroundColor: '#1abc9c'}}>
                <Box style={{backgroundColor: '#1abc9c', color: 'white'}} mb={2}>
                    <div style={{padding: '2rem 2rem 0 2rem', textAlign: 'center'}}>
                        <Typography variant="h4" component="div">{t('home.title')}</Typography>
                    </div>
                    <div style={{padding: '0rem 2rem 0 2rem', textAlign: 'center'}}>
                        <h3>{t('home.detail1')}</h3>
                    </div>
                    <div style={{padding: '0rem 2rem 0 2rem', textAlign: 'center'}}>
                        <h3 style={{marginBottom: 0}}>{t('home.detail2')}</h3>
                    </div>
                </Box>
                <AnimatePresence mode="wait">
                    <div key={'delivery-anime'} className="App">
                        <Box>
                            <motion.div key={"anime4"} className="child" variants={{
                                offscreen: {
                                    y: 100
                                },
                                onscreen: {
                                    y: 2,
                                    transition: {
                                        type: "spring",
                                        bounce: 0.4,
                                        duration: 0.8
                                    }
                                }
                            }}
                                        initial="offscreen"
                                        whileInView="onscreen"
                                        viewport={{ once: true }}>
                                <Box>
                                    <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/city.png`} alt="Food Delivery"
                                         style={{verticalAlign: 'bottom', width: '100%'}}
                                    />
                                </Box>
                            </motion.div>
                        </Box>
                    </div>
                    <div style={{backgroundColor: '#09a488'}}>
                        <motion.div key={"anime5"} className="child" variants={{
                            offscreen: {
                                x: -200,
                                y: -50,
                            },
                            onscreen: {
                                x: 0,
                                y: -50,
                                transition: {
                                    type: "spring",
                                    bounce: 0.4,
                                    duration: 0.8,
                                    delay: 0.2
                                }
                            }
                        }}
                                    initial="offscreen"
                                    whileInView="onscreen"
                                    viewport={{ once: true }}>
                            <Box>
                                <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/rider.png`} alt="FoodKub Delivery"
                                     style={{verticalAlign: 'top', width: '100%'}}
                                />
                            </Box>
                        </motion.div>
                    </div>
                </AnimatePresence>
            </div>
            <Grid container>
                <Grid item sm={12} md={6}>
                    <div style={{background: '#f2f2f2', color: '#1B1A17'}}>
                        <div style={{padding: '2rem 0 0 0', textAlign: 'center'}}>
                            <Typography variant="h4" component="h6"><b>{t('web.scanQRTitle')}</b></Typography>
                        </div>
                        <div style={{padding: '0.5rem 0 1rem 0', textAlign: 'center'}}>
                            <Typography variant="h4" component="h6"><b>{t('web.scanQRSubtitle')}</b></Typography>
                        </div>
                        <div style={{padding: '0 1rem 1rem 1rem', textAlign: 'center'}}>
                            <h3 style={{color: '#696969'}}>{t('web.scanQRDetail')}</h3>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/table04_03WEBP2.webp`} alt="QR Code"
                                 style={{verticalAlign: 'bottom', width: '100%'}}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item sm={12} md={6}>
                    <div style={{background: '#f2f2f2', color: '#1B1A17'}}>
                        <div style={{padding: '2rem 0 0 0', textAlign: 'center'}}>
                            <Typography variant="h4" component="h6"><b>{t('web.tableTitle')}</b></Typography>
                        </div>
                        <div style={{padding: '0.5rem 0 1rem 0', textAlign: 'center'}}>
                            <Typography variant="h4" component="h6"><b>{t('web.tableSubtitle')}</b></Typography>
                        </div>
                        <div style={{padding: '0 0 1rem 0', textAlign: 'center'}}>
                            <h3 style={{color: '#696969'}}>{t('web.tableDetail')}</h3>
                        </div>
                        <Box pl={4} pr={4} style={{textAlign: 'center', padding: '0'}}>
                            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/mobile_merchant.webp`} alt="iPad โต๊ะอาหาร"
                                 style={{verticalAlign: 'bottom', width: '100%'}}
                            />
                        </Box>
                    </div>
                </Grid>
            </Grid>
            <Grid container sx={{background: '#ffffff'}}>
                <Grid item sm={12} md={6}>
                    <div style={{color: '#1B1A17'}}>
                        <div style={{padding: '2rem 0 0 0', textAlign: 'center'}}>
                            <Typography variant="h4" component="h6"><b>{t('web.POSTitle')}</b></Typography>
                        </div>
                        <div style={{padding: '0 1rem 0rem 1rem', textAlign: 'center'}}>
                            <h3 style={{color: '#696969'}}>{t('web.POSSubtitle')}</h3>
                        </div>
                    </div>
                    <div style={{textAlign: 'center', paddingBottom: '3rem'}}>
                        <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/FK_POS_3.webp`} alt="FoodKub POS Tablet ipad"
                             style={{verticalAlign: 'bottom', width: '80%'}}
                        />
                    </div>
                </Grid>
                <Grid item sm={12} md={6}>
                    <div style={{color: '#1B1A17'}}>
                        <div style={{padding: '2rem 0 0 0', textAlign: 'center'}}>
                            <Typography variant="h4" component="h6"><b>{t('web.printerTitle')}</b></Typography>
                        </div>
                        <div style={{padding: '0 1rem 0 1rem', textAlign: 'center'}}>
                            <h3 style={{color: '#696969'}}>{t('web.printerDetail')}</h3>
                        </div>
                        <Box pl={4} pr={4} pb={2} style={{textAlign: 'center'}}>
                            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/FK_XPRINTER.webp`} alt="POS Printer"
                                 style={{verticalAlign: 'bottom', width: '100%'}}
                            />
                        </Box>
                    </div>
                </Grid>
            </Grid>
            <Grid container sx={{background: '#1abc9c'}}>
                <Grid item sm={12} md={12}>
                    <div style={{padding: '2rem 0 0 0', textAlign: 'center', color: '#ffffff'}}>
                        <Typography variant="h4" component="h6"><b>{t('web.reportTitle')}</b></Typography>
                    </div>
                    <div style={{padding: '0 1rem 0 1rem', textAlign: 'center'}}>
                        <h3 style={{color: '#ffffff'}}>{t('web.reportSubtitle')} {t('web.reportDetail')}</h3>
                    </div>
                </Grid>
            </Grid>
            <Grid container sx={{background: "#1abc9c"}}>
                <Grid item sm={12} md={6}>
                    <div style={{color: '#1B1A17', marginTop:'1rem', marginBottom: '1rem'}}>
                        <Box pl={4} pr={4} pb={2} style={{textAlign: 'center'}}>
                            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/report_12_s.webp`} alt="Daily Reports"
                                 style={{verticalAlign: 'bottom', width: '100%'}}
                            />
                        </Box>
                    </div>
                </Grid>
                <Grid item sm={12} md={6}>
                    <div style={{color: '#1B1A17', marginTop:'1rem', marginBottom: '1rem'}}>
                        <Box pl={4} pr={4} pb={2} style={{textAlign: 'center'}}>
                            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/report_34_s.webp`} alt="Monthly Reports"
                                 style={{verticalAlign: 'bottom', width: '100%'}}
                            />
                        </Box>
                    </div>
                </Grid>
                <Grid item sm={12} md={12}>
                    <div style={{padding: '0 2rem 0 2rem', textAlign: 'left'}}>
                        <h3 style={{color: '#ffffff'}}>{t('web.reportRemark')}</h3>
                    </div>
                </Grid>
            </Grid>
            <Box sx={{background: '#f2f2f2'}} pt={2}>
                <Box style={{padding: '2rem 0 0 0', textAlign: 'center', color: '#1B1A17'}}>
                    <Typography variant="h4" component="h6"><b>{t('web.bankingTitle')}</b></Typography>
                </Box>
                <Box display={"flex"} justifyContent={"center"} mt={4}>
                    <Box ml={2} mr={2}>
                        <img style={{verticalAlign: 'bottom', width: '100%', maxWidth: '220px'}} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/prompt-pay-logo.png`} alt="PromptPay" />
                    </Box>
                    <Box ml={2} mr={2}>
                        <img style={{verticalAlign: 'bottom', width: '100%', maxWidth: '220px'}} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/Thai_QR_Code.webp`} alt="Thai QR Code" />
                    </Box>
                </Box>
                <Box>
                    <div style={{padding: '0 1rem 0 1rem', textAlign: 'center'}}>
                        <h3 style={{color: '#696969'}}>{t('web.bankingSubtitle')}</h3>
                    </div>
                </Box>
                <Box display={"flex"} justifyContent={"center"} mb={2}>
                    <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/logoAppBankAllPadding.webp`} alt="Thai Banking"
                         style={{verticalAlign: 'bottom', width: '80%'}}
                    />
                </Box>
            </Box>
            <Box pt={4} id="restaurant-type">
                <Box pb={2} display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                    <div style={{textAlign: 'center'}}>
                        <Typography variant="h4" component="div">{t('web.restaurantType')} </Typography>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <b className={classes.heroText} style={{fontSize: '2.1rem'}}>FoodKub Merchant</b>
                    </div>
                </Box>
                <AnimatePresence mode="wait">
                    <Grid container spacing={4}>
                        <Grid item sm={12} md={4}>
                            <motion.div
                                variants={{
                                    offscreen: {
                                        scale: 0,
                                    },
                                    onscreen: {
                                        scale: 1,
                                        transition: {
                                            type: "spring",
                                            bounce: 0.4,
                                            duration: 0.8
                                        }
                                    }
                                }}
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true }}
                            >
                                <Box display={"flex"} justifyContent={"center"}>
                                    <img style={{width: '100%'}} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/actor4.webp`} alt="ร้านอาหาร รองรับการทำงานตั้งแต่ลูกค้าเดินเข้าร้าน ระบบโต๊ะอาหาร ดูเมนู รับออเดอร์ ชำระเงิน ออกใบเสร็จ" />
                                </Box>
                            </motion.div>
                            <Box style={{padding: 0, textAlign: 'center'}}>
                                <h2 style={{margin:0}}><b style={{color: '#ff6600'}}>{t('web.restaurantType1')}</b></h2>
                            </Box>
                            <Box pl={2} pr={2} style={{textAlign: 'center'}}>
                                <h3 style={{color: '#696969'}}>{t('web.restaurantType1Subtitle')}</h3>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <motion.div
                                variants={{
                                    offscreen: {
                                        scale: 0,
                                    },
                                    onscreen: {
                                        scale: 1,
                                        transition: {
                                            type: "spring",
                                            bounce: 0.4,
                                            duration: 0.8
                                        }
                                    }
                                }}
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true }}
                            >
                                <Box display={"flex"} justifyContent={"center"}>
                                    <img style={{width: '100%'}} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/actor1.webp`} alt={"คาเฟ่ ร้านกาแฟ ลูกค้าสั่งอาหารชำระเงินได้เอง ระบบแจ้งเตือนเมื่อร้านจัดเตรียมเสร็จ"} />
                                </Box>
                            </motion.div>
                            <Box style={{padding: 0, textAlign: 'center'}}>
                                <h2 style={{margin:0}}><b style={{color: '#ff6600'}}>{t('web.restaurantType2')}</b></h2>
                            </Box>
                            <Box pl={2} pr={2} style={{textAlign: 'center'}}>
                                <h3 style={{color: '#696969'}}>{t('web.restaurantType2Subtitle')}</h3>
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <motion.div
                                variants={{
                                    offscreen: {
                                        scale: 0,
                                    },
                                    onscreen: {
                                        scale: 1,
                                        transition: {
                                            type: "spring",
                                            bounce: 0.4,
                                            duration: 0.8
                                        }
                                    }
                                }}
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true }}
                            >
                                <Box display={"flex"} justifyContent={"center"}>
                                    <img style={{width: '100%'}} src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/actor5.webp`} alt={"ร้านขนาดเล็ก พื้นที่จำกัด ต้องการความคล่องตัว ไม่ง้อเครื่อง POS แค่มือถือเครื่องเดียวก็เอาอยู่"} />
                                </Box>
                            </motion.div>
                            <Box style={{padding: 0, textAlign: 'center'}}>
                                <h2 style={{margin:0}}><b style={{color: '#ff6600'}}>{t('web.restaurantType3')}</b></h2>
                            </Box>
                            <Box pl={2} pr={2} style={{textAlign: 'center'}}>
                                <h3 style={{color: '#696969'}}>{t('web.restaurantType3Subtitle')}</h3>
                            </Box>
                        </Grid>
                    </Grid>
                </AnimatePresence>
            </Box>
            <div itemScope itemType="https://schema.org/image">
                <Box style={{background: '#f2f2f2', color: '#1B1A17'}} pt={4}>
                    <Box style={{padding: 0, textAlign: 'center'}}>
                        <Typography variant="h4" component="h6"><b>{t('web.customerTitle')}</b></Typography>
                    </Box>
                    <div style={{padding: '0 0 2rem 0', textAlign: 'center'}}>
                        <h3 style={{color: '#696969', marginTop: 0}}>{t('web.customerSubtitle')}</h3>
                    </div>
                </Box>
                <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/foodkub_merchant_delivery_area.webp`} alt="พื้นที่ให้บริการ Delivery" style={{width: '100%'}} />
            </div>
            <div id="pricing-rate">
                <Registration />
                <Pricing />
            </div>
            <CookieConsent style={{ display: "block" }}
                           buttonStyle={{padding: '10px 10px'}}
                           buttonWrapperClasses={classes.btnFullWidth}
                           buttonText={t('cookie.accept')}>{t('cookie.description')}</CookieConsent>
        </>
    );
}
