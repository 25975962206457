import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import {httpClient} from "../../../core/HttpClient";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    inputBrowse: {
        display: 'none',
    },
    media: {
        cursor: 'pointer'
    },
});

export function AttachmentPaySlip({value, onChange}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log('AttachmentUpload');
        if(value){
            setImage(value);
        }
    },[value]);

    const handleUpload = ({ target }) => {
        setLoading(true);
        const data = new FormData();
        for(var x = 0; x < target.files.length; x++) {
            data.append('file', target.files[x]);
        }
        data.append('type', 'payment');
        const url = process.env.REACT_APP_API_BASE_URL + '/upload/attachment';
        httpClient.post(url, data)
            .then(res => {
                if(res.data){
                    setImage({ ...res.data });
                    onChange({ ...res.data });
                }
                setLoading(false);
            });
        target.value = ''; // fix same file select
    };
    return(
        <>
            {loading &&
            <Box display="flex" justifyContent="center" mt={4} mb={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <>
                <label htmlFor="contained-button-file">
                    {image &&
                        <>
                            <input
                                accept="image/*"
                                className={classes.inputBrowse}
                                id="contained-button-file-retry"
                                onChange={handleUpload}
                                type="file"
                            />
                            <label htmlFor="contained-button-file-retry">
                                <Card variant="outlined">
                                    <CardContent>
                                        <img
                                            className={classes.media}
                                            style={{width: '100%'}}
                                            src={`${process.env.REACT_APP_CDN_BASE_URL}/${image.key}`}
                                            alt="slip"
                                        />
                                    </CardContent>
                                </Card>
                            </label>
                        </>
                    }
                    {!image &&
                        <>
                            <input
                                accept="image/*"
                                className={classes.inputBrowse}
                                id="contained-button-file"
                                onChange={handleUpload}
                                type="file"
                            />
                            <label htmlFor="contained-button-file">
                                <Button variant="outlined"
                                        fullWidth={true}
                                        component="span"
                                        startIcon={<CameraAltIcon/>}>
                                    {t('payment.slip')}
                                </Button>
                            </label>
                        </>
                    }
                </label>

            </>
            }
        </>
    )
}