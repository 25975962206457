import React from 'react';
import { useTranslation } from "react-i18next";
import CardContent from '@mui/material/CardContent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import {TextI18n} from "../../TextI18n";
import Avatar from "@mui/material/Avatar";

const shapeStyles = { border: '1px solid grey', padding: '0 8px 0 8px', fontSize: '0.8rem' };

const itemOptionalInfoBox = (item) => {
    let items = [];
    if(item.optional){
        item.optional.options.forEach(opt=>{
            opt.checkedItems.forEach(chk=>{
                items.push({name: chk.name, price: chk.price * item.qty});
            });
        });
    }
    return (
        <Box>
            {
                items.map((item, i) => (
                    <Box key={`optional-${item._id}-${i}`} sx={{ color: 'text.secondary' }} display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"}>
                            <CheckBoxIcon sx={{ fontSize: 15, marginTop: '5px' }} />
                            <Box sx={{ marginLeft: '3px' }}><TextI18n value={item.name} lines={2}></TextI18n></Box>
                        </Box>
                        <Box display={"flex"} justifyContent={"flex-end"} style={{minWidth: '70px'}}>
                            <Typography variant="subtitle2">
                                <NumberFormat value={item.price} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                            </Typography>
                        </Box>
                    </Box>
                ))
            }
        </Box>

    )
};


const MyProductItem = (({item, i, t}) => {
    return (
        <ListItem divider={true} key={`item-${item.product._id}-${i}`} alignItems="flex-start">
            <ListItemAvatar>
                <Avatar variant="square">
                    <img src={(item.product.images && item.product.images[0])?`${process.env.REACT_APP_CDN_BASE_URL}/${item.product.images[0].permission}/merchant/s/${item.product.images[0].name}`
                        : "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="}
                         alt='product'
                         style={ {height: '100%', border: 0, objectFit: 'cover'} } />
                </Avatar>
            </ListItemAvatar>
            <ListItemText style={{wordBreak: 'break-word'}}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography component={'span'} variant="body1">
                        <TextI18n value={item.product.name} ></TextI18n>
                    </Typography>
                    <Box display={"flex"} justifyContent={"flex-end"} style={{minWidth: '80px'}}>
                        <Typography variant="subtitle2">
                            <NumberFormat value={item.price * item.qty} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                        </Typography>
                    </Box>
                </Box>
                {itemOptionalInfoBox(item)}
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography component={'span'} variant="body1">
                        {t('product.quantity')}
                    </Typography>
                    <Box display={"flex"} justifyContent={"flex-start"}>
                        <Box sx={shapeStyles}>x{item.qty}</Box>
                    </Box>
                </Box>
                {item.remarks &&
                    <>
                        {item.remarks.map((remark) => (
                            <Box>
                                <Typography variant={"caption"} style={{color: 'red'}}>
                                    * {remark}
                                </Typography>
                            </Box>
                        ))}
                    </>
                }
            </ListItemText>
        </ListItem>
    )
});

export default function MobileOrderItems({value}) {
    const { t } = useTranslation();
    return (
        <Box>
            <Box display="flex" justifyContent="center" mt={1}>
                <Typography variant="h6" component="div">
                    {t('order.foodOrders')}
                </Typography>
            </Box>
            {value && value.items &&
            <>
                {value.items.map((item, i) => (
                    <Box key={`item-${item._id}-${i}`}><MyProductItem item={item} i={i} t={t} /></Box>
                ))}
            </>
            }
            {value.remarks && value.remarks[0] &&
            <Box m={2} p={2} style={{border:'1px solid red', color: 'red'}}>
                {value.remarks.map((remark) => (
                    <Box>
                        <Typography variant={"caption"} style={{color: 'red'}}>
                            * {remark}
                        </Typography>
                    </Box>
                ))}
            </Box>
            }
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center"
                     style={{width: '100%'}}>
                    <div>{t('order.total')}</div>
                    <div><NumberFormat value={value.total} displayType={'text'}
                                       thousandSeparator={true} prefix={'฿'}/></div>
                </Box>
                <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                {value.deliverType === 'DELIVERY'&&
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%'}}>
                            <div>
                                <span>{t('order.delivery')}</span>
                                {value.deliveryDistance > 0 &&
                                    <Typography variant="body1" color="textSecondary"
                                                sx={{fontSize: '0.85rem'}}>{t('cart.distance')} {value.deliveryDistance} {t('cart.distanceUnit')}</Typography>}
                            </div>
                            <div><NumberFormat value={value.deliveryPrice || 0} displayType={'text'}
                                               thousandSeparator={true} prefix={'฿'}/></div>
                        </Box>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    </>
                }
                {value.discount && value.discount.total === 0 &&
                    <>
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%'}}>
                            <div>
                                <span>{t('discount.title')}</span>
                            </div>
                            <div><NumberFormat value={0} displayType={'text'}
                                               thousandSeparator={true} prefix={'฿'}/></div>
                        </Box>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                    </>
                }
                {value.discount && value.discount.food > 0 && <Box display="flex" justifyContent="space-between" alignItems="center"
                                                                   style={{width: '100%', color: 'green'}}>
                    <div>
                        <span>{t('discount.food')}</span>
                    </div>
                    <div><NumberFormat value={value.discount.food || 0} displayType={'text'}
                                       thousandSeparator={true} prefix={'-฿'}/></div>
                </Box>}
                {value.discount && value.discount.delivery > 0 && <Box display="flex" justifyContent="space-between" alignItems="center"
                                                                        style={{width: '100%', color: 'green'}}>
                    <div>
                        <span>{t('discount.delivery')}</span>
                    </div>
                    <div><NumberFormat value={value.discount.delivery || 0} displayType={'text'}
                                       thousandSeparator={true} prefix={'-฿'}/></div>
                </Box>}
                <Box mt={1} display="flex" justifyContent="space-between" alignItems="center"
                     fontWeight="fontWeightBold"
                     style={{width: '100%'}}>
                    <div>{t('common.grandTotal')}</div>
                    <div><NumberFormat value={value.grandTotal} displayType={'text'}
                                       thousandSeparator={true} prefix={'฿'}/></div>
                </Box>
            </CardContent>
        </Box>
    );
}
