import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import StoreIcon from '@mui/icons-material/Store';
import PhoneIcon from '@mui/icons-material/Phone';
// import ChatIcon from '@mui/icons-material/Chat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {TextI18n} from "../../TextI18n";
import Box from "@mui/material/Box";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import BottomNavigation from "@mui/material/BottomNavigation";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import {isDesktop} from "react-device-detect";
import AddressDialog from "../../Location/AddressDialog";
import './style.css';
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";
import {DateTime} from "../../DateTime";
import {useParams} from "react-router-dom";
import {NFormatter} from "../../NFormatter";
import {httpClient} from "../../../core/HttpClient";
import Typography from "@mui/material/Typography";
import RewardButton from "../../Reward/RewardButton";
import ReviewButton from "../../Review/ReviewButton";
import Verified from "../../Verified";
import {LastActive} from "../../LastActive";
import CircleIcon from "@mui/icons-material/Circle";
import Divider from "@mui/material/Divider";
import liff from "@line/liff";

const MemoizedShopCloseInfo = React.memo(({openDate, openTime, closeTime, t})=>{
    if(openDate){
        let current = new Date();
        let isSameDay = dayjs(openDate).isSame(current, 'day');
        if(isSameDay){
            return <Typography variant="caption">
                <Box display={"flex"}>
                    <span>* {t('shop.willOpenAtTime')} {openTime} - {closeTime}</span>
                </Box>
            </Typography>;
        } else {
            return <Typography variant="caption">
                <Box display={"flex"}>
                    <span style={{marginRight: '3px'}}>* {t('shop.willOpenAtDate')}</span>
                    <span style={{marginRight: '3px'}}><DateTime value={openDate}
                                    format="DD MMM YY"></DateTime></span>
                    {openTime === closeTime &&
                        <span>{t('common.time')} {openTime}</span>
                    }
                    {openTime !== closeTime &&
                        <span>{t('common.time')} {openTime} - {closeTime}</span>
                    }
                </Box>
            </Typography>;
        }

    } else {
        return null;
    }
});

const MemoizedShopState = React.memo(({shop, mode, t})=>{
    console.log('MemoizedShopState');
    if(['open', 'temporarily-close'].indexOf(shop.state) > -1 && shop.active){
        return <Box display={"flex"}>
            <Chip color={"primary"} size="small" sx={{borderRadius: '0px', height: '20px'}} icon={<StoreIcon />} label={
                <Box display={"flex"}>
                    <span style={{marginRight: '3px'}}>{t('shop.open')}</span>
                    {shop.openTime === shop.closeTime &&
                        t('shop.24Hours')
                    }
                    {shop.openTime !== shop.closeTime &&
                        <span>{t('common.time')} {shop.openTime} - {shop.closeTime}</span>
                    }
                </Box>
            } />
        </Box>;
    } else if(shop.state === 'close' && shop.active){
        return <Box display={"flex"} alignItems={"center"}>
            <Chip size="small" icon={<StoreIcon />} sx={{borderRadius: '0px', height: '20px'}} label={
                <Box>{t('shop.closed')}</Box>
            } />
            {shop.orderingType === 'preorder' && mode === 'o' &&
                <Box ml={1}>
                    <Chip color={"primary"} size="small" icon={<AccessTimeIcon/>}
                          sx={{borderRadius: '0px', height: '20px'}} label={
                        <Box>{t('shop.preorders')}</Box>
                    }/>
                </Box>
            }
        </Box>;
    } else if(shop.active === false){
        return <Box display={"flex"}>
            <Chip color={"secondary"} size="small" icon={<StoreIcon />} sx={{borderRadius: '0px', height: '20px'}} label={
                <Box display={"flex"} alignItems={"center"}>
                    <span style={{marginRight: '3px'}}>{t('shop.status.temporarilyClosed')}</span>
                </Box>
            } />
        </Box>;
    }

    return <Box></Box>;
});

export default function ShopInfo({shop, time, mode, review=true}) {
    const { t } = useTranslation();
    const { cid } = useParams();
    const [state, setState] = useState({image:{}});
    const [imagesView, setImagesView] = useState([]);
    const [openMap, setOpenMap] = useState(false);
    const [loyalty, setLoyalty] = useState(shop.loyalty);

    console.log('ShopInfo');

    useEffect(() => {
        console.log('[ShopInfo]');
        setState(shop);
        if(shop && shop.images.length>0){
            let views = [];
            shop.images.forEach(v=>{
                views.push({
                    original: `${process.env.REACT_APP_CDN_BASE_URL}/${v.permission}/merchant/o/${v.name}`,
                    originalClass: 'my-image-gallery-image'
                });
            });
            setImagesView(views);
        }
    }, [shop]);

    const handleTel = () => {
        const link = document.createElement('a');
        link.id = 'phone';
        link.href = 'tel:'+state.phone;
        link.click();
    }

    // const handleChat = () => {
    //
    // }

    const handleClickLike = () => {
        console.log('handleClickLike');
        let token = localStorage.getItem('token');
        if(token){

            let action = 'remove';
            if(!loyalty){
                action = 'add';
            }

            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/like/account';
            httpClient.put(url, {action: action, cid: cid})
                .then(res => {
                    if(res.status === 200){
                        // done
                    } else {
                        if(res.data.code === 'DTA_001'){
                            // customer not found then clear token
                            localStorage.removeItem('token');
                        }
                    }
                }).catch(e=>{});
            setLoyalty(!loyalty);

        } else {


            let ua = navigator.userAgent || navigator.vendor || window.opera;
            let isInAppBrowser =  (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
                || (ua.indexOf("Instagram") > -1);
            // Facebook, Instagram In-App Browser detect.
            if(isInAppBrowser && ["ios","android"].indexOf(liff.getOS()) > -1){
                // window.location.href = `line://app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}`;
                window.location.href = `https://line.me/R/app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}`;
            } else {
                // user liff.login because open Line App to login (if use window.location.href = url; will go to Line web login page)
                // Note: LINE Liff login (Callback URL must start with same prefix of liff Endpoint URL (/hm)
                const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login?cid=${cid}&mode=${mode}`;
                liff.login({ redirectUri: redirectUri});
            }
        }
    };

    const handleClose = () => {
        setOpenMap(false);
    }

    const handleOpen = () => {
        setOpenMap(true);
    }

    return (
        <Box>
            <Box>
                {imagesView.length > 0 &&
                <ImageGallery items={imagesView}
                              infinite={false}
                              showNav={isDesktop && imagesView.length > 1}
                              showThumbnails={false}
                              showFullscreenButton={false}
                              useBrowserFullscreen={false}
                              showBullets={imagesView.length > 1}
                              showPlayButton={false} />
                }

                <CardHeader
                    sx={{
                        'paddingBottom': '5px',
                        'paddingLeft': '8px',
                        'paddingRight': '8px',
                        '& .MuiCardHeader-action': {
                            margin: 'auto',
                        },
                    }}
                    avatar={
                        <Avatar aria-label="recipe"
                                variant={"square"}
                                src={(shop.image && shop.image.name)?`${process.env.REACT_APP_CDN_BASE_URL}/${shop.image.permission}/merchant/s/${shop.image.name}`:`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} />
                    }
                    title={
                        <Box>
                            <TextI18n value={state.name}></TextI18n>
                        </Box>
                    }
                    titleTypographyProps={{variant: "h6"}}
                    subheader={
                        <MemoizedShopState shop={shop} mode={mode} t={t} />
                    }
                    action={
                        mode!=='v'?<Verified shop={shop} />:<Box />
                    }
                />
                {shop.state === 'temporarily-close' && shop.active && shop.openDate &&
                    <Box sx={{paddingLeft: '63px'}}>
                        <Typography variant="caption">
                            <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                <CircleIcon sx={{fontSize: 12}} color={'secondary'} />
                                <Box sx={{marginLeft: '3px', marginRight: '3px'}}>
                                    {t('cart.stopOrdering')}
                                </Box>
                            </Box>
                        </Typography>
                    </Box>
                }
                {shop.state === 'open' && shop.active && shop.openDate &&
                    <>
                        {mode === 'm' &&
                            <Box sx={{paddingLeft: '63px'}}>
                                <LastActive />
                            </Box>
                        }
                        {mode === 'o' &&
                            <>
                                {shop.ordering &&
                                    <Box sx={{paddingLeft: '63px'}}>
                                        <LastActive />
                                    </Box>
                                }
                                {!shop.ordering &&
                                    <Box sx={{paddingLeft: '63px'}}>
                                        <Typography variant="caption">
                                            <Box display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                                <CircleIcon sx={{fontSize: 12}} color={'secondary'} />
                                                <Box sx={{marginLeft: '3px', marginRight: '3px'}}>
                                                    {t('cart.stopOrdering')}
                                                </Box>
                                            </Box>
                                        </Typography>
                                    </Box>
                                }
                            </>
                        }
                    </>
                }
                {shop.state === 'close' && shop.active && shop.openDate &&
                    <Box sx={{paddingLeft: '63px'}}>
                        <MemoizedShopCloseInfo openDate={shop.openDate} openTime={shop.openTime} closeTime={shop.closeTime}
                                               t={t}/>
                    </Box>
                }
                <Box m={1}>
                    <Stack direction="row" justifyContent="flex-start"
                           alignItems="center" spacing={1}>
                        {time && !state.expired &&
                            <>
                                {mode === 'v' &&
                                    <Chip size="small" icon={<MenuBookIcon/>} sx={{borderRadius: '4px'}} label={t('VIEW')}/>
                                }
                                {mode === 'o' && state.pickup &&
                                    <Chip size="small" icon={<ShoppingBagIcon/>} sx={{borderRadius: '4px'}} label={t('PICKUP')}/>
                                }
                                {mode === 'o' && state.delivery &&
                                    <Chip size="small" icon={<TwoWheelerIcon/>} sx={{borderRadius: '4px'}} label={t('DELIVERY')}/>
                                }
                                {mode === 'o' && state.parcel &&
                                    <Chip size="small" icon={<LocalShippingIcon/>} sx={{borderRadius: '4px'}} label={t('PARCEL')}/>
                                }
                                {mode === 'm' && state.dineIn &&
                                    <Chip size="small" icon={<RestaurantIcon/>} sx={{borderRadius: '4px'}} label={t('DINE-IN')}/>
                                }
                                {mode === 'm' && state.takeaway &&
                                    <Chip size="small" icon={<ShoppingBagIcon/>} sx={{borderRadius: '4px'}} label={t('TAKEAWAY')}/>
                                }
                            </>
                        }
                    </Stack>
                </Box>
                <BottomNavigation
                    sx={{background: '#f7f7f7', paddingTop: '4px'}}
                    showLabels={true}
                >
                    <BottomNavigationAction label={t('shop.tel')} icon={<PhoneIcon />} onClick={handleTel} />
                    {/*<BottomNavigationAction label={t('shop.chat')} icon={<ChatIcon />} onClick={handleChat} />*/}
                    <BottomNavigationAction label={t('shop.addressServiceArea')} icon={<LocationOnIcon />} onClick={handleOpen} />
                    <BottomNavigationAction label={<NFormatter value={shop.reaches} />}
                                            icon={loyalty?<FavoriteIcon color="secondary" />:<FavoriteBorderIcon />}
                                            onClick={handleClickLike} />
                </BottomNavigation>
                {!review && <Box pb={0} sx={{background: '#f7f7f7'}}><Divider /></Box>}
                {shop.reward > 0 && <Box p={1} pb={0} sx={{background: '#f7f7f7'}}><RewardButton mode={mode} shop={shop} ></RewardButton></Box>}
                {review && <Box p={1} pb={0} sx={{background: '#f7f7f7'}}><ReviewButton mode={mode} shop={shop} ></ReviewButton></Box>}
                <Box sx={{height: '10px', background: '#f7f7f7'}}></Box>
            </Box>
            <AddressDialog open={openMap} shop={shop} onClose={handleClose} />
        </Box>
    )
}