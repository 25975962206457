import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Slide from '@mui/material/Slide';
import NumberFormat from 'react-number-format';
import CartUtil from "../../../core/CartUtil";
import {TextI18n} from "../../TextI18n";
import {TextI18nTruncate} from "../../TextI18nTruncate";
import ProductControlDialog from "../ProductControlDialog";
import {ProductEdit} from "../ProductEdit";
import {Context} from "../../../core/Context";
import {getCache} from "../../../core/LocalStorageUtil";
import ListItemButton from "@mui/material/ListItemButton";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import {httpClient} from "../../../core/HttpClient";
import liff from "@line/liff";
import {v4 as uuidv4} from "uuid";
import TopRecommend from "../../TopRecommend";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import {CountNumber} from "../../CountNumber";

const shapeStyles = { backgroundColor: 'orange.main', padding: '0 8px 0 8px', color: '#fff', fontSize: '0.8rem' };
const shapeInvertStyles = { backgroundColor: '#fff', padding: '0 8px 0 8px', color: '#1abc9c', fontSize: '1 rem' };

const useStyles = makeStyles({
    root: {},
    link: {
        color: '#1abc9c'
    },
    list: {
        paddingTop: 0
    },
    listContainer: {
        backgroundColor: '#f7f7f7',
        minHeight: '100vh'
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    qty: {
        minWidth: '1.5rem',
        textAlign: 'center'
    },
    avatar: {
        backgroundColor: 'none'
    }
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const selectedBox = (cart, value) => {
    let selectedValue = 0;

    if(cart && cart.products[value._id]){
        selectedValue = cart.products[value._id].qty;
    }

    return (
        <div>
            <Box hidden={selectedValue<1} sx={shapeStyles}>{selectedValue}</Box>
        </div>
    )
};


export default function ProductList({list, shop, mode, partner}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const history = useHistory();
    const [context] = useContext(Context);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState({add: false, edit: false});
    const [item, setItem] = useState({index: -1, remark: '', optional: {total: 0}, qty: 1});
    const [selectProduct, setSelectProduct] = useState({qty: 1});
    const [productGroups] = useState(list);
    const [likeProduct, setLikeProduct] = useState(shop.likeProduct || {});
    let cacheData = getCache(cid);
    let cart = cacheData.cart;

    useEffect(() => {
        console.log('[ProductList]');
        // Trigger Selected Product.
        let product = JSON.parse(localStorage.getItem('pid'));
        if(product){
            let cartQty = 0;
            if(cart && cart.products[product._id]){
                cartQty = cart.products[product._id].qty;
                setOpen({add: false, edit: true});
            }else{
                setItem({index: -1, remark: '', optional: {total: 0}, qty: 1});
                setOpen({add: true, edit: false});
            }
            let oos = !product.open ||  product.remaining-cartQty < 1;
            setSelectProduct({...product, qty:1, oos: oos, cartQty: cartQty});
            localStorage.removeItem('pid');
        }
    }, [cart, partner]);

    const handleClickOpen = (product) => {
        if(mode !=='v' && (!product.open || product.remaining < 1)){
            return;
        }

        let cartQty = 0;
        if(mode === 'v'){
            setOpen({add: true, edit: false});
        } else {
            if(cart && cart.products[product._id]){
                cartQty = cart.products[product._id].qty;
                setOpen({add: false, edit: true});
            }else{
                setItem({index: -1, remark: '', optional: {total: 0}, qty: 1});
                setOpen({add: true, edit: false});
            }
        }

        let oos = !product.open || product.remaining-cartQty < 1;
        setSelectProduct({...product, qty:1, oos: oos, cartQty: cartQty});
    };

    const handleClose = () => {
        setOpen({add: false, edit: false});
    };

    const handleSubmitCart = () => {

        let contextData = {...context};
        if (!liff.isLoggedIn()) {
            let ua = navigator.userAgent || navigator.vendor || window.opera;
            let isInAppBrowser =  (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
                || (ua.indexOf("Instagram") > -1);
            // Facebook, Instagram In-App Browser detect.
            if(isInAppBrowser && ["ios","android"].indexOf(liff.getOS()) > -1){
                const url = process.env.REACT_APP_API_BASE_URL + '/publish/cart';
                let uid = uuidv4();
                let cartData = {
                    cid: cid,
                    uid: uid,
                    result: cacheData,
                };
                setLoading(true);
                httpClient.post(url, cartData)
                    .then(res => {
                        history.push({
                            pathname: `/hm/${mode}/${cid}/c`,
                            search: `?uid=${uid}`,
                            contextData: contextData
                        });

                        setLoading(false);
                    }).catch(e=>{
                        setLoading(false);
                    });
            } else {
                history.push({
                    pathname: `/hm/${mode}/${cid}/c`,
                    contextData: contextData
                });
            }
        } else {
            history.push({
                pathname: `/hm/${mode}/${cid}/c`,
                contextData: contextData
            });
        }
    };

    const handleChange = (value) => {
        console.log('handleChange');
        if(value.mode === -1){
            // remove
            new CartUtil(cid).removeCartItem(value.product, value.item);
            setOpen({add: false, edit: false});
        } else if(value.mode === 0){
            // update
            new CartUtil(cid).updateCart(value.product, value.item);
            setOpen({add: false, edit: false});
        } else if(value.mode === 1){
            // add
            new CartUtil(cid).addToCart(value.product, value.item);
            setOpen({add: false, edit: false});
        } else if(value.mode === 2){
            // like
            let _like = value.likeCount || 0;
            let _liked = value.like;
            let _likeProduct = {...likeProduct, [value.product._id]: _liked};
            setLikeProduct(_likeProduct);

            // update product like
            productGroups.forEach(pg=>{
                pg.products.forEach(p=>{
                    if(value.product._id === p._id){
                        p.like = _like;
                    }
                });
            });
        }
    };

    const handleSelectProductEdit = ({value, index}) => {
        console.log('handleSelectProductEdit');
        let cartQty = 0;
        if(cart && cart.products[value._id]){
            cart.products[value._id].items.forEach((item,i)=>{
                if(index !== i){
                    cartQty+=item.qty;
                }
            })
        }
        let newSelectProduct = {...selectProduct, qty: value.qty, oos: false, cartQty: cartQty};
        setSelectProduct(newSelectProduct);
        setItem({index: index, remark: value.remark, optional: value.optional, qty: value.qty});
        setOpen({add: true, edit: false});
    };

    const handleAddAnother = () => {
        setItem({index: -1, remark: '', optional: {total: 0}, qty: 1});
        setOpen({add: true, edit: false});
    };

    const handleClickLike = (e,product) => {
        e.stopPropagation();

        let _like = product.like || 0;
        let _liked = false;
        if(likeProduct[product._id]){
            _like--;
        } else {
            _like++;
            _liked = true;
        }
        setLikeProduct({...likeProduct, [product._id]: _liked});
        product.liked = _liked;
        product.like = _like;

        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/like/product';
        httpClient.put(url, {accountId: product._account, productId: product._id, liked: _liked})
            .then(res => {
                // done
            }).catch(e=>{});
    };

    return (
        <div className={classes.listContainer}>
            <TopRecommend productGroups={productGroups} likeProduct={likeProduct} mode={mode} shop={shop}
                          onClick={
                              value=>{
                                  handleClickOpen(value);
                              }
                          }
                          onLikeChange={handleChange}
            />
            <Card sx={{borderRadius: '5px 5px 0 0', border: 0}} variant={"outlined"}>
            <>
            {productGroups.length === 0 &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Typography>
                    {t('product.notFound')}
                </Typography>
            </Box>
            }
            {productGroups.length > 0 &&
            <Box className={classes.listContainer}>
                <Box pt={2} pb={1} display={"flex"} justifyContent={"center"}>
                    {/*<Typography variant={"h6"}>*/}
                    {/*    == {t('product.all')} ==*/}
                    {/*</Typography>*/}
                    {/*<Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />*/}
                </Box>
                <Box pb={5}>
                    <List subheader={<li />}>
                        {productGroups.map((productGroup, sectionId) => (
                            <Box mb={2} key={`section-${sectionId}`} >
                                <Card variant={"outlined"} sx={{borderRadius: 0}} >
                                    <li className={classes.listSection}>
                                        <ul className={classes.ul}>
                                            <ListSubheader style={{
                                                zIndex: 0,
                                                background: 'linear-gradient(180deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0))'
                                            }}>
                                                <Box mt={2} mb={1}>
                                                    <Typography variant="h6" display="block" color={"black"}>
                                                        <TextI18n value={productGroup.name}></TextI18n>
                                                    </Typography>
                                                </Box>
                                            </ListSubheader>
                                            {productGroup.products.map((value, index) => (
                                                <Box key={`item-${value._id}`}>
                                                    <ListItemButton disabled={(mode !=='v' && (!value.open || value.remaining < 1))}
                                                                    sx={{padding: '8px', opacity: (!value.open || value.remaining < 1)?0.35:1}}
                                                                    onClick={e=>{handleClickOpen(value)}}>
                                                        <ListItemAvatar>
                                                            <Avatar variant="square" sx={{ width: 96 , height: 96}} className={classes.avatar}>
                                                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={
                                                                    value.images[0]?
                                                                        `${process.env.REACT_APP_CDN_BASE_URL}/${value.images[0].permission}/merchant/m/${value.images[0].name}`: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`
                                                                }
                                                                     alt={value.name.i18n['en']}/>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText style={{marginLeft: '10px'}}>
                                                            <React.Fragment>
                                                                <Box display="flex" alignItems="center">
                                                                    <Typography variant="h6" style={{fontSize: '1.15rem'}}>
                                                                        <TextI18nTruncate value={value.name} lines={2}></TextI18nTruncate>
                                                                    </Typography>
                                                                </Box>
                                                                <Box display="flex" justifyContent="space-between" >
                                                                    <Box display="flex" alignItems="center">
                                                                        <Box mr={1}>
                                                                            <Typography color="caption">
                                                                                {t('product.price')}
                                                                            </Typography>
                                                                        </Box>
                                                                        <Typography>
                                                                            <strong><NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} /></strong>
                                                                        </Typography>
                                                                        <Box ml={1}>
                                                                            <Typography color="caption">
                                                                                {t('common.baht')}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                    {mode !== 'v' && selectedBox(cart, value)}
                                                                    {(!value.open || value.remaining < 1) &&
                                                                        <Typography variant="subtitle2" color="secondary">{t('product.outOfStock')}</Typography>
                                                                    }
                                                                </Box>
                                                                <Box sx={{marginTop: '5px'}} display="flex" justifyContent="space-between" alignItems="center">
                                                                    <Box>
                                                                        {value.recommend &&
                                                                            <Box mr={1} sx={{ background: 'red', color: 'white', paddingLeft: '3px', paddingRight: '3px', borderRadius: '3px', fontSize:'0.7rem'}}>
                                                                                {t('product.recommend')}
                                                                            </Box>
                                                                        }
                                                                    </Box>
                                                                    <Box display="flex" justifyContent="end" alignItems="center" onClick={e=>handleClickLike(e, value)}>
                                                                        {likeProduct[value._id] ? <ThumbUpAltIcon color="primary" fontSize="small" /> : <ThumbUpOutlinedIcon color="default" fontSize="small" /> }

                                                                        <Typography variant="caption" style={{marginLeft: '3px'}}>
                                                                            <CountNumber value={value.like} />
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                            </React.Fragment>
                                                        </ListItemText>
                                                    </ListItemButton>
                                                    {index !== productGroup.products.length - 1 &&
                                                        <Divider />
                                                    }
                                                </Box>
                                            ))}
                                        </ul>
                                    </li>
                                </Card>
                            </Box>
                        ))}
                    </List>
                </Box>
                {cart && cart.qty > 0 && mode !== 'v' &&
                <Box>
                    <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={0}>
                        <div style={{paddingBottom: '30px', backgroundImage: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.99))'}}>
                            <Box pl={1} pr={1}>
                                <Button variant="contained"
                                        size="large"
                                        fullWidth={true}
                                        onClick={handleSubmitCart}
                                        disabled={loading}
                                        style={{zIndex: 99, fontWeight: 'bold', paddingTop: '12px', paddingBottom: '12px'}}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center"
                                         style={{fontSize: '1.2rem', width: '100%'}}>
                                        <span style={shapeInvertStyles}>{cart.qty}</span>
                                        <span>{t('cart.myCart')}</span>
                                        <span><NumberFormat value={cart.total} displayType={'text'} thousandSeparator={true}
                                                            prefix={'฿'}/></span>
                                    </Box>
                                </Button>
                            </Box>
                        </div>
                    </Box>

                </Box>
                }
            </Box>
            }
            </>
            </Card>
            <ProductControlDialog
                shop={shop}
                mode={mode}
                product={selectProduct}
                item={item}
                likeProduct={likeProduct}
                open={open.add}
                onChange={handleChange}
                onClose={handleClose}
            />
            <Dialog fullScreen
                    open={open.edit}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                    style={{top: 'auto'}} >
                <ProductEdit value={selectProduct}
                             cid={cid}
                             onClose={handleClose}
                             onEdit={handleSelectProductEdit} />
                <div style={
                    {
                        position: 'fixed',
                        top: 'auto',
                        bottom: 0,
                        left:0,
                        right:0,
                        padding: '8px',
                        background: '#fff',
                        borderRadius: '4px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                        overflow: 'hidden',
                        zIndex: 999
                    }
                }>
                <Button style={{fontSize: '1.2rem', marginBottom: '1rem'}}
                        variant="contained"
                        onClick={handleAddAnother}
                        color="primary"
                        size="large"
                        fullWidth={true}
                        disableElevation autoFocus>
                    <span>{t('cart.addAnother')}</span>
                </Button>
                </div>
            </Dialog>
        </div>
    )
}