import React from "react";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";

export function DeliverTypeIcon(props) {

    return(
        <>
        {props.type === 'DELIVERY' && <TwoWheelerIcon color={'default'} sx={{ fontSize: 20 }} /> }
        {props.type === 'PICKUP' && <ShoppingBagIcon color={'default'} sx={{ fontSize: 20 }} /> }
        {props.type === 'PARCEL' && <LocalShippingIcon color={'default'} sx={{ fontSize: 20 }} /> }
        {props.type === 'SERVE' && <TableRestaurantIcon color={'default'} sx={{ fontSize: 20 }} /> }
        {props.type === 'DINE-IN' && <RestaurantIcon color={'default'} sx={{ fontSize: 20 }} /> }
        </>
    )
}