import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import {httpClient} from "../../../core/HttpClient";
import {ListItem, ListItemSecondaryAction, Rating} from "@mui/material";
import Divider from "@mui/material/Divider";
import {TextI18n} from "../../TextI18n";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import {getNFormat} from "../../NFormatter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import {useHistory} from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_RELOAD":
            return {
                ...state,
                isLoading: true,
                hasError: false,
                hasNext: false,
                cursor: '',
                hits: [],
            };
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursorKey === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch, cancelToken) {
    dispatch({ type: "FETCH_START" });
    try {
        console.log('fetchHits', query.cursor);
        let url = process.env.REACT_APP_API_BASE_URL + '/publish/account/relate?cursor='+query.cursor;
        httpClient.get(url,
            {cancelToken: cancelToken}
        ).then(res => {
            if(Array.isArray(res.data)){
                dispatch({ type: "FETCH_SUCCESS", payload: res.data, cursorKey: query.cursor });
            } else {
                dispatch({ type: "FETCH_FAILURE" });
            }
        }).catch(e=>{
            console.log('error', e);
            dispatch({ type: "FETCH_FAILURE" });
        });
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

function init() {
    return {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null,
        rate: 0,
    };
}

export default function RecommendNearly() {
    const { t } = useTranslation();
    const history = useHistory();
    const [query] = useState({cursor: ''});
    const [{ hits, hasNext, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null,
        rate: 0
    }, init);

    const debouncedFetchHits = useRef(
        _.debounce(
            (query, cancelToken) => fetchHits(query, dispatch, cancelToken),
            500
        )
    ).current;

    useEffect(() => {
        console.log('[RecommendPage]');
        const { token } = httpClient.CancelToken.source();
        debouncedFetchHits(query, token);
    }, [debouncedFetchHits, query]);

    const handleFetchData = (cursor) =>{
        // setQuery({...query, cursor: cursor}); // trig to reload data by useReducer
    };

    const handleClick = (cid) =>{
        history.push(`/hm/o/${cid}`);
    };

    return (
        <Box sx={{width: '100%', height: '100vh', backgroundColor: '#f7f7f7'}} pb={2}>
            <Box p={2}>
                <Typography variant={"h6"}>
                    {t('recommendShop.title')}
                </Typography>
            </Box>
            <Box pb={4}>
                {(hits.length === 0) && !isLoading &&
                <>
                    <Divider sx={{marginTop: '13px', marginBottom: '10px'}} />
                    <Box display="flex" justifyContent="center" mt={4}>
                        <Typography variant="body1" color="textSecondary"
                                    sx={{fontSize: '0.85rem'}}>{t('common.notFound')}</Typography>
                    </Box>
                </>
                }
                {hits.length > 0 &&
                <InfiniteScroll
                    dataLength={hits.length} //This is important field to render the next data
                    next={e=>handleFetchData(cursor)}
                    hasMore={hasNext}>
                    <Box>
                        {hits.map((value, index) => (
                            <Box key={`item-${value._id}-${index}`} mb={2}>
                                <Card variant={"outlined"} onClick={e=>handleClick(value.cid)} style={{borderRadius: 0}} >
                                    {value.image && value.images[0] &&
                                        <CardMedia
                                            component="img"
                                            image={`${process.env.REACT_APP_CDN_BASE_URL}/public/merchant/o/${value.images[0].name}`}
                                            alt="image"
                                        />
                                    }
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar aria-label="recipe"
                                                    variant={"square"}
                                                    src={(value.image && value.image.name) ? `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/s/${value.image.name}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`}/>
                                        </ListItemAvatar>
                                        <ListItemText primary={
                                            <TextI18n value={value.name}></TextI18n>
                                        } />
                                        <ListItemSecondaryAction>
                                            <Box style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                                                <Typography variant={"body1"}>{getNFormat(value.dist.calculated)} {t('cart.distanceUnit')}</Typography>
                                            </Box>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Box style={{color: 'rgba(0, 0, 0, 0.6)'}} pl={2} pr={2} pb={2} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Box display="flex" justifyContent="start" alignItems={"center"}>
                                            <Rating
                                                size={"small"}
                                                name="rate"
                                                value={value.reviewRate}
                                                readOnly={true}
                                            />
                                            <Typography variant={"body1"}>({getNFormat(value.reviewCount)})</Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
                                            {value.followed?<FavoriteIcon color="secondary" />:<FavoriteBorderIcon />}
                                            <Box ml={1}>
                                                <Typography variant={"body1"}>
                                                    {getNFormat(value.reaches)}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Card>
                            </Box>
                        ))}
                    </Box>
                </InfiniteScroll>
                }
            </Box>
            {isLoading &&
                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CircularProgress size={20}/>
                </Box>
            }
        </Box>
    )
};