import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import FoodOrderDialog from '../../components/FoodOrder/FoodOrderDialog'
import {getDatabase, onValue, ref} from "firebase/database";
import {getAuth, signInAnonymously} from "firebase/auth";
import {httpClient} from "../../core/HttpClient";
import {getDatabaseInstance} from "../../core/Firebase";
import jwt_decode from "jwt-decode";
import {useParams} from "react-router-dom";

let searching = false;

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        marginLeft: '10px',
    },


});

export default function OrdersTracking({accountStatus}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { cid } = useParams();
    const [list, setList] = useState([]);
    const [openFoodOrder, setOpenFoodOrder ] = useState(false);
    console.log('OrdersTracking');
    useEffect(() => {
        console.log('[OrdersTracking]');
        const app = getDatabaseInstance(cid);
        console.log('app', app.name);
        let token = localStorage.getItem('token');
        if(token){
            let decoded = jwt_decode(token);
            let customerId = decoded.customer;
            let key = 'customers/'+customerId;
            const db = getDatabase(app);
            const auth = getAuth(app);
            signInAnonymously(auth)
                .then(() => {
                    const statusRef = ref(db, key);
                    onValue(statusRef, (snapshot) => {
                        const data = snapshot.val();
                        if(data){
                            let list = [];
                            if(!searching){
                                searching = true;
                                let url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/orders?d=true';
                                httpClient.get(url)
                                    .then(res => {
                                        searching = false;
                                        if(res.data){
                                            if(Array.isArray(res.data)){
                                                res.data.forEach(d=>{
                                                    d.account = d._account;
                                                    list.push(d);
                                                });
                                            }
                                            setList(list);
                                        }else{
                                            setList([]);
                                        }
                                    });
                            }
                        }
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [cid]);

    const handleFoodOrderOpen = () => {
        setOpenFoodOrder(true);
    };

    const handleFoodOrderClose = () => {
        setOpenFoodOrder(false);
    };

    return (
        <div>
            {list.length > 0 &&
            <div style={{height: '3.9rem'}}>
                <Box
                    style={{
                        width: '100%',
                        cursor: 'pointer',
                        top: 0,
                        zIndex: 999,
                        position: 'fixed'
                    }}
                >
                    <AppBar elevation={0} color="orange" style={{marginTop: accountStatus.verified === 0?'48px':'0px'}}
                            onClick={handleFoodOrderOpen}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                            >
                                <ExpandMoreIcon style={{ color: '#FFFFFF' }} />
                            </IconButton>
                            <Typography className={classes.title} variant="body" noWrap>
                                <CardHeader
                                    style={{padding: '8px', fontWeight: 'bold', color: 'white'}}
                                    title={list[0].transactionNo}
                                    titleTypographyProps={{variant:'title' }}
                                    subheader={list[0].timeLine.step===1?t('order.orders'):list[0].timeLine.step===2?t('order.cooking'):list[0].timeLine.step===3?t('order.prepared'):list[0].timeLine.step===4?t('order.delivering'):t('order.received')}
                                    subheaderTypographyProps={{variant:'subtitle2', color: 'white'}}
                                />
                            </Typography>
                            <AvTimerIcon style={{ color: '#FFFFFF' }} />
                        </Toolbar>
                    </AppBar>
                </Box>
            </div>
            }
            <FoodOrderDialog open={openFoodOrder} items={list} onClose={handleFoodOrderClose} />
        </div>
    );
}