import React, {useState} from 'react';
import Box from "@mui/material/Box";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {httpClient} from "../../../core/HttpClient";
import {makeStyles} from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DeleteIcon from '@mui/icons-material/Delete';
import CardMedia from "@mui/material/CardMedia";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    inputBrowse: {
        display: 'none',
    },
    media: {
        cursor: 'pointer'
    },
});

export default function VerifyPhotos({images, onChange}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = React.useState(null);

    const handleOpen = (image) => {
        setImage(image);
    };
    const handleClose = () => setImage(null);
    const handleDelete = () => {
        if(image){
            onChange('delete', image);
        }
        setImage(null);

    };

    const handleUpload = ({ target }) => {
        setLoading(true);
        const image = target.files[0];
        if(image){
            const data = new FormData();
            data.append('file', image);
            data.append('type', 'payment');
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/report/attachment';
            httpClient.post(url, data)
                .then(res => {
                    if(res.data){
                        onChange('add', res.data);
                    }
                    setLoading(false);
                });
        }

        target.value = ''; // fix same file select
    };

    return (
        <Box display={"flex"} sx={{ flexWrap: 'wrap', alignContent: 'flex-start' }} p={1}>
            {images && images.map((image, i) => (
                <Box key={`review-images-${i}`} mr={1} sx={{

                    backgroundColor: '#eaeaea',
                    '&:hover': {
                        backgroundColor: '#eaeaea',
                        opacity: [0.9, 0.8, 0.7],
                    },
                }}
                     display={"flex"} justifyContent={"center"} alignItems={"center"}
                     onClick={e=>handleOpen(image)}
                >
                    <Card variant={"outlined"}>
                        <CardMedia
                            component="img"
                            image={`${process.env.REACT_APP_CDN_BASE_URL}/${image.key}`}
                            alt="review"

                            sx={{width: 62,
                                height: 62, }}
                        />
                    </Card>
                </Box>
            ))
            }
            {(!images || images.length < 4) &&
            <Box mr={1} sx={{
                    width: 62,
                    height: 62,
                    backgroundColor: '#eaeaea',
                    '&:hover': {
                        backgroundColor: '#eaeaea',
                        opacity: [0.9, 0.8, 0.7],
                    },
                }}
                 display={"flex"} justifyContent={"center"} alignItems={"center"}
            >
                <input
                    accept="image/*"
                    className={classes.inputBrowse}
                    id="contained-button-file-retry"
                    onChange={handleUpload}
                    type="file"
                />
                <label htmlFor="contained-button-file-retry">
                    {loading && <CircularProgress size={20} />}
                    {!loading && <AddAPhotoIcon />}
                </label>
            </Box>
            }
            <Dialog open={image !== null}
                    onClose={handleClose}>
                <DialogContent>
                    <Box>
                        {image !== null &&
                            <img
                                style={{width: '100%'}}
                                src={`${process.env.REACT_APP_CDN_BASE_URL}/${image.key}`}
                                alt="review"
                            />
                        }
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            disableElevation
                            size="large"
                            color={"secondary"}
                            startIcon={<DeleteIcon />}
                            fullWidth={true}
                            onClick={handleDelete}
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.delete')}</span>
                    </Button>
                    <Button variant="contained"
                            disableElevation
                            size="large"
                            color={"grey"}
                            fullWidth={true}
                            onClick={handleClose}
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.close')}</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
};