import React from 'react';
import Box from '@mui/material/Box';
import {Paper} from "@mui/material";

export default function MenuOnlyPackage() {

    return (
        <Box >
            <Box pb={2} style={{backgroundColor: '#e9edf0', color: '#1B1A17'}}>
                {Array.apply(0, Array(20)).map(function (x, i) {
                    return <Box mt={2} mb={2} key={i+1}>
                        <Paper elevation={0}>
                            <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/documents/1.4/MENU/Slide${i+1}.JPG`} style={{width: '100%'}} alt={"user manual"} />
                        </Paper>
                    </Box>;
                })}
            </Box>
        </Box>
    )
};