import React, {useState, useEffect, useCallback} from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../core/HttpClient";
import ShopInfo from "../Shop/ShopInfo";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {useHistory, useLocation, useParams} from "react-router-dom";
import Lottie from "lottie-react";
import chickyAnimation from "../../lotties/chicky.json";
import ProductGrid from "../Product/ProductGrid";
import FooterPoweredBy from "../FooterPoweredBy";

export default function ViewMenuPage() {
    const { t } = useTranslation();
    const search = useLocation().search;
    const partner = new URLSearchParams(search).get('p');
    const { cid } = useParams();
    const history = useHistory();
    const [state, setState] = useState({image:{}});
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expired, setExpired] = useState(false);

    const loyalistFunction = useCallback(() => {
        return new Promise( (resolve, reject) => {

            const token = localStorage.getItem('token');
            if (token) {
                let url =  process.env.REACT_APP_API_BASE_URL + '/secure/customer/loyalist';
                httpClient.get(url)
                    .then(res => {
                        if(res.data){
                            resolve(res.data);
                        } else {
                            resolve({
                                "customer": {_id: '', membership: false, point: 0},
                                "loyalty": false
                            });
                        }
                    }).catch(e=>{
                    resolve({
                        "customer": {_id: '', membership: false, point: 0},
                        "loyalty": false
                    });
                });
            } else {
                resolve({
                    "loyalty": false
                });
            }
        });
    }, []);

    const autoFavoriteFunction = useCallback(()=>{
        console.log('autoFavoriteFunction');
        let token = localStorage.getItem('token');
        if(token){
            let action = 'add';
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/like/account';
            httpClient.put(url, {action: action, cid: cid})
                .then(res => {
                    // done
                }).catch(e=>{});
        }
    }, [cid]);

    useEffect(() => {
        console.log('[ViewMenuPage]');
        let tasks = [
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account'),
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account/products'),
            loyalistFunction(),
            autoFavoriteFunction(),
        ];
        Promise.all(tasks)
            .then(rs=>{
                let shopInfo = {};
                if(rs[0] && rs[0].data){
                    if(rs[0].data.expired){
                        if(rs[0].data.expiredDay > 7){
                            history.replace({
                                pathname: `/hm/r/${cid}`,
                                historyData: {showFoodOrder: false}
                            });
                        } else {
                            setExpired(true);
                        }
                    }
                    shopInfo = rs[0].data;
                }

                if(rs[1] && rs[1].data){
                    setList(rs[1].data.results);
                }

                if(rs[2]){
                    shopInfo.loyalty = rs[2].loyalty;
                    shopInfo.customer = rs[2].customer;
                    if(rs[2].likes){
                        let likeProduct = {};
                        rs[2].likes.forEach(like=>{
                            likeProduct[like] = true;
                        });
                        shopInfo.likeProduct = likeProduct;
                    }
                }

                setState(shopInfo);
                setLoading(false);
            });
    }, [loyalistFunction, autoFavoriteFunction, history, cid]);

    return (
        <div>
            {state.name &&
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{state.name.i18n.th}</title>
                </Helmet>
            </HelmetProvider>
            }
            {state.status === 202 && state.code === 'DTA_001' &&
                <Box justifyContent={"center"} m={2}>
                    <Lottie animationData={chickyAnimation} loop={true} />
                    <Alert severity="error">{t('errors.invalidCID')}</Alert>
                </Box>
            }
            {state.status === 'denied' &&
                <Box p={2}>
                    <Alert severity="error" style={{borderRadius: 0}} >{t('common.message.reject')}</Alert>
                </Box>
            }
            {loading &&
                <Box display="flex" justifyContent="center" mt={4} sx={{background: 'white'}}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {state.status === 'activated' &&
            <>
                {!loading && expired &&
                    <Box p={2}>
                        <Alert severity="warning" style={{borderRadius: 0}}>{t('common.message.expired')}</Alert>
                    </Box>
                }
                {!loading && !expired  &&
                    <>
                        <ShopInfo shop={state} time={true} mode={"v"} review={false} />
                        <ProductGrid list={list} shop={state} mode="v" partner={partner} />
                        <FooterPoweredBy />
                    </>
                }
            </>
            }
        </div>
    );
}
