import React, { forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import Slide from '@mui/material/Slide';
import FoodOrderTimeLine from '../FoodOrderTimeLine';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});



export default function FoodOrderDialog({open, items, onClose}) {
    console.log('[FoodOrderDialog]');
    const { t } = useTranslation();

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}>
            <AppBar sx={{ top: 'auto', bottom: 0 }} color="orange" >
                <Toolbar
                    onClick={onClose}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="close"
                    >
                        <ExpandLessIcon style={{ color: '#FFFFFF' }} />
                    </IconButton>
                    {items[0] &&
                    <>
                        <Typography style={{
                            flexGrow: 1
                        }} variant="body" noWrap>
                            <CardHeader
                                style={{padding: '8px', fontWeight: 'bold', color: 'white'}}
                                title={items[0].transactionNo}
                                titleTypographyProps={{variant:'title' }}
                                subheader={items[0].timeLine.step===1?t('order.orders'):items[0].timeLine.step===2?t('order.cooking'):items[0].timeLine.step===3?t('order.prepared'):items[0].timeLine.step===4?t('order.delivering'):t('order.received')}
                                subheaderTypographyProps={{variant:'subtitle2', color: 'white'}}
                            />
                        </Typography>
                        <AvTimerIcon style={{ color: '#FFFFFF' }} />
                    </>
                    }
                    {!items[0] &&
                    <Typography style={{
                        flexGrow: 1,
                        color:'white'
                    }} variant="body" noWrap>
                        {t('order.deliveryOrder')}
                    </Typography>
                    }
                </Toolbar>
            </AppBar>
            <Box mb={5}>
                <FoodOrderTimeLine mode={"o"} items={items} />
            </Box>
        </Dialog>
    )
};