import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function CancellationPolicy() {
    const { t } = useTranslation();

    return (
        <div>
            <Box p={1} m={1} style={{background: 'white', color: 'rgba(0, 0, 0, 0.87)'}}>
                <Box mb={2}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}><b>{t('cancellationPolicy.title')}</b></Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>{t('cancellationPolicy.data1')}<b>{t('cancellationPolicy.data1-1')}</b>{t('cancellationPolicy.data1-2')}<b>{t('cancellationPolicy.data1-3')}</b>{t('cancellationPolicy.data1-4')}</Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>{t('cancellationPolicy.data2')}</Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>{t('cancellationPolicy.data3')}</Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>{t('cancellationPolicy.data4')}</Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>{t('cancellationPolicy.data5')}</Typography>
                </Box>
            </Box>
        </div>
    )
};