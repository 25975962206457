import React, {useCallback, useEffect, useState} from 'react';
import { Route, useParams } from 'react-router-dom';
import { Context, StoreContext, OnlineContext } from "../../core/Context";
import {httpClient} from "../../core/HttpClient";
import {getCache, saveCache} from "../../core/LocalStorageUtil";
import "../../core/Firebase";
import {getDatabaseInstance} from "../../core/Firebase";
import {getAuth, signInAnonymously} from "firebase/auth";
import {getDatabase, goOffline, goOnline, onValue, ref, remove} from "firebase/database";
import Base64MongoId from "base64-mongo-id";
import dayjs from "dayjs";
import {useIdleTimer} from "react-idle-timer";
import jwt_decode from "jwt-decode";
import liff from "@line/liff";

// const liff = window.liff;

const lineAuthentication = async(cb) => {
    console.log('lineAuthentication');
    const liffId = process.env.REACT_APP_LINE_LIFF_ID;
    try{
        // withLoginOnExternalBrowser = false (User don't have LINE App should not redirect to login's LINE)
        await liff.init({ liffId: liffId, withLoginOnExternalBrowser: false }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            const idToken = liff.getIDToken();
            const accessToken = liff.getAccessToken();
            return cb({idToken: idToken, accessToken: accessToken});
        }else{
            // external browser
            console.log('no logged in');
            return cb(null);
        }
    }catch(e){}
};

const BlankWithAuthLayout = ({ children}) => {
    const { cid } = useParams();
    const [context, setContext] = useState({cid: cid});
    const [onlineContext, setOnlineContext] = useState(-1);
    const [storeContext, setStoreContext] = useState({
        deliverType: '',
        profile: {name: '', mobile: '', landmark: ''},
        errors: []
    });
    let cacheData = getCache(cid);
    let visited = cacheData.visited;
    if(!visited){
        cacheData.visited = true;
        saveCache(cacheData);
    }

    const tokenProcess = useCallback((mode) => {
        lineAuthentication(lineToken=> {
            if(lineToken && cid){
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({accessToken: lineToken.accessToken, idToken: lineToken.idToken, redirectUri: process.env.REACT_APP_BASE_URL+`/hm/login?cid=`+cid, cid: cid})
                };
                const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
                fetch(url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if(data.token){
                            localStorage.setItem('token', data.token);
                            let customer = {
                                displayName: data.displayName,
                                pictureUrl: data.pictureUrl
                            };
                            localStorage.setItem('customer', JSON.stringify(customer));
                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('customer');
                        }
                    });
            } else {
                // don't have LINE should not to login page
                // window.location = `/hm/login`;
            }
        });
    }, [cid]);

    useEffect(() => {
        console.log('[BlankWithAuthLayout]');

        // save path history for refresh token
        let mode = 'v';
        let urlPath = window.location.pathname;
        if(urlPath){
            if(urlPath.startsWith('/hm')){
                mode = urlPath.charAt(4);
                localStorage.setItem('mode', mode);
            }
        }

        // get customer token by line
        let token = localStorage.getItem('token');
        if(!token){
            tokenProcess(mode);
        } else {
            let decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000) {
                console.log('expired');
                tokenProcess(mode);
            } else {
                console.log('not expired');
            }
        }

        if(cid){
            const app = getDatabaseInstance(cid);
            const auth = getAuth(app);
            signInAnonymously(auth)
                .then(() => {
                    const db = getDatabase(app);
                    let accountId = Base64MongoId.toHex(cid);
                    let key = 'accounts/'+accountId+'/online';
                    const statusRef = ref(db, key);
                    onValue(statusRef, (snapshot) => {
                        const data = snapshot.val();
                        if (data) {
                            if(data.users){
                                setOnlineContext(1); // 1 = online

                                // remove old data more than 30 days
                                Object.keys(data.users).forEach((key) => {
                                    let d = dayjs().diff(data.users[key], 'days', true);
                                    if(d > 30){
                                        let removeKey = 'accounts/'+accountId+'/online/users/'+key;
                                        remove(ref(db, removeKey)).catch();
                                    }
                                });
                            } else {
                                setOnlineContext(data.last);
                            }
                        }
                    });

                    // update visitor
                    if(!visited){
                        if(auth.currentUser && auth.currentUser.uid){
                            const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/view';
                            httpClient.put(url, {cid: cid, uid: auth.currentUser.uid})
                                .then(res => {
                                    // done
                                }).catch(e=>{});

                            const reachUrl = process.env.REACT_APP_API_BASE_URL + '/private/customer/reach';
                            httpClient.put(reachUrl, {cid: cid, uid: auth.currentUser.uid})
                                .then(res => {
                                    // done
                                }).catch(e=>{});
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        localStorage.setItem('cid', cid);

    },[cid, visited, tokenProcess]);

    const onAction = (event) => {
        if(['visibilitychange','focus'].indexOf(event.type) > -1){
            console.log('visibilityState', document.visibilityState);
            if(document.visibilityState === 'hidden'){
                const app = getDatabaseInstance(cid);
                const db = getDatabase(app);
                goOffline(db);
            } else if(document.visibilityState === 'visible'){
                const app = getDatabaseInstance(cid);
                const db = getDatabase(app);
                goOnline(db);
            }
        }
    }

    useIdleTimer({
        onAction,
        timeout: 10_000,
        throttle: 500
    });

    return (
        <div>
            <Context.Provider value={[context, setContext]}>
                <StoreContext.Provider value={[storeContext, setStoreContext]}>
                    <OnlineContext.Provider value={[onlineContext]}>
                        {children}
                    </OnlineContext.Provider>
                </StoreContext.Provider>
            </Context.Provider>
        </div>
    );
};

const BlankWithAuthLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                return <BlankWithAuthLayout><Component {...props} /></BlankWithAuthLayout>
            }}
        />
    );
};

export default BlankWithAuthLayoutRoute;