import React from 'react';
import { makeStyles } from '@mui/styles';
import { Route } from 'react-router';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Footer from "../../components/HomePage/Footer";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {useTranslation} from "react-i18next";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column'
    },
    body: {
        // color: '#fff',
        background: '#fff',
        // background: '#1abc9c'
    }
}));

const DefaultLayout = ({ children}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    React.useEffect(() => {
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            new Promise(resolve => setTimeout(resolve, 100)).then(r => {
                const element = document.getElementById(anchor);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            });
        }
    }, []);

    const handleGoToPage = (path) => {
        history.push(path);
    };

    const handleGoToLogin = () => {
        const url = `${process.env.REACT_APP_MERCHANT_LIFF_URL}`;
        window.location.href = url;
    };

    const handleGoTo = (popupState, id) => {
        if(popupState){
            popupState.close();
        }
        const pathname = window.location.pathname;
        if(pathname === "/"){
            new Promise(resolve => setTimeout(resolve, 100)).then(r => {
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            });
        } else {
            const url = `${process.env.REACT_APP_BASE_URL}/#${id}`;
            window.location.href = url;
        }
    };

    const handleGoToDocument = (popupState) => {
        popupState.close();
        new Promise(resolve => setTimeout(resolve, 100)).then(r => {
            window.location.href = `${process.env.REACT_APP_BASE_URL}/documents`;
        });
    };
    return (
        <Container style={{padding: 0}}>
            <Box className={classes.body}>
                <AppBar position="static" >
                    <Toolbar>
                        <Link href="/" pt={1}>
                            <img edge="start" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fki.jpg`} alt="foodkub"  style={{height: '40px'}} />
                        </Link>
                        <Box sx={{ flexGrow: 1 }}>
                            <Box display={"flex"} justifyContent={"center"} sx={{display: { xs: 'none', md: 'flex' }}}>
                                <Button
                                sx={{ my: 2, mx:4, color: 'white', fontSize: '1rem' }}
                                onClick={e=>handleGoTo(null, 'our-products')}
                                >
                                    {t('web.productTitle')}
                                </Button>
                                <Button
                                    sx={{ my: 2, mx:4, color: 'white', fontSize: '1rem' }}
                                    onClick={e=>handleGoTo(null, 'restaurant-type')}
                                >
                                    {t('web.merchantTypeTitle')}
                                </Button>
                                <Button
                                    sx={{ my: 2, mx:4, color: 'white', fontSize: '1rem' }}
                                    onClick={e=>handleGoTo(null, 'pricing-rate')}
                                >
                                    {t('pricing.rate')}
                                </Button>
                                <Button
                                    sx={{ my: 2, mx:4, color: 'white', fontSize: '1rem' }}
                                    onClick={e=>handleGoToPage('/contact')}
                                >
                                    {t('contactUs')}
                                </Button>
                            </Box>
                        </Box>
                        <Box display={"flex"} justifyContent={"end"} sx={{display: { xs: 'none', md: 'flex' }}}>
                            <Button endIcon={<ArrowForwardRoundedIcon />}
                                sx={{ my: 2, color: 'white', fontSize: '1rem' }}
                                onClick={handleGoToLogin}
                            >
                                {t('login.title')}
                            </Button>
                        </Box>
                        <Box sx={{display: { xs: 'flex', md: 'none' }}}>
                            <PopupState variant="popover" popupId="demo-popup-menu">
                                {(popupState) => (
                                <React.Fragment>
                                    <IconButton
                                        size="large"
                                        edge="end"
                                        color="inherit"
                                        aria-label="menu"
                                        {...bindTrigger(popupState)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu {...bindMenu(popupState)}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <MenuItem onClick={handleGoToLogin}>{t('login.title')}</MenuItem>
                                        <MenuItem onClick={e=>handleGoTo(popupState, 'our-products')}>{t('web.productTitle')}</MenuItem>
                                        <MenuItem onClick={e=>handleGoTo(popupState, 'restaurant-type')}>{t('web.merchantTypeTitle')}</MenuItem>
                                        <MenuItem onClick={e=>handleGoTo(popupState, 'pricing-rate')}>{t('pricing.rate')}</MenuItem>
                                        <MenuItem onClick={e=>handleGoToDocument(popupState)}>{t('document.title')}</MenuItem>
                                    </Menu>
                                </React.Fragment>
                                )}
                            </PopupState>
                        </Box>
                    </Toolbar>
                </AppBar>
                <div className={classes.root}>
                    {children}
                </div>
                <Box>
                    <Footer />
                </Box>
            </Box>
        </Container>
    );
};

const DefaultLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                return <DefaultLayout><Component {...props} /></DefaultLayout>
            }}
        />
    );
};

export default DefaultLayoutRoute;