import React from 'react';
import { useTranslation } from "react-i18next";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {SvgIcon} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function FooterMenuSlide() {
    const { t } = useTranslation();
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

    return (
        <Box p={2} pb={0}>
            <Grid>
                <Grid container alignItems={"center"} spacing={0} direction={matches?'row':'column-reverse'}>
                    <Grid item xs={8} sx={{maxWidth: '100%'}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Link href="/contact" underline="none"><Typography variant="body1" component={"span"} style={{color: '#9aa3ae'}} >{t('contactUs')}</Typography></Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link href="/privacy" underline="none"><Typography variant="body1" component={"span"} style={{color: '#9aa3ae'}}>{t('privacy')}</Typography></Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link href="/terms" underline="none"><Typography variant="body1" component={"span"} style={{color: '#9aa3ae'}}>{t('termsOfService')}</Typography></Link>
                            </Grid>
                            <Grid item xs={6}>
                                <Link href="/cancellation" underline="none"><Typography variant="body1" component={"span"} style={{color: '#9aa3ae'}}>{t('cancellationPolicy.title')}</Typography></Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Box display={"flex"} justifyContent={matches?'end':'center'} mb={matches?0:2} >
                            <Box mr={1}>
                                <IconButton>
                                    <SvgIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                                             aria-describedby="desc" role="img" color="#9aa3ae">
                                            <title>TikTok</title>
                                            <desc>FoodKub TikTok</desc>
                                            <path data-name="layer1"
                                                  d="M 53.865 15.5065 a 14.2795 14.2795 90 0 1 -1.2349 -0.7187 a 17.3635 17.3635 90 0 1 -3.1717 -2.6947 c -2.3653 -2.7064 -3.2487 -5.452 -3.5741 -7.3742 h 0.0131 C 45.6256 3.1233 45.738 2.0909 45.7549 2.0909 H 34.9817 V 43.749 c 0 0.5593 0 1.1121 -0.0235 1.6583 c 0 0.068 -0.0065 0.1307 -0.0104 0.2039 c 0 0.03 0 0.0615 -0.0065 0.0928 c 0 0.0079 0 0.0157 0 0.0235 a 9.1476 9.1476 90 0 1 -4.6026 7.2606 a 8.9908 8.9908 90 0 1 -4.4575 1.1761 c -5.0194 0 -9.0875 -4.0929 -9.0875 -9.1476 s 4.068 -9.1476 9.0875 -9.1476 a 9.0039 9.0039 90 0 1 2.7979 0.443 l 0.0131 -10.9693 a 20.0123 20.0123 90 0 0 -15.4202 4.5111 a 21.1427 21.1427 90 0 0 -4.613 5.6885 c -0.4547 0.7841 -2.1706 3.9348 -2.3784 9.0483 c -0.1307 2.9024 0.741 5.9094 1.1565 7.1521 v 0.0261 c 0.2614 0.7318 1.2741 3.2291 2.9246 5.3344 A 21.8928 21.8928 90 0 0 15.0282 61.5059 v -0.0261 l 0.0261 0.0261 C 20.2698 65.0498 26.0524 64.8173 26.0524 64.8173 c 1.001 -0.0405 4.3543 0 8.1623 -1.8047 c 4.2236 -2.0007 6.6281 -4.9816 6.6281 -4.9816 a 20.7076 20.7076 90 0 0 3.612 -6.0021 c 0.9749 -2.5627 1.3003 -5.6362 1.3003 -6.8646 V 23.0637 c 0.1307 0.0784 1.8714 1.2297 1.8714 1.2297 s 2.5077 1.6074 6.4203 2.6541 c 2.807 0.7449 6.5889 0.9017 6.5889 0.9017 V 17.1544 C 59.3104 17.2982 56.6198 16.88 53.865 15.5065 Z"></path>
                                        </svg>
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                            <Box ml={1} mr={1}>
                                <IconButton onClick={e=>{window.location.href = 'https://www.facebook.com/FoodKubThailand?mibextid=LQQJ4d'}}>
                                    <SvgIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                                             aria-describedby="desc" role="img" color="#9aa3ae">
                                            <title>Facebook</title>
                                            <desc>FoodKub Facebook</desc>
                                            <path data-name="layer1"
                                                  d="M39.8 12.2H48V0h-9.7C26.6.5 24.2 7.1 24 14v6.1h-8V32h8v32h12V32h9.9l1.9-11.9H36v-3.7a3.962 3.962 0 0 1 3.8-4.2z"
                                            ></path>
                                        </svg>
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                            <Box ml={1} mr={1}>
                                <IconButton onClick={e=>{window.location.href = 'https://instagram.com/foodkubth?igshid=NzZlODBkYWE4Ng=='}}>
                                    <SvgIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                                             aria-describedby="desc" color="#9aa3ae">
                                            <title>Instagram</title>
                                            <desc>FoodKub Instagram</desc>
                                            <path data-name="layer1"
                                                  d="M8.2 0h47.6A7.989 7.989 0 0 1 64 8.2v47.6a7.989 7.989 0 0 1-8.2 8.2H8.2A7.989 7.989 0 0 1 0 55.8V8.2A7.989 7.989 0 0 1 8.2 0zm38.4 7.1a2.9 2.9 0 0 0-2.9 2.9v6.9a2.9 2.9 0 0 0 2.9 2.9h7.2a2.9 2.9 0 0 0 2.9-2.9V10a2.9 2.9 0 0 0-2.9-2.9zm10.2 20h-5.6a19.758 19.758 0 0 1 .8 5.5c0 10.6-8.9 19.3-19.9 19.3s-19.9-8.6-19.9-19.3a19.758 19.758 0 0 1 .8-5.5H7.1v27a2.476 2.476 0 0 0 2.5 2.5h44.6a2.476 2.476 0 0 0 2.5-2.5l.1-27zm-24.7-7.7a12.723 12.723 0 0 0-12.9 12.5 12.64 12.64 0 0 0 12.9 12.4A12.723 12.723 0 0 0 45 31.8a12.64 12.64 0 0 0-12.9-12.4z"
                                            ></path>
                                        </svg>
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                            <Box ml={1}>
                                <IconButton>
                                    <SvgIcon>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                                             aria-describedby="desc" color="#9aa3ae">
                                            <title>Youtube</title>
                                            <desc>FoodKub Youtube</desc>
                                            <path data-name="layer2"
                                                  d="M39.933 6.027v14.051a7.659 7.659 0 0 0 .329 2.635 1.972 1.972 0 0 0 2.086 1.317c1.208 0 2.415-.768 3.623-2.2v1.976h3.293V6.027h-3.293v13.612c-.768.988-1.427 1.537-2.086 1.537-.439 0-.659-.22-.768-.768V6.027zM32.468 24.14a4.341 4.341 0 0 0 3.732-1.866 7.08 7.08 0 0 0 .988-4.172v-6.257a7.08 7.08 0 0 0-.988-4.172 4.48 4.48 0 0 0-3.732-1.866 4.341 4.341 0 0 0-3.732 1.866 7.08 7.08 0 0 0-.988 4.172V18.1a7.08 7.08 0 0 0 .988 4.172 4.48 4.48 0 0 0 3.732 1.868zm-1.537-12.954c0-1.647.549-2.415 1.537-2.415s1.537.768 1.537 2.415v7.465c0 1.647-.549 2.525-1.537 2.525s-1.537-.878-1.537-2.525zM23.357 23.92v-9.66L27.638-.011h-3.623L21.6 9.32 19.075-.011h-3.732C16 1.965 16.77 4.16 17.648 6.685a65.168 65.168 0 0 1 2.2 7.575v9.66z"
                                            ></path>
                                            <path data-name="layer1" d="M56.729 29.519a6.09 6.09 0 0 0-3.623-1.647c-4.391-.439-10.978-.768-19.76-.768a176.435 176.435 0 0 0-19.76.768 6.9 6.9 0 0 0-3.623 1.647 6.453 6.453 0 0 0-2.086 3.513C7.22 35.776 7 39.948 7 45.546a67.519 67.519 0 0 0 .878 12.515 6.453 6.453 0 0 0 2.086 3.513 6.09 6.09 0 0 0 3.623 1.647 176.435 176.435 0 0 0 19.76.768 175.145 175.145 0 0 0 19.76-.768 5.563 5.563 0 0 0 3.623-1.647 6.453 6.453 0 0 0 2.086-3.513c.659-2.744.878-6.916.878-12.515a67.519 67.519 0 0 0-.878-12.515 6.727 6.727 0 0 0-2.087-3.512zm-34.8 7.136h-3.842v20.308h-3.622V36.654h-3.733v-3.4H21.82v3.4zm9.551 20.308H28.3v-1.976c-1.317 1.427-2.525 2.2-3.623 2.2a1.972 1.972 0 0 1-2.086-1.317 7.659 7.659 0 0 1-.329-2.635V39.289h3.293V53.45c.11.549.329.768.768.768.659 0 1.317-.549 2.086-1.537V39.289h3.181l-.11 17.674zm12.185-5.379a11.8 11.8 0 0 1-.329 3.513 2.465 2.465 0 0 1-2.525 1.976 4.5 4.5 0 0 1-3.293-1.973v1.756h-3.184v-23.6h3.184v7.794a4.158 4.158 0 0 1 3.293-1.976 2.465 2.465 0 0 1 2.525 1.976 12.319 12.319 0 0 1 .329 3.513zm12.076-2.854h-6.367v3.07c0 1.647.549 2.415 1.647 2.415.768 0 1.208-.439 1.427-1.317 0-.22.11-.988.11-2.2h3.293v.439a7.923 7.923 0 0 1-.11 2.086 4.931 4.931 0 0 1-.768 1.976 4.888 4.888 0 0 1-7.684 0 7.08 7.08 0 0 1-.988-4.172v-6.139a7.08 7.08 0 0 1 .988-4.172 4.541 4.541 0 0 1 3.841-1.866 4.341 4.341 0 0 1 3.732 1.866 7.08 7.08 0 0 1 .988 4.172l-.11 3.842z"
                                            ></path>
                                            <path data-name="layer1" d="M50.911 41.924c-1.1 0-1.647.768-1.647 2.415v1.647h3.184v-1.647c.109-1.539-.439-2.415-1.537-2.415zm-11.856 0a2.287 2.287 0 0 0-1.647.768V53.45a2.287 2.287 0 0 0 1.647.768c.878 0 1.427-.768 1.427-2.415v-7.574c0-1.429-.439-2.305-1.427-2.305z"
                                            ></path>
                                        </svg>
                                    </SvgIcon>
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
};