import React, { useState, useRef } from 'react';
import Box from '@mui/material/Box';
import L from 'leaflet';
import {MapContainer, TileLayer, Marker} from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './style.css'
import {isDesktop} from "react-device-detect";
// import Control from "react-leaflet-custom-control";
// import Alert from "@mui/material/Alert";
// import NumberFormat from "react-number-format";

const redIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

const LocationMarker = (props) => {
    const [position] = useState(props.position);
    const markerRef = useRef(null);

    const marker = markerRef.current;
    if (marker != null) {
        marker.setLatLng(props.position);
    }

    return position === null ? null : (
        <Marker position={position} ref={markerRef} icon={redIcon}></Marker>
    )
};

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

export default function LocationView({lat, lng, zoom, t, profile}) {
    console.log('[LocationView]');

    return (
        <Box style={{width: 'inherit', height: 'inherit'}}>
            {lat && lng &&
            <MapContainer className="view-map"
                          id={"view-map"}
                          center={[lat, lng]}
                          zoom={zoom || 15}
                          {...interactionOptions}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker effectOn={this} position={[lat, lng]} />
                {/*<Control prepend position='bottomleft' >*/}
                {/*    <Box>*/}
                {/*        <Alert color={"info"} icon={false}>*/}
                {/*            <div style={{paddingBottom: 0}}><strong>{t('address.contact')} : {profile.name}</strong></div>*/}
                {/*            <div style={{paddingBottom: 0}}>{t('address.mobile')} : {profile.mobile && <NumberFormat value={profile.mobile} displayType={'text'} format="###-###-####" />}{!profile.mobile && <span>{t('common.notYetSpecify')}</span>}</div>*/}
                {/*            {profile.landmark &&*/}
                {/*                <div style={{paddingBottom: 0}}>{t('address.landmarkAlt')} : {profile.landmark}</div>*/}
                {/*            }*/}
                {/*        </Alert>*/}
                {/*    </Box>*/}
                {/*</Control>*/}
            </MapContainer>
            }
        </Box>
    )
};