import React, {useRef} from 'react';
import { useTranslation } from "react-i18next";
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Countdown from "react-countdown";
import dayjs from "dayjs";
import MobilePaymentChannel from "../../Payment/MobilePaymentChannel";
import Card from "@mui/material/Card";

export default function MobileOrderInfo({value, cid}) {
    const { t } = useTranslation();
    const clockRef = useRef();

    return (
        <Card sx={{borderRadius: '0 0 5px 5px', border: 0}} variant={"outlined"}>
            <CardContent>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Typography variant={"h6"}>
                        {t('mobileOrder.orderCode')}
                    </Typography>
                </Box>
                <Box mt={2} mb={4}>
                    <Box p={2}
                         sx={{borderRadius: '4px', border: 1}}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Typography variant={"h6"} sx={{fontSize: '32px'}}>
                                <strong>{value.orderCode}</strong>
                            </Typography>
                        </Box>
                        {value.orderDate &&
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Typography variant="caption">
                                    <Countdown
                                        ref={clockRef}
                                        date={dayjs(value.orderDate).unix() * 1000 + 1000 * 60 * 30}
                                        // renderer={props => <span>{t('verification.countDown', {counter: props.seconds})}</span>}
                                        renderer={({hours, minutes, seconds, completed}) => {
                                            if (completed) {
                                                // Render a completed state
                                                return <Box sx={{color: 'red'}}>{t('mobileOrder.orderTimeout')}</Box>;
                                            } else {
                                                // Render a countdown
                                                return <span>{t('mobileOrder.completeYourPaymentIn')} {minutes}:{seconds} {t('common.duration.minute')}</span>;
                                            }
                                        }}
                                    />
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Typography variant={"h5"} sx={{color: "#ffa602"}}>
                        <strong>{t('mobileOrder.paymentCashMessage')}</strong>
                    </Typography>
                </Box>
                {(value._account.payment.qrPayment || value._account.payment.fundsTransfer) &&
                    <>
                        <Box mt={2} mb={2}>
                            <Divider>
                                <Typography variant={"h6"}>
                                    <strong>{t('common.or')}</strong>
                                </Typography>
                            </Divider>
                        </Box>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Typography variant={"h5"} sx={{color: "#ffa602"}}>
                                <strong>{t('mobileOrder.paymentOnlineMessage')}</strong>
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <MobilePaymentChannel cid={cid} mobileOrder={value} />
                        </Box>
                    </>
                }
            </CardContent>
        </Card>
    );
}
