import React from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import './style.css';

const useStyles = makeStyles({
    wrapper: {
        margin: 1,
        position: 'relative',
    },
    tableHeaderPackage: {
        fontSize: '1rem',
        fontWeight: 'bold'
    },
    tableHeader: {
        background: '#ff8a00',
        fontWeight: 'bold'
    },
    tableContentOdd: {
        background:'#f0f0f0',
    },
    tableTitle: {
        borderBottom: "none",
        paddingBottom: 0,
        fontWeight: 'bold',
        borderLeft: '1px solid #dfe0df'
    },
    tableCellHeader: {
        fontSize: "1rem"
    },
    tableCellNormalPrice: {
        textDecoration: 'line-through',
        color: '#a6a6a6'
    },
    tableCell: {
        fontSize: "1.2rem !important",
        background: '#fff'
    },
});

export default function Pricing() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box pb={2}>
            <Box sx={{ display: { xs: 'block', md: 'none' }}}>
                <table className="tablePricing" style={{border: '1px solid orange', overflow: 'auto'}}>
                    <thead>
                        <tr>
                            <th className="stickyHeader"><div><b style={{fontSize: '1.1rem'}}>{t('pricing.free')}</b></div><div className="arrowContainer"><i className="arrow down"></i></div></th>
                            <th className="stickyHeader"><div><b style={{fontSize: '1.1rem'}}>{t('pricing.start')}</b></div><div className="arrowContainer"><i className="arrow down"></i></div></th>
                            <th className="stickyHeader"><div><b style={{fontSize: '1.1rem'}}>{t('pricing.basic')}</b></div><div className="arrowContainer"><i className="arrow down"></i></div></th>
                            <th className="stickyHeader"><div><b style={{fontSize: '1.1rem'}}>{t('pricing.standard')}</b></div><div className="arrowContainer"><i className="arrow down"></i></div></th>
                            <th className="stickyHeader"><div><b style={{fontSize: '1.1rem'}}>{t('pricing.pro')}</b></div><div className="arrowContainer"><i className="arrow down"></i></div></th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.fee')}</b></td>
                    </tr>
                    <tr>
                        <td>
                            <div className="tableItem" style={{borderLeft: 0}}>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                    <Typography variant={"h4"} color={"#44cc00"} style={{fontSize: '1.5rem', minWidth: '50px'}}>
                                        <strong>{t('pricing.price.free')}</strong>
                                    </Typography>
                                </Box>
                            </div>
                        </td>
                        <td>
                            <div className="tableItem">
                                <div className="normalPrice">{t('pricing.price.startNormal')}</div>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                    <Typography variant={"h4"} color={"#44cc00"} style={{fontSize: '1.5rem'}}>
                                        <strong>{t('pricing.price.start')}.-</strong>
                                    </Typography>
                                </Box>
                            </div>
                        </td>
                        <td>
                            <div className="tableItem">
                                <div className="normalPrice">{t('pricing.price.basicNormal')}</div>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                    <Typography variant={"h4"} color={"#44cc00"} style={{fontSize: '1.5rem'}}>
                                        <strong>{t('pricing.price.basic')}.-</strong>
                                    </Typography>
                                </Box>
                            </div>
                        </td>
                        <td>
                            <div className="tableItem">
                                <div className="normalPrice">{t('pricing.price.standardNormal')}</div>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                    <Typography variant={"h4"} color={"#44cc00"} style={{fontSize: '1.5rem'}}>
                                        <strong>{t('pricing.price.standard')}.-</strong>
                                    </Typography>
                                </Box>
                            </div>
                        </td>
                        <td>
                            <div className="tableItem">
                                <div className="normalPrice">{t('pricing.price.proNormal')}</div>
                                <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                    <Typography variant={"h4"} color={"#44cc00"} style={{fontSize: '1.5rem'}}>
                                        <strong>{t('pricing.price.pro')}.-</strong>
                                    </Typography>
                                </Box>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="5" style={{borderBottom: '1px solid lightgray', paddingTop: '15px'}}></td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.menu')}</b></td>
                    </tr>
                    <tr>
                        <td><div className="tableItem" style={{borderLeft: 0}}>{t('pricing.features.menu.free')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.menu.start')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.menu.basic')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.menu.standard')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.menu.pro')}</div></td>
                    </tr>
                    <tr>
                        <td colSpan="5" style={{borderBottom: '1px solid lightgray', paddingTop: '15px'}}></td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.order')}</b></td>
                    </tr>
                    <tr>
                        <td><div className="tableItem" style={{borderLeft: 0}}>{t('pricing.features.order.free')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.order.start')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.order.basic')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.order.standard')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.order.pro')}</div></td>
                    </tr>
                    <tr>
                        <td colSpan="5" style={{borderBottom: '1px solid lightgray', paddingTop: '15px'}}></td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.table')}</b></td>
                    </tr>
                    <tr>
                        <td><div className="tableItem" style={{borderLeft: 0}}>{t('pricing.features.table.free')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.table.start')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.table.basic')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.table.standard')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.table.pro')}</div></td>
                    </tr>
                    <tr>
                        <td colSpan="5" style={{borderBottom: '1px solid lightgray', paddingTop: '15px'}}></td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.kitchen')}</b></td>
                    </tr>
                    <tr>
                        <td><div className="tableItem" style={{borderLeft: 0}}>{t('pricing.features.kitchen.free')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.kitchen.start')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.kitchen.basic')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.kitchen.standard')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.kitchen.pro')}</div></td>
                    </tr>
                    <tr>
                        <td colSpan="5" style={{borderBottom: '1px solid lightgray', paddingTop: '15px'}}></td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.user')}</b></td>
                    </tr>
                    <tr>
                        <td><div className="tableItem" style={{borderLeft: 0}}>{t('pricing.features.user.free')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.user.start')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.user.basic')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.user.standard')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.user.pro')}</div></td>
                    </tr>
                    <tr>
                        <td colSpan="5" style={{borderBottom: '1px solid lightgray', paddingTop: '15px'}}></td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.delivery')}</b></td>
                    </tr>
                    <tr>
                        <td><div className="tableItem" style={{borderLeft: 0}}>{t('pricing.features.delivery.free')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.delivery.start')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.delivery.basic')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.delivery.standard')}</div></td>
                        <td><div className="tableItem">{t('pricing.features.delivery.pro')}</div></td>
                    </tr>
                    <tr>
                        <td colSpan="5">&nbsp;</td>
                    </tr>
                    <tr style={{background: 'orange'}}>
                        <td colSpan="5"><div style={{fontSize: '1.2rem', padding: '5px', color: 'white'}}><b>{t('pricing.extension')}</b></div></td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.extensions.mobileOrder')}</b></td>
                    </tr>
                    <tr>
                        <td colSpan="5"><div className="tableItem" style={{borderLeft: 0, paddingBottom: '10px'}}>{t('pricing.extensions.credit.mobileOrder')}</div></td>
                    </tr>
                    <tr>
                        <td colSpan="5" style={{borderBottom: '1px solid lightgray'}}></td>
                    </tr>
                    <tr>
                        <td colSpan="5" className="subHeader"><b>{t('pricing.extensions.onlineOrder')}</b></td>
                    </tr>
                    <tr>
                        <td colSpan="5"><div className="tableItem" style={{borderLeft: 0}}>{t('pricing.extensions.credit.onlineOrder')}</div></td>
                    </tr>
                    <tr>
                        <td colSpan="5">&nbsp;</td>
                    </tr>
                    </tbody>
                </table>
            </Box>
            <TableContainer sx={{ display: { xs: 'none', md: 'block' }}}>
                <Table aria-label="pricing table" style={{border: '1px solid #ffffff'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeaderPackage} align="center" style={{background: '#fff',fontSize: '1rem', padding: '8px', width: '40%'}}><b>{t('pricing.feature')}</b></TableCell>
                            <TableCell className={classes.tableHeader} align="center" style={{color: '#fff', padding: '8px', width: '12%', borderLeft: '2px solid #fff', fontSize: '1.3rem'}}><b>{t('pricing.free')}</b></TableCell>
                            <TableCell className={classes.tableHeader} align="center" style={{color: '#fff', padding: '8px', width: '12%', borderLeft: '2px solid #fff', fontSize: '1.3rem'}}><b>{t('pricing.start')}</b></TableCell>
                            <TableCell className={classes.tableHeader} align="center" style={{color: '#fff', padding: '8px', width: '12%', borderLeft: '2px solid #fff', fontSize: '1.3rem'}}><b>{t('pricing.basic')}</b></TableCell>
                            <TableCell className={classes.tableHeader} align="center" style={{color: '#fff', padding: '8px', width: '12%', borderLeft: '2px solid #fff', fontSize: '1.3rem'}}><b>{t('pricing.standard')}</b></TableCell>
                            <TableCell className={classes.tableHeader} align="center" style={{color: '#fff', padding: '8px', width: '12%', borderLeft: '2px solid #fff', fontSize: '1.3rem'}}><b>{t('pricing.pro')}</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.fee')}</h3></TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>
                                <div>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                        <Typography variant={"h4"} color={"#44cc00"}>
                                            <strong>{t('pricing.price.free')}</strong>
                                        </Typography>
                                    </Box>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <div className={classes.tableCellNormalPrice}>{t('pricing.price.startNormal')}</div>
                                <div>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                        <Typography variant={"h4"} color={"#44cc00"}>
                                            <strong>{t('pricing.price.start')}.-</strong>
                                        </Typography>
                                    </Box>
                                </div>
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center" style={{background: '#f3f3f3'}}>
                                <div className={classes.tableCellNormalPrice}>{t('pricing.price.basicNormal')}</div>
                                <div>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                        <Typography variant={"h4"} color={"#44cc00"}>
                                            <strong>{t('pricing.price.basic')}.-</strong>
                                        </Typography>
                                    </Box>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center">
                                <div className={classes.tableCellNormalPrice}>{t('pricing.price.standardNormal')}</div>
                                <div>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                        <Typography variant={"h4"} color={"#44cc00"}>
                                            <strong>{t('pricing.price.standard')}.-</strong>
                                        </Typography>
                                    </Box>
                                </div>
                            </TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>
                                <div className={classes.tableCellNormalPrice}>{t('pricing.price.proNormal')}</div>
                                <div>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"baseline"}>
                                        <Typography variant={"h4"} color={"#44cc00"}>
                                            <strong>{t('pricing.price.pro')}.-</strong>
                                        </Typography>
                                    </Box>
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow className={`${classes.tableCell}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.menu')}</h3></TableCell>
                            <TableCell className={`${classes.tableCell}`} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.menu.free')}</TableCell>
                            <TableCell className={`${classes.tableCell}`} align="center">{t('pricing.features.menu.start')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.menu.basic')}</TableCell>
                            <TableCell className={`${classes.tableCell}`} align="center">{t('pricing.features.menu.standard')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.menu.pro')}</TableCell>
                        </TableRow>
                        <TableRow className={`${classes.tableCell}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.order')}</h3></TableCell>
                            <TableCell className={`${classes.tableCell}`} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.order.free')}</TableCell>
                            <TableCell className={`${classes.tableCell}`} align="center">{t('pricing.features.order.start')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.order.basic')}</TableCell>
                            <TableCell className={`${classes.tableCell}`} align="center">{t('pricing.features.order.standard')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.order.pro')}</TableCell>
                        </TableRow>
                        <TableRow className={`${classes.tableCell}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.table')}</h3></TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.table.free')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center">{t('pricing.features.table.start')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.table.basic')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center">{t('pricing.features.table.standard')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.table.pro')}</TableCell>
                        </TableRow>
                        <TableRow className={`${classes.tableCell}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.kitchen')}</h3></TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.kitchen.free')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center">{t('pricing.features.kitchen.start')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.kitchen.basic')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center">{t('pricing.features.kitchen.standard')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.kitchen.pro')}</TableCell>
                        </TableRow>
                        <TableRow className={`${classes.tableCell}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.user')}</h3></TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.user.free')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center">{t('pricing.features.user.start')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.user.basic')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center">{t('pricing.features.user.standard')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.user.pro')}</TableCell>
                        </TableRow>
                        <TableRow className={`${classes.tableCell}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.delivery')}</h3></TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.delivery.free')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center">{t('pricing.features.delivery.start')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.delivery.basic')}</TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.tableContentOdd}`} align="center">{t('pricing.features.delivery.standard')}</TableCell>
                            <TableCell className={classes.tableCell} align="center" style={{background: '#f3f3f3'}}>{t('pricing.features.delivery.pro')}</TableCell>
                        </TableRow>
                        <TableRow className={`extensionHeader`}>
                            <TableCell colSpan={6} style={{color: '#ffffff',textAlign: 'center', fontSize: '1.2rem' }}>
                                <b>{t('pricing.extension')}</b>
                            </TableCell>
                        </TableRow>
                        <TableRow className={`${classes.tableCell}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.extensions.mobileOrder')}</h3></TableCell>
                            <TableCell colSpan={5}  className={classes.tableCell} align="right" style={{background: '#f3f3f3'}}>{t('pricing.extensions.credit.mobileOrder')}</TableCell>
                        </TableRow>
                        <TableRow className={`${classes.tableCell}`}>
                            <TableCell className={`${classes.tableCell} ${classes.tableTitle}`} align="center"><h3>{t('pricing.extensions.onlineOrder')}</h3></TableCell>
                            <TableCell colSpan={5}  className={classes.tableCell} align="right" style={{background: '#f3f3f3'}}>{t('pricing.extensions.credit.onlineOrder')}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box m={2}>
                <div><Typography variant="caption" component="div" align="left">* {t('pricing.remark1')}</Typography></div>
                <div><Typography variant="caption" component="div" align="left">* {t('pricing.remark2')}</Typography></div>
                <div><Typography variant="caption" component="div" align="left">* {t('pricing.remark3')}</Typography></div>
            </Box>
        </Box>
    )
};