import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import UpdateIcon from '@mui/icons-material/Update';
import {getCache, saveCache} from "../../core/LocalStorageUtil";
import {useParams} from "react-router-dom";

export default function NotifyDialog() {
    const { t } = useTranslation();
    const { cid } = useParams();
    const [open, setOpen] = useState(false);
    console.log('[NotifyDialog]');

    let cacheData = getCache(cid);
    let notify = cacheData.notify;

    useEffect(() => {
        if(!notify){
            setOpen(true);
        }
    },[notify]);

    const handleNotifyClose = () => {
        setOpen(false);
        cacheData.notify = true;
        saveCache(cacheData);
    };

    return (
        <Dialog open={open}
                onClose={handleNotifyClose}>
            <DialogContent>
                <Box display="flex"  justifyContent="center" mb={2}>
                    <UpdateIcon sx={{ fontSize: 60 }} color={"primary"} />
                </Box>
                {t('cart.preordersMessage')}
            </DialogContent>
            <DialogActions>
                <Box mb={1} style={{width: '100%'}}>
                    <Button variant="contained"
                            size="large"
                            disableElevation={true}
                            fullWidth={true}
                            onClick={handleNotifyClose}
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.ok')}</span>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
};