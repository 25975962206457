import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {CardActions, Collapse, ListItem, Rating} from "@mui/material";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SaveIcon from '@mui/icons-material/Save';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {httpClient} from "../../../core/HttpClient";
import {DateTime} from "../../DateTime";
import ReviewMenuDialog from "../ReviewMenuDialog";
import ReviewPhotosEdit from "../ReviewPhotosEdit";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";


export default function MyReview({onChange, data}) {
    const { t } = useTranslation();
    const [review, setReview] = useState(data);
    const [openMenu, setOpenMenu] = useState(false);
    const [show, setShow] = useState(false);
    const [reviewComplete, setReviewComplete] = useState(false);
    const [myReviewChange, setMyReviewChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [require, setRequire] = useState(0);
    const [customer] = useState(JSON.parse(localStorage.getItem('customer')));

    const handleShowDetail = () => {
        setShow(!show);
    }

    const handleRateChange = (event, newValue) => {
        if(localStorage.getItem('token')){
            setMyReviewChange(true);
            setShow(true);
            let newRate = newValue || 0;
            setReview({...review, rate: newRate});
        } else {
            let newRate = newValue || 0;
            onChange(false, {...review, rate: newRate});
        }

    };

    const handleMessageChange = ({target}) => {
        setReview({...review, message: target.value});
        setMyReviewChange(true);
    };

    function _validation(review) {
        if(review.rate === 0){
            setRequire(0);
            return true;
        } else if(review.rate === 3){
            let requireBitwise = 0;
            if(!review.message || review.message.trim().length === 0){
                requireBitwise += 2;
            }
            setRequire(requireBitwise);

            if(requireBitwise > 0){
                return false;
            } else {
                return true;
            }
        } else if(review.rate < 3){
            let requireBitwise = 0;
            if(!review.images || review.images.length === 0){
                requireBitwise += 1;
            }
            if(!review.message || review.message.trim().length === 0){
                requireBitwise += 2;
            }
            setRequire(requireBitwise);

            if(requireBitwise > 0){
                return false;
            } else {
                return true;
            }
        } else {
            setRequire(0);
            return true;
        }
    };

    const handleSaveChange = ({target}) => {
        console.log('handleSaveChange');
        if(_validation(review)){
            setLoading(true);
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/review';
            httpClient.post(url, {...review})
                .then(res => {
                    setLoading(false);
                    if(res.status === 200){
                        if(res.data && res.data.modified){
                            setReview({...review, rate: res.data.rate, message: res.data.message, modified: res.data.modified});
                        }
                        onChange(true, res.data);
                        setReviewComplete(true);
                        setShow(false);
                    } else {
                        if(res.data.code === 'DTA_001'){
                            // customer not found then clear token
                            localStorage.removeItem('token');
                            onChange(false, review);
                        }
                    }
                    setMyReviewChange(false);
                });
        }
    };

    const handlePhotoChange = (mode, data) => {
        let images = review.images;
        if(mode === 'add'){
            images.push(data);
        } else if(mode === 'remove'){
            const idx = images.findIndex(object => {
                return object._id === data._id;
            });
            if(idx !== -1){
                images.splice(idx, 1);
            }
        }
        setReview({...review, images: images});
        setMyReviewChange(true);
    };

    const handleCloseMenu = (review) => {
        setOpenMenu(false);
        if(review){
            setReview({...review});
            setMyReviewChange(true);
        }
    }

    return (
        <Box m={1}>
            <Card variant={"outlined"}>
                <CardContent>
                    <Box display={"flex"} justifyContent={"center"}>
                        {t('review.myReview')}
                    </Box>
                    <Box display={"flex"} justifyContent={"center"}>
                        <List dense={true}>
                            <ListItem>
                                {customer &&
                                <>
                                    <ListItemAvatar sx={{minWidth: 42}}>
                                        <Avatar alt={customer.displayName} src={customer.pictureUrl} sx={{ width: 32, height: 32 }} />
                                    </ListItemAvatar>
                                    <ListItemText primary={
                                        <Typography noWrap={true} component={"div"} style={{flexGrow: 1}}>
                                            <span>{customer.displayName}</span>
                                        </Typography>
                                    } secondary={review.rate >0 &&
                                        <Typography variant={"caption"} component={"div"} noWrap={true} style={{flexGrow: 1}}>
                                            <DateTime value={review.modified}
                                                      format="DD MMM YY, HH:mm"></DateTime>
                                        </Typography>
                                    }>
                                    </ListItemText>
                                </>
                                }
                            </ListItem>
                        </List>
                    </Box>
                    <Box>
                        <Box display={"flex"} justifyContent={"center"} mb={1}>
                            <Typography variant={"caption"}>{t('review.star')} </Typography>
                        </Box>
                        <Box display={"flex"} justifyContent={"center"}>
                            <Rating
                                name="rate"
                                size="large"
                                value={review.rate}
                                onChange={handleRateChange}
                            />
                        </Box>
                    </Box>
                    {review.rate > 0 &&
                        <Collapse in={show}>
                            <Box mt={3} mb={1}>
                                <TextField label={t('review.message')}
                                           fullWidth={true}
                                           multiline
                                           rows={3}
                                           name="message"
                                           error={((require & 2) === 2 && review.rate < 4)}
                                           value={review.message}
                                           type="text"
                                           onChange={handleMessageChange}/>
                                <Box mt={2} sx={((require & 1) === 1 && review.rate < 3)?{border: '1px solid #d94a48', borderRadius: '3px', width: '100%'}:{border: '1px solid #c4c4c4', borderRadius: '3px', width: '100%'}} mb={3}>
                                    <ReviewPhotosEdit images={review.images}
                                                      onChange={handlePhotoChange}></ReviewPhotosEdit>
                                </Box>
                                {require > 0 && <Box mt={1}>
                                    <Typography variant={"caption"} color={"red"}>
                                        {review.rate < 3?t('review.requireMessageAndImage'):t('review.requireMessage')}
                                    </Typography>
                                </Box>
                                }
                            </Box>
                        </Collapse>
                    }
                    {myReviewChange &&
                        <Box mt={2}>
                            <Button fullWidth={true}
                                    disableElevation={true}
                                    variant={"contained"}
                                    startIcon={<SaveIcon />}
                                    disabled={loading}
                                    onClick={handleSaveChange} >
                                {t('common.save')}
                            </Button>
                        </Box>
                    }
                </CardContent>
                {review.rate > 0 &&
                    <CardActions sx={{padding: 0}}>
                        <Button
                            onClick={handleShowDetail}
                            startIcon={show?<KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
                            fullWidth={true}>
                            {show?t('common.hideDetail'):t('common.showDetail')}
                        </Button>
                    </CardActions>
                }
            </Card>
            <ReviewMenuDialog review={review} open={openMenu} onClose={handleCloseMenu}></ReviewMenuDialog>
            <Snackbar open={reviewComplete}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={e=>setReviewComplete(false)}>
                <Alert onClose={e=>setReviewComplete(false)} severity="success">
                    {t('review.thanks')}
                </Alert>
            </Snackbar>
        </Box>
    )
};