import { v4 as uuidv4 } from 'uuid';
import {getCache, saveCache} from "../LocalStorageUtil";

export default class CartUtil {
    constructor(cid){
        this.cid = cid;
    }

    addToCart(product, item) {
        let cacheData = getCache(this.cid);
        let cart = cacheData.cart;
        if(!cart){
            let uid = uuidv4();
            cart = {uid: uid, total: 0, qty: 0, products: {}, remark: ''};
        }
        if(!cart.products){
            let uid = uuidv4();
            cart = {uid: uid, total: 0, qty: 0, products: {}, remark: ''};
        }

        let old = cart.products[product._id];
        if(old){
            old.qty += item.qty;
            old.items.push({_id: product._id, qty: item.qty, remark: item.remark, optional: item.optional});
        } else {
            let optionalPrice = 0;
            if(item.optional && item.optional.total){
                optionalPrice = item.optional.total
            }
            cart.products[product._id] = {
                price: product.price + optionalPrice,
                qty: item.qty,
                deliverPrice: product.deliverPrice,
                deliverLimit: product.deliverLimit,
                items: [{_id: product._id, qty: item.qty, remark: item.remark, optional: item.optional}]
            };
        }

        let totalPrice = 0;
        if(cart.products[product._id].items){
            cart.products[product._id].items.forEach(item=>{
                if(item.optional && item.optional.total){
                    totalPrice += (product.price + item.optional.total) * item.qty;
                } else {
                    totalPrice += (product.price * item.qty);
                }
            });
        }
        cart.products[product._id].price = totalPrice;

        _summary(cart);
        cacheData.cart = cart;
        saveCache(cacheData);
    };

    updateCart(product, item) {
        console.log('updateCart');
        let cacheData = getCache(this.cid);
        let cart = cacheData.cart;
        if(cart.products[product._id]){
            cart.products[product._id].items[item.index].remark = item.remark;
            cart.products[product._id].items[item.index].optional = item.optional;
            cart.products[product._id].items[item.index].qty = item.qty;
            console.log('cart.products', cart.products[product._id]);
            let count = 0;
            cart.products[product._id].items.forEach(item => {
                count += item.qty;
            });
            cart.products[product._id].qty = count;

            let totalPrice = 0;
            if(cart.products[product._id].items){
                cart.products[product._id].items.forEach(item=>{
                    if(item.optional && item.optional.total){
                        totalPrice += (product.price + item.optional.total) * item.qty;
                    } else {
                        totalPrice += (product.price * item.qty);
                    }
                });
            }
            cart.products[product._id].price = totalPrice;
        }
        _summary(cart);
        cacheData.cart = cart;
        saveCache(cacheData);
    }

    removeCartItem(product, item) {
        console.log('removeCartItem');
        let cacheData = getCache(this.cid);
        let cart = cacheData.cart;
        if(cart.products[product._id]){
            cart.products[product._id].items.splice(item.index, 1);
            let count = 0;
            cart.products[product._id].items.forEach(item => {
                count += item.qty;
            });

            if(count){
                cart.products[product._id].qty = count;

                let totalPrice = 0;
                if(cart.products[product._id].items){
                    cart.products[product._id].items.forEach(item=>{
                        if(item.optional && item.optional.total){
                            totalPrice += (product.price + item.optional.total) * item.qty;
                        } else {
                            totalPrice += (product.price * item.qty);
                        }
                    });
                }
                cart.products[product._id].price = totalPrice;

            }else{
                delete cart.products[product._id];
            }
        }
        _summary(cart);
        cacheData.cart = cart;
        saveCache(cacheData);
    }

    updateCartRemark(remark) {
        let cacheData = getCache(this.cid);
        let cart = cacheData.cart;
        if(!cart){
            let uid = uuidv4();
            cart = {uid: uid, total: 0, qty: 0, products: {}, remark: remark};
        } else {
            cart.remark = remark;
        }

        cacheData.cart = cart;
        saveCache(cacheData);
        return cart;
    }

    clearCart() {
        console.log('clearCart');
        let cacheData = getCache(this.cid);
        cacheData.cart = null;
        saveCache(cacheData);
    }

}

function _summary(cart) {
    console.log('_summary');
    let count = 0;
    let total = 0;
    if(cart && cart.products){
        const keys = Object.keys(cart.products);
        keys.forEach(k=>{
            count += cart.products[k].qty;
            total += cart.products[k].price;
        });
    }
    cart.qty = count;
    cart.total = total;
}