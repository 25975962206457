import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../../core/HttpClient";
import {TextI18n, getTextI18n} from "../../TextI18n";
import {DateTime} from "../../DateTime";
import ListItemButton from "@mui/material/ListItemButton";
import Base64MongoId from "base64-mongo-id";

let MyAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo.png`;
    let name = '';
    if(value && value.name) {
        name = getTextI18n(value.name);
    }
    if(value && value.image) {
        imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/s/${value.image.name}`;
    }
    return (<Avatar
        variant={"square"}
        alt={name}
        src={imageUrl}
    />)
};

export default function CustomerOrdering() {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);

    useEffect(() => {
        console.log('Ordering');
        let url = process.env.REACT_APP_API_BASE_URL + '/private/customer/orders/ordering';
        httpClient.get(url)
            .then(res => {
                if(Array.isArray(res.data)){
                    setTransactions(res.data);
                }
                setLoading(false);
            }).catch(e=>{
            console.log('error', e);
        });
    }, [history]);

    const handleClickItem = (foodOrder)=>{
        if(foodOrder.orderCode){
            let cid = Base64MongoId.toBase64(foodOrder._account._id);
            history.push(`/hm/c/mobile/${cid}/${foodOrder.uid}?m=ongoing`);
        } else {
            history.push(`/hm/c/order/${foodOrder.uid}?m=ongoing`);
        }

    };

    return (
        <div>
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <Box>
                {transactions.length === 0 &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <Typography variant="body1" color="textSecondary"
                                sx={{fontSize: '0.85rem'}}>{t('order.noOrdering')}</Typography>
                </Box>
                }
                {transactions.length > 0 &&
                <List className="SortableList">
                    {transactions.map((value, index) => (
                        <ListItemButton key={`item-${value._id}`}
                                  divider={index !== transactions.length - 1 ? true : false}
                                  onClick={e => handleClickItem(value)} >
                            <ListItemAvatar>
                                <MyAvatar value={value._account} />
                            </ListItemAvatar>
                            <ListItemText secondary={<Box display="flex" justifyContent="space-between" component="span">
                                <Typography noWrap={true} component="span">
                                    {t('common.status')}
                                </Typography>
                                <Typography noWrap={true} component="span">
                                    {t(`order.${value.status}`)}
                                </Typography>
                            </Box>}>
                                <TextI18n value={value._account.name}></TextI18n>
                                <div>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography noWrap={true}>
                                            <span>
                                                {value.orderCode || value.transactionNo}
                                            </span>
                                        </Typography>
                                        <div>
                                            <span><DateTime value={value.orderDate}
                                                            format="DD MMM YYYY HH:mm"></DateTime></span>
                                        </div>
                                    </Box>
                                </div>
                            </ListItemText>
                        </ListItemButton>
                    ))}
                </List>
                }
            </Box>
            }
        </div>
    )
};