import React, { useState, forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import {isDesktop} from 'react-device-detect';
import L from 'leaflet';
import Control from 'react-leaflet-custom-control'
import {MapContainer, TileLayer, Marker, Polygon} from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import './style.css'
import Box from "@mui/material/Box";

const markerIcon = new L.Icon({
    iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fkmark.png`,
    iconRetinaUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fkmark2.png`,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 44],
    iconAnchor: [15, 44],
    className: 'leaflet-div-icon'
});

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

export { markerIcon };

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddressDialog({open, shop, onClose}) {
    const { t } = useTranslation();
    const [setMap] = useState(null);

    const handleClose = ({target})=>{
        onClose(target);
    };
    const handleGoToLocation = ()=>{
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${shop.lat},${shop.lng}&dir_action=navigate`, '_blank');
    };

    return (
        <>
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {shop.delivery?t('shop.addressAndDeliveryArea'):t('shop.address')}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MapContainer center={[shop.lat, shop.lng]}
                          zoom={shop.zoom}
                          {...interactionOptions}
                          ref={setMap}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {shop && shop.lat && shop.lng &&
                    <Marker icon={markerIcon} position={[shop.lat, shop.lng] } ></Marker>
                }
                {shop && shop.delivery && shop.deliveryAreas &&
                    <Polygon
                        positions={shop.deliveryAreas}
                        color="#1abc9c"
                        fillColor="#1abc9c" />
                }
                <Control position='bottomleft'>
                    <Box>
                        <Fab variant="extended" sx={{width: '100%'}} onClick={handleGoToLocation}>
                            <AssistantDirectionIcon sx={{ mr: 1 }} />
                            {t('shop.gotoLocation')}
                        </Fab>
                    </Box>
                </Control>
            </MapContainer>
        </Dialog>
        </>
    )
};