import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {useHistory, useParams} from "react-router-dom";
import {httpClient} from "../../core/HttpClient";

export default function QRLandingPage() {
    const { cid } = useParams();
    const history = useHistory();

    console.log('QRLandingPage');

    useEffect(() => {
        console.log('[QRLandingPage]', cid);
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/qr/'+cid+'/1';
        httpClient.get(url)
            .then(res => {
                if(res.data && res.data.type){
                    history.push({
                        pathname: `/hm/${res.data.type}/${cid}`
                    });
                } else {
                    history.push({
                        pathname: `/hm/v/${cid}`
                    });
                }
            });

    }, [cid, history]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
        </div>
    );
}
