import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Route, useParams, useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import jwt_decode from "jwt-decode";
import liff from "@line/liff";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Footer from '../../components/Footer'
import SettingDialog from '../../components/Profile/SettingDialog'
import ModeDialog from '../../components/ModeDialog'
import OrdersTracking from '../../components/OrdersTracking'
import AlertMessage from '../../components/AlertMessage'
import { Context } from "../../core/Context";
import { httpClient } from "../../core/HttpClient";


let DEFAULT_ZOOM = 17;
let DEFAULT_LOCATION = {coordinates: null, gps: 'UNDEFINED', zoom: DEFAULT_ZOOM, type: 'NO_LOCATION', address: null};
let _permissions = [];
// const fpPromise = FingerprintJS.load();
(async () => {
    // Get the visitor identifier when you need it.
    // const fp = await fpPromise;
    // const result = await fp.get();

    // This is the visitor identifier:
    // const visitorId = result.visitorId;
    const cid = localStorage.getItem('cid');
    if (cid) {
        // firebase
        // let key = 'accounts/'+cid;
        // firebase.database().ref(key).child('customer').child(visitorId).set({date: new Date().getTime(), status: 'online'});
        // firebase.database().ref(key).child('customer').child(visitorId).onDisconnect().remove();
    }


})();

// const liff = window.liff;
const lineAuthentication = async(cb) => {
    console.log('lineAuthentication');
    const liffId = process.env.REACT_APP_LINE_LIFF_ID;
    try{
        await liff.init({ liffId: liffId, withLoginOnExternalBrowser: true }).catch(err=>{throw err});
        if (liff.isLoggedIn()) {
            const idToken = liff.getIDToken();
            const accessToken = liff.getAccessToken();
            return cb({idToken: idToken, accessToken: accessToken});
        }else{
            // external browser
            console.log('no logged in');
            return cb(null);
        }
    }catch(e){}
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    header: {
        borderBottom: '1px solid #e7e7e7',
    },
    title: {
        flexGrow: 1,
        marginLeft: '10px',
    },
    menuButton: {
        padding: 0,
    },
    foodOrderBar: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#efefef'
    },
    content: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column'
    },
    footer: {
        borderTop: '1px solid #e7e7e7',
    },


});

const PublishSlimLayout = ({ children}) => {
    const { t } = useTranslation();
    const { cid } = useParams();
    const history = useHistory();
    const { historyData } = useLocation();
    const [customer, setCustomer] = useState({customerId: '', token: '', displayName: '', mobile: '', pictureUrl: ''});
    const [context, setContext] = useState({shop: {}, mode: 'VIEW', location: DEFAULT_LOCATION, openMap: false, success: false, fail: false, warning: false});
    const [anchorEl, setAnchorEl] = useState(null);
    const [accountName, setAccountName] = useState('');
    const [accountStatus, setAccountStatus] = useState({status: '', verified: 1, expiredDay: 0});
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const [initial, setInitial ] = useState(0);
    const [openSetting, setOpenSetting] = useState(false);
    const [openMode, setOpenMode] = useState(false);

    console.log('[PublishLayout]');

    useEffect(() => {
        let token = localStorage.getItem('token');
        if(!token){
            let autoLogin = window.sessionStorage.getItem("autoLogin");
            if(autoLogin !== 'no'){
                lineAuthentication(lineToken=> {
                    if(lineToken && cid){
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({accessToken: lineToken.accessToken, idToken: lineToken.idToken, redirectUri: process.env.REACT_APP_BASE_URL+'/hm/login?cid='+cid, cid: cid})
                        };
                        const url = process.env.REACT_APP_API_BASE_URL + '/oauth/line/token';
                        fetch(url, requestOptions)
                            .then(results => results.json())
                            .then(data => {
                                if(data.token){
                                    let decoded = jwt_decode(data.token);
                                    localStorage.setItem('token', data.token);
                                    setCustomer({customerId: decoded.customer, token: data.token, displayName: data.displayName, mobile: data.mobile, pictureUrl: data.pictureUrl});
                                    let customer = {
                                        displayName: data.displayName,
                                        pictureUrl: data.pictureUrl,
                                        mobile: data.mobile,
                                        address: data.address
                                    };
                                    localStorage.setItem('customer', JSON.stringify(customer));
                                    if(cid){
                                        // update visitor
                                        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/visit';
                                        httpClient.put(url, {cid: cid})
                                            .then(res => {
                                                // done
                                            }).catch(e=>{});
                                    }
                                } else {
                                    localStorage.removeItem('token');
                                }
                            });
                    }
                });
            }
        } else {
            let customer = JSON.parse(localStorage.getItem('customer'));
            if(customer){
                let decoded = jwt_decode(token);
                setCustomer({customerId: decoded.customer, token: token, displayName: customer.displayName, mobile: customer.mobile, pictureUrl: customer.pictureUrl});
            }

        }

        if(localStorage.getItem('customer') && cid){
            // update visitor
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/visit';
            httpClient.put(url, {cid: cid})
                .then(res => {
                    // done
                }).catch(e=>{});
        }


        if(cid){
            localStorage.setItem('cid', cid);
        }


        // current account
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/location';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    if(res.data.delivery){
                        _permissions.push('DELIVERY');
                    }
                    if(res.data.pickup){
                        _permissions.push('PICKUP');
                    }
                    setInitial(1);
                    setAccountName(res.data.name.i18n.en);
                    setAccountStatus({status: res.data.status, verified: res.data.verified, expiredDay: res.data.expiredDay});
                } else {
                    // invalid cid
                    setInitial(2);
                }
            }).catch(e=>{
                console.log(e);
                setInitial(2);
            });

    }, [cid, historyData]);

    const handleLogin = (event) => {
        // const clientId = process.env.REACT_APP_LINE_CLIENT_ID;
        const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login?cid=${cid}`;
        // const state = Math.floor(100000 + Math.random() * 900000);
        //
        // const url =
        //     'https://access.line.me/oauth2/v2.1/authorize?response_type=code' +
        //     '&client_id=' +
        //     clientId +
        //     '&redirect_uri=' +
        //     redirectUri +
        //     '&state=' +
        //     state +
        //     '&scope=profile';
        //
        // window.location.href = url;

        liff.login({ redirectUri: redirectUri });
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMyProfile = () => {
        handleMenuClose();
        let _cid = cid || localStorage.getItem('cid');
        history.push(`/p/${_cid}`);
    };

    const handleMyOrderHistory = () => {
        handleMenuClose();
        let _cid = cid || localStorage.getItem('cid');
        history.push(`/h/${_cid}`);
    };

    const handleSetting = (value) => {
        handleMenuClose();
        setOpenSetting(value);
    };

    const handleSelectMode = (value) => {
        setOpenMode(false);
        setInitial(1);
        setContext({...context, mode: value});
    };

    const handleLogout = () => {
        localStorage.clear();
        window.sessionStorage.setItem("autoLogin", "no");
        window.location.href = `/hm/${cid}`;
    };

    return (
        <div>
            <CssBaseline />
            {initial === 0 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {initial === 1 &&
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{accountName}</title>
                    </Helmet>
                    <OrdersTracking accountStatus={accountStatus} />
                    <AlertMessage status={accountStatus} />
                    <div>
                        <header className={classes.header}>
                            <AppBar elevation={0} position="static" color="light">
                                <Toolbar>
                                    <Typography variant="h6" component="div" className={classes.title}>
                                        {customer.displayName}
                                    </Typography>

                                    {!customer.token &&
                                    <IconButton edge="end"
                                                onClick={handleLogin}
                                                color="inherit" aria-label="menu">
                                        <AccountCircleIcon fontSize="large"/>
                                    </IconButton>
                                    }

                                    {customer.token &&
                                    <IconButton edge="end"
                                                onClick={handleMenu}
                                                color="inherit" aria-label="menu">
                                        <Avatar alt="profile" src={customer.pictureUrl}/>
                                    </IconButton>
                                    }

                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem dense onClick={(e) => handleMyProfile()}>{t('profile.myInformation')}</MenuItem>
                                        <MenuItem dense onClick={(e) => handleMyOrderHistory()}>{t('profile.ordersHistory')}</MenuItem>
                                        <MenuItem dense onClick={(e) => handleSetting(true)}>{t('setting.changeLanguage')}</MenuItem>
                                        <Divider sx={{my: 0.5}}/>
                                        <MenuItem dense onClick={(e) => handleLogout()}>{t('logout')}</MenuItem>
                                    </Menu>
                                </Toolbar>
                            </AppBar>
                        </header>
                        <div className={classes.content}>
                            <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
                        </div>
                    </div>
                    <Box className={classes.footer}>
                        <Footer />
                    </Box>
                </HelmetProvider>
            }
            {initial === 2 &&
            <Box display="flex" justifyContent="center" mt={4}>
                <Alert elevation={0} severity="warning">
                    {t('common.message.invalidCid')}
                </Alert>
            </Box>
            }
            <SettingDialog open={openSetting} onClose={(e) => handleSetting(false)} />
            <ModeDialog open={openMode} onSelect={handleSelectMode} />
        </div>
    );
};

const PublishSlimLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                return <PublishSlimLayout><Component {...props} /></PublishSlimLayout>
            }}
        />
    );
};

export default PublishSlimLayoutRoute;