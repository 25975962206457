import React, {useContext, useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {httpClient} from "../../core/HttpClient";
import {StoreContext} from "../../core/Context";
import ContactProfileEditor from "../ContactProfile/ContactProfileEditor";
import ContactProfileSelection from "../ContactProfile/ContactProfileSelection";
import LocationEditor from "../Location/LocationEditor";
import LocationSelection from "../Location/LocationSelection";
import AddressEditor from "../Location/AddressEditor";
import AddressSelection from "../Location/AddressSelection";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Alert from "@mui/material/Alert";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";


export default function DeliverSelection({shop}) {
    const { t } = useTranslation();
    const [storeContext] = useContext(StoreContext);
    const [open, setOpen] = useState('');
    const [profile] = useState({name: '', mobile: ''});
    const [addressList, setAddressList] = useState({loading: false, list: []});

    console.log('[DeliverSelection]');

    const handleClick = (mode) => {
        setAddressList({loading: true, list: []});
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
        httpClient.get(url)
            .then(res => {
                if(res.data && res.data.length > 0){
                    setAddressList({loading: false, list: res.data});
                    if(mode === 'DELIVERY'){
                        setOpen('selectLocation');
                    } else if(mode === 'PICKUP'){
                        setOpen('selectContact');
                    } else if(mode === 'PARCEL'){
                        setOpen('selectAddress');
                    }
                } else {
                    setAddressList({loading: false, list: []});
                    if(mode === 'DELIVERY'){
                        setOpen('specifyLocation');
                    } else if(mode === 'PICKUP'){
                        setOpen('specifyContact');
                    } else if(mode === 'PARCEL'){
                        setOpen('specifyAddress');
                    }
                }

            });
    };

    const handleClose = () => {
        setOpen('');
    };

    return (
        <Box>
            {shop.pickup &&
            <Box>
                <List>
                    <ListItemButton variant="outlined"
                                    sx={{
                                        border: storeContext.deliverType==='PICKUP'?'1px solid rgba(26, 188, 156, 0.5)':'1px solid #c3c4c3',
                                        borderRadius: '4px'
                                    }}
                                    onClick={e=>handleClick('PICKUP')}
                                    size={"large"}
                                    color={storeContext.deliverType==='PICKUP'?'primary':'default'}>
                        <ListItemAvatar>
                            <ShoppingBagIcon color={storeContext.deliverType==='PICKUP'?'primary':'default'} sx={{ fontSize: 30 }} />
                        </ListItemAvatar>
                        <ListItemText primary={t('deliver.pickup')} />
                    </ListItemButton>
                </List>
            </Box>
            }
            {shop.delivery &&
            <Box>
                <List>
                    <ListItemButton variant="outlined"
                                    sx={{
                                        border: storeContext.deliverType === 'DELIVERY' ? '1px solid rgba(26, 188, 156, 0.5)' : '1px solid #c3c4c3',
                                        borderRadius: '4px'
                                    }}
                                    onClick={e => handleClick('DELIVERY')}
                                    size={"large"}
                                    color={storeContext.deliverType === 'DELIVERY' ? 'primary' : 'default'}>
                        <ListItemAvatar>
                            <TwoWheelerIcon color={storeContext.deliverType === 'DELIVERY' ? 'primary' : 'default'}
                                            sx={{fontSize: 30}}/>
                        </ListItemAvatar>
                        <ListItemText primary={t('deliver.delivery')}/>
                    </ListItemButton>
                </List>
            </Box>
            }
            {shop.parcel &&
            <Box>
                <List>
                    <ListItemButton variant="outlined"
                                    sx={{
                                        border: storeContext.deliverType === 'PARCEL' ? '1px solid rgba(26, 188, 156, 0.5)' : '1px solid #c3c4c3',
                                        borderRadius: '4px'
                                    }}
                                    onClick={e => handleClick('PARCEL')}
                                    size={"large"}
                                    color={storeContext.deliverType === 'PARCEL' ? 'primary' : 'default'}>
                        <ListItemAvatar>
                            <LocalShippingIcon color={storeContext.deliverType === 'PARCEL' ? 'primary' : 'default'}
                                               sx={{fontSize: 30}}/>
                        </ListItemAvatar>
                        <ListItemText primary={t('deliver.parcel')}/>
                    </ListItemButton>
                </List>
            </Box>
            }
            {!shop.pickup && !shop.delivery && !shop.parcel &&
            <Box>
                <Alert icon={<WarningAmberIcon fontSize="inherit" />} severity="error">
                    <div>{t('common.message.deliveryUnConfiguration')}</div>
                </Alert>
            </Box>
            }
            <Box>
                {open === 'selectContact' && <ContactProfileSelection list={addressList.list}
                                                                      open={open === 'selectContact'}
                                                                      shop={shop}
                                                                      onClose={handleClose} />}
                {open === 'selectLocation' && <LocationSelection list={addressList.list}
                                                               open={open === 'selectLocation'}
                                                               shop={shop}
                                                               onClose={handleClose} />}
                {open === 'selectAddress' && <AddressSelection  list={addressList.list}
                                                                     open={open === 'selectAddress'}
                                                                     shop={shop}
                                                                     onClose={handleClose} />}
                {open === 'specifyContact' && <ContactProfileEditor open={open === 'specifyContact'} profile={profile} onClose={handleClose} />}
                {open === 'specifyLocation' && <LocationEditor open={open === 'specifyLocation'} shop={shop} onClose={handleClose} />}
                {open === 'specifyAddress' && <AddressEditor open={open === 'specifyAddress'} onClose={handleClose} />}
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={addressList.loading} >
                    <CircularProgress color="inherit" size={20}/>
                </Backdrop>
            </Box>
        </Box>
    )
};