import React, {useState, forwardRef, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import {StoreContext} from "../../../core/Context";
import {httpClient} from "../../../core/HttpClient";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {Select} from "@mui/material";
import {PROVINCES} from "../../../core/Static";
import {getProvinceI18n} from "../../TextI18n";
import CardContent from "@mui/material/CardContent";
import NumberFormat from "react-number-format";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

export default function AddressEditor({open, onClose}) {
    const { t, i18n } = useTranslation();
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [address, setAddress] = useState({name: '', mobile: '', address: '', province: '', district: '',  subDistrict: '', postalCode: ''});
    const [error, setError] = useState({name: false, mobile: false, address: false, province: false, district: false,  subDistrict: false, postalCode: false});
    const [district, setDistrict] = useState([]);
    const [subDistrict, setSubDistrict] = useState([]);

    console.log('[AddressEditor]');

    useEffect(() => {
        console.log('AddressEditor', storeContext.profile);

        if(storeContext.profile && storeContext.profile){
            setAddress({...storeContext.profile});
            if(storeContext.profile.province){
                const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/district/'+storeContext.profile.province;
                httpClient.get(url)
                    .then(res => {
                        if(res.data){
                            setDistrict(res.data);
                        }
                    });
            }
            if(storeContext.profile.district){
                const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/subdistrict/'+storeContext.profile.district;
                httpClient.get(url)
                    .then(res => {
                        if(res.data){
                            setSubDistrict(res.data);
                        }
                    });
            }
        }

    }, [storeContext]);

    const handleChange = ({target})=>{
        let newAddress = {...address, [target.name]: target.value};
        setAddress(newAddress);
    };

    const handleClose = () => {
        let newAddress = {...address};
        setAddress(newAddress);
        setError({name: false, mobile: false, province: false, district: false,  subDistrict: false, address: false, postalCode: false});
        onClose();
    };

    const handleSave = () => {
        if(_validation()){

            let p = PROVINCES.find(obj => {
                return ''+obj.id === ''+address.province;
            });
            let d = district.find(obj => {
                return ''+obj.id === ''+address.district;
            });
            let s = subDistrict.find(obj => {
                return ''+obj.id === ''+address.subDistrict;
            });

            let addressInfo = address.address;
            if(p){
                addressInfo += ' '+p.name_th;
            }
            if(d && s){
                addressInfo += ', '+d.name_th+', '+s.name_th+' '+address.postalCode;
            }

            let _data = {...address, addressInfo: addressInfo};

            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
            httpClient.post(url, _data)
                .then(res => {
                    let data = res.data;
                    setStoreContext({...storeContext, deliverType: 'PARCEL', profile: data});
                    onClose();
                });
        }

    };

    function _validation() {
        let result = true;
        let _error = {name: false, mobile: false, province: false, district: false,  subDistrict: false, address: false, postalCode: false};
        if(!address.name){
            result = false;
            _error.name = true;
        }
        if(!address.mobile ||
            address.mobile.length !== 10 ||
            !address.mobile.startsWith('0')
        ){
            result = false;
            _error.mobile = true;
        }

        if(!address.address){
            result = false;
            _error.address = true;
        }

        if(!address.province || address.province==='NONE'){
            result = false;
            _error.province = true;
        }

        if(!address.district || address.district==='NONE'){
            result = false;
            _error.district = true;
        }

        if(!address.subDistrict || address.subDistrict==='NONE'){
            result = false;
            _error.subDistrict = true;
        }

        if(!address.postalCode){
            result = false;
            _error.postalCode = true;
        }

        if(!result){
            setError(_error);
        }

        return result;
    }

    const handleProvinceChange = ({ target }) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/district/'+target.value;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setDistrict(res.data);
                }
            });
        setDistrict([]);
        setSubDistrict([]);
        setAddress({ ...address, [target.name]: target.value, district: 'NONE', subDistrict: 'NONE'});
    };

    const handleDistrictChange = ({ target }) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/subdistrict/'+target.value;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setSubDistrict(res.data);
                }
            });
        setSubDistrict([]);
        setAddress({ ...address, [target.name]: target.value, subDistrict: 'NONE'});

    };

    const handleSubDistrictChange = ({ target }) => {
        let sd = subDistrict.find(obj => {
            return ''+obj.id === target.value
        });
        let pc = '';
        if(sd){
            pc = sd.zip_code;
        }
        setAddress({ ...address, [target.name]: target.value, postalCode: pc });
    };

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('address.specify')}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box mt={2}>
                <CardContent>
                    <form autoComplete="false" noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.contact')}
                                           fullWidth={true}
                                           name="name"
                                           value={address.name}
                                           type="text"
                                           error={error.name}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.mobile')}
                                           fullWidth={true}
                                           name="mobile"
                                           value={address.mobile}
                                           type="tel"
                                           InputProps={{
                                               inputComponent: NumberFormatCustom,
                                           }}
                                           error={error.mobile}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined"
                                             fullWidth={true}
                                             error={error.province}>
                                    <InputLabel id="province-label">{t('address.province')}</InputLabel>
                                    <Select
                                        native
                                        labelId="province-label"
                                        label={t('address.province')}
                                        name="province"
                                        value={address.province}
                                        error={error.province}
                                        onChange={handleProvinceChange}>
                                        <>
                                            <option key={'NONE'} value={'NONE'}>
                                                {t('address.pleaseSelectProvince')}
                                            </option>
                                            {
                                                PROVINCES.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {getProvinceI18n(item, i18n)}
                                                    </option>
                                                ))
                                            }
                                        </>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined"
                                             fullWidth={true}
                                             error={error.district}>
                                    <InputLabel id="district-label">{t('address.district')}</InputLabel>
                                    <Select
                                        native
                                        labelId="district-label"
                                        label={t('address.district')}
                                        name="district"
                                        value={address.district}
                                        error={error.district}
                                        onChange={handleDistrictChange}>
                                        <>
                                            <option key={'NONE'} value={'NONE'}>
                                                {t('address.pleaseSelectDistrict')}
                                            </option>
                                            {
                                                district.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {getProvinceI18n(item, i18n)}
                                                    </option>
                                                ))
                                            }
                                        </>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined"
                                             fullWidth={true}
                                             error={error.subDistrict}>
                                    <InputLabel id="subDistrict-label">{t('address.subDistrict')}</InputLabel>
                                    <Select
                                        native
                                        labelId="subDistrict-label"
                                        label={t('address.subDistrict')}
                                        name="subDistrict"
                                        value={address.subDistrict}
                                        error={error.subDistrict}
                                        onChange={handleSubDistrictChange}>
                                        <>
                                            <option key={'NONE'} value={'NONE'}>
                                                {t('address.pleaseSelectSubDistrict')}
                                            </option>
                                            {
                                                subDistrict.map((item) => (
                                                    <option key={item.id} value={item.id}>
                                                        {getProvinceI18n(item, i18n)}
                                                    </option>
                                                ))
                                            }
                                        </>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <fieldset style={{
                                    border: error.postalCode?'1px solid #d32f2f':'1px solid rgba(0, 0, 0, 0.23)',
                                    paddingTop: '0px',
                                    borderRadius: '4px',
                                    fontSize: '12px',
                                    color: error.postalCode?'#d32f2f': 'rgba(0, 0, 0, 0.6)'
                                }}>
                                    <legend>{t('address.postalCode')}</legend>
                                    <Box m={1}>
                                        <Typography>
                                            {address.postalCode}&nbsp;
                                        </Typography>
                                    </Box>
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.title')}
                                           fullWidth={true}
                                           name="address"
                                           value={address.address}
                                           type="text"
                                           error={error.address}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange}/>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Box>
            <DialogActions>
                <Box mb={4} style={{width: '100%'}}>
                    <Button variant="contained"
                            size="large"
                            fullWidth={true}
                            onClick={handleSave}
                            disableElevation
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.ok')}</span>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
};