import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import {httpClient} from "../../../core/HttpClient";
import FoodOrderTimeLine from "../../FoodOrder/FoodOrderTimeLine";

export default function CustomerOrdersInfo() {
    const { t } = useTranslation();
    const { uid } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [cid, setCid] = useState('');
    const mode = 'o';

    useEffect(() => {
        console.log('CustomerOrdersInfo');
        let url = process.env.REACT_APP_API_BASE_URL + `/private/customer/order/${uid}`;
        httpClient.get(url)
            .then(res => {
                if(res.data && res.status ===  200){
                    setItems([res.data]);
                    if(res.data.account){
                        setCid(res.data.account.cid);
                    }
                }
                setLoading(false);
            }).catch(e=>{
                console.log('error', e);
            });
    }, [uid]);

    const handleClick = ()=>{
        history.push(`/hm/c/orders?m=${mode}`);
    };

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb" pl={1} pt={2}>
                <Link underline="hover" color="inherit" onClick={handleClick}>
                    <Typography color="primary">{t('menu.orderHistory')}</Typography>
                </Link>
                <Typography color="text.primary">{t('common.detail')}</Typography>
            </Breadcrumbs>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
                <FoodOrderTimeLine mode={mode} cid={cid} items={items} transaction={true} />
            }
            <Box style={{height: '4rem'}}></Box>
        </Box>
    );
}
