import React, {forwardRef, useState} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {ListItemSecondaryAction} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import VerifyPhotos from "../VerifyPhotos";
import {httpClient} from "../../../core/HttpClient";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReportDialog({open, shop, onClose}) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [report, setReport] = useState({reasonCode: 0, issue: '', detail: '', images: []});

    const handleClose = ({target})=>{
        setPage(1);
        setReport({reasonCode: 0, issue: '', detail: '', images: []});
        onClose(target);
    };

    const handleBack = ()=>{
        let _page = page-1;
        setPage(_page);
    };

    const handleReason = (value)=>{
        let issue = t(`verify.reportReason${value}`);
        setPage(2);
        setReport({...report, issue: issue, reasonCode: value});
    };

    const handleChange = ({target})=>{
        setPage(2);
        setReport({...report, detail: target.value});
    };

    const handleSendReport = ()=>{
        if(localStorage.getItem('token')){
            setLoading(true);
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/report';
            httpClient.post(url, {...report})
                .then(res => {
                    setPage(3);
                    setLoading(false);
                });
        }
    };

    const handlePhotoChange = (mode, data) => {
        if(mode === 'add'){
            let _images = [...report.images, data];
            setReport({...report, images: _images});
        } else if(mode === 'delete'){
            const _images = report.images.filter(object => {
                return object.key !== data.key;
            });
            setReport({...report, images: _images});
        }
    };

    return (
        <>
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    {page > 1 && page < 3 &&
                    <IconButton edge="start" color="inherit"
                                onClick={handleBack}
                                aria-label="menu" sx={{ mr: 2 }}>
                        <ChevronLeftIcon />
                    </IconButton>
                    }
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {page === 0?t('verify.title'):t('verify.report')}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {page === 1 &&
            <Box>
                <Box mt={1} p={2} pb={0}>
                    <Typography variant="h6">
                        {t('verify.reportReason')}
                    </Typography>
                </Box>
                <List>
                    <Divider />
                    <ListItemButton onClick={e=>handleReason(1)}>
                        <ListItemText primary={t('verify.reportReason1')} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon fontSize={"large"} />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={e=>handleReason(2)}>
                        <ListItemText primary={t('verify.reportReason2')} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon fontSize={"large"} />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={e=>handleReason(3)}>
                        <ListItemText primary={t('verify.reportReason3')} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon fontSize={"large"} />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={e=>handleReason(4)}>
                        <ListItemText primary={t('verify.reportReason4')} />
                        <ListItemSecondaryAction>
                            <ChevronRightIcon fontSize={"large"} />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                </List>
            </Box>
            }
            {page === 2 &&
            <Box>
                <Box mt={1} p={2} pb={0}>
                    <Typography variant="h6">
                        {t(`verify.reportReason${report.reasonCode}`)}
                    </Typography>
                </Box>
                <Box p={2}>
                    <TextField label={t('verify.reportDetail')}
                               fullWidth={true}
                               required={true}
                               multiline
                               rows={4}
                               name="message"
                               value={report.detail}
                               type="text"
                               onChange={handleChange}/>
                </Box>
                <Box p={2} pt={0}>
                    <Box sx={{border: '1px solid #c4c4c4', borderRadius: '3px', width: '100%'}}>
                        <VerifyPhotos images={report.images}
                                          onChange={handlePhotoChange}></VerifyPhotos>
                    </Box>
                </Box>
                <Box p={2} pt={1}>
                    <Button variant={"contained"}
                            disabled={loading || report.detail.trim().length < 1}
                            fullWidth={true}
                            onClick={handleSendReport}
                            disableElevation={true}>
                        {t('verify.submitReport')}
                    </Button>
                </Box>
            </Box>
            }
            {page === 3 &&
            <Box p={2} mt={2}>
                <Alert severity="success">
                    <AlertTitle>{t('verify.reportThank')}</AlertTitle>
                    {t('verify.reportThankInfo')}
                </Alert>
            </Box>
            }
        </Dialog>
        </>
    )
};