import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useHistory, useParams} from "react-router-dom";
import {Context} from "../../../core/Context";
import {Rating} from "@mui/material";
import {getNFormat} from "../../NFormatter";


export default function ReviewButton({mode, shop}) {
    const { t } = useTranslation();
    const history = useHistory();
    const { cid } = useParams();
    const [context] = useContext(Context);

    const handleClick = () => {
        let data = {...context};
        history.push({
            pathname: `/hm/${mode}/${cid}/review`,
            contextData: data
        });
    };

    return (
        <Button variant="outlined"
                size="large"
                fullWidth={true}
                sx={{padding: '7px 7px', background: 'white'}}
                onClick={handleClick}>
            <Box display="flex" justifyContent="space-around" alignItems="center"
                 style={{width: '100%'}}>
                <Box display="flex" alignItems="center">
                    <Box>
                        <Typography variant={"h6"} sx={{color: '#626362', marginRight: '3px'}}>
                            <b>{shop.reviewScore}</b>
                        </Typography>
                    </Box>
                    <Rating
                        name="rate"
                        value={shop.reviewRate}
                        readOnly={true}
                        precision={0.5}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography>
                        {t('review.views')}
                    </Typography>
                    <Typography ml={1} variant={"caption"}>
                        ({getNFormat(shop.reviewCount)} {t('review.title')})
                    </Typography>
                </Box>
            </Box>
        </Button>
    )
};