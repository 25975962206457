import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import {ImageList, ImageListItem, ListItem} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";


export default function DiscountInfo({shop}) {
    const { t } = useTranslation();

    console.log('DiscountInfo');

    useEffect(() => {
        console.log('[DiscountInfo]');
    }, []);

    return (
        <Box sx={{backgroundColor: '#f7f7f7'}}>
            {shop.discount && (shop.discount.food === 'discount' || shop.discount.delivery === 'discount') &&
                <Box pb={2}>
                    <Box p={1} pt={0}>
                        <Typography variant={"h6"}>{t('promotion.title')}</Typography>
                    </Box>
                    <Box>
                        <ImageList sx={{margin: '0px', '&::-webkit-scrollbar': {display: 'none'}}}>
                            <ImageListItem>
                                <Box display={"flex"} justifyContent={"start"} alignItems={"center"} pl={1} pr={1}>
                                    {shop.discount.food === 'discount' &&
                                        <Box>
                                            <Card variant={"outlined"}>
                                                <List dense={true}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar variant={"square"} sx={{ backgroundColor: "#1abc9c" }}>
                                                                <LocalOfferIcon/>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primaryTypographyProps={{noWrap: true}}
                                                                      secondaryTypographyProps={{noWrap: true}}
                                                                      primary={
                                                                          <Box display={"flex"}>
                                                                              <Typography>
                                                                                  <strong>{`${t('discount.food')}`}</strong>
                                                                              </Typography>
                                                                              <Typography ml={1}>
                                                                                  <strong>{`฿${shop.discount.foodRates[0].discount}`}</strong>
                                                                              </Typography>
                                                                          </Box>
                                                                      }
                                                                      secondary={t('discount.minimumPrice', {price: shop.discount.foodRates[0].price})}/>
                                                    </ListItem>
                                                </List>
                                            </Card>
                                        </Box>
                                    }
                                    {shop.discount.delivery === 'discount' &&
                                        <Box ml={shop.discount.food === 'discount'?1:0} pr={2}>
                                            <Card variant={"outlined"}>
                                                <List dense={true}>
                                                    <ListItem>
                                                        <ListItemAvatar>
                                                            <Avatar variant={"square"} sx={{ backgroundColor: "#1abc9c" }}>
                                                                {/*{shop.discount.deliveryRates[0].discount > 0?<LocalOfferIcon/>:<TwoWheelerIcon/>}*/}
                                                                <TwoWheelerIcon/>
                                                            </Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primaryTypographyProps={{noWrap: true}}
                                                                      secondaryTypographyProps={{noWrap: true}}
                                                                      primary={shop.discount.deliveryRates[0].discount > 0?
                                                                          <Box display={"flex"}>
                                                                              <Typography>
                                                                                  <strong>{`${t('discount.delivery')}`}</strong>
                                                                              </Typography>
                                                                              <Typography ml={1}>
                                                                                  <strong>{`฿${shop.discount.deliveryRates[0].discount}`}</strong>
                                                                              </Typography>
                                                                          </Box> : <Box display={"flex"}>
                                                                              <Typography>
                                                                                  <strong>{`${t('discount.deliveryFree')}`}</strong>
                                                                              </Typography>
                                                                          </Box>
                                                                      }
                                                                      secondary={t('discount.minimumPrice', {price: shop.discount.deliveryRates[0].price})}/>
                                                    </ListItem>
                                                </List>
                                            </Card>
                                        </Box>
                                    }
                                </Box>
                            </ImageListItem>
                        </ImageList>
                    </Box>
                </Box>
            }
        </Box>
    )
}