
export default class PriceUtil {

    getGrandTotalPriceAfterDiscount(deliverType, total, discount, discountFlag, deliveryPrice){
        let myDiscount = 0;
        if(discountFlag){
            if(discount && discount.food === 'discount'){
                if(discount.foodRates && discount.foodRates[0]){
                    if(total >= discount.foodRates[0].price){
                        myDiscount = discount.foodRates[0].discount;
                    }
                }
            }

            if(['DELIVERY', 'PARCEL'].indexOf(deliverType) > -1){
                if(discount && discount.delivery === 'discount'){
                    if(discount.deliveryRates && discount.deliveryRates[0]){
                        if(total >= discount.deliveryRates[0].price){
                            if(discount.deliveryRates[0].discount === 0){
                                myDiscount += deliveryPrice;
                            } else if(deliveryPrice < discount.deliveryRates[0].discount){
                                myDiscount += deliveryPrice;
                            } else {
                                myDiscount += discount.deliveryRates[0].discount;
                            }
                        }
                    }
                }

            }
        }

        return total + (deliveryPrice - myDiscount);
    }

    getGrandTotalPriceBeforeDiscount(deliverType, total, discount, discountFlag, deliveryPrice){
        if(['DELIVERY', 'PARCEL'].indexOf(deliverType) > -1){
            let myDiscount = 0;
            if(discountFlag && discount && discount.delivery === 'discount'){
                if(discount.deliveryRates && discount.deliveryRates[0]){
                    if(total >= discount.deliveryRates[0].price){
                        if(discount.deliveryRates[0].discount === 0){
                            myDiscount = deliveryPrice;
                        }
                    }
                }
            }
            return total + (deliveryPrice - myDiscount);
        } else {
            return total;
        }
    }

    getDeliveryPrice(deliverType, total, discount, discountFlag, deliveryPrice){
        if(['DELIVERY', 'PARCEL'].indexOf(deliverType) > -1){
            let myDiscount = 0;
            if(discountFlag && discount && discount.delivery === 'discount'){
                if(discount.deliveryRates && discount.deliveryRates[0]){
                    if(total >= discount.deliveryRates[0].price){
                        if(discount.deliveryRates[0].discount === 0){
                            myDiscount = deliveryPrice;
                        }
                    }
                }
            }
            return deliveryPrice - myDiscount;
        } else {
            return 0;
        }
    }

}