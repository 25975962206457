
export default class DeliverUtil {
    constructor(productList){
        this.productList = productList;
    }

    calculateDeliverPrice(deliverType, shop, distance, districtId){
        let rounded = Math.ceil(distance * 10) / 10;
        let deliveryPrice = {
            distance: distance>0?rounded:0,
            price: 0,
            additionalPrice: 0
        }

        if(deliverType === 'DELIVERY'){
            let price = 0;
            let roundedInt = Math.ceil(rounded) || 1;
            if(roundedInt >= shop.deliveryStartAt){
                price = shop.deliveryStartPrice;
            }
            if(roundedInt >= shop.deliveryAdditionalAt){
                let _additionalDistance = (rounded+1)-shop.deliveryAdditionalAt;
                price += shop.deliveryAdditionalPrice * _additionalDistance;
            }
            deliveryPrice.distance = rounded;
            deliveryPrice.price = Math.round(price);

        } else if(deliverType === 'PARCEL'){
            let price = shop.parcelPrice;
            let additionalPrice = 0;
            let idx = shop.remoteAreas.indexOf(districtId);
            if(idx>-1){
                additionalPrice = shop.parcelBooniesPrice;
            }
            deliveryPrice.price = price;
            deliveryPrice.additionalPrice = additionalPrice;
        }
        return deliveryPrice;
    }

}
