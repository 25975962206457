import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import {getTextI18n} from "../../TextI18n";
import NumberFormat from "react-number-format";

export default function MobileOrderShop({value}) {
    const { t } = useTranslation();

    return (
        <Card sx={{borderRadius: '0 0 5px 5px', border: 0}} variant={"outlined"}>
            <List dense={true} >
                <ListItem>
                    <ListItemAvatar>
                        <Avatar variant={"square"} src={
                            value._account.image?
                                `${process.env.REACT_APP_CDN_BASE_URL}/public/merchant/s/${value._account.image.name}`: 'data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
                        }></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={
                        <Box display={"flex"} alignItems={"center"}>
                            <Typography variant="h6">{getTextI18n(value._account.name)}</Typography>
                        </Box>
                    } secondary={<Typography variant="body1"><span style={{marginRight: '4px'}}>{t('shop.tel')}</span><a href={`tel:${value._account.phone}`}><NumberFormat value={value._account.phone} displayType={'text'} format="###-###-####" /></a></Typography>} />
                </ListItem>
            </List>
        </Card>
    );
}
