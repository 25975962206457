import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import {StoreContext} from "../../core/Context";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";


export default function TakeOrderSelection({shop}) {
    const { t } = useTranslation();
    const [storeContext, setStoreContext] = useContext(StoreContext);

    console.log('[TakeOrderSelection]');

    const handleClick = (method) => {
        setStoreContext({...storeContext, deliverType: method});
    };

    return (
        <Box>
            <Box>
                <List>
                    <ListItemButton variant="outlined"
                                    sx={{
                                        border: storeContext.deliverType === 'DINE-IN' ? '1px solid rgba(26, 188, 156, 0.5)' : '1px solid #c3c4c3',
                                        borderRadius: '4px'
                                    }}
                                    onClick={e => handleClick('DINE-IN')}
                                    size={"large"}
                                    color={storeContext.deliverType === 'DINE-IN' ? 'primary' : 'default'}>
                        <ListItemAvatar>
                            <RestaurantIcon color={storeContext.deliverType === 'DINE-IN' ? 'primary' : 'default'}
                                            sx={{fontSize: 30}}/>
                        </ListItemAvatar>
                        <ListItemText primary={t('takeOrders.forHere')}/>
                    </ListItemButton>
                </List>
            </Box>
            <Box>
                <List>
                    <ListItemButton variant="outlined"
                                    sx={{
                                        border: storeContext.deliverType==='TAKEAWAY'?'1px solid rgba(26, 188, 156, 0.5)':'1px solid #c3c4c3',
                                        borderRadius: '4px'
                                    }}
                                    onClick={e=>handleClick('TAKEAWAY')}
                                    size={"large"}
                                    color={storeContext.deliverType==='TAKEAWAY'?'primary':'default'}>
                        <ListItemAvatar>
                            <ShoppingBagIcon color={storeContext.deliverType==='TAKEAWAY'?'primary':'default'} sx={{ fontSize: 30 }} />
                        </ListItemAvatar>
                        <ListItemText primary={t('takeOrders.takeaway')} />
                    </ListItemButton>
                </List>
            </Box>
        </Box>
    )
};