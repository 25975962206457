import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DiscountIcon from '@mui/icons-material/Discount';
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import {Context} from "../../../core/Context";
import Button from "@mui/material/Button";

export default function CartDiscountNotify({onAddMore}) {
    const { t } = useTranslation();
    const [context] = useContext(Context);

    return (
        <div>
            {context.discount && context.discount.offer && context.discount.offer.minPrice > 0 &&
                <Box mb={1}>
                    <Alert icon={(context.discount.offer.food>0 && context.discount.offer.delivery>0)?<DiscountIcon />:<LocalOfferIcon />} severity="info">
                        <AlertTitle>{t('discount.title')}<Button onClick={onAddMore}>({t('discount.addMore')})</Button></AlertTitle>
                        {
                            t('discount.offer', {price: context.discount.offer.minPrice
                                , discount: context.discount.offer.food + context.discount.offer.delivery})
                        }
                    </Alert>
                </Box>
            }
        </div>
    );
}
