import React, {useContext} from 'react';
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useHistory, useParams} from "react-router-dom";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import RedeemIcon from '@mui/icons-material/Redeem';
import {Context} from "../../../core/Context";
import {ButtonGroup} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import NumberFormat from "react-number-format";


export default function RewardButton({mode, shop}) {
    const { t } = useTranslation();
    const history = useHistory();
    const { cid } = useParams();
    const [context] = useContext(Context);

    const handleClick = () => {
        let data = {...context};
        history.push({
            pathname: `/hm/${mode}/${cid}/reward`,
            contextData: data
        });
    };

    return (
        <ButtonGroup variant="outlined" aria-label="reward" fullWidth={true}>
            <Button variant="outlined"
                    size="large"
                    sx={{padding: '7px 7px', background: 'white'}}
                    onClick={handleClick}>
                <Box display="flex" justifyContent="center" alignItems="center"
                     style={{width: '100%'}}>
                    <Box display="flex" alignItems="center">
                        {shop.customer && shop.customer.membership &&
                            <>
                                <Avatar sx={{width: '32px', height: '32px'}} alt="Customer" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${shop.customer._id}`} />
                                <Typography ml={1} sx={{color: '#303031'}}>
                                    <NumberFormat value={shop.customer.point} displayType={'text'}
                                                  thousandSeparator={true} suffix={` ${t('reward.points')}`} />
                                </Typography>
                            </>
                        }
                        {(!shop.customer || (shop.customer && !shop.customer.membership)) &&
                            <>
                                <CardMembershipIcon />
                                <Typography ml={1}>
                                    {t('reward.register')}
                                </Typography>
                            </>
                        }

                    </Box>
                </Box>
            </Button>
            <Button variant="outlined"
                    size="large" sx={{padding: '7px 7px', background: 'white'}}
                    onClick={handleClick}>
                <Box display="flex" alignItems="center">
                    <RedeemIcon />
                    <Typography ml={1}>
                        {t('reward.redeem')}
                    </Typography>
                </Box>
            </Button>
        </ButtonGroup>

    )
};