import React, {forwardRef, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import {httpClient} from "../../../core/HttpClient";
import ReviewProductList from "../ReviewProductList";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReviewMenuDialog({review, open, onClose}) {
    const { t } = useTranslation();
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);

    console.log('ReviewMenuDialog');

    useEffect(() => {
        httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account/products')
            .then(res => {
                if(res.data){
                    if(res.data){
                        setList(res.data.results);
                    }
                }
                setLoading(false);
            });
    }, []);

    const handleClose = ()=>{
        onClose();
    };

    const handleSubmit = (recommends)=>{
        let newReview = {...review, recommends: recommends};
        onClose(newReview);
    };

    return (
        <>
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('review.select')}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {!loading &&
            <Box>
                <ReviewProductList list={list} recommends={review.recommends} onSubmit={handleSubmit} />
            </Box>
            }
        </Dialog>
        </>
    )
};