import React, {forwardRef, useContext, useEffect, useState, useRef, useCallback} from 'react';
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import {StoreContext} from "../../core/Context";
import {ListItem} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useTranslation} from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Select from 'react-select';
import dayjs from "dayjs";
import locale_th from 'dayjs/locale/th';
import {httpClient} from "../../core/HttpClient";
let isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const customSingleValue = (props) => (
    <Box display={"flex"} alignItems={"center"}>
        <Box mr={1}>{props.icon === 'CalendarMonth'?<CalendarMonthIcon sx={{fontSize: 30, marginTop: '7px'}} />:<AccessTimeIcon sx={{fontSize: 30, marginTop: '7px'}} />}</Box>
        <Box><Typography>{ props.data.label }</Typography></Box>
    </Box>
);

const customPlaceholder = (props) => {
    return (
        <Box display={"flex"} alignItems={"center"}>
            <Box mr={1}>{props.icon === 'CalendarMonth'?<CalendarMonthIcon color={"default"} sx={{fontSize: 30, marginTop: '7px'}} />:<AccessTimeIcon color={"default"} sx={{fontSize: 30, marginTop: '7px'}} />}</Box>
            <Box><Typography color={"gray"}>{props.children}</Typography></Box>
        </Box>
    )
};

const customStyles = {
    valueContainer: (styles) => ({ ...styles, display: "flex"}),
}

export default function TimeSelection({shop, deliverType}) {
    const { t } = useTranslation();
    const selectTimeRef = useRef();
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [open, setOpen] = useState(false);
    const [disable, setDisable] = useState(true);
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [deliveryTime, setDeliveryTime] = useState(null);
    const [pickupDate, setPickupDate] = useState(null);
    const [pickupTime, setPickupTime] = useState(null);
    const [dates, setDates] = useState([]);
    const [deliveryTimes, setDeliveryTimes] = useState([]);
    const [pickupTimes, setPickupTimes] = useState([]);
    const [selectedDateTime, setSelectedDateTime] = useState('');

    const reloadTimes = useCallback((value) => {
        console.log('reloadTimes');
        let djs = dayjs(value).locale(locale_th);

        //  round times
        let roundTimeList = [];
        if(shop.deliveryTimes){
            let currentTime = dayjs().locale(locale_th);
            shop.deliveryTimes.forEach(dt=>{
                let startTime = djs.startOf('day').locale(locale_th);
                let roundTime = startTime.add(dt.hour, 'hour').add(dt.minute, 'minute');
                if(currentTime.isBefore(roundTime)){
                    let _hour = roundTime.hour();
                    let _minute = roundTime.minute();
                    let _time = `${("0" + _hour).slice(-2)}:${("0" + _minute).slice(-2)}`;
                    roundTimeList.push({label: _time, value: {hour: _hour, minute: _minute}});
                }

            });
        }

        //  all times
        let allTimeList = [];
        let currentTime = djs.startOf('day').locale(locale_th);
        let endTime = djs.endOf('day').locale(locale_th);
        if(djs.isSame(new Date(), 'day') && shop.state === 'open'){
            let _time = t('common.immediately');
            allTimeList.push({label: _time, value: null});
        }

        if(shop.times[0]){
            currentTime = currentTime.add((shop.times[0].open.hour), 'hour').add((shop.times[0].open.minute), 'minute');
            if(shop.times[0].open.hour !== shop.times[0].close.hour){
                if(parseInt(shop.times[0].open.hour) <= parseInt(shop.times[0].close.hour)){
                    endTime = endTime.add((23-shop.times[0].close.hour) * -1, 'hour').add((59-shop.times[0].close.minute) * -1, 'minute');
                }
            }
        }

        if(currentTime.isBefore(new Date())){
            currentTime = djs.locale(locale_th);
        }

        let offsetMinute = currentTime.minute() % 15;
        currentTime = currentTime.add(15-offsetMinute, 'minute');

        while(currentTime.isBefore(endTime)){
            let _hour = currentTime.hour();
            let _minute = currentTime.minute();
            let _time = `${("0" + _hour).slice(-2)}:${("0" + _minute).slice(-2)}`;
            allTimeList.push({label: _time, value: {hour: _hour, minute: _minute}});
            currentTime = currentTime.add(15, 'minute');
        }

        if(shop.deliveryType === 'round'){
            setDeliveryTimes(roundTimeList);
            setPickupTimes(allTimeList);
        } else {
            setDeliveryTimes(allTimeList);
            setPickupTimes(allTimeList);
        }

    }, [shop, t]);

    useEffect(() => {
        console.log('[TimeSelection]');

        let weekdays = [];
        let deliveryWeekdays = [];
        if(shop.times[0]){
            weekdays = shop.times[0].weekdays;
            deliveryWeekdays = shop.deliveryDays;
        }

        let openDates = [];
        let closedDates = [];
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/account/calendar';
        httpClient.get(url)
        .then(res => {
            if(res.data){
                res.data.forEach(d=>{
                    if(d.method === 'close'){
                        closedDates.push(d.dateCode);
                    } else {
                        openDates.push(d.dateCode);
                    }
                });
            }

            let dateList = [];
            let dayOffset = 0;
            let djs = dayjs().add(dayOffset, 'day').locale(locale_th);
            for (let i = 0; i < 7; i++) {
                if(dateList.length < 4){
                    let day = djs.add(i, 'day');
                    if(weekdays.indexOf(day.day()) > -1){
                        // open in weekdays so check calendar was close
                        let date = day.format('dd DD MMM YY');
                        let sameDay = day.isSame(new Date(), 'day');
                        let closedDay = closedDates.indexOf(day.format('YYYY-MM-DD')) > -1;
                        if(!closedDay){
                            if(deliverType === 'PICKUP' || shop.deliveryType === 'immediately'){
                                let data = {label: sameDay?t('common.today') : date, value: day.toDate()};
                                dateList.push(data);
                            } else {
                                let isDeliveryDay = deliveryWeekdays.indexOf(day.day()) > -1;
                                if(isDeliveryDay || shop.orderingType !== 'preorder'){
                                    let data = {label: sameDay?t('common.today') : date, value: day.toDate()};
                                    dateList.push(data);
                                }
                            }

                        }
                    } else {
                        // not open in weekday so check calendar was open
                        let date = day.format('dd DD MMM YY');
                        let sameDay = day.isSame(new Date(), 'day');
                        let openDay = openDates.indexOf(day.format('YYYY-MM-DD')) > -1;
                        if(openDay){
                            if(deliverType === 'PICKUP' || shop.deliveryType === 'immediately'){
                                let data = {label: sameDay?t('common.today') : date, value: day.toDate()};
                                dateList.push(data);
                            } else {
                                let isDeliveryDay = deliveryWeekdays.indexOf(day.day()) > -1;
                                if(isDeliveryDay || shop.orderingType !== 'preorder') {
                                    let data = {label: sameDay ? t('common.today') : date, value: day.toDate()};
                                    dateList.push(data);
                                }
                            }

                        }
                    }
                }
            }

            //  if shop not closed then default select today.
            if(dateList.length>0){
                if(dayjs(dateList[0].value).isSame(new Date(), 'day')){
                    setDeliveryDate(dateList[0]);
                    setPickupDate(dateList[0]);
                }
            }

            // init dates
            setDates(dateList);

            // init times
            let deliverDate = null;
            let deliverTime = null;
            if(deliverType === 'DELIVERY'){
                deliverDate = storeContext.deliveryDate;
                deliverTime = storeContext.deliveryTime;
            } else {
                deliverDate = storeContext.pickupDate;
                deliverTime = storeContext.pickupTime;
            }

            if(deliverDate && deliverDate.value){
                reloadTimes(deliverDate.value);
            } else {
                reloadTimes(djs.toDate());
            }

            if(deliverDate && deliverTime){
                if(deliverTime.value){
                    setSelectedDateTime(`${deliverDate.label}, ${deliverTime.label}`);
                } else {
                    setSelectedDateTime(t('common.immediately'));
                }
            } else {
                setSelectedDateTime('');
            }

        });

    }, [shop, storeContext, deliverType, t, reloadTimes]);

    const handleClick = () => {
        setOpen(true);
    };

    const handleSubmit = () => {
        console.log('handleSubmit');
        setOpen(false);
        let errors = storeContext.errors;
        let idx = errors.indexOf('deliverTime');
        if(idx>-1){
            errors.splice(idx, 1);
        }

        if(deliverType === 'DELIVERY'){
            setStoreContext({
                ...storeContext,
                errors: errors,
                deliveryDate: {label: deliveryDate.label, value: deliveryDate.value},
                deliveryTime: {label: deliveryTime.label, value: deliveryTime.value}
            });
        } else {
            setStoreContext({
                ...storeContext,
                errors: errors,
                pickupDate: {label: pickupDate.label, value: pickupDate.value},
                pickupTime: {label: pickupTime.label, value: pickupTime.value}
            });
        }

    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDateChange = (data) => {
        console.log('handleDateChange');
        reloadTimes(data.value);
        if(deliverType === 'DELIVERY'){
            setDeliveryDate(data);
        } else {
            setPickupDate(data);
        }

        setDisable(true);
        selectTimeRef.current.clearValue();
    };

    const handleTimeChange = (time) => {
        console.log('handleTimeChange');
        if(time){
            if(deliverType === 'DELIVERY'){
                setDeliveryTime(time);
            } else {
                setPickupTime(time);
            }
            setDisable(false);
        }
    };

    return (
        <Box>
            <ListItem disablePadding>
                <ListItemButton sx={{
                    border: selectedDateTime?'1px solid rgba(26, 188, 156, 0.5)':'1px solid #c3c4c3',
                    borderRadius: '4px'
                }} onClick={handleClick}>
                    <ListItemAvatar>
                        <AccessTimeIcon color={selectedDateTime?"primary":"default"} sx={{ fontSize: 30 }} />
                    </ListItemAvatar>
                    <ListItemText primary={`${selectedDateTime?selectedDateTime:t('common.message.pleaseSelect')}`}
                                  primaryTypographyProps={{variant: "body1"}}
                                  secondaryTypographyProps={{ component: 'div' }}
                    />
                </ListItemButton>
            </ListItem>
            {storeContext.deliverType === 'DELIVERY' && shop.deliveryType === 'round' &&
            <Box>
                <Typography variant={"caption"}>* {t('address.specifyRoundTimeMessage')}</Typography>
            </Box>
            }
            <Dialog fullScreen
                    open={open}
                    TransitionComponent={Transition}
                    onClose={handleClose}>
                <AppBar sx={{ position: 'relative' }} color="default" >
                    <Toolbar>
                        <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                            {deliverType === 'DELIVERY'?t('address.specifyRoundTime'):t('address.specifyTime')}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box m={2}>
                    {shop.orderingType === 'preorder' &&
                        <Box mt={2} mb={2}>
                            <Typography variant="h6" component="div">
                                {t('order.selectDate')}
                            </Typography>
                            <Box mt={1} mb={2}>
                                <Select options={dates}
                                        noOptionsMessage={() => t('common.message.outOfDeliveryDays')}
                                        defaultValue={deliverType === 'DELIVERY'?deliveryDate:pickupDate}
                                        isSearchable={false}
                                        styles={customStyles}
                                        onChange={handleDateChange}
                                        components={{
                                            SingleValue: ({...props})=>customSingleValue({...props, icon: 'CalendarMonth'}),
                                            Placeholder: ({...props})=>customPlaceholder({...props, icon: 'CalendarMonth'})
                                        }}
                                        placeholder={t('common.message.pleaseSelect')} />
                            </Box>
                        </Box>
                    }
                    <Box mt={2}>
                        <Typography variant="h6" component="div">
                            {t('order.selectTime')}
                        </Typography>
                        <Box mt={1} mb={2}>
                            <Select options={deliverType === 'DELIVERY'?deliveryTimes:pickupTimes}
                                    defaultValue={deliverType === 'DELIVERY'?deliveryTime:pickupTime}
                                    noOptionsMessage={() => t('common.message.outOfDeliveryTimes')}
                                    isSearchable={false}
                                    styles={customStyles}
                                    ref={selectTimeRef}
                                    onChange={handleTimeChange}
                                    isDisabled={deliverType === 'DELIVERY'?deliveryDate===null:pickupDate===null}
                                    components={{
                                        SingleValue: ({...props})=>customSingleValue({...props, icon: 'AccessTime'}),
                                        Placeholder: ({...props})=>customPlaceholder({...props, icon: 'AccessTime'})
                                    }}
                                    placeholder={t('common.message.pleaseSelect')} />
                        </Box>
                    </Box>
                </Box>
                <DialogActions>
                    <Box style={{width: '100%'}}>
                        <Button variant="contained"
                                size="large"
                                fullWidth={true}
                                disabled={disable}
                                disableElevation
                                onClick={handleSubmit}
                                style={{fontWeight: 'bold'}}>
                            <span>{t('common.ok')}</span>
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    )
};