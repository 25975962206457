import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {DateTime} from "../../DateTime";
import NumberFormat from "react-number-format";


export default function FoodOrderPayment({value}) {
    const { t } = useTranslation();

    return (
        <Box m={2} style={{textAlign: 'center'}}>
            <Box display="flex" justifyContent="center" mb={1}>
                <Typography variant="h6" component="div">
                    {t('payment.title')}
                </Typography>
            </Box>
            {value.payment &&
                <>
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Typography component={'span'} variant="body1">
                            {t('payment.method')}
                        </Typography>
                        {!value.payment.method &&
                            <Typography component={'span'} variant={"caption"}>
                                *{t(`payment.notAvailable`)}
                            </Typography>
                        }
                        {value.payment.method &&
                            <Typography component={'span'} variant="body1">
                                {t(`payment.${value.payment.method}`)}
                            </Typography>
                        }
                    </Box>
                    {value.payment.payDate &&
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography component={'span'} variant="body1">
                                {t('payment.paymentDate')}
                            </Typography>
                            <Typography component={'span'} variant="body1">
                                <DateTime value={value.payment.payDate}
                                          format="DD MMM YYYY HH:mm"></DateTime>
                            </Typography>
                        </Box>
                    }
                    {value.payment.receiver &&
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography component={'span'} variant="body1">
                                {t('payment.paymentReceiver')}
                            </Typography>
                            <Typography component={'span'} variant="body1">
                                {value.payment.receiver.firstName?(value.payment.receiver.firstName + ' ' +value.payment.receiver.lastName):value.payment.receiver.displayName}
                            </Typography>
                        </Box>
                    }
                    {value.payment.receive > 0 &&
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography component={'span'} variant="body1">
                                {t('payment.paymentReceiverAmount')}
                            </Typography>
                            <Typography component={'span'} variant="body1">
                                <NumberFormat value={value.payment.receive || 0} displayType={'text'}
                                              thousandSeparator={true} prefix={'฿'}/>
                            </Typography>
                        </Box>
                    }
                    {value.payment.receive > 0 && value.payment.change >= 0 &&
                        <Box display={"flex"} justifyContent={"space-between"}>
                            <Typography component={'span'} variant="body1">
                                {t('payment.change')}
                            </Typography>
                            <Typography component={'span'} variant="body1">
                                <NumberFormat value={value.payment.change} displayType={'text'}
                                              thousandSeparator={true} prefix={'฿'}/>
                            </Typography>
                        </Box>
                    }
                    <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={2}>
                        {value.payment.status === 'pending' &&
                            <Box sx={{width: '100%', color: 'red', border: '1px solid red', borderRadius: '4px'}} p={2}>
                                <b>** {t('payment.notPaid')} **</b>
                            </Box>
                        }
                        {value.payment.status === 'waiting' &&
                            <Box sx={{width: '100%', color: '#ffa12b', border: '1px solid #ffa12b', borderRadius: '4px'}} p={2}>
                                <b>** {t('payment.waiting')} **</b>
                            </Box>
                        }
                        {value.payment.status === 'success' &&
                            <Box sx={{width: '100%', color: '#44cc00', border: '1px solid #44cc00', borderRadius: '4px'}} p={2}>
                                <b>** {t('payment.paid')} **</b>
                            </Box>
                        }
                    </Box>
                    <Box>
                        {value.payment && value.payment.attachment &&
                            <img
                                style={{width: '100%'}}
                                alt={value.payment.attachment.originName}
                                src={`${process.env.REACT_APP_CDN_BASE_URL}/${value.payment.attachment.key}`}
                            />
                        }
                    </Box>
                </>
            }
        </Box>
    );
}
