import React, {useEffect, useState} from "react";

export function CountNumber(props) {
    let [display, setDisplay] = useState('');
    useEffect(() => {
        let value = props.value || 0;
        let suffix = '';
        if(props.value > 999999){
            value = Math.floor(props.value/1000000 * 10.0) / 10.0;
            suffix = 'M';
        } else if(props.value > 999){
            value = Math.floor(props.value/1000 * 10.0) / 10.0;
            suffix = 'K';
        } else if(value < 0){
            value = 0;
        }
        setDisplay(''+value + suffix);

    }, [props, setDisplay]);

    return(
        <div style={{ paddingTop: '3px' }}>{display}</div>
    )
}