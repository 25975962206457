import React, {useState, forwardRef, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import NumberFormat from 'react-number-format';
import {StoreContext} from "../../../core/Context";
import {httpClient} from "../../../core/HttpClient";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

export default function ContactProfileEditor({open, profile, onClose}) {
    const { t } = useTranslation();
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [contactProfile, setContactProfile] = useState({name: '', mobile: ''});
    const [error, setError] = useState({name: false, mobile: false});

    console.log('[ContactProfileEditor]');

    useEffect(() => {
        console.log('ContactProfileEditor');
        setContactProfile({...profile});
    }, [profile]);


    const handleChange = ({target})=>{
        let newProfile = {...contactProfile, [target.name]: target.value};
        setContactProfile(newProfile);
    };

    const handleClose = () => {
        setError({name: false, mobile: false});
        onClose();
    };

    const handleSave = () => {
        if(_validation()){
            let _contact = {
                key: contactProfile.key,
                name: contactProfile.name,
                mobile: contactProfile.mobile,
                address: contactProfile.address
            };

            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
            let newAddress = {..._contact};
            httpClient.post(url, newAddress)
            .then(res => {
                let data = res.data;
                setStoreContext({...storeContext, deliverType: 'PICKUP', profile: data});
                onClose();
            });

        }

    };

    function _validation() {
        let result = true;
        let error = {name: false, mobile: false};
        if(!contactProfile.name){
            result = false;
            error.name = true;
        }
        if(!contactProfile.mobile ||
            contactProfile.mobile.length !== 10 ||
            !contactProfile.mobile.startsWith('0')
        ){
            result = false;
            error.mobile = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('profile.specify')}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{marginTop: '10px'}}>
                <CardContent>
                    <form autoComplete="false" noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.contact')}
                                           fullWidth={true}
                                           name="name"
                                           value={contactProfile.name}
                                           type="text"
                                           error={error.name}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange}/>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField label={t('address.mobile')}
                                           fullWidth={true}
                                           name="mobile"
                                           value={contactProfile.mobile}
                                           type="tel"
                                           InputProps={{
                                               inputComponent: NumberFormatCustom,
                                           }}
                                           error={error.mobile}
                                           InputLabelProps={{
                                               shrink: true,
                                           }}
                                           required
                                           onChange={handleChange} />
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </div>
            <DialogActions>
                <Box mb={4} style={{width: '100%'}}>
                    <Button variant="contained"
                            size="large"
                            fullWidth={true}
                            onClick={handleSave}
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.ok')}</span>
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
};