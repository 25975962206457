import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from "react-i18next";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import {httpClient} from "../../../core/HttpClient";
import {ListItem, Rating} from "@mui/material";
import {TextMarking} from "../../TextMarking";
import {DateTime} from "../../DateTime";
import Divider from "@mui/material/Divider";
import ReviewPhotos from "../ReviewPhotos";
import PushPinIcon from '@mui/icons-material/PushPin';
import IconButton from "@mui/material/IconButton";
import HighlightOff from "@mui/icons-material/HighlightOff";
import Modal from "@mui/material/Modal";
import {TextI18n} from "../../TextI18n";

const modalStyle = {
    margin: '10px',
    bgcolor: 'background.paper',
    border: '0px',
    boxShadow: 24,
    p: 1
};

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_RELOAD":
            return {
                ...state,
                isLoading: true,
                hasError: false,
                hasNext: false,
                cursor: '',
                hits: [],
            };
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursorKey === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch, cancelToken) {
    dispatch({ type: "FETCH_START" });
    try {
        console.log('fetchHits', query.cursor);
        let url = process.env.REACT_APP_API_BASE_URL + '/publish/account/reviews?cursor='+query.cursor+'&rate='+query.rate;
        httpClient.get(url,
            {cancelToken: cancelToken}
        ).then(res => {
            if(Array.isArray(res.data)){
                dispatch({ type: "FETCH_SUCCESS", payload: res.data, cursorKey: query.cursor });
            } else {
                dispatch({ type: "FETCH_FAILURE" });
            }
        }).catch(e=>{
            console.log('error', e);
            dispatch({ type: "FETCH_FAILURE" });
        });
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

function init() {
    return {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null,
        rate: 0,
    };
}

export default function ReviewList({summary, myReview, shopInfo}) {
    const { t } = useTranslation();
    const [reviewImages, setReviewImages] = useState([]);
    const [query, setQuery] = useState({cursor: '', rate: 0});
    const [{ hits, hasNext, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null,
        rate: 0
    }, init);

    const debouncedFetchHits = useRef(
        _.debounce(
            (query, cancelToken) => fetchHits(query, dispatch, cancelToken),
            500
        )
    ).current;

    useEffect(() => {
        console.log('[ReviewList]');
        const { token } = httpClient.CancelToken.source();
        debouncedFetchHits(query, token);
    }, [debouncedFetchHits, query]);

    const handleFetchData = (cursor) =>{
        setQuery({...query, cursor: cursor}); // trig to reload data by useReducer
    };

    // const handleFilterChange = (event) =>{
    //     dispatch({ type: "FETCH_RELOAD", payload: [], cursorKey: '' });
    //     setQuery({cursor: '', rate: event.target.value});
    // };

    const handleViewImages = (index, images) =>{
        setReviewImages(images);
        if(index>0){
            setTimeout(function(){
                const element = document.getElementById(`id-${index}`);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100);
        }
    };

    const handleCloseReview = () =>{
        setReviewImages([]);
    };

    return (
        <Box sx={{width: '100%'}} pb={2}>
            <Box pb={4}>
                {(hits.length === 0 && myReview.rate === 0) && !isLoading &&
                <>
                    <Divider sx={{marginTop: '13px', marginBottom: '10px'}} />
                    <Box display="flex" justifyContent="center" mt={4}>
                        <Typography variant="body1" color="textSecondary"
                                    sx={{fontSize: '0.85rem'}}>{t('review.notFound')}</Typography>
                    </Box>
                </>
                }
                {myReview && myReview.rate > 0 &&
                    <Box key={`item-my-review`}
                         p={1} m={1} mb={0}
                         sx={
                             myReview.rate <= 2 ? {backgroundColor: '#fdedee', border: '1px solid #ff544d', borderRadius: 1} :
                             myReview.rate === 3 ? {backgroundColor: '#fff4e5', border: '1px solid #eb8d4c', borderRadius: 1} :
                             {backgroundColor: '#edf7ed', border: '1px solid #458947', borderRadius: 1}
                        }>
                        <Box>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar
                                        alt={myReview.customer.displayName}
                                        src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${myReview.customer._id}`}
                                    />
                                </ListItemAvatar>
                                <ListItemText>
                                    <span><TextMarking value={myReview.customer.displayName} /></span>
                                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                        <Rating
                                            size={"small"}
                                            name="rate"
                                            value={myReview.rate}
                                            readOnly={true}
                                        />
                                        <Box display={"flex"} justifyContent={"end"} >
                                            <Box style={{position: 'absolute', top: '5px'}}>
                                                <PushPinIcon sx={{
                                                    transform: "rotate(45deg)",
                                                }} />
                                            </Box>
                                            <Box>
                                                <Typography variant={"caption"}>
                                                    <DateTime value={myReview.modified}
                                                              format="DD MMM YY, HH:mm"></DateTime>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </ListItemText>
                            </ListItem>
                            {myReview.message &&
                                <Box pl={8} pr={2}>
                                    <Typography variant={"body1"} sx={{ whiteSpace: 'pre-line'}}>
                                        {myReview.message}
                                    </Typography>
                                </Box>
                            }
                            {myReview.images &&
                                <Box mt={1}>
                                    <ReviewPhotos images={myReview.images} onClick={handleViewImages} />
                                </Box>
                            }
                        </Box>
                        {myReview.replyMessages && myReview.replyMessages[0] && <Box>
                            <Divider sx={{marginTop: '10px', marginBottom: '10px'}} />
                            <Box sx={{backgroundColor: '#F8F8F8', borderRadius: 1}}>
                                <ListItem dense={true} >
                                    <ListItemAvatar>
                                        <Avatar aria-label="recipe"
                                                sx={{height: '40px', width: '40px'}}
                                                src={(shopInfo.image && shopInfo.image.name) ? `${process.env.REACT_APP_CDN_BASE_URL}/${shopInfo.image.permission}/merchant/s/${shopInfo.image.name}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`}/>
                                    </ListItemAvatar>
                                    <ListItemText>
                                        <Typography variant={"subtitle1"}>
                                            <strong><TextI18n value={shopInfo.name}></TextI18n></strong>
                                        </Typography>
                                        <Box>
                                            <Typography variant={"caption"}>
                                                <DateTime value={myReview.replyMessages[0].date}
                                                          format="DD MMM YY, HH:mm"></DateTime>
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                </ListItem>
                                <Box ml={2} mr={2}>
                                    <Typography variant={"body1"} sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word'}}>
                                        {myReview.replyMessages[0].message}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        }
                    </Box>
                }
                {hits.length > 0 &&
                <InfiniteScroll
                    dataLength={hits.length} //This is important field to render the next data
                    next={e=>handleFetchData(cursor)}
                    hasMore={hasNext}>
                    <Box>
                        {hits.map((value, index) => (
                            <Box key={`item-${value._id}-${index}`}
                                 p={1} m={1}
                                 sx={
                                     value.rate <= 2 ? {backgroundColor: '#fdedee', border: '1px solid #ff544d', borderRadius: 1} :
                                         value.rate === 3 ? {backgroundColor: '#fff4e5', border: '1px solid #eb8d4c', borderRadius: 1} :
                                             {backgroundColor: '#edf7ed', border: '1px solid #458947', borderRadius: 1}
                                 }>
                                <Box>
                                    <ListItem dense={true}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={value.customer.displayName}
                                                src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${value.customer._id}`}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <span><TextMarking value={value.customer.displayName} /></span>
                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                <Rating
                                                    size={"small"}
                                                    name="rate"
                                                    value={value.rate}
                                                    readOnly={true}
                                                />
                                                <Box display={"flex"} justifyContent={"end"} >
                                                    <Box>
                                                        <Typography variant={"caption"}>
                                                            <DateTime value={value.modified}
                                                                      format="DD MMM YY, HH:mm"></DateTime>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                    {value.message &&
                                        <Box pl={8} pr={2}>
                                            <Typography variant={"body1"} sx={{ whiteSpace: 'pre-line'}}>
                                                {value.message}
                                            </Typography>
                                        </Box>
                                    }
                                    {value.images &&
                                        <Box mt={1}>
                                            <ReviewPhotos images={value.images} onClick={handleViewImages} />
                                        </Box>
                                    }
                                </Box>
                                {value.replyMessages && value.replyMessages[0] && <Box>
                                    <Divider sx={{marginTop: '10px', marginBottom: '10px'}} />
                                    <Box sx={{backgroundColor: '#F8F8F8', borderRadius: 1}}>
                                        <ListItem dense={true} >
                                            <ListItemAvatar>
                                                <Avatar aria-label="recipe"
                                                        sx={{height: '40px', width: '40px'}}
                                                        src={(shopInfo.image && shopInfo.image.name) ? `${process.env.REACT_APP_CDN_BASE_URL}/${shopInfo.image.permission}/merchant/s/${shopInfo.image.name}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`}/>
                                            </ListItemAvatar>
                                            <ListItemText>
                                                <Typography variant={"subtitle1"}>
                                                    <strong><TextI18n value={shopInfo.name}></TextI18n></strong>
                                                </Typography>
                                                <Box>
                                                    <Typography variant={"caption"}>
                                                        <DateTime value={value.replyMessages[0].date}
                                                                  format="DD MMM YY, HH:mm"></DateTime>
                                                    </Typography>
                                                </Box>
                                            </ListItemText>
                                        </ListItem>
                                        <Box ml={2} mr={2}>
                                            <Typography variant={"body1"}>
                                                {value.replyMessages[0].message}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                }
                            </Box>
                        ))}
                    </Box>
                </InfiniteScroll>
                }
            </Box>
            {isLoading &&
                <Box display="flex" justifyContent="center" mt={2} mb={2}>
                    <CircularProgress size={20}/>
                </Box>
            }
            <Modal
                open={reviewImages.length>0}
                style={{overflow:'scroll'}}
                onClose={handleCloseReview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Box display={"flex"} alignItems="center" justifyContent={"space-between"}>
                        <Typography variant="h6">
                            {t('common.images')}
                        </Typography>
                        <IconButton
                            edge="start"
                            color="default"
                            aria-label="close"
                            onClick={handleCloseReview}
                        >
                            <HighlightOff fontSize="large" style={{background: 'white', borderRadius: '18px'}} />
                        </IconButton>
                    </Box>
                    {reviewImages.map((image, i) => (
                        <img
                            id={`id-${i}`}
                            key={image.key}
                            style={{width: '100%', marginTop: '8px'}}
                            src={`${process.env.REACT_APP_CDN_BASE_URL}/${image.key}`}
                            alt="review"
                        />
                    ))
                    }
                </Box>
            </Modal>
        </Box>
    )
};