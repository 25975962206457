import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FoodOrderTimeLine from '../../FoodOrder/FoodOrderTimeLine';
import {httpClient} from "../../../core/HttpClient";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {getDatabaseInstance} from "../../../core/Firebase";
import jwt_decode from "jwt-decode";
import {getDatabase, onValue, ref} from "firebase/database";
import {getAuth, signInAnonymously} from "firebase/auth";

export default function OrdersInfo() {
    const { cid, uid, mode } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [transactionData, setTransactionData] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        console.log('[OrdersInfo]');
        const app = getDatabaseInstance(cid);
        console.log('app', app.name);
        let token = localStorage.getItem('token');
        if(token){
            let decoded = jwt_decode(token);
            let customerId = decoded.customer;
            let key = 'customers/'+customerId;
            const db = getDatabase(app);
            const auth = getAuth(app);
            signInAnonymously(auth)
                .then(() => {
                    const statusRef = ref(db, key);
                    onValue(statusRef, (snapshot) => {
                        const data = snapshot.val();
                        if(data){
                            let url = process.env.REACT_APP_API_BASE_URL + `/secure/customer/order/${uid}`;
                            httpClient.get(url)
                                .then(res => {
                                    if(res.status === 200){
                                        if(res.data){
                                            setTransactionData(res.data.transaction);
                                            setItems([res.data]);
                                        } else {
                                            setItems([]);
                                        }
                                    }
                                    setLoading(false);
                                }).catch(e=>{
                                console.log('Error',e);
                                // let _cid = cid || localStorage.getItem('cid');
                                // history.push(`/m/${_cid}`);
                            });
                        } else {
                            localStorage.removeItem('token');
                            history.push('/hm/login');
                        }
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [cid, uid, history]);

    return (
        <Box>
            {!loading && items[0] &&
                <HelmetProvider>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{items[0]._account.name.i18n.th}</title>
                    </Helmet>
                </HelmetProvider>
            }
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
            <>
                <FoodOrderTimeLine mode={mode} cid={cid} items={items} transaction={transactionData} />
            </>
            }
        </Box>
    );
}
