import { initializeApp } from "firebase/app";
import Base64MongoId from "base64-mongo-id";

var firebaseConfig = {
    apiKey: "AIzaSyCjHYUA26hl6I7P3o4xLEICy8kLFqAANPc",
    authDomain: "foodkub.firebaseapp.com",
    projectId: "foodkub",
    storageBucket: "foodkub.appspot.com",
    messagingSenderId: "209225889289",
    appId: "1:209225889289:web:79630bb66851348da96ad8",
    measurementId: "G-JMQPN62V9N"
};

const MAX_FIREBASE_INSTANCE = process.env.REACT_APP_FIREBASE_INSTANCE;
let databaseURL1 = `${process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_URL_1}`;
let databaseURL2 = `${process.env.REACT_APP_FIREBASE_REALTIME_DATABASE_URL_2}`;

const app1 = initializeApp({...firebaseConfig, databaseURL: databaseURL1,}, 'db1');
const app2 = initializeApp({...firebaseConfig, databaseURL: databaseURL2,}, 'db2');
console.log('initialFirebaseDatabase success');

export const db1 = app1;
export const db2 = app2;

export const getDatabaseInstance = (cid) => {
    let accountId = Base64MongoId.toHex(cid);
    let tmp = parseInt(accountId.slice(18, (24)), 16);
    let firebaseInstance = tmp % MAX_FIREBASE_INSTANCE;
    if(firebaseInstance === 0){
        return db1;
    } else if(firebaseInstance === 1){
        return db2;
    }
};

