import React, {forwardRef, useContext, useState, useEffect, useRef, createRef} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import CardContent from '@mui/material/CardContent';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from "@mui/material/Stack";
import NumberFormat from "react-number-format";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import ListItem from "@mui/material/ListItem";
import {StoreContext} from "../../../core/Context";
import MapUtil from "../../../core/MapUtil";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {httpClient} from "../../../core/HttpClient";
import {MapContainer, Marker, Polygon, TileLayer, useMapEvents} from "react-leaflet";
import {isDesktop} from "react-device-detect";
import L from "leaflet";
import DialogActions from "@mui/material/DialogActions";
import DeliverUtil from "../../../core/DeliverUtil";

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

const blueIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_icon&chld=caution|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    }),
    redIcon = new L.Icon({
        iconUrl:
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|ff0000&chf=a,s,ee00FFFF",
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LocationMarker = (props) => {
    const [icon, setIcon] = useState(props.ood?blueIcon:redIcon);
    const [position, setPosition] = useState(props.position);
    const markerRef = useRef(null);
    const map = useMapEvents({
        move() {
            const marker = markerRef.current;
            if (marker != null) {
                marker.setLatLng(map.getCenter());
            }
        },
        moveend() {
            let center = map.getCenter();
            if(position){
                let ood = new MapUtil().isOutSidePolygon(props.shop, center.lat, center.lng);
                if(ood){
                    setIcon(blueIcon);
                } else {
                    setIcon(redIcon);
                }
                setPosition([center.lat, center.lng]);
            } else {
                setIcon(blueIcon);
                setPosition([center.lat, center.lng]);
            }

        }
    });

    return position === null ? null : (
        <Marker position={position} ref={markerRef} icon={icon}></Marker>
    )
};

const NumberFormatCustom = forwardRef(function Transition(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

const MemoizedMap = React.memo(({profile,outOfDelivery, shop, setMap})=>{
    let coordinates = {
        lat: shop.lat,
        lng: shop.lng,
        ood: outOfDelivery
    };
    if(profile && profile.lat && profile.lng){
        coordinates.lat = profile.lat;
        coordinates.lng = profile.lng;
    }

    return(<MapContainer center={[coordinates.lat, coordinates.lng]}
                         zoom={15}
                         {...interactionOptions}
                         ref={setMap}
                         scrollWheelZoom={false}>
        <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker effectOn={this}
                        shop={shop}
                        ood={coordinates.ood}
                        position={[coordinates.lat, coordinates.lng]} />
        {shop && shop.lat && shop.lng &&
            <>
                <Polygon
                    positions={shop.deliveryAreas}
                    color="#1abc9c"
                    fillColor="#1abc9c" />
            </>
        }

    </MapContainer>)
});

export default function LocationSelection({list, open, shop, onClose}) {
    const { t } = useTranslation();
    const [mode, setMode] = useState('list');
    const [storeContext, setStoreContext] = useContext(StoreContext);
    const [addressList, setAddressList] = useState(list);
    const [contactProfile, setContactProfile] = useState({name: '', mobile: '', landmark: ''});
    const [error, setError] = useState({name: false, mobile: false, landmark: false});
    const [map, setMap] = useState(null);
    const markerRef = createRef();
    console.log('[LocationSelection]');

    useEffect(() => {
        console.log('LocationSelection');
    }, []);

    const handleClose = () => {
        onClose();
    };

    const handleBack = () => {
        setMode('list');
    };

    const handleClickCreate = () => {
        setContactProfile({name: '', mobile: '', landmark: ''});
        setMode('add');
    };

    const handleClickSelect = (item) => {
        if(!item.lat || !item.lng || !item.landmark){
            let ood = new MapUtil().isOutSidePolygon(shop, item.lat, item.lng);
            setContactProfile({...contactProfile, ...item, outOfDelivery: ood});
            setMode('enhance');
        } else {
            let ood = new MapUtil().isOutSidePolygon(shop, item.lat, item.lng);
            let _profile = {
                key: item.key,
                name: item.name,
                mobile: item.mobile,
                landmark: item.landmark,
                lat: item.lat,
                lng: item.lng
            };

            let errors = storeContext.errors;
            let idx = errors.indexOf('deliverType');
            if(idx>-1){
                errors.splice(idx, 1);
            }

            const distance = new MapUtil().getDistance(shop, _profile.lat, _profile.lng);
            let deliverPrice = new DeliverUtil().calculateDeliverPrice('DELIVERY', shop, distance, null);
            setStoreContext({...storeContext, deliverType: 'DELIVERY', deliverPrice: deliverPrice, profile: _profile, outOfDelivery: ood, errors: errors});
            onClose();
        }


    };

    const handleClickEdit = (item) => {
        console.log('handleClickEdit');
        let ood = new MapUtil().isOutSidePolygon(shop, item.lat, item.lng);
        setContactProfile({...item, outOfDelivery: ood});
        setMode('edit');
    };

    const handleChange = ({target})=>{
        let newProfile = {...contactProfile, [target.name]: target.value};
        setContactProfile(newProfile);
    };

    const handleReloadList = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAddressList(res.data);
                } else {
                    setAddressList([]);
                }
            });
    };

    const handleSave = () => {
        if(_validation()){
            let c = map.getCenter();
            let ood = new MapUtil().isOutSidePolygon(shop, c.lat, c.lng);
            let _contact = {
                key: contactProfile.key,
                name: contactProfile.name,
                mobile: contactProfile.mobile,
                landmark: contactProfile.landmark,
                lat: c.lat,
                lng: c.lng
            };

            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';
            let newAddress = {..._contact};
            httpClient.post(url, newAddress)
                .then(res => {
                    let data = res.data;
                    if(mode === 'edit'){
                        handleReloadList();
                        setMode('list');
                        if(storeContext.deliverType === 'DELIVERY' && storeContext.profile && storeContext.profile.key === data.key){
                            setStoreContext({...storeContext, deliverType: 'DELIVERY', profile: data, outOfDelivery: ood});
                        }
                    } else {
                        if(storeContext.deliverType === 'DELIVERY'){
                            let _profile = {
                                key: data.key,
                                name: data.name,
                                mobile: data.mobile,
                                landmark: data.landmark,
                                lat: data.lat,
                                lng: data.lng
                            };
                            const distance = new MapUtil().getDistance(shop, _profile.lat, _profile.lng);
                            let deliverPrice = new DeliverUtil().calculateDeliverPrice('DELIVERY', shop, distance, null);
                            setStoreContext({...storeContext, deliverType: 'DELIVERY', deliverPrice: deliverPrice, profile: _profile, outOfDelivery: ood, errors: []});
                            onClose();
                        } else {
                            handleReloadList();
                            setMode('list');
                        }
                    }
                });

        }

    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address/'+contactProfile.key;
        httpClient.delete(url, { method: 'DELETE' })
            .then(res => {
                handleReloadList();
                setMode('list');
                if(storeContext.profile && storeContext.profile.key === contactProfile.key){
                    setStoreContext({...storeContext, deliverType: '', profile: {name: '', mobile: ''}});
                }
            });
    };

    function _validation() {
        console.log('contactProfile.outOfDelivery', contactProfile.outOfDelivery);
        console.log('map', map);
        let result = true;
        let _error = {name: false, mobile: false, landmark: false};
        if(!contactProfile.name){
            result = false;
            _error.name = true;
        }
        if(!contactProfile.mobile ||
            contactProfile.mobile.length !== 10 ||
            !contactProfile.mobile.startsWith('0')
        ){
            result = false;
            _error.mobile = true;
        }

        if(!contactProfile.landmark){
            result = false;
            _error.landmark = true;
        }

        if(!result){
            setError(_error);
        }

        return result;
    }

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    {mode !== 'list' && <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleBack}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>}
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {mode === 'list'?t('address.selectLocation'):t('address.specifyLocation')}
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div>
                {mode === 'list' &&
                    <>
                        <Box display="flex" justifyContent="center" mb={1} mt={2} pl={2} pr={2}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth={true}
                                disableElevation
                                startIcon={<AddIcon />}
                                onClick={handleClickCreate}>
                                {t('address.addLocation')}
                            </Button>
                        </Box>
                        <CardContent>
                            <Box>
                                {addressList && addressList.map(item => {
                                    let ood = new MapUtil().isOutSidePolygon(shop, item.lat, item.lng);
                                    return (
                                        <Box key={item._id} mb={2}>
                                            <ListItem
                                                secondaryAction={
                                                    <IconButton edge="end" aria-label="edit" onClick={e=>handleClickEdit(item)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                }
                                                disablePadding
                                            >
                                                <ListItemButton sx={{border: '1px solid #e0e0e0', borderRadius: '3px'}} onClick={e=>handleClickSelect(item)}>
                                                    <ListItemAvatar>
                                                        {!item.landmark && <NotListedLocationIcon color={"default"} sx={{ fontSize: 30 }} />}
                                                        {item.landmark && !ood && <LocationOnIcon color={"default"} sx={{ fontSize: 30 }} />}
                                                        {item.landmark && ood && <LocationOffIcon color={"default"} sx={{ fontSize: 30 }} />}
                                                    </ListItemAvatar>
                                                    <ListItemText primary={item.name}
                                                                  secondaryTypographyProps={{ component: 'div' }}
                                                                  secondary={<Stack>
                                                                      <Typography><NumberFormat value={item.mobile} displayType={'text'} format="###-###-####" /></Typography>
                                                                      <Typography>{item.landmark}</Typography>
                                                                  </Stack>} />
                                                </ListItemButton>
                                            </ListItem>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </CardContent>
                    </>
                }
                {['add','edit', 'enhance'].indexOf(mode) > -1 && <Box>
                    <div style={{height: '300px'}}>
                        <MemoizedMap shop={shop} profile={contactProfile} outOfDelivery={contactProfile.outOfDelivery} setMap={setMap} markerRef={markerRef} />
                    </div>
                    <div style={{height: '300px', marginTop: '10px'}}>
                        <CardContent>
                            <form autoComplete="false" noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12}>
                                        <TextField label={t('address.contact')}
                                                   fullWidth={true}
                                                   name="name"
                                                   value={contactProfile.name}
                                                   type="text"
                                                   error={error.name}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   required
                                                   onChange={handleChange}/>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField label={t('address.mobile')}
                                                   fullWidth={true}
                                                   name="mobile"
                                                   value={contactProfile.mobile}
                                                   type="tel"
                                                   InputProps={{
                                                       inputComponent: NumberFormatCustom,
                                                   }}
                                                   error={error.mobile}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   required
                                                   onChange={handleChange} />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <TextField label={t('address.landmark')}
                                                   fullWidth={true}
                                                   name="landmark"
                                                   value={contactProfile.landmark}
                                                   type="text"
                                                   error={error.landmark}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   required
                                                   onChange={handleChange}/>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </div>
                </Box>}
            </div>
            {['add','edit', 'enhance'].indexOf(mode) > -1 && <DialogActions>
                <Box mb={4} style={{width: '100%'}}>
                    <Button variant="contained"
                            size="large"
                            fullWidth={true}
                            onClick={handleSave}
                            disableElevation
                            style={{zIndex: 99, fontWeight: 'bold'}}>
                        <span>{t('common.ok')}</span>
                    </Button>
                    {mode === 'edit' && <Box mt={2}>
                        <Button variant="contained"
                                disableElevation
                                size="large"
                                color={"secondary"}
                                fullWidth={true}
                                onClick={handleDelete}
                                style={{zIndex: 99, fontWeight: 'bold'}}>
                            <span>{t('common.delete')}</span>
                        </Button>
                    </Box>}
                </Box>
            </DialogActions>}
        </Dialog>
    )
};