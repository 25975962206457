import React from 'react';
import { useTranslation } from "react-i18next";
import TextTruncate from 'react-text-truncate';

export function TextI18nTruncate({value, lines}) {
    if(!value){
        value = {i18n:{en: '', th: ''}}
    }
    const { i18n } = useTranslation();
    const currentLang = i18n.language.split('-')[0] || value.i18n['en'];
    return(
        <TextTruncate line={lines}
                      element="div"
                      text={value.i18n[currentLang]} />
    )
}