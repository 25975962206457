import React, {useContext, useState} from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import Box from "@mui/material/Box";
import {ListItem} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import {httpClient} from "../../../core/HttpClient";
import {StoreContext} from "../../../core/Context";
import Typography from "@mui/material/Typography";
import NumberFormat from "react-number-format";
import ListItemButton from "@mui/material/ListItemButton";
import LocationSelection from "../LocationSelection";

export default function Location({shop}) {
    const { t } = useTranslation();
    const [storeContext] = useContext(StoreContext);
    const [open, setOpen] = useState(false);
    const [addressList, setAddressList] = useState({show: false, loading: false, list: []});

    console.log('[Location]');

    const handleClick = () => {
        setAddressList({show: true, loading: true, list: []});
        const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/address';

        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAddressList({show: true, loading: false, list: res.data});
                } else {
                    setAddressList({show: true, loading: false, list: []});
                }
                setOpen(true);
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    if(storeContext && storeContext.profile){
        return (<>
            {storeContext.outOfDelivery && <Typography variant={"subtitle1"} color={"red"}>*{t('common.message.outOfDeliveryAddress')}</Typography>}
            <Box>
                <ListItem disablePadding>
                    <ListItemButton sx={{
                        border: storeContext.outOfDelivery?'1px solid red':'1px solid rgba(26, 188, 156, 0.5)',
                        borderRadius: '4px'
                    }} onClick={handleClick}>
                        <ListItemAvatar>
                            {storeContext.outOfDelivery && <LocationOffIcon color={"primary"} sx={{ fontSize: 30 }} />}
                            {!storeContext.outOfDelivery && <LocationOnIcon color={"primary"} sx={{ fontSize: 30 }} />}
                        </ListItemAvatar>
                        <ListItemText primary={storeContext.profile.name}
                                      secondaryTypographyProps={{ component: 'div' }}
                                      secondary={<Stack>
                                          <Typography><NumberFormat value={storeContext.profile.mobile} displayType={'text'} format="###-###-####" /></Typography>
                                          <Typography>{storeContext.profile.landmark}</Typography>
                                      </Stack>} />
                    </ListItemButton>
                </ListItem>
            </Box>
            <Box>
                {open && <LocationSelection list={addressList.list}
                                            open={open}
                                            shop={shop}
                                            onClose={handleClose} />}
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={addressList.loading} >
                    <CircularProgress color="inherit" size={20}/>
                </Backdrop>
            </Box>
        </>);
    } else {
        return (<Box></Box>);
    }
};