import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Typography from "@mui/material/Typography";
import {useHistory, useParams} from "react-router-dom";
import {httpClient} from "../../core/HttpClient";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import MyReview from "./MyReview";
import {Rating} from "@mui/material";
import ReviewList from "./ReviewList";
import {getNFormat} from "../NFormatter";
import liff from "@line/liff";

export default function ReviewPage() {
    const { cid, mode } = useParams();
    const history = useHistory();
    const [shopInfo, setShopInfo] = useState({});
    const [myReview, setMyReview] = useState({rate: 0, message: 0, recommends:[], images: [], replyMessages:[], customer: {display: ''}, modified: new Date()});
    const [loading, setLoading] = useState(true);
    const [reviewSummary, setReviewSummary] = useState({score: 0, rate: 0,count: 0, count1: 0, count2: 0, count3: 0, count4: 0, count5: 0, });

    console.log('ReviewPage');
    useEffect(() => {
        console.log('[ReviewPage]');
        let tasks = [
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account'),
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account/review'),
        ];
        if(localStorage.getItem('token')){
            tasks.push(httpClient.get(process.env.REACT_APP_API_BASE_URL + '/secure/review/me'));
            tasks.push(httpClient.get(process.env.REACT_APP_API_BASE_URL + '/secure/customer/profile'));
        }
        Promise.all(tasks).then(rs=> {
            let shopInfo = {};
            if (rs[0] && rs[0].data) {
                shopInfo = rs[0].data;
            }

            if (rs[1] && rs[1].data) {
                setReviewSummary({
                    rate: rs[1].data.reviewRate,
                    score: rs[1].data.reviewScore,
                    count: rs[1].data.reviewCount,
                    count1: rs[1].data.reviewCount1,
                    count2: rs[1].data.reviewCount2,
                    count3: rs[1].data.reviewCount3,
                    count4: rs[1].data.reviewCount4,
                    count5: rs[1].data.reviewCount5,
                });
            }

            if(rs[2] && rs[2].data){
                let rw = rs[2].data;
                setMyReview({
                    rate: rw.rate,
                    message: rw.message || '',
                    recommends: rw.recommends || [],
                    images: rw.images || [],
                    replyMessages: rw.replyMessages || [],
                    customer: rw.customer,
                    modified: rw.modified
                });
            }

            setShopInfo(shopInfo);
            setLoading(false);
        });
    }, [cid]);

    const loadData = () => {
        let tasks = [
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/publish/account/review'),
        ];
        Promise.all(tasks).then(rs=> {
            if (rs[0] && rs[0].data) {
                setReviewSummary({
                    rate: rs[0].data.reviewRate,
                    score: rs[0].data.reviewScore,
                    count: rs[0].data.reviewCount,
                    count1: rs[0].data.reviewCount1,
                    count2: rs[0].data.reviewCount2,
                    count3: rs[0].data.reviewCount3,
                    count4: rs[0].data.reviewCount4,
                    count5: rs[0].data.reviewCount5,
                });
            }
        });
    };

    const handleBack = () => {
        let _cid = cid || localStorage.getItem('cid');
        history.push(`/hm/${mode}/${_cid}`);
    };

    const handleReviewChange = (value, myReview) => {
        if(value){
            loadData();
            setMyReview(myReview);
        } else {
            let ua = navigator.userAgent || navigator.vendor || window.opera;
            let isInAppBrowser =  (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1)
                || (ua.indexOf("Instagram") > -1);
            // Facebook, Instagram In-App Browser detect.
            if(isInAppBrowser && ["ios","android"].indexOf(liff.getOS()) > -1){
                // window.location.href = `line://app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}/review?rate=${myReview.rate}`;
                window.location.href = `https://line.me/R/app/${process.env.REACT_APP_LINE_LIFF_ID}?liff.state=/${mode}/${cid}/review?rate=${myReview.rate}`;
            } else {
                // user liff.login because open Line App to login (if use window.location.href = url; will go to Line web login page)
                // Note: LINE Liff login (Callback URL must start with same prefix of liff Endpoint URL (/hm)
                const redirectUri = `${process.env.REACT_APP_BASE_URL}/hm/login?cid=${cid}&mode=${mode}&rate=${myReview.rate}`;
                liff.login({ redirectUri: redirectUri});
            }
        }
    };

    return (
        <div>
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <Box style={{background:"#f7f7f7"}}>
                    {shopInfo.name &&
                        <HelmetProvider>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>{shopInfo.name.i18n.th}</title>
                            </Helmet>
                        </HelmetProvider>
                    }
                    <header>
                        <AppBar elevation={0}
                                color="light"
                                position="static"
                                sx={{borderBottom: '1px solid #e7e7e7'}}>
                            <Toolbar>
                                <IconButton edge="start" color="inherit"
                                            onClick={handleBack}
                                            aria-label="menu" sx={{ mr: 2 }}>
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    <Box display="flex" justifyContent="center" alignItems={"center"}>
                                        <Box mr={1}><Typography variant={"body1"}><b>{reviewSummary.score}</b></Typography></Box>
                                        <Rating
                                            name="rate"
                                            value={reviewSummary.rate}
                                            readOnly={true}
                                            precision={0.5}
                                        />
                                        <Box ml={1}><Typography variant={"body1"}>({getNFormat(reviewSummary.count)})</Typography></Box>
                                    </Box>
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    </header>
                    <MyReview data={myReview} onChange={handleReviewChange}></MyReview>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <ReviewList summary={reviewSummary} myReview={myReview} shopInfo={shopInfo} />
                    </Box>
                </Box>
            }
        </div>
    );
}
