import dayjs from 'dayjs';

export function getCache(cid) {
    let cacheData = JSON.parse(localStorage.getItem('cache'));
    if(cacheData && cacheData.cid === cid){
        if(dayjs().diff(cacheData.date, 'hour', true) > 2){
            cacheData = {cid: cid, date: new Date(), location: null, cart: null, deliver: null, notify: false, visited: false};
        }
    } else {
        cacheData = {cid: cid, date: new Date(), location: null, cart: null, deliver: null, notify: false, visited: false};
    }

    return cacheData;
}

export function saveCache(cacheData) {
    cacheData.date = new Date();
    localStorage.setItem('cache', JSON.stringify(cacheData));
}

export function saveNotify(cid) {
    let cacheData = JSON.parse(localStorage.getItem('cache'));
    if(cacheData && cacheData.cid === cid){
        if(dayjs().diff(cacheData.date, 'hour', true) > 2){
            cacheData = {cid: cid, date: new Date(), location: null, cart: null, deliver: null, notify: false, visited: false};
        }
    } else {
        cacheData = {cid: cid, date: new Date(), location: null, cart: null, deliver: null, notify: false, visited: false};
    }
    cacheData.notify = true;
    localStorage.setItem('cache', JSON.stringify(cacheData));
}