import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import NumberFormat from 'react-number-format';
import {httpClient} from "../../../core/HttpClient";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {useHistory, useParams} from "react-router-dom";

const PhoneFormatCustom = React.forwardRef(function PhoneFormatCustom(props, ref) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            type="tel"
            format="###-###-####"
            isNumericString
        />
    );
});

export default function MyInformation() {
    const { t } = useTranslation();
    const { cid, mode } = useParams();
    const history = useHistory();
    const [contact, setContact] = useState({firstName: '', lastName: '', gender: 'male', mobile: ''});
    const [error, setError] = useState({firstName: false, mobile: false});
    const [open, setOpen] = useState({success: false, fail: false});

    useEffect(() => {
        console.log('MyInformation');
        let url = process.env.REACT_APP_API_BASE_URL + `/secure/customer/profile`;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setContact({
                        _id: res.data._id,
                        firstName: res.data.firstName,
                        lastName: res.data.lastName,
                        gender: res.data.gender,
                        mobile: res.data.mobile,
                        pictureUrl: res.data.pictureUrl
                    });
                }
            });
    }, []);

    const handleSave = () => {
        if(_validation()){
            const url = process.env.REACT_APP_API_BASE_URL + '/secure/customer/profile';
            httpClient.put(url, contact)
                .then(res => {
                    setError({displayName: false, mobile: false});
                    setOpen({success: true, fail: false});
                    let customer = {displayName: res.data.displayName, mobile: res.data.mobile, pictureUrl: res.data.pictureUrl};
                    localStorage.setItem('customer', JSON.stringify(customer));
                }).catch(e=>{
                setOpen({success: false, fail: true});
            });
        }
    };

    const handleChange = ({ target }) => {
        setContact({ ...contact, [target.name]: target.value });
    };

    const handleClose = () => {
        setOpen({success: false, fail: false});
    };

    const handleBack = () => {
        history.push(`/hm/${mode}/${cid}/reward`);
    };

    function _validation() {
        let result = true;
        let error = {displayName: false, mobile: false};
        if(!contact.displayName){
            result = false;
            error.displayName = true;
        }
        if(!contact.mobile ||
            contact.mobile.length !== 10 ||
            !contact.mobile.startsWith('0')
        ){
            result = false;
            error.mobile = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    return (
        <Box>
            <header>
                <AppBar elevation={0}
                        color="light"
                        position="static"
                        sx={{borderBottom: '1px solid #e7e7e7'}}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit"
                                    onClick={handleBack}
                                    aria-label="menu" sx={{ mr: 2 }}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1}}>
                            <Box display="flex" justifyContent="start" alignItems={"center"}>
                                <Box><Typography variant={"body1"}>{t('profile.myInformation')}</Typography></Box>
                            </Box>
                        </Typography>
                        {contact._id &&
                        <IconButton edge={"end"}>
                            <Avatar alt={`${contact.firstName} ${contact.lastName}`}
                                    sx={{width: '32px', height: '32px'}}
                                    src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${contact._id}`} />
                        </IconButton>
                        }
                    </Toolbar>
                </AppBar>
            </header>
            <Box p={2}>
                <Box>
                    <TextField id="outlined-basic"
                               label={t('profile.firstName')}
                               variant="outlined"
                               fullWidth={true}
                               error={error.firstName}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               inputProps={{
                                   autoComplete: 'new-password',
                               }}
                               name="firstName" value={ contact.firstName } onChange={ handleChange } required />
                </Box>
                <Box mt={2} >
                    <TextField id="outlined-basic"
                               label={t('profile.lastName')}
                               variant="outlined"
                               fullWidth={true}
                               error={error.lastName}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               inputProps={{
                                   autoComplete: 'new-password',
                               }}
                               name="lastName" value={ contact.lastName } onChange={ handleChange } required />
                </Box>
                <Box mt={2}>
                    <FormControl variant="outlined"
                                 fullWidth={true}>
                        <InputLabel id="reward-select-gender-label">{t('reward.gender')}</InputLabel>
                        <Select
                            native
                            labelId="reward-select-gender-label"
                            label={t('reward.gender')}
                            name="gender"
                            onChange={handleChange}
                            value={contact.gender}>
                            <option value="male">{t('reward.male')}</option>
                            <option value="female">{t('reward.female')}</option>
                            <option value="lgbt">{t('reward.lgbt')}</option>
                        </Select>
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <TextField id="outlined-basic"
                               label={t('profile.mobile')}
                               variant="outlined"
                               fullWidth={true}
                               name="mobile" type="tel"
                               value={contact.mobile}
                               onChange={ handleChange }
                               error={error.mobile}
                               InputLabelProps={{
                                   shrink: true,
                               }}
                               InputProps={{
                                   inputComponent: PhoneFormatCustom,
                               }}
                               required />
                </Box>
                <Box mt={4}>
                    <Button autoFocus variant="contained" fullWidth={true} size="large" disableElevation onClick={handleSave}>
                        {t('common.save')}
                    </Button>
                </Box>
            </Box>
            <Snackbar open={open.success} anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose} >
                <Alert severity="success">
                    {t('common.message.saveSuccess')}
                </Alert>
            </Snackbar>
            <Snackbar open={open.fail} anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose} >
                <Alert severity="error">
                    {t('common.message.saveFail')}
                </Alert>
            </Snackbar>
        </Box>
    );
}
