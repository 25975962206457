import React, { useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import { Context } from "../../core/Context";
import { StoreContext } from "../../core/Context";

const BlankLayout = ({ children}) => {
    const { cid } = useParams();
    const [context, setContext] = useState({cid: cid, location: {}});
    const [storeContext, setStoreContext] = useState({deliverType: '', profile: {name: '', mobile: '', landmark: ''}});
    console.log('[BlankLayout]');
    if(cid){
        localStorage.setItem('cid', cid);
    }

    return (
        <div>
            <Context.Provider value={[context, setContext]}>
                <StoreContext.Provider value={[storeContext, setStoreContext]}>
                    {children}
                </StoreContext.Provider>
            </Context.Provider>
        </div>
    );
};

const BlankLayoutRoute = ({ component: Component, ...rest }) => {

    return (
        <Route
            {...rest}
            render={props => {
                return <BlankLayout><Component {...props} /></BlankLayout>
            }}
        />
    );
};

export default BlankLayoutRoute;