import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import {httpClient} from "../../core/HttpClient";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import {useHistory, useParams} from "react-router-dom";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import {DialogTitle} from "@mui/material";
import {getCache, saveCache} from "../../core/LocalStorageUtil";
import {getDatabaseInstance} from "../../core/Firebase";
import jwt_decode from "jwt-decode";
import {getDatabase, onValue, ref} from "firebase/database";
import {getAuth, signInAnonymously} from "firebase/auth";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import MobileOrderItems from "./MobileOrderItems";
import MobileOrderInfo from "./MobileOrderInfo";
import MobileOrderShop from "./MobileOrderShop";

export default function MobileOrderPaymentPage() {
    const { t } = useTranslation();
    const history = useHistory();
    const { uid, cid } = useParams();
    const [mobileOrder, setMobileOrder] = useState({_account: {image:{}}});
    const [loading, setLoading] = useState(true);
    const [openCancel, setOpenCancel] = useState(false);

    console.log('MobileOrderPaymentPage');
    useEffect(() => {
        console.log('[MobileOrderPaymentPage]');
        let cacheData = getCache(cid);
        cacheData.mobileOrderUID = uid;
        saveCache(cacheData);

        const app = getDatabaseInstance(cid);
        console.log('app', app.name);
        let token = localStorage.getItem('token');
        if(token){
            let decoded = jwt_decode(token);
            let customerId = decoded.customer;
            let key = 'customers/'+customerId;
            const db = getDatabase(app);
            const auth = getAuth(app);
            signInAnonymously(auth)
                .then(() => {
                    const statusRef = ref(db, key);
                    onValue(statusRef, (snapshot) => {
                        // const data = snapshot.val();
                        let tasks = [
                            httpClient.get(process.env.REACT_APP_API_BASE_URL + `/secure/customer/mobile/order/${uid}`),
                        ];
                        Promise.all(tasks)
                            .then(rs=>{
                                if(rs[0] && rs[0].status === 200 && rs[0].data){
                                    if(rs[0].data.state === 'success'){
                                        let cacheData = getCache(cid);
                                        cacheData.mobileOrderUID = null;
                                        saveCache(cacheData);
                                        history.push(`/hm/m/${cid}/${rs[0].data.uid}/i`);
                                    }

                                    let diff = dayjs(new Date()).diff(rs[0].data.orderDate, 'minutes');
                                    if(diff > 30){
                                        let cacheData = getCache(cid);
                                        cacheData.mobileOrderUID = null;
                                        saveCache(cacheData);
                                        history.push(`/hm/m/${cid}`);
                                    } else {
                                        setMobileOrder(rs[0].data);
                                    }
                                } else {
                                    let cacheData = getCache(cid);
                                    cacheData.mobileOrderUID = null;
                                    saveCache(cacheData);
                                    history.push(`/hm/m/${cid}`);
                                }
                                setLoading(false);
                            });
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [history, cid, uid]);


    const handleCancel = () => {

        const url = process.env.REACT_APP_API_BASE_URL + `/secure/customer/mobile/order/cancel/${uid}`;
        httpClient.delete(url, { method: 'DELETE' })
            .then(res => {
                // done
            }).catch(e=>{
                    console.log(e);
            });
        let cacheData = getCache(cid);
        cacheData.mobileOrderUID = null;
        saveCache(cacheData);
        history.push(`/hm/m/${cid}`);
    };
    const handleCloseCancel = () => {
        setOpenCancel(false)
    };

    return (
        <div style={{background: '#f7f7f8'}}>
            {mobileOrder._account.name &&
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{mobileOrder._account.name.i18n.th}</title>
                </Helmet>
            </HelmetProvider>
            }
            {loading &&
                <Box display="flex" justifyContent="center" mt={4} sx={{background: 'white'}}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MobileOrderShop value={mobileOrder} />
                </Grid>
                <Grid item xs={12}>
                    <MobileOrderInfo value={mobileOrder} cid={cid} />
                </Grid>
                <Grid item xs={12}>
                    <Card sx={{borderRadius: '0 0 5px 5px', border: 0}} variant={"outlined"}>
                        <Box p={2}>
                            <Card>
                                <MobileOrderItems value={mobileOrder}></MobileOrderItems>
                                <Divider />
                                <Box m={2}>
                                    {mobileOrder.deliverType === 'DINE-IN' &&
                                        <Box display={"flex"} justifyContent={"center"}>
                                            <RestaurantIcon/>
                                            <Box ml={1}>
                                                <Typography>
                                                    {t('DINE-IN')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                    {mobileOrder.deliverType === 'TAKEAWAY' &&
                                        <Box display={"flex"} justifyContent={"center"}>
                                            <ShoppingBagIcon/>
                                            <Box ml={1}>
                                                <Typography>
                                                    {t('TAKEAWAY')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    }
                                </Box>
                            </Card>
                        </Box>
                        <Box p={2} pt={0} mb={2}>
                            <Button variant="contained" color={"grey"} disableElevation={true} fullWidth={true}
                                onClick={e=>setOpenCancel(true)}>
                                {t('mobileOrder.cancel')}
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            }

            <Dialog
                fullWidth
                open={openCancel}
                onClose={handleCloseCancel}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{t('mobileOrder.confirmCancel')}</DialogTitle>
                <Box m={2}>
                    <Box mb={2}>
                        <Button variant="contained"
                                color="secondary"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                onClick={(e) => handleCancel(e)}>
                            {t('mobileOrder.cancel')}
                        </Button>
                    </Box>
                    <Box>
                        <Button variant="contained"
                                color="grey"
                                onClick={handleCloseCancel}
                                fullWidth={true}
                                size="large"
                                disableElevation>
                            {t('common.close')}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    );
}
